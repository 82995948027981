import {faTimes} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useState} from "react"
import {FormControl, FormGroup, FormLabel} from "react-bootstrap"
import {ButtonC} from ".."
import cn from 'classnames'
import styles from './input-field.module.css'


export const TextField = ({
	label,
	errors,
	name,
	className,
	disabled,
	required,
	size = 'full',
	defaultValue,
	onChange = () => {},
	iconClick = () => {},
	icon,
	register,
	...props
}) => {
	const [value, setValue] = useState(defaultValue ?? '')
	return (
		<FormGroup className={cn(styles.wrapper, className, {
			[styles.fullWidth]: size === 'full',
			[styles.halfWidth]: size === 'half'
		})}>
			{label && <FormLabel className={styles.label}>
				{label}{required && <span className='asterix'> *</span>}
			</FormLabel>}
			<div>
				<FormControl
					{...register}
					value={value}
					onChange={(event) => {
						if (defaultValue) setValue(defaultValue)
						setValue(event.target.value)
						onChange(event)
					}}
					{...props}
					disabled={disabled}
				/>
				<div className={cn(styles.resetIcon, {
					[styles.resetIconNoLabel]: !label
				})}>
					{(!disabled && value !== '') && <ButtonC
						variant='download'
						onClick={(e) => {
							e.target.value = ''
							setValue('')
							onChange(e)
						}}
					>
						<FontAwesomeIcon icon={faTimes} />
					</ButtonC>}
					{icon && <ButtonC
						variant='download'
						onClick={iconClick}
					>
						<FontAwesomeIcon icon={icon} />
					</ButtonC>}
				</div>
			</div>
			{errors && <FormLabel style={{color: 'red'}}>{errors.message}</FormLabel>}
		</FormGroup>
	)
}
import {useState} from "react"
import {Col, Form, Row} from "react-bootstrap"
import {useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"
import {ButtonC, InputField} from "../../components"
import {notifyError, notifyInfo, notifySuccess} from "../../components/notify"
import {useApi} from '../../hooks'

export const RestorePassword = () => {
	const {handleSubmit, control, formState: {errors}} = useForm()
	const history = useHistory()
	const {t} = useTranslation()
	const api = useApi()
	const [isEmail, setIsEmail] = useState(false)
	const sendEmail = async () => {
		const email = {
			email: document.querySelector('#email').value
		}
		try {
			await api.put('users/reset-password-code', {body: email})
				.then((res) => {
					setIsEmail(true)
					notifyInfo(t('profile.restore-password.email_correct'))
				})
		} catch (err) {
			notifyError(t('profile.restore-password.uncorrect_email'))
			setIsEmail(false)
			throw new Error(err.message)
		}
	}
	const changePassword = async (data) => {
		try {
			await api.put('users/reset-password', {body: data})
				.then((res) => {
					notifySuccess(t('profile.restore-password.password_changed'))
					history.push('/sign-in')
				})
		} catch (err) {
			notifyError(t('profile.restore-password.uncorrect_code'))
		}
	}
	return (
		<Form onSubmit={handleSubmit(changePassword)}>
			<Row>
				<InputField
					label={t('profile.restore-password.email_label')}
					control={control}
					name='email'
					rules={{required: t('error_message.no_empty')}}
					errors={errors.email}
					id='email'
				/>
				{!isEmail && <Row xs={1} md={2}>
					<Col className='ps-4'>
						<ButtonC variant='primary' onClick={sendEmail}>
							{t('profile.restore-password.email_send')}
						</ButtonC>
					</Col>
					<Col className='d-flex justify-content-end'>
						<ButtonC variant='close' onClick={() => history.goBack()}>
							{t('profile.restore-password.back')}
						</ButtonC>
					</Col>

				</Row>}
			</Row>
			{isEmail && <> <InputField
				label={t('profile.restore-password.password_label')}
				control={control}
				type="password"
				name='new_password'
				rules={{required: t('error_message.no_empty')}}
				errors={errors.new_password}
			/>
				<InputField
					label={t('profile.restore-password.code_label')}
					control={control}
					name='code'
					rules={{required: t('error_message.no_empty')}}
					errors={errors.code}
				/>
				<Row xs={1} md={2}>
					<Col className='ps-3'>
						<ButtonC variant='primary' type='submit'>
							{t('profile.restore-password.send')}
						</ButtonC>
					</Col>
					<Col className='d-flex justify-content-end'>
						<ButtonC variant='close' onClick={() => history.goBack()}>
							{t('profile.restore-password.back')}
						</ButtonC>
					</Col>
				</Row>
			</>}
		</Form>
	)
}

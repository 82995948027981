import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Layout } from '../../../app/layout'
import { ButtonC, TableComponent } from '../../../components'
import { api } from '../../../service/old-api-2'
import dateFormat from "dateformat";

export const A1ActiveSchoolDetails = () => {
    const params = useParams();
    const [detailsData, setDetailsData] = useState()
    const {t} = useTranslation()
    const getDetailsData = () => {
        api.get(`reports/schools-activity-by-district/${params.id}/`).then((response) => {
            setDetailsData(response)
        })
    }

    useEffect(() => {
        getDetailsData()
    }, [])
  return (
<>
<Layout>
<Link to={{
					pathname: `/report`,
		}} ><ButtonC variant="primary">{t('report.a1.back_to_report')}</ButtonC></Link>
<TableComponent>
        <thead>
            <tr>
                <th>#</th>
                <th>Школа</th>
                <th>Статус</th>
                <th>Дата</th>
                <th>ФИО</th>
                <th>Телефон</th>
            </tr>
        </thead>
        <tbody>
           {detailsData?.map((data) => {
            return (
                <tr  key={data?.school_id}>
                <td data-label="№">{data?.school_id}</td>
                <td data-label="Школа">{data?.school_name}</td>
                <td data-label="Статус">{data?.is_d10_verified ? <FontAwesomeIcon icon={faCheck} style={{color: 'forestgreen'}} /> : <FontAwesomeIcon icon={faBan} style={{color: 'orangered'}} />}</td>
                <td data-label="Дата">{dateFormat(data?.d10_verified_date, "dd-mm-yyyy")}</td>
                <td data-label="ФИО">{data?.fullname}</td>
                <td data-label="Телефон">{data?.phone}</td>
            </tr>
            )
           })}
        </tbody>
    </TableComponent>
</Layout>
</>
  )
}

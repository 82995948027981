import React from 'react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ButtonC, TableComponent } from '../../../components'
import { api } from '../../../service/old-api-2';

export const A1ActiveSchools = () => {
    const [activitySchools, setActivitySchools] = useState();
    const {t} = useTranslation()
    const getActivitySchools = () => {
        api.get('reports/schools-activity/').then((response) => {
            setActivitySchools(response)
        })
    }

    useEffect(() => {
        getActivitySchools()
    }, [])
  return (
    <TableComponent>
        <thead>
            <tr>
                <th>№</th>
                <th>Район / Область</th>
                <th>Всего числиться</th>
                <th>Потвержденный</th>
                <th>Не потвержденный</th>
                <th>Действие</th>
            </tr>
        </thead>
        <tbody>
            {activitySchools?.map((schools) => {
                return (
                    <>
                     <tr data-label="№" key={schools?.id}>
                <td data-label="Район / Область">{schools?.district_name}</td>
                <td data-label="Всего числиться">{schools?.total}</td>
                <td data-label="Потвержденный">{schools?.verified}</td>
                <td data-label="Не потвержденный">{schools?.not_verified}</td>
                <td data-label="Дейтствие"><Link to={{
					pathname: `/a1-activity-schools/${schools?.district_id}`,
		}} ><ButtonC variant="primary">{t('report.d10-district.check')}</ButtonC></Link></td>
            </tr>
        </>
                )
            })}
        </tbody>
    </TableComponent>
  )
}

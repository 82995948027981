import {BookSingle} from "./book-single";
import {useAppSelector} from "../../hooks";

export const BookList = ({books}) => {
    const cartItems = useAppSelector(
        (state) => state['bookstore']['cart']
    )
    const canAddBookToCart = useAppSelector(
        (state) => state['bookstore']['canAddBookToCart']
    )
    return (
        <>
            <div className="row">
                {books?.map((book) => (
                    <BookSingle
                        book={book}
                        key={book.id}
                        canAddBookToCart={canAddBookToCart}
                        cartItems={cartItems}
                    />
                ))}
            </div>
        </>
    )
}

import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { TableComponent } from '../../../components';
import { storage } from '../../../service';
import { api } from '../../../service/old-api-2';

export const B1FillingReportRegion = () => {
    const [fillingReport, setFillingReport] = useState();
    const {t} = useTranslation()
    const getFillingReport = () => {
        api.get(`reports/filling-report/?region_id=${storage.read('region_id')}`).then((response) => {
            setFillingReport(response)
        })
    }
    let index = 1;
    useEffect(() => {
        getFillingReport()
    }, [])
  return ( <TableComponent>
    <thead>
       <tr>
        <th>№</th>
       <th>{t('profile.region')}</th>
        <th>{t('profile.district')}</th>
        <th>{t('profile.school_name')}</th>
        <th>{t('report.d10.title')}</th>
        <th>Отчеты Д10 кыргызский язык обучения</th>
        <th>Отчеты Д10 русский язык обучения</th>
        <th>Отчеты Д10 узбекский язык обучения </th>
        <th>Отчеты Д10 таджикский язык обучения </th>
        <th>Данные профиля библ</th>
        <th>ФИО библ</th>
        <th>Email библ</th>
        <th>Телефон библ</th>
       </tr>
    </thead>
    <tbody>
        {fillingReport?.map((report) => {
            return (
                <>
        <tr key={report?.id}>
            <td>{index++}</td>
            <td>{report?.region_name}</td>
            <td>{report?.district_name}</td>
            <td>{report?.school_name}</td>
            <td>{report?.is_d10_verified ? <FontAwesomeIcon icon={faCheck} style={{color: 'forestgreen'}} /> : <FontAwesomeIcon icon={faBan} style={{color: 'orangered'}} />}</td>
            <td>{report?.is_d10_ky_verified ? <FontAwesomeIcon icon={faCheck} style={{color: 'forestgreen'}} /> : <FontAwesomeIcon icon={faBan} style={{color: 'orangered'}} />}</td>
            <td>{report?.is_d10_ru_verified ? <FontAwesomeIcon icon={faCheck} style={{color: 'forestgreen'}} /> : <FontAwesomeIcon icon={faBan} style={{color: 'orangered'}} />}</td>
            <td>{report?.is_d10_uz_verified ? <FontAwesomeIcon icon={faCheck} style={{color: 'forestgreen'}} /> : <FontAwesomeIcon icon={faBan} style={{color: 'orangered'}} />}</td>
            <td>{report?.is_d10_tj_verified ? <FontAwesomeIcon icon={faCheck} style={{color: 'forestgreen'}} /> : <FontAwesomeIcon icon={faBan} style={{color: 'orangered'}} />}</td>
            <td>{report?.is_library_info_filled_out ? <FontAwesomeIcon icon={faCheck} style={{color: 'forestgreen'}} /> : <FontAwesomeIcon icon={faBan} style={{color: 'orangered'}} />}</td>
            <td>{report?.has_name ? <FontAwesomeIcon icon={faCheck} style={{color: 'forestgreen'}} /> : <FontAwesomeIcon icon={faBan} style={{color: 'orangered'}} />}</td>
            <td>{report?.has_email ? <FontAwesomeIcon icon={faCheck} style={{color: 'forestgreen'}} /> : <FontAwesomeIcon icon={faBan} style={{color: 'orangered'}} />}</td>
            <td>{report?.has_phone ? <FontAwesomeIcon icon={faCheck} style={{color: 'forestgreen'}} /> : <FontAwesomeIcon icon={faBan} style={{color: 'orangered'}} />}</td>
        </tr>
                </>
            )
        })}
    </tbody>
</TableComponent>
  )
}

import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import langKG from './locales/kg.i18n.json'
import langRu from './locales/ru.i18n.json'
import langUZ from './locales/uz.i18n .json'
import {storage} from './service';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next)
  .init({
    fallbackLng: storage.read('lang') || 'ru',
    debug: true,
    resources: {
      ky: {translation: langKG},
      ru: {translation: langRu},
      uz: {translation: langUZ},
    },
    detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie']
    },
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const Chart = ({labels, datas, backgroundColors, borderColors}) => {
  const data = {
    labels: labels, // ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: '',
        data: datas,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
      }
    ]
    // datasets: [
    //   {
    //     label: "# of Votes",
    //     data: [12, 19, 3, 5, 2, 3],
    //     backgroundColor: [
    //       "rgba(255, 99, 132, 0.2)",
    //       "rgba(54, 162, 235, 0.2)",
    //       "rgba(255, 206, 86, 0.2)",
    //       "rgba(75, 192, 192, 0.2)",
    //       "rgba(153, 102, 255, 0.2)",
    //       "rgba(255, 159, 64, 0.2)",
    //     ],
    //     borderColor: [
    //       "rgba(255, 99, 132, 1)",
    //       "rgba(54, 162, 235, 1)",
    //       "rgba(255, 206, 86, 1)",
    //       "rgba(75, 192, 192, 1)",
    //       "rgba(153, 102, 255, 1)",
    //       "rgba(255, 159, 64, 1)",
    //     ],
    //     borderWidth: 1,
    //   },
    // ],
  };
  const pieChart = (
    <Pie
      type="pie"
      width={130}
      height={50}
      options={{
        title: {
          display: true,
          text: "",
          fontSize: 15,
        },
        legend: {
          display: true,
          position: "top",
        },
      }}
      data={data}
    />
  );
  return pieChart;
};
export default Chart;

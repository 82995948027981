import {useState} from "react"
import {Col, Form, Modal} from "react-bootstrap"
import {useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"
import {ButtonC, InputField} from "../../components"
import {notifyError, notifySuccess} from "../../components/notify"
import {storage} from "../../service"
import {useApi} from '../../hooks'

export const PasswordChange = ({
	show,
	setShow,
}) => {
	const {t} = useTranslation()
	const {control, handleSubmit, formState: {errors}, reset} = useForm()
	const history = useHistory()
	const api = useApi()
	const [newPasswordCorrect, setNewPasswordCorrect] = useState(false)
	const onClose = () => {
		setShow(false)
		reset()
		setNewPasswordCorrect(false)
	}
	const updatePassword = async (data) => {
		if (data.new_password === data.new_password1) {
			setNewPasswordCorrect(true)
		}
		if (newPasswordCorrect === false) {
			notifyError(t('profile.password-change.uncorrect_password'))
			return
		}
		try {
			await api.put('users/change_password/', {
				body: {
					current_password: data.current_password,
					new_password: data.new_password
				}
			})
			reset()
			setShow(false)
			notifySuccess(t('profile.password-change.changed'))
			storage.clearAll()
			history.push('/sign-in')
		} catch (err) {
			notifyError(t('profile.password-change.uncorrect_password'))
			throw new Error(err)
		}
	}
	return (
		<Modal
			show={show}
			onHide={onClose}
			keyboard={false}
			size="lg"
		>
			<Modal.Header>
				<Modal.Title>{t('profile.password-change.title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(updatePassword)}>
					<InputField
						type='password'
						control={control}
						name='current_password'
						rules={{
							required: t('error_message.no_empty'),
						}}
						label={t('profile.password-change.current_password')}
						errors={errors.current_password}
					/>
					<InputField
						type='password'
						control={control}
						name='new_password1'
						rules={{required: t('error_message.no_empty')}}
						label={t('profile.password-change.new_password1')}
						errors={errors.new_password1}
					/>
					<InputField
						type='password'
						control={control}
						name='new_password'
						rules={{required: t('error_message.no_empty')}}
						label={t('profile.password-change.new_password')}
						errors={errors.new_password}
					/>
					<Col className='moduleBtns'>
						<ButtonC variant="close" onClick={onClose}>
							{t('btn.close')}
						</ButtonC>
						<ButtonC variant="add" type='submit'>{t('btn.send')}</ButtonC>
					</Col>
				</Form>
			</Modal.Body>
		</Modal>
	)
}
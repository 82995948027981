import {createSlice} from '@reduxjs/toolkit';

const menuReducer = createSlice({
	name: 'menu',
	initialState: {
		show: true
	},
	reducers: {
		setShow: (state, actions) => {
			state.show = actions.payload
		}
	}
})

export const {setShow} = menuReducer.actions

export default menuReducer.reducer
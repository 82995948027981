import React, { useState, useEffect } from 'react'
import { TableComponent } from '../../../components';
import { api } from '../../../service/old-api-2';

export const G5GivenBooks = () => {
    const [givenbooks, setGivenBooks] = useState();
    const getGivenBooks = () => {
        api.get('teacher-books/').then((response) => {
            setGivenBooks(response)
        })
    }

    useEffect(() => {
        getGivenBooks()
    }, [])
    let index = 1;
  return (
    <TableComponent className="mt-5">
        <thead>
         <tr>
            <th>№</th>
            <th className='text-center'>id</th>
            <th className='text-center'>ФИО преподавателя</th>
            <th className='text-center'>Наименование и автор учебника</th>
            <th>Количество выданных</th>
         </tr>
        </thead>
        <tbody>
            {givenbooks && givenbooks?.map((givenbook) => {
                return (
                    <tr key={givenbook.id}>
                        <td>{index++}</td>
                    <td>{givenbook.id}</td>
                       <td>{givenbook.teacher}</td>
                       <td>{givenbook?.school_book?.book?.name} - {givenbook?.school_book?.book?.author}</td>
                       <td>{givenbook?.count}</td>
                    </tr>
                )
            })}

        </tbody>
    </TableComponent>
  )
}

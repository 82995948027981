import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import { ButtonC, TableComponent } from '../../../components';
import { D10TBody } from './D10TBody';
import { D10THead } from './D10THead';
import './styles.css'
import { useTranslation } from 'react-i18next';
import { storage } from '../../../service'
import { PdfHeaderToD10 } from '../../../components/pdf-header/pdf-header-to-d10';
import { PdfFooterToD10 } from '../../../components/pdf-header/pdf-footer-to-d10';
export const D10Table = ({ report, language }) => {
  const componentRef = useRef(null)
  const { t } = useTranslation();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  const role = storage.read('role')
  return (
    <div className='d-flex flex-column'>
      <ButtonC className="no-print mt-3" variant='download' onClick={handlePrint} style={{ width: 'max-content', float: 'left', marginBottom: '15px', backgroundColor: 'red', color: '#fff' }}>
        {t('report.download_pdf_file')} <FontAwesomeIcon style={{ color: '#fff' }} icon={faFilePdf} />
      </ButtonC>
      <TableComponent id='pdftable' responsive={true} ref={componentRef}>
        {role === 'librarian' && <PdfHeaderToD10 language={language} />}
        <div id='pdfdiv'>
          <D10THead report={report} />
          <D10TBody report={report} />
        </div>
        {role === 'librarian' && <PdfFooterToD10 language={language} />}
      </TableComponent>
    </div>
  )
}

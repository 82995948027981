import React, {useState} from 'react'
import {Col, Form, Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {useTranslation} from "react-i18next"
import {ButtonC, InputField, SelectField} from '../../../components'
import {useAppSelector} from '../../../hooks'
import {langOptions, years} from '../../../lib/helper'
import { storage } from '../../../service'


export const FilterModal = ({show, setShow, onChange}) => {
    const {t} = useTranslation()
    const {control, register, formState: {errors}} = useForm()
    const categories = useAppSelector((state) => state.categories.categories)
    const categoriesOptions = categories.map((c) => {
        return {
            label: c.name, options: c?.subjects?.map((s) => {
                return {label: s.name, value: s.id}
            })
        }
    })
    const [language, setLanguage] = useState('kyrgyz')
    const [subjectId, setSubjectId] = useState()
    const [bookName, setBookName] = useState()
    const [bookAuthor, setBookAuthor] = useState()
    const [bookYear, setBookYear] = useState()
    const [bookYearLte, setBookYearLte] = useState()
    const [bookYearGte, setBookYearGte] = useState()
    const [part, setPart] = useState(1)
    const [isRecommended, setIsRecommended] = useState()
    const [isForRenting, setIsForRenting] = useState()
    const onClose = () => {
        setShow(false)
    }
    const learnLanguage = storage.write('language', language)
    const submit = (e) => {
        e.preventDefault();
        setShow(false);
        onChange({
            'language': language,
            'subject_id': subjectId,
            'book_name': bookName,
            'book_author': bookAuthor,
            'book_year': bookYear,
            'book_year_lte': bookYearLte,
            'book_year_gte': bookYearGte,
            'part': part,
            'is_recommended': isRecommended,
            'is_for_renting': isForRenting
        });
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            keyboard={false}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>{t('report.filter')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={submit} setShow={false}>
                    <Col>
                        <SelectField
                            options={[
                                ...langOptions
                            ]}
                            register={{...register('book__language')}}
                            onChange={(event) => setLanguage(event.target.value)}
                        />
                    </Col>
                    <Col>
                        <SelectField
                            register={{...register('subject')}}
                            optGrpHead={t('school-books.add.categories')}
                            optionGroup={[
                                ...categoriesOptions
                            ]}
                            onChange={(event) => setSubjectId(event.target.value)}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type="text"
                            placeholder={t('school-books.book_name')}
                            name='b_name'
                            style={{width: '100%'}}
                            control={control}
                            onChange={(event) => setBookName(event.target.value)}
                        />
                    </Col>
                    <Col>
                        <InputField
                            type="text"
                            placeholder={t('school-books.author')}
                            name='b_author'
                            control={control}
                            onChange={(event) => setBookAuthor(event.target.value)}
                        />
                    </Col>
                    <Col>
                        <SelectField
                            register={{...register('book_year')}}
                            options={[
                                {value: '', label: t('school-books.book_year')},
                                ...years.map((y) => ({value: y, label: y}))
                            ]}
                            onChange={(event) => setBookYear(event.target.value)}
                        />
                    </Col>
                    <Col>
                        <SelectField
                            register={{...register('book_year_lte')}}
                            options={[
                                {value: '', label: t('report.less')},
                                ...years.map((y) => ({value: y, label: y}))
                            ]}
                            onChange={(event) => setBookYearLte(event.target.value)}
                        />
                    </Col>
                    <Col>
                        <SelectField
                            register={{...register('book_year_gte')}}
                            options={[
                                {value: '', label: t('report.more')},
                                ...years.map((y) => ({value: y, label: y}))
                            ]}
                            onChange={(event) => setBookYearGte(event.target.value)}
                        />
                    </Col>
                    <Col>
                        <SelectField
                            register={{...register('part')}}
                            options={[
                                {value: 1, label: t('report.part-one')},
                                {value: '', label: t('report.all-parts')},
                            ]}
                            onChange={(event) => setPart(event.target.value)}
                        />
                    </Col>
                    <Col>
                        <SelectField
                            register={{...register('is_recommended')}}
                            options={[
                                {value: '', label: t('report.isRecommended.recommended')},
                                {value: true, label: t('report.isRecommended.yes')},
                                {value: false, label: t('report.isRecommended.no')}
                            ]}
                            onChange={(event) => setIsRecommended(event.target.value)}
                        />
                    </Col>
                    <Col>
                        <SelectField
                            register={{...register('is_for_renting')}}
                            options={[
                                {value: '', label: 'аренда'},
                                {value: true, label: t('report.isRecommended.yes')},
                                {value: false, label: t('report.isRecommended.no')}
                            ]}
                            onChange={(event) => setIsForRenting(event.target.value)}
                        />
                    </Col>
                    <Col className='moduleBtns'>
                        <ButtonC variant="close" onClick={onClose}>
                            {t('btn.close')}
                        </ButtonC>
                        <ButtonC variant="add" type="submit">{t('report.d10-filter')}</ButtonC>
                    </Col>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

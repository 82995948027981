import {useEffect, useState} from 'react'

export const useScroll = () => {
  const [scrollY, setScrollY] = useState(0)

  const handleScroll = () => {
    setScrollY(document.body.scrollTop)
  }

  useEffect(() => {
    document.body.addEventListener('scroll', handleScroll)

    return () => document.body.removeEventListener('scroll', handleScroll)
  }, [])
  return scrollY
}
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { storage } from '../../service'
import {api} from '../../service/old-api-2'

const namespace = 'teacher'

const fetchTeacher = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let teachers

		try {
			teachers = await api.get('teachers/')

		} catch (error) {
			throw new Error(error)
		}

		return {teachers}
	})

const addTeacher = createAsyncThunk(
	`${namespace}/add`,
	async (data) => {
		let teachers

		try {
			teachers = await api.post('teachers/', {body: data})
		} catch (error) {
			throw new Error(error)
		}
		return {teachers}
	})

const updateTeacher = createAsyncThunk(
	`${namespace}/update`,
	async ({id, data}) => {
		let teachers

		try {
			teachers = await api.put(`teachers/${id}/`, {body: data})
		} catch (error) {
			throw new Error(error)
		}
		return {teachers}
	})

const deleteTeacher = createAsyncThunk(
	`${namespace}/delete`,
	async (id) => {
		try {
			await api.delete(`teachers/${id}/fire/`)
		} catch (error) {
			throw new Error(error)
		}
		return {id}
	})

const hireTeacher = createAsyncThunk(
	`${namespace}/hire`,
	async (id) => {
		let teachers

		try {
			teachers = await api.post(`teachers/${id}/hire/`, {body: {}})
		} catch (error) {
			throw new Error(error)
		}
		return {teachers}
	})

const searchTeachers = createAsyncThunk(
	`${namespace}/search`,
	async (query) => {
		let teachers
		try {
			teachers = await api.get('teachers', {
				search: query
			})
		} catch (error) {
			throw new Error(error)
		}

		return {teachers}
	})

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		teachers: [],
		searchResult: [],
		loading: false,
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchTeacher.fulfilled, (state, action) => {
				state.teachers = action.payload.teachers
				state.searchResult = action.payload.teachers
			})
			.addCase(searchTeachers.fulfilled, (state, action) => {
				state.searchResult = action.payload.teachers
			})
			.addCase(addTeacher.pending, (state) => {
				delete state.error
				state.loading = true
			})
			.addCase(addTeacher.fulfilled, (state, action) => {
				state.loading = false
				state.teachers.push(action.payload.teachers)
				state.searchResult.push(action.payload.teachers)
			})
			.addCase(addTeacher.rejected, (state) => {
				state.error = 'error'
				state.loading = false
			})
			.addCase(hireTeacher.pending, (state) => {
				delete state.error
				state.loading = true
			})
			.addCase(hireTeacher.fulfilled, (state, action) => {
				state.loading = false
				state.teachers.push(action.payload.teachers)
				state.searchResult.push(action.payload.teachers)
			})
			.addCase(hireTeacher.rejected, (state) => {
				state.error = 'error'
				state.loading = false
			})
			.addCase(deleteTeacher.fulfilled, (state, action) => {
				state.loading = false
				state.teachers = state.teachers.filter((t) => t.id != action.payload.id)
				state.searchResult = state.searchResult.filter((t) => t.id != action.payload.id)
			})
			.addCase(updateTeacher.fulfilled, (state, action) => {
				const index = state.teachers.findIndex((t) => t.id == action.payload.teachers.id)
				state.teachers[index] = {...state.teachers[index], ...action.payload.teachers}
				const index1 = state.searchResult.findIndex((t) => t.id == action.payload.teachers.id)
				state.searchResult[index1] = {...state.searchResult[index], ...action.payload.teachers}
			})
})

const thunks = {searchTeachers, fetchTeacher, addTeacher, hireTeacher, deleteTeacher, updateTeacher}

export {reducer, thunks}

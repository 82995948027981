import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api-2'

const namespace = 'districts'

const fetchDistricts = createAsyncThunk(
    `${namespace}/get`,
    async (region) => {
        let districts

        try {
            districts = await api.get('references/districts/', {
                    search: {
                        region: region
                    }
                }
            )
        } catch (error) {
            throw new Error(error)
        }

        return {districts}
    })

const {reducer} = createSlice({
    name: namespace,
    initialState: {
        districts: [],
    },
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchDistricts.fulfilled, (state, action) => {
                state.districts = action.payload.districts
            })
})

const thunks = {fetchDistricts}

export {reducer, thunks}

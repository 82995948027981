import React, {useState, useEffect} from "react";
import {Col, Form, Modal, Row} from 'react-bootstrap'
import {useForm} from "react-hook-form";
import {notifyError, notifySuccess} from "../../components/notify";
import {gradeEnum, langOptions} from "../../lib/helper";
import {useTranslation} from "react-i18next";
import {ButtonC, InputField, SelectField} from "../../components";
import {useActions, useAppSelector} from '../../hooks'
import {thunks} from './school-news-reducer';
import { thunks as eventThunks } from "../school-events/school-events-reducer";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';

import {thunks as gradeThunks} from '../../app/store/grade-reducer'

const actions = {
  addSchoolNews: thunks.addSchoolNews,
  fetchSchoolEvents: eventThunks.fetchSchoolEvents,
}

export const AddSchoolNews = ({show, setShow}) => {
  const {handleSubmit, register, reset, formState: {errors}, control, setValue} = useForm()
  // const schoolEventTypes = useAppSelector((state) => state.schoolEventTypes.school_event_types)
  const {addSchoolNews} = useActions(actions)
  // const { fetchSchoolEvents } = useActions(actions);
  const school_events = useAppSelector((state) => state.schoolEvents.school_events).filter((event) => !event.schoolnews).map((l) => ({value: l.id, label: l.date + ' / ' + l.name}))
  const {t} = useTranslation()

  const handleClose = () => {
    reset()
    setShow(false)
  };
  // const [body, setBody] = useState('');

  // const school_event_types = schoolEventTypes.map((l) => ({value: l.id, label: l.name, label_kg: l.name_kg}))

  const postSchoolEvent = async (data) => {
    // console.log(data);
    // if (!active) {
    //   notifyError('Выберите учителя из списка')
    //   return
    // }
    const school_new_news = {
      title: data.title,
      body: data.body,
      event: data.event,
      status: data.status,
    }
    const a = await addSchoolNews(school_new_news)
    if (a.payload) {
      reset()
      notifySuccess(`${t('notify.success.added')}`)
      setShow(false)
      // setCurrentTeacher({})
    }
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{t('news.add-news')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(postSchoolEvent)}>
            {/* <Row md={1} xs={1}>
              <Col>
                <InputField
                  control={control}
                  type='date'
                  name='date'
                  label='Дата'
                  rules={{
                    required: t('error_message.no_empty'),
                    // max: {value: 60, message: t('classes.add-class.student_count_error')},
                    // min: {value: 1, message: t('classes.add-class.student_count_error')}
                  }}
                  errors={errors.date}
                />
              </Col>
            </Row> */}
            <Row md={1} xs={1}>
            <Col>
              <SelectField
                  control={control}
                  name="event"
                  register={{
                    ...register('status',
                      {required: t('error_message.no_empty')})
                  }}
                  required={false}
                  optGrpHead="Выберите мероприятие"
                  errors={errors.event}
                  label="Выберите мероприятие по дате и названию"
                  options={school_events}
                  // translated={true}
                />
                <Col>
                <SelectField
                  control={control}
                  name="status"
                  register={{
                    ...register('status',
                      {required: t('error_message.no_empty')})
                  }}
                  required={true}
                  optGrpHead="Выберите статус"
                  errors={errors.status}
                  label='Статус'
                  options={[
                    {"value": "draft", "label": "Созранить в черновик"},
                    {"value": "published", "label": "Проведено"},
                    {"value": "declined", "label": "Отменено"}]}
                  // translated={true}
                />
              </Col>
                <InputField
                  rules={{
                    required: t('error_message.no_empty'),
                  }}
                  control={control}
                  name='title'
                  required={true}
                  label='Комментарий по проведенному мероприятию'
                  errors={errors.name}
                />
              </Col>
              <Col>
                <InputField
                  control={control}
                  name='body'
                  rules={{
                    required: t('error_message.no_empty'),
                    // max: {value: 60, message: t('classes.add-class.student_count_error')},
                    // min: {value: 1, message: t('classes.add-class.student_count_error')}
                  }}
                  label={t('news.news-body')}
                  errors={errors.name}
                />
              </Col>
              {/* <Col>
                <CKEditor
                    editor={ ClassicEditor }
                    // name="body"
                    data={body}
                    onReady=
                    { (editor) => {
                        console.log( 'Editor is ready to use!', editor );
                      }
                    }
                    onChange={ ( event, editor ) => {
                        console.log(editor.getData());
                        setBody(editor.getData());
                        // console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    config={
                      {ckfinder: {
                        // Upload the images to the server using the CKFinder QuickUpload command
                        // You have to change this address to your server that has the ckfinder php connector
                        uploadUrl: 'http://localhost:8000/ckeditor/upload/',
                        options: {
                          resourceType: 'Images'
                        }
                      },
                      // toolbar: ['ckfinder'],
                      // plugins: [CKFinder],
                    }}
                />
              </Col> */}
              {/* <Col>
                <InputField
                  control={control}
                  name='result'
                  rules={{
                    required: t('error_message.no_empty'),
                    // max: {value: 60, message: t('classes.add-class.student_count_error')},
                    // min: {value: 0, message: t('classes.add-class.student_count_error')}
                  }}
                  errors={errors.result}
                  label={t('events.field-result')}
                />
              </Col> */}
            </Row>
            <Col className='moduleBtns'>
              <ButtonC variant="close" onClick={handleClose}>
                {t('btn.close')}
              </ButtonC>
              <ButtonC variant="add" type='submit'>{t('btn.add')}</ButtonC>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
      {/* <script src="https://ckeditor.com/apps/ckfinder/3.5.0/ckfinder.js"></script> */}

    </>
  )
}

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'

const namespace = 'letters'

const fetchLetters = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let letters

		try {
			letters = await api.get('references/letters')
		} catch (error) {
			throw new Error(error)
		}

		return {letters}
	})

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		letters: [],
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchLetters.fulfilled, (state, action) => {
				state.letters = action.payload.letters
			})
})

const thunks = {fetchLetters}

export {reducer, thunks}

import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { ButtonC, PdfHeader, TableComponent } from "../../../components";
import { api } from "../../../service/new-api-2";
import { storage } from "../../../service";
import { D10DownloadFile } from "../d10-download-file";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

export const G3BeneficiariesReg = () => {
  const [beneficiaries, setBeneficiaries] = useState();
  const [expandedRegion, setExpandedRegion] = useState(null);
  const [allStudentsCount, setAllStudentsCount] = useState(0);
  const [all50DiscountCount, setAll50DiscountCount] = useState(0);
  const [all100DiscountCount, setAll100DiscountCount] = useState(0);

  const region_id = storage.read("region_id");
  const { t } = useTranslation();

  function calculateTotalClasses(school) {
    const classes = school.classes;
    const classTotals = {};
    classes.forEach((cls) => {
      const grade = cls.grade;
      if (!classTotals[grade]) {
        classTotals[grade] = {
          grade: grade,
          student_count2: 0,
          count_50_discount: 0,
          count_100_discount: 0,
        };
      }
      classTotals[grade].student_count2 += parseInt(cls.student_count);
      classTotals[grade].count_50_discount += parseInt(
        cls.student_count_50_discount
      );
      classTotals[grade].count_100_discount += parseInt(
        cls.student_count_100_discount
      );
    });

    return Object.values(classTotals);
  }

  function calculateTotalSchools(district) {
    const schools = district.schools;
    const schoolsWithTotalClasses = schools.map((school) => ({
      ...school,
      totalClasses: calculateTotalClasses(school),
    }));
    return Object.values(schoolsWithTotalClasses);
  }

  const getBeneficiaries = () => {
    api
      .get(`reports/students-with-discount/?region_id=${region_id}`)
      .then((response) => {
        if (response && response.length > 0) {
          const updatedBeneficiaries = response.map((district) => {
            const schoolsWithTotalClasses = calculateTotalSchools(district);
            const classTotals = {};
            schoolsWithTotalClasses.forEach((school) => {
              school.totalClasses.forEach((cls) => {
                const grade = cls.grade;
                if (!classTotals[grade]) {
                  classTotals[grade] = {
                    grade: grade,
                    student_count2: 0,
                    count_50_discount: 0,
                    count_100_discount: 0,
                  };
                }
                classTotals[grade].student_count2 += parseInt(
                  cls.student_count2
                );
                classTotals[grade].count_50_discount += parseInt(
                  cls.count_50_discount
                );
                classTotals[grade].count_100_discount += parseInt(
                  cls.count_100_discount
                );
              });
              school.classTotals = classTotals;
            });
            return {
              ...district,
              newSchools: schoolsWithTotalClasses,
              classTotals: Object.values(classTotals),
            };
          });
          setBeneficiaries(updatedBeneficiaries);
          let totalStudentsCount = 0;
          let total50DiscountCount = 0;
          let total100DiscountCount = 0;
          response.forEach((district) => {
            totalStudentsCount += parseInt(district.student_count);
            total50DiscountCount += parseInt(
              district.student_count_50_discount
            );
            total100DiscountCount += parseInt(
              district.student_count_100_discount
            );
          });
          setAllStudentsCount(totalStudentsCount);
          setAll50DiscountCount(total50DiscountCount);
          setAll100DiscountCount(total100DiscountCount);
        }
      });
  };
  useEffect(() => {
    getBeneficiaries();
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleExpandRegion = (regionId) => {
    if (expandedRegion === regionId) {
      setExpandedRegion(null);
    } else {
      setExpandedRegion(regionId);
    }
  };
  return (
    <div>
      {" "}
      <>
        <TableComponent ref={componentRef}>
          <ButtonC
            variant="download"
            onClick={handlePrint}
            style={{
              float: "left",
              marginBottom: "15px",
              backgroundColor: "red",
              color: "#fff",
            }}
          >
            PDF <FontAwesomeIcon style={{ color: "#fff" }} icon={faFilePdf} />
          </ButtonC>
          <PdfHeader title={t("report.g3.title")} />
          <thead>
            <tr>
              <td>№</td>
              <td>Код района</td>
              <td>Район</td>
              <td>{t("report.g3.number_of_students")}</td>
              <td>{t("report.g3.student_with_50_percent")}</td>
              <td>{t("report.g3.student_with_100_percent")}</td>
              <td>Подробнее</td>
            </tr>
          </thead>
          <tbody>
            {beneficiaries &&
              beneficiaries.length > 0 &&
              beneficiaries.map((beneficiary, index) => {
                return (
                  <>
                    <tr key={beneficiary.id}>
                      <td data-label="№">{index + 1}</td>
                      <td data-label="Код района">{beneficiary.id}</td>
                      <td data-label="Район">{beneficiary.district_name}</td>
                      <td data-label="Количество учащихся">
                        <ul>
                          {beneficiary.classTotals.map((cls) => (
                            <li key={cls.grade}>
                              {cls.grade} кл. - {cls.student_count2}
                            </li>
                          ))}
                        </ul>
                        <b>Всего - {beneficiary.student_count}</b>
                      </td>
                      <td data-label="Количество льготников 50% скидкой">
                        <ul>
                          {beneficiary.classTotals.map((cls) => (
                            <li key={cls.grade}>
                              {cls.grade} кл. - {cls.count_50_discount}
                            </li>
                          ))}
                        </ul>
                        <b>Всего - {beneficiary.student_count_50_discount}</b>
                      </td>
                      <td data-label="Количество льготников 100% скидкой">
                        <ul>
                          {beneficiary.classTotals.map((cls) => (
                            <li key={cls.grade}>
                              {cls.grade} кл. - {cls.count_100_discount}
                            </li>
                          ))}
                        </ul>
                        <b>Всего - {beneficiary.student_count_100_discount}</b>
                      </td>
                      <td data-label="Подробнее">
                        <ButtonC
                          // onClick={() => handleExpandRegion(beneficiary.id)}
                          // className="accordion-button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#panelsStayOpen-collapseOne-${beneficiary.id}`}
                          aria-expanded="true"
                          aria-controls={`#panelsStayOpen-collapseOne-${beneficiary.id}`}
                        >
                          Подробнее
                        </ButtonC>
                      </td>
                    </tr>
                    <tr
                      id={`panelsStayOpen-collapseOne-${beneficiary.id}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`panelsStayOpen-headingOne-${beneficiary.id}`}
                    >
                      <th>№</th>
                      <th>Код школы</th>
                      <th>Школа</th>
                      <th>Количество учащихся</th>
                      <th>Количество льготников 50% скидкой</th>
                      <th>Количество льготников 100% скидкой</th>
                    </tr>
                    {beneficiary.newSchools.map((school, index2) => (
                      <tr
                        key={school.id}
                        id={`panelsStayOpen-collapseOne-${beneficiary.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`panelsStayOpen-headingOne-${beneficiary.id}`}
                      >
                        <td>
                          {index + 1}.{index2 + 1}
                        </td>
                        <td>{school.id}</td>
                        <td>{school.name}</td>
                        <td>
                          <ul>
                            {school.totalClasses.map((cls) => (
                              <li key={cls.grade}>
                                {cls.grade} кл. - {cls.student_count2}
                              </li>
                            ))}
                          </ul>
                          <b>Всего - {school.student_count3}</b>
                        </td>
                        <td>
                          <ul>
                            {school.totalClasses.map((cls) => (
                              <li key={cls.grade}>
                                {cls.grade} кл. - {cls.count_50_discount}
                              </li>
                            ))}
                          </ul>
                          <b>Всего - {school.student_count_50_discount3}</b>
                        </td>
                        <td>
                          <ul>
                            {school.totalClasses.map((cls) => (
                              <li key={cls.grade}>
                                {cls.grade} кл. - {cls.count_100_discount}
                              </li>
                            ))}
                          </ul>
                          <b>Всего - {school.student_count_100_discount3}</b>
                        </td>
                      </tr>
                    ))}
                  </>
                );
              })}
            {beneficiaries && beneficiaries.length > 0 && (
              <tr>
                <td colSpan={3}>
                  <b>Всего</b>
                </td>
                <td data-label="Количество учащихся">
                  <b>{allStudentsCount}</b>
                </td>
                <td data-label="Количество льготников 50% скидкой">
                  <b>{all50DiscountCount}</b>
                </td>
                <td data-label="Количество льготников 100% скидкой">
                  <b>{all100DiscountCount}</b>
                </td>
              </tr>
            )}
          </tbody>
        </TableComponent>
      </>
    </div>
  );
};

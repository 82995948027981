import { useTranslation } from "react-i18next";
import { Layout } from "../app/layout";
import {
  ButtonC,
  InputField,
  SelectField,
  TableComponent,
} from "../components";
import { Payment } from "../feature/payments";
import { storage } from "../service";
import { useActions, useApi, useAppSelector, usePageTitle } from "../hooks";
import { useState, useEffect, useMemo, use, useRef } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {thunks} from "../app/store/bookstore-reducer";
import {useDispatch} from "react-redux";
import {read} from "../service/storage";
import {notifyError, notifySuccess, notifyInfo} from "../components/notify";
import {useParams} from "react-router";
import {useReactToPrint} from 'react-to-print'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import { PdfHeaderToBalance } from "../components/pdf-header/pdf-header-to-balance";
import { PdfFooterToBalance } from "../components/pdf-header/pdf-footer-to-balance";

export const DetailBalance = () => {
  const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const actions = {
        fetchBalance: thunks.fetchBalance,
    }
  const balance = useAppSelector((state) => state["bookstore"]["balance"]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    register
  } = useForm();
  const { t } = useTranslation();
  usePageTitle('Сверка баланса');
  const componentRef = useRef(null)
  const params = useParams()
  const school = useAppSelector((state) => state.profile.school);
  const [gradebalance, setGradebalance] = useState([]);
  const [balanceConfirm, setBalanceConfirm] = useState(true);
  const [grades, setGrades] = useState([]);
  const [gradesSum, setGradesSum] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null)

  const selectedFileHandler = (event) => {
    setSelectedFile(event.target.files[0])
  }
  const selectedFileUploadHandler = () => {
    const access = read('access')
    if (selectedFile) {
        const fd = new FormData()
        fd.append("doc", selectedFile, selectedFile.name)
        const uri = `${process.env.REACT_APP_BASE_URL}/api/acts/${balanceConfirm?.id}/documents/`
        fetch(uri, {
            method: 'POST',
            body: fd,
            headers: {
                "Authorization": `Bearer ${access}`,
            }
        }).then((response) => {
          if (response.ok) {
              return response.json().then((data) => {
                  // setOrder(data)
                  setBalanceConfirm(data);
                  notifySuccess("Документ успешно загружен и передан в Жаны китеп")
              })
          } else if (response.status === 400) {
              response.json().then((errorData) => {
                  const errorMessage = errorData[0]
                  notifyError(errorMessage)
              })
          }
      })
    } else {
        notifyError("Документ не прикреплен.")
    }
  }
  const docDownloadHandler = (id) => {
    const access = read('access')
    const uri = `${process.env.REACT_APP_BASE_URL}/api/acts/${id}/documents/`
    fetch(uri, {
        method: 'GET',
        headers: {
            "Authorization": `Bearer ${access}`,
            'Content-Type': 'application/pdf',
        },
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `document.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
}
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  useEffect(() => {
  }, []);
  useEffect(() => {
    dispatch(actions.fetchBalance());
  }, [dispatch]);
  const onSubmit = async (data) => {
    const gradesBalance = {
      grade: data.grade,
      balance: data.balance,
    };
    try {
      await api
        .post("update-grade-balance", {
          body: gradesBalance,
        })
        .then((response) => {
          if (response === "Success") {
            get_balances();
          }
        });
    } catch (err) {
      throw new Error(err);
    }
  };
  const get_grades_for_balances = () => {
    api.get("grades-for-balance").then((response) => {
      setGrades(response);
    });
  };
  const get_balances = () => {
    api.get("grade-balances").then((response) => {
      setGradebalance(response);
    });
  };
  const get_actual_balance_confirm = () => {
    api.get("actual-balance-confirm").then((response) => {
      if (response !== false) {
        // set(response);
        setBalanceConfirm(response);
      }
    });
  };
  const confirmeBalance = () => {
    api.get("set-actual-balance-confirm").then((response) => {
      if (!response?.status) {
        setBalanceConfirm(response);
        notifyInfo("Баланс сформирован, далее скачайте файл, распечатайте, подпишите, поставьте печать, загрузите и подтвердите");
      } else {
        notifySuccess("Баланс не отправлен. Ошибка");
      }
      setShow(false)
    });
  };
  // const confirmeBalance= () => {
  //   set_actual_balance_confirm()
  // }
  const handleClick = () => {
    setShow(true)
  };
  useEffect(() => {
    get_balances();
    get_actual_balance_confirm();
    get_grades_for_balances();
  }, []);
  const api = useApi();

  let total = 0.0;
  gradebalance.forEach((balance) => {
    total += parseFloat(balance?.balance);
  });

  return (
    <Layout>
      <style type="text/css" media="print">{"\
        @page {\ size: portrait;\ }\
      "}</style>
      <h2 className="mb-4">{t('balance.title1')}<br />{t('balance.title2')}</h2>
      <p>
        <b>{t('balance.text1')}</b>{t('balance.text2')}<br />
        {t('balance.text3')}<br />
        {t('balance.text4')}<b>{t('balance.text5')}</b> <br />
        {t('balance.text6')}<br />
        {t('balance.text7')}<br />
        <span style={{color: 'red'}}><b>{t('balance.text8')}</b></span>
      </p>
      <br />
      {!balanceConfirm?.balance_confirmed &&
        <Form onSubmit={handleSubmit(onSubmit)} style={{'width': '50%'}}>
          <Row xs={3} md={3}>
            <Col>
              <SelectField
                  register={{
                      ...register('grade',
                        {required: t('error_message.no_empty')})
                    }}
                    required={true}
                //   register={{ ...register("book__year__in") }}
                name="grade"
                control={control}
                options={[
                  { value: "", label: "Класс" },
                  ...grades?.map((y) => ({ value: y.grade, label: y.grade + ' класс' })),
                ]}
                //   onChange={(event) => yearChange(event.target.value)}
              />
            </Col>
            <Col>
              <InputField
                type="number"
                control={control}
                name="balance"
                //   defaultValue={user.email}
                id="balance"
                //   rules={{
                //     pattern: {
                //       value: /\S+@\S+\.\S+\S+/,
                //       message: t("profile.confirm-email.email_error"),
                //     },
                //   }}
                placeholder="Баланс"
                //   errors={errors.email}
              />
            </Col>
            <Col>
              <ButtonC
                variant="add"
                type="submit"
                style={{ marginBottom: "4px" }}
              >
                {t('balance.add')}
              </ButtonC>
            </Col>
          </Row>
        </Form>
      }
      { (balanceConfirm?.balance_confirmed && !balanceConfirm?.act_doc) &&
        <div>
          <ButtonC variant='download' onClick={handlePrint} style={{marginBottom: '15px', backgroundColor: 'red', color: '#fff'}}>
              СКАЧАТЬ АКТ <FontAwesomeIcon style={{color: '#fff'}} icon={faFilePdf} />
          </ButtonC>
        </div>
      }
      <div ref={componentRef} style={{'margin': '20mm'}}>
        <PdfHeaderToBalance/>
        <TableComponent style={{'width': '43%', 'minWidth': '150px'}}>
          <thead>
            <tr>
              <th>№</th>
              <th>Класс</th>
              <th>Сумма <br />собранных средств</th>
            </tr>
          </thead>
          <tbody>
            {gradebalance?.map((balance, index) => {
              return (
                <>
                  <tr>
                    <td data-label="№">{index + 1}</td>
                    <td data-label="№Класс">{balance?.grade_number} - класс</td>
                    <td data-label="Баланс">{balance?.balance}</td>
                  </tr>
                </>
              );
            })}
            <tr>
              <td data-label="№Класс" colSpan={2}><b>Итого баланс школы</b></td>
              <td data-label="Баланс"><b>{total}</b></td>
            </tr>
            <tr>
              <td data-label="№Класс" colSpan={2}><b>Итого баланс Жаңы Китеп</b></td>
              <td data-label="Баланс"><b>{balance}</b></td>
            </tr>
          </tbody>
        </TableComponent>
        <PdfFooterToBalance/>
      </div>
      {(!balanceConfirm.balance_confirmed) &&
        <Button
          variant="primary d-block fs-5 mb-3"
          onClick={handleClick}
          disabled = {((grades?.length !== gradebalance?.length) || (total > balance) )?true:false}
        >
          {t("balance.submit")}
        </Button>
      }
      {(!balanceConfirm.balance_confirmed && total > balance) &&
          <div className="alert alert-danger" role="alert">
            <p>{t("balance.error-text1")}</p>
            <p>{t("balance.error-text2")}</p>
            <p>{t("balance.error-text3")}</p>
            <p>&ensp;{t("balance.error-text4")}</p>
            <p>&ensp;{t("balance.error-text5")}</p>
            <p>&ensp;{t("balance.error-text6")}</p>
            <p>{t("balance.error-text7")}</p>
            <p>{t("balance.error-text8")} <a target="_blank" href="https://kitep.org.kg/" rel="noopener noreferrer">https://kitep.org.kg/</a></p>
            <p>{t("balance.error-text9")}</p>
          </div>
      }
      {(!balanceConfirm.balance_confirmed && grades?.length !== gradebalance?.length) &&
        <div className="alert alert-danger" role="alert">
          <p>{t("error-ass-classes")}</p>
        </div>
      }
      {balanceConfirm?.act_doc && <>
          <div>Акт сверки: {balanceConfirm?.act_doc}</div>
          {/* <div>Акт сверки: {balanceConfirm?.act_doc.split('/').slice(-1)}</div> */}
          <button className="btn btn-primary" onClick={() => {
              docDownloadHandler(balanceConfirm?.id)
          }}>Скачать
          </button>
      </>}
      {(balanceConfirm?.balance_confirmed && !balanceConfirm?.act_doc) && <>
          <input
              type="file"
              accept="application/pdf"
              placeholder="Choose a file"
              onChange={selectedFileHandler}
              className="mt-4"
          />
          <button className="btn btn-success text-white" onClick={selectedFileUploadHandler}>Загрузить файл и подтвердить</button>
      </>}
      <Modal
        show={show}
        onHide={function() {
          setShow(false)
          }}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            {t('balance.submit-title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('balance.submit-text')}</p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonC variant="close" onClick={function() {
          setShow(false)
          }}>
            {t('btn.close')}
          </ButtonC>
          <ButtonC variant="add" onClick={confirmeBalance}>{t('balance.add-btn')}</ButtonC>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

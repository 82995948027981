import React, {useEffect, useState} from "react";
import {Layout} from "../../../app/layout";
import {thunks} from "../../../app/store/bookstore-reducer";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../hooks";
import {Link} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {Button, ListGroup} from "react-bootstrap";
import styles from '../store.module.css'
import { isDistObserver, isLabrarian, isJanyKitep } from "../../../lib/helper";
import {faAngleDown, faFileExcel, faFilePdf, faWindowClose, faMinusCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import {langStatus} from '../../../lib/helper'
import {api} from '../../../service/old-api-2';

export const GeneralOrderList = () => {
    const filterOrders  = ((order) => {
        if (order === 'district_declined') {
            return <FontAwesomeIcon icon={faWindowClose}
                                                style={{color: 'orangered', fontSize: '25px'}}/>
        } else if (order === 'district_approved') {
            return <FontAwesomeIcon icon={faAngleDown} style={{color: 'forestgreen', fontSize: '25px'}}/>
        } else if (order === 'district_review') {
            return <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'blue', fontSize: '25px'}}/>
        } else {
            return <FontAwesomeIcon icon={faMinusCircle} style={{color: 'orange', fontSize: '25px'}}/>
        }
    })
    const dispatch = useDispatch()
    // const actions = {
    //     fetchOrders: thunks.fetchOrders
    // }
    // const orders = useAppSelector(
    //     (state) => state['bookstore']['orders']['results']
    // )
    const [orders, setOrders] = useState([])
    const GeneralOrderlist = () => {
        api.get(`store/general-orders/`).then(
            (response) => {
                setOrders(response)
                // console.log(response);
                // if (response.status === 'success') {
                //     console.log();
                // }
            })
    }
    // console.log(orders);
    useEffect(() => {
        // dispatch(actions.fetchOrders())
        GeneralOrderlist()
    }, [])
    return (
        <>
            <Layout>
                {(isLabrarian && <NavLink
                  exact
                  to="/store"
                  className={styles.links}
                  activeClassName={styles.active}
                >
                    <Button variant="warning" style={{fontSize: '15px', fontWeight: 700}}>
                        В магазин
                    </Button>
                    <br />
                </NavLink>)}
                <table style={{width: "100%", marginTop: '25px'}}>
                    <thead>
                        <tr>
                            <th>№ заявки</th>
                            <th>Учебный год</th>
                            {isJanyKitep &&
                                <>
                                    <th>№ района</th>
                                    <th>Название района</th>
                                </>
                            }
                            {/* <th>Общее количество учебников</th>
                            <th>Общая сумма (цена)</th> */}
                            <th>Статус</th>
                            <th>Комментарий</th>
                            <th>Действие</th>
                        </tr>
                    </thead>
                    <tbody>
                    {orders?.map((order) => (
                        <tr key={order['id']}>
                            <td>{order['id']}</td>
                            <td>{order['study_period_start']} - {order['study_period_end']}</td>
                            {isJanyKitep &&
                                <>
                                    <td>{order['district_id']}</td>
                                    <td>{order['district']}</td>
                                </>
                            }
                            {/* <td>{order['total_quantity']}</td>
                            <td>{order['total_price']}</td> */}
                            <td>
                                {/* {order['status']} */}
                                {langStatus(order['status'])}
                                {/* {filterOrders(order['status'])} */}
                            </td>
                            <td>{order['comment']}</td>
                            <td>
                                <Link to={{pathname: `/store/general-orders/${order['id']}`}}>Подробнее</Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Layout>
        </>
    )
}

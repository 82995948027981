import { Button } from "react-bootstrap";
import styles from "./button.module.css";
import cn from "classnames";

const vars = {
  search: "dark",
  add: "success",
  delete: "outline-danger",
  delete2: "danger",
  change: "outline-primary",
  info: "info",
  disable: "secondary",
  primary: "primary",
  close: "warning",
  download: "outline-primary",
};
export const ButtonC = ({ variant, className, children, ...props }) => {
  return (
    <Button
      variant={vars[variant]}
      className={cn(styles.button, className, {
        [styles.delete]: variant === "delete",
        [styles.search]: variant === "search",
        [styles.download]: variant === "download",
      })}
      {...props}
    >
      {children}
    </Button>
  );
};

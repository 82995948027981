import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'
import {api as api2} from '../../service/old-api-2'

const namespace = 'schoolBooks'
const uri = 'school-books'

const fetchSchoolBooks = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let schoolBooks

		try {
			schoolBooks = await api.get(`${uri}`)
		} catch (error) {
			throw new Error(error)
		}

		return {schoolBooks}
	})

const searchSchoolBooks = createAsyncThunk(
	`${namespace}/search/`,
	async (query) => {
		let schoolBooks

		try {
			schoolBooks = await api.get(`${uri}`, {search: query})
		} catch (error) {
			throw new Error(error)
		}

		return {schoolBooks}
	})

const addSchoolBooks = createAsyncThunk(
	`${namespace}/add/`,
	async (data) => {
		let schoolBook
		try {
			await api.post(`school-books`, {body: {book: data?.id, year: data?.year, part: data?.part}})
					.then(async (res) => schoolBook = await api.get(`${uri}/${res.id}`))
		} catch (error) {
			throw new Error(error)
		}
		return {schoolBook}
	})

const updateSchoolBook = createAsyncThunk(
	`${namespace}/update`,
	async ({id, data}) => {
		let schoolBook

		if (!data) {
			schoolBook = await api2.get(`${uri}/${id}/`)
		} else {
			try {
				await api.put(`${uri}/${id}`, {body: data})
					.then(async () => {
						schoolBook = await api.get(`${uri}/${id}`)
					})
			} catch (error) {
				throw new Error(error)
			}
		}

		return {schoolBook}
	})

const deleteSchoolBook = createAsyncThunk(
	`${namespace}/delete`,
	async (id) => {
		try {
			await api.delete(`${uri}/${id}`)
		} catch (error) {
			throw new Error(error)
		}
		return {id}
	})

const recomendedBooks = createAsyncThunk(
	`${namespace}/recomended`,
	async (payload) => {
		try {
			api.get(`${uri}`, {body: {recommended: payload}} )
		} catch (error) {
			throw new Error(error)
		}
	}
)

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		schoolBooks: [],
		loading: false,
		searchResult: [],
		recomendedBooks: [],
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchSchoolBooks.fulfilled, (state, action) => {
				state.schoolBooks = action.payload.schoolBooks,
					state.searchResult = action.payload.schoolBooks
			})
			.addCase(searchSchoolBooks.fulfilled, (state, action) => {
				state.searchResult = action.payload.schoolBooks
			})
			.addCase(addSchoolBooks.fulfilled, (state, action) => {
				state.loading = false
				state.schoolBooks.push(action.payload.schoolBook)
				state.searchResult.push(action.payload.schoolBook)
			})
			.addCase(updateSchoolBook.fulfilled, (state, action) => {
				const index = state.schoolBooks.findIndex((t) => t.id == action.payload.schoolBook.id)
				state.schoolBooks[index] = action.payload.schoolBook
				const index1 = state.searchResult.findIndex((t) => t.id == action.payload.schoolBook.id)
				state.searchResult[index1] = action.payload.schoolBook
			})
			.addCase(deleteSchoolBook.fulfilled, (state, action) => {
				state.loading = false
				state.schoolBooks = state.schoolBooks.filter((t) => t.id != action.payload.id)
				state.searchResult = state.searchResult.filter((t) => t.id != action.payload.id)
			})
			.addCase(recomendedBooks.fulfilled, (state, action) => {
				state.recomendedBooks = action.payload.recomendedBooks
			})
})

const thunks = {fetchSchoolBooks, addSchoolBooks, updateSchoolBook, deleteSchoolBook, searchSchoolBooks, recomendedBooks}

export {reducer, thunks}

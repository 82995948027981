import React from 'react'
import {Layout} from '../app/layout'

export const developmentMode = () => {
  return (
   <Layout>
       <h1 className='text-center'>В данный момент ведутся технические работы!!!</h1>
    </Layout>
  )
}

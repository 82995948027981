import {useTranslation} from 'react-i18next'
import {Layout} from '../app/layout'
import {Videos} from '../feature/videos'
import {usePageTitle} from '../hooks'
import './styles.css'

export const VideoLessons = () => {
	const {t} = useTranslation()
	usePageTitle(t('videos.title'))
	return (
		<Layout>
			<h2 className='mb-4 video-title'>{t('videos.title')}</h2>
			<Videos />
		</Layout>
	)
}
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { langLongName } from '../../lib/helper'
import { storage } from '../../service'
import {api} from '../../service/old-api-2'


const namespace = 'news'
const language = storage.read('lang')

// const addNews = createAsyncThunk(
// 	`${namespace}/add`,
// 	async (data) => {
// 		let news

// 		try {
// 			await api.post(`${uri}`, {body: data})
// 				.then(async (res) => news = await api.get(`${uri}/${res.id}`))
// 		} catch (error) {
// 			throw new Error(error)
// 		}
// 		return {news}
// 	})

const fetchNews = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let news;

		try {
			news = await api.get(`news/?language=${langLongName(language)}`)
		} catch (error) {
			throw new Error(error)
		}
		return {news}
	})

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		news: [],
		loading: false,
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchNews.fulfilled, (state, action) => {
				state.news = action.payload.news.results
			})
			// .addCase(addNews.pending, (state) => {
			// 	delete state.error
			// 	state.loading = true
			// })
			// .addCase(addNews.fulfilled, (state, action) => {
			// 	state.loading = false
			// 	state.news.push(action.payload.new_news)
			// })
			// .addCase(addNews.rejected, (state) => {
			// 	state.error = 'error'
			// 	state.loading = false
			// })
})

const thunks = {
	fetchNews
	// addNews
}

export {reducer, thunks}

import {Modal, Form, Col, Row} from "react-bootstrap"
import {Controller, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {ButtonC, InputField, PhoneField} from "../../components"
import {notifyError, notifySuccess} from "../../components/notify"
import {useActions} from '../../hooks'
import {thunks} from './model'

const actions = {updateTeacher: thunks.updateTeacher}

export const UpdateTeacher = (props) => {
  const {show, setShow, teacher} = props
  const {updateTeacher} = useActions(actions)
  const {t} = useTranslation()
  const {handleSubmit, reset, formState: {errors}, control} = useForm()

  const putTeacher = async (data) => {
    if (!(data.pin.toString().startsWith('1') || data.pin.toString().startsWith('2'))) {
      notifyError('Первый символ ИННа должен быть либо 1, либо 2')
      return
    }
    if (data.email === '') delete data.email
    data = {...data, phone: data.phone}
    const res = await updateTeacher({id: teacher.id, data})
    if (res.payload) {
      notifySuccess('Изменено!')
      reset()
      setShow(false)
    }
  }
  return (
    <>
      <Modal
        show={show}
        onHide={setShow}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{t('classes.update-teacher.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(putTeacher)}>
            <Col>
              <InputField
                control={control}
                name='fullname'
                rules={{
                  required: t('error_message.no_empty')
                }}
                defaultValue={teacher?.fullname}
                label={t('classes.add-teacher.fullname')}
                errors={errors.fullname}
              />
            </Col>
            <Col>
              <InputField
                control={control}
                name='email'
                rules={{
                  pattern: {
                    value: /\S+@\S+\.\S+\S+/,
                    message: t('classes.add-teacher.email_error')
                  }
                }}
                defaultValue={teacher?.email}
                label='E-mail'
                errors={errors.email}
              />
            </Col>
            <Row xs={1} md={2}>
              <Col>
                <InputField
                  control={control}
                  name='pin'
                  rules={{
                    required: t('error_message.no_empty'),
                    maxLength: {value: 14, message: t('classes.add-teacher.pin_error')},
                    minLength: {value: 14, message: t('classes.add-teacher.pin_error')}
                  }}
                  defaultValue={teacher?.pin}
                  label={t('classes.add-teacher.pin')}
                  errors={errors.pin}
                />
              </Col>

              <Col>
              <InputField
						type='text'
						control={control}
						name='phone'
						defaultValue={teacher?.phone}
						rules={{
							required: t('error_message.no_empty'),
						}}
						label={t('classes.add-teacher.phone')}
						errors={errors.phone}
					/>
              </Col>
            </Row>
            <Col className='moduleBtns'>
              <ButtonC variant="close" onClick={() => {
                reset()
                setShow(false)
              }}>
                {t('btn.close')}
              </ButtonC>
              <ButtonC variant="add" type='submit'>{t('btn.change')}</ButtonC>
            </Col>

          </Form>

        </Modal.Body>
      </Modal>
    </>
  )
}
import React, {useEffect} from "react";
import {Layout} from "../../../app/layout";
import {thunks} from "../../../app/store/bookstore-reducer";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../hooks";
import {Link} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {Button, ListGroup} from "react-bootstrap";
import styles from '../store.module.css'
import {useParams} from "react-router";
import {isLibrarian, isDistObserver, isDirector} from "../../../lib/helper";
import {isJanyKitep, langStatus} from '../../../lib/helper'

export const DistrictsOrderList = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const actions = {
        fetchOrdersInDistrict: thunks.fetchOrdersInDistrict
    }
    const orders = useAppSelector(
        (state) => state['bookstore']['orders']['results']
    )
    // console.log(orders);
    useEffect(() => {
        dispatch(actions.fetchOrdersInDistrict(params?.id))
    }, [])
    return (
        <>
            <Layout>
                <Button variant="primary" className={'my-3'}>
                    <NavLink exact to="/store/general-orders-districts" className="text-white">
                        <FontAwesomeIcon icon={faBook} className="mx-3"/> Вернуться к списку районов
                    </NavLink>
                </Button>
                <table style={{width: "100%"}}>
                    <thead>
                        <th>№ заявки</th>
                        <th>Учебный год</th>
                        <th>Баланс района</th>
                        <th>Доступный баланс</th>
                        <th>Сумма заявки</th>
                        <th>Статус</th>
                        <th>Действие</th>
                    </thead>
                    <tbody>
                    {orders?.map((order) => (
                        <tr key={order['id']}>
                            <td>{order['id']}</td>
                            <td>{order['study_period_start']} - {order['study_period_end']}</td>
                            {/* <td>{order['total_quantity']}</td> */}
                            {(isDistObserver || isDirector || isJanyKitep) &&
                                <td>{order['school_balance']} сом</td>
                            }
                            {(!isDistObserver && !isDirector && !isJanyKitep) &&
                                <td>{balance} сом</td>
                            }
                            {(isDistObserver || isDirector || isJanyKitep) &&
                                <td>{order['school_available_balance']} сом</td>
                            }
                            {(!isDistObserver && !isDirector && !isJanyKitep) &&
                                <td>{available_balance} сом</td>
                            }
                            {/* {(isDistObserver || isDirector) &&
                                <td>{order['total_price']} сом</td>
                            } */}
                            <td>{order['total_price']} сом</td>
                            <td>
                            {/* {order['status']} */}
                                {langStatus(order['status'])}
                                {/* {filterOrders(order['status'])} */}
                            </td>
                            <td>
                                <Link to={{pathname: `/store/general-orders/${order['id']}`}}>Подробнее</Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Layout>
        </>
    )
}

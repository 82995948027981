import React, {useEffect, useState, useRef} from "react";
import {useParams} from "react-router";
import {Layout} from "../../../app/layout";
import {api} from "../../../service/old-api-2";
import styles from './order-detail.module.css'
import {read} from "../../../service/storage";
import {notifyError, notifySuccess} from "../../../components/notify";
import {isLibrarian, isDistObserver, isDirector, isJanyKitep} from "../../../lib/helper";
import {  Modal, Form } from "react-bootstrap";
import {ButtonC, DeleteNotifyWithRedux, InputField, PdfHeader, TableComponent } from "../../../components";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import classes from '../../../feature/school-books/books.module.css';
import {
    moveToCart,
    updateTotalPrice
} from "../../../app/store/bookstore-reducer.js";
import {useDispatch} from "react-redux";
import {faTrash, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, ListGroup} from "react-bootstrap";
import {useAppSelector} from "../../../hooks";
import { thunks } from "../../../app/store/bookstore-reducer";
import {useReactToPrint} from 'react-to-print'
import { PdfHeaderToOrder } from "../../../components/pdf-header/pdf-header-to-order";
import { PdfFooterToOrder } from "../../../components/pdf-header/pdf-footer-to-order";
import {storage} from '../../../service'
import {langStatus, transLangFromEng} from '../../../lib/helper'
import { useHistory } from "react-router";

export const OrderDetail = () => {
    const today = new Date(Date.now()).toLocaleDateString()
    const componentRef = useRef(null)
    const actions = {
        fetchBalance: thunks.fetchBalance,
      };
    const balance = useAppSelector((state) => state["bookstore"]["balance"])
    const available_balance = useAppSelector((state) => state["bookstore"]["available_balance"]);
    const shop_grades = useAppSelector((state) => state["bookstore"]["shop_grades"]);
    // const {updateSchoolBook, deleteSchoolBook} = useActions(actions)
    const dispatch = useDispatch()
    const {handleSubmit, register, reset, formState: {errors}, control, setValue} = useForm()
    const {t} = useTranslation()
    const [show, setShow] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [generalOrderDeclined, setGeneralOrderDeclined] = useState(false)
    const [order, setOrder] = useState({})
    const [selectedFile, setSelectedFile] = useState(null)
    const params = useParams()
    const history = useHistory();

    const selectedFileHandler = (event) => {
        setSelectedFile(event.target.files[0])
    }
    const onFocus = (id) => {
        const btn = document.querySelector(`#button${id}`)
        btn.style.borderColor = '#0d6efd'
        btn.style.color = 'black'
    }

    const onBlur = (id) => {
        const btn = document.querySelector(`#button${id}`)
        btn.style.borderColor = '#90909a'
        btn.style.color = 'black'
    }
    const selectedFileUploadHandler = () => {
        const access = read('access')
        if (selectedFile) {
            const fd = new FormData()
            fd.append("doc", selectedFile, selectedFile.name)
            const uri = `${process.env.REACT_APP_BASE_URL}/api/store/orders/${params?.id}/documents/`
            fetch(uri, {
                method: 'POST',
                body: fd,
                headers: {
                    "Authorization": `Bearer ${access}`,
                }
            }).then((response) => {
                if (response.ok) {
                    return response.json().then((data) => {
                        setOrder(data)
                        notifySuccess("Документ успешно загружен.")
                    })
                } else if (response.status === 400) {
                    response.json().then((errorData) => {
                        const errorMessage = errorData[0]
                        notifyError(errorMessage)
                    })
                }
            })
        } else {
            notifyError("No file is selected.")
        }
    }
    const getOrder = () => {
        try {
            api.get(`store/orders/${params.id}/`)
                .then((data) => {
                    setOrder(data)
                })
        } catch (error) {
            throw new Error(error)
        }
    }
    const docDownloadHandler = (doc) => {
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/orders/${params?.id}/documents/?file=${doc}`
        fetch(uri, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${access}`,
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `document.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }
    const approve = () => {
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/orders/${params?.id}/approve/`
            fetch(uri, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${access}`,
                }
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then((data) => {
                setOrder(data)
                if (isDirector) {
                    notifySuccess("Заявка передана районному наблюдателю на утверждение.")
                }
                if (isDistObserver) {
                    history.push("/store/orders-shools");
                    notifySuccess("Заявка одобрена.")
                }
            })
    }
    const decline = (data) => {
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/orders/${params?.id}/decline/`
            fetch(uri, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${access}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then((data) => {
                setOrder(data)
                notifySuccess("Заявка отклонена")
                setShow(false)
            })
    }
    const changeQuantity = async (orderItem) => {
        const quantity = document.querySelector(`#quantity${orderItem.id}`)
        if (orderItem.quantity === quantity.value) {
            notifyError('Новая значения должно быть отличным от старого')
            return
        }
        const body = {
            quantity: quantity.value,
        }
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/order-item/${orderItem.id}/`
        fetch(uri, {
            method: 'PATCH',
            headers: {
                "Authorization": `Bearer ${access}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }).then((response) => {
            if (response.ok) {
                return response.json()
            }
        }).then(() => {
            getOrder()
            notifySuccess("Кол-во книги в заявке изменено")
        })

    }
    const deleteOrderItem = async (orderItemId) => {
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/order-item/delete/${orderItemId}/`
        fetch(uri, {
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${access}`,
            },
        }).then((response) => {
            if (response.ok) {
                return response.json()
            }
        }).then(() => {
            // setOrder()
            getOrder()
            notifySuccess("Книга удалена с заявки")
            setShowDelete(false)
        })

    }
    const toDeleteModal = async (id) => {
        setShowDelete(true)
        setItemId(id)
    }
    const handleResend = () => {
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/orders/${params?.id}/resend/`
            fetch(uri, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${access}`,
                }
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then((data) => {
                setOrder(data)
                notifySuccess("Заявка отправлена на утверждение директору.")
            })
    }
    const IsGeneralOrderDeclined = () => {
        api.get(`store/id-general-order-declined/`).then(
            (response) => {
                if (response.status === 'success') {
                    setGeneralOrderDeclined(true)
                }
            })
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: () => `{ margin: ${20} ${50} ${20} ${50} !important; }`
      })
    if (!isDistObserver) {
        useEffect(() => {
            dispatch(actions.fetchBalance());
        }, [dispatch]);
    }
    useEffect(() => {
    IsGeneralOrderDeclined()
    }, []);
    useEffect(() => {
        getOrder()
    }, [])
    const ordersDate = new Date(Date(order?.ordered_date))
	const year = ordersDate.toLocaleString("default", { year: "numeric" });
	const month = ordersDate.toLocaleString("default", { month: "2-digit" });
	const day = ordersDate.toLocaleString("default", { day: "2-digit" });
	const formattedDate = day + "-" + month + '-' + year;

    const getPageMargins = () => {
        return `@page { margin: ${20} ${50} ${20} ${50} !important; }`;
      };

    return (
        <>
            <Layout>
                <style type="text/css" media="print">{"\
                    @page {\ size: portrait;\ }\
                "}</style>
                <p style={{textAlign: 'center'}}>
                    <h3 style={{fontSize: '38px', fontWeight: 600, letterSpacing: '5px'}}>{t('shop.order.forPrint.title', {value: order?.id})}</h3><span>от {formattedDate} на {parseInt(order?.study_period_start) + 1} - {parseInt(order?.study_period_end) + 1} учебный год</span>
                </p>
                <br />
                <div>Статус: <b>{langStatus(order?.status)}</b></div>
                {(isDistObserver || isJanyKitep) && <div>Баланс: {order?.school_balance}</div>}
                {(!isDistObserver && !isJanyKitep) && <div>Баланс: {balance}</div>}
                {/* <div>Баланс: <b>{order?.school_balance} сом</b></div> */}
                {(isDistObserver || isJanyKitep) && <div>Баланс от аренды {shop_grades?.join(', ')} классов: <b>{order?.school_available_balance} сом</b></div>}
                {(!isDistObserver && !isJanyKitep) && <div>Баланс от аренды {shop_grades?.join(', ')} классов: <b>{available_balance} сом</b></div>}
                {/* <div>Баланс от аренды {shop_grades?.join(', ')} классов: <b>{available_balance} сом</b></div> */}
                {!isJanyKitep &&
                    <p>{t('print.region')}: <b>{storage.read('region')}, {storage.read('district')}</b></p>
                }
                {isJanyKitep &&
                    <p>{t('print.region')}: <b>{order?.region}, {order?.district}</b></p>
                }
                {/* <p>{t('print.school_name')}: <b>{storage.read('school')}</b></p> */}
                {/* <p>{t('print.school_id')}: <b>{storage.read('school_id')}</b></p> */}
                <p>{t('print.school_name')}: <b>{order?.school_name}</b></p>
                <p>{t('print.school_id')}: <b>{order?.school_id}</b></p>
                {order?.comment && <div style={{fontSize: '25px', fontWeight: 600, letterSpacing: '3px', color: 'red'}}><b>Причина отказа: {order?.comment}</b></div>}
                <br />
                { ((isDirector || isLibrarian) && order?.status == 'director_review') &&
                    <ButtonC variant='download' onClick={handlePrint} style={{float: 'left', marginBottom: '15px', backgroundColor: 'red', color: '#fff'}}>
                        СКАЧАТЬ ЗАЯВКУ <FontAwesomeIcon style={{color: '#fff'}} icon={faFilePdf} />
                    </ButtonC>
                }
                <TableComponent ref={componentRef} style={{width: '100%'}}>
                    {/* <style>{getPageMargins()}</style> */}
                    {/* <style type="text/css" media="print">{"\
                        @page {\ size: portrait;\ margin: 20 50 20 50 !important;\ }\
                    "}</style> */}
                    <style> {`
                            @media print { 
                            html, body { 
                                height: initial !important; 
                                overflow: initial !important; 
                                -webkit-print-color-adjust: exact; 
                            } 
                        } 
                        @media all {
                        .page-break {
                            display: none;
                        }
                        }
                        @media print {
                        .page-break {
                            margin-top: 1rem;
                            display: block;
                            page-break-before: auto;
                        }
                        }

                        @page {
                        size: auto;
                        margin: 20mm;
                        }
                        `
                    }
                    </style>
                    <PdfHeaderToOrder title={t('shop.order.forPrint.title', {value: order?.id})} order={order} />
                    <br />
                    <thead className="pt-3">
                    <tr>
                        <th>№</th>
                        <th>Наименование учебника</th>
                        <th>Автор</th>
                        <th>Класс</th>
                        <th>Язык</th>
                        <th>Цена</th>
                        <th>Количество</th>
                        <th>Сумма</th>
                        {(isLibrarian && order?.status == 'director_declined') && <th>Удалить</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {/* {order && order.order_items?.map((orderItem) => ( */}
                    {order && order?.order_items?.map((orderItem, index) => (
                        <tr key={orderItem.id}>
                            <td>{index + 1}</td>
                            <td>{orderItem?.book_name}</td>
                            <td>{orderItem?.book_author}</td>
                            <td>{orderItem?.book_grades.slice(1, -1)}</td>
                            <td>{transLangFromEng(orderItem?.book_language)}</td>
                            <td>{orderItem?.book_price}</td>
                            <td>
                                {(order?.status === 'director_declined' || order?.status == 'district_declined') &&
                                <div className=''>
                                    <input
                                        defaultValue={orderItem?.quantity}
                                        type='number'
                                        className={classes.total}
                                        id={`quantity${orderItem.id}`}
                                        onFocus={() => onFocus(orderItem.id)}
                                        onBlur={() => onBlur(orderItem.id)}
                                    />
                                    {isLibrarian &&     <ButtonC
                                        variant='change'
                                        onClick={() => changeQuantity(orderItem)}
                                        id={`button${orderItem.id}`}
                                        className={classes.changeButton}
                                    >
                                        {t('btn.change')}
                                    </ButtonC>}
                                </div>}
                                {(order?.status!='director_declined' && order?.status!='district_declined')?orderItem?.quantity:''}
                            </td>
                            <td>{orderItem?.total_price}</td>
                            { (isLibrarian && (order?.status == 'director_declined' || order?.status == 'district_declined')) &&
                                <td>
                                    <ButtonC variant='delete' onClick={() => toDeleteModal(orderItem?.id)}>
                                        <FontAwesomeIcon icon={faTrash} className="mx-3"/>
                                    </ButtonC>
                                </td>
                            }
                        </tr>
                    ))}
                    <tr className={styles.print}>
                        <th colSpan={5}>Итого</th>
                        <td></td>
                        <td><b>{order?.total_quantity}</b></td>
                        <td><b>{order?.total_price}</b></td>
                    </tr>
                    </tbody>
                    <PdfFooterToOrder order={order} />
                </TableComponent>
                <br />
                {(!isDistObserver == !isJanyKitep) &&
                    <>
                        <p>{t('print.disclamer')}</p>
                        <p>{t('print.director2')} ____________________ {storage.read('director')}</p>
                        <p>{t('print.directorPin')}: {order?.director_pin}</p>
                        <p>{t('print.directorPhone')}: {order?.director_phone}</p>
                        <br />
                        <p>{t('print.disclamer')}</p>
                        <p>{t('print.librarian')} _______________ {order?.librarian}</p>
                        <p>{t('print.librarianPin')}: {order?.librarian_pin}</p>
                        <p>Номер: {order?.librarian_phone}</p>
                        <p>Почта: {order?.librarian_email}</p>
                        <p>{t('print.dateOrder')}: {today}</p>
                        <br />
                        <p>{t('print.stamp')}</p>
                    </>
                }
                <br />
                <br />
                <div>
                    {order?.librarian_doc && <>
                        <div>Документ директора: {order?.librarian_doc.split('/').slice(-1)}</div>
                        <button className="btn btn-primary" onClick={() => {
                            docDownloadHandler('librarian')
                        }}>Скачать
                        </button>
                    </>}
                    {order?.observer_doc && <>
                        <div>Документ районного наблюдателя: {order?.observer_doc.split('/').slice(-1)}</div>
                        <button onClick={() => {
                            docDownloadHandler('observer')
                        }} className="btn btn-primary mt-2">Скачать
                        </button>
                    </>}
                </div>
                {(isDirector && order?.status === 'director_review') &&
                    <div>
                        {order?.librarian_doc && <>
                            <button className="btn btn-success me-2 mt-2" onClick={approve}>Одобрить</button>
                        </>}
                        {!order?.librarian_doc && <>
                            <p><b>Для подтверждения, необходимо распечатать заявку, поставить подпись и печать, отканировать и загрузить файл</b></p>
                        </>}
                        <button className="btn btn-danger me-2 mt-2" onClick={() =>setShow(true)}>Отклонить</button>
                    </div>
                }
                {(isDistObserver && order?.status === 'district_review') &&
                    <div>
                        <button className="btn btn-success me-2 mt-2" onClick={approve}>Одобрить</button>
                        <button className="btn btn-danger me-2 mt-2" onClick={() =>setShow(true)}>Отклонить</button>
                    </div>
                }
                {(isDistObserver && generalOrderDeclined && order?.status === 'district_approved') &&
                    <div>
                        <button className="btn btn-danger me-2 mt-2" onClick={() =>setShow(true)}>Отклонить</button>
                    </div>
                }
                {(isDirector && order?.status === 'director_review') && <>
                    <input
                        type="file"
                        accept="application/pdf"
                        placeholder="Choose a file"
                        onChange={selectedFileHandler}
                        className="mt-4"
                    />
                    <button className="btn btn-info text-white" onClick={selectedFileUploadHandler}>Загрузить файл</button>
                </>}


                {/* {(total_price<=available_balance && total != 0) &&
                    <NavLink exact to="/store/checkout" className="text-white">
                        <Button variant="primary" className={styles.orderBtn} disabled={total>available_balance}>
                            <FontAwesomeIcon icon={faBook} className="mx-3"/> Перейти к оформлению заявки
                        </Button>
                    </NavLink>
                }
                {(total>available_balance || total === 0) &&
                    <div>
                        <Button variant="danger" className={styles.orderBtn} disabled={total>available_balance || total === 0}>
                            <FontAwesomeIcon icon={faBook} className="mx-3"/> Перейти к оформлению заявки
                        </Button>
                        <div style={{'width': '100%', 'textAlign': 'center', 'fontWeight': 700, 'fontSize': '24px', 'color': 'brown'}}><p>Недостаточно денежных средств</p></div>
                    </div>
                } */}


                {(order?.total_price<=available_balance && order?.total_price != 0) && (isLibrarian && (order?.status === 'director_declined' || order?.status === 'district_declined')) &&
                    <Button variant="primary" className={styles.orderBtn} disabled={order?.total_price>balance} onClick={handleResend}>
                        Повторно отправить директору на утверждение
                    </Button>
                }
                {(order?.total_price>available_balance || order?.total_price === 0) && (isLibrarian && (order?.status === 'director_declined' || order?.status === 'district_declined')) &&
                    <>
                        <p style={{color: 'red', fontSize: '25px'}}><b>Ваша сумма заказа больше баланса от аренды ! ! ! !</b></p>
                        <br />
                        <Button variant="primary" className={styles.orderBtn} disabled='true'>
                            Повторно отправить директору на утверждение
                        </Button>
                    </>
                }
                <br />
                <br />
                <br />
                <Modal
                    show={show}
                    onHide={function() {
                    setShow(false)
                    }}
                    keyboard={false}
                    size="lg"
                >
                    <Modal.Header>
                    <Modal.Title>{t('shop.order.modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(decline)}>
                    <Modal.Body>
                    <p>{t('shop.order.modal.body')}</p>
                    <InputField
                        control={control}
                        name='comment'
                        rules={{
                            required: t('error_message.no_empty'),
                            // max: {value: 60, message: t('classes.add-class.student_count_error')},
                            // min: {value: 1, message: t('classes.add-class.student_count_error')}
                        }}
                        label={t('events.field-comment')}
                        errors={errors.comment}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                    <ButtonC variant="close" onClick={function() {
                    setShow(false)
                    }}>
                        {t('btn.close')}
                    </ButtonC>
                    <button className="btn btn-danger me-2 mt-2" type='submit'>{t('shop.order.modal.decline')}</button>
                    </Modal.Footer>
                    </Form>
                </Modal>
                <Modal
                    show={showDelete}
                    onHide={function() {
                    setShowDelete(false)
                    }}
                    keyboard={false}
                    size="lg"
                >
                    <Modal.Header>
                    <Modal.Title>{t('shop.order.modalDelete.title')}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(() => deleteOrderItem(itemId))}>
                    <Modal.Body>
                    <p>{t('shop.order.modalDelete.body')}</p>
                    </Modal.Body>
                    <Modal.Footer>
                    <ButtonC variant="close" onClick={function() {
                    setShow(false)
                    }}>
                        {t('btn.close')}
                    </ButtonC>
                    <button className="btn btn-danger me-2 mt-2" type='submit'>{t('shop.order.modalDelete.decline')}</button>
                    </Modal.Footer>
                    </Form>
                </Modal>
            </Layout>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import {Layout} from '../app/layout'
import { ButtonC } from '../components';
import {useApi} from '../hooks'

export const WhomItIsRent = () => {
    const api = useApi();
    const params = useParams();
    const [rentedBooks, setRentedBooks] = useState();
    const [bookName, setBookName] = useState();
    const {t} = useTranslation()
    const getRentedBooks = () => {
        api.get(`school-books/${params.id}/debts`).then((response) => {
            setRentedBooks(response)
        })
    }
    const getBookName  = () => {
      api.get(`school-books/${params.id}`).then((response) => {
        setBookName(response)
      })
    }

    useEffect(() => {
        getRentedBooks(),
        getBookName()
    }, [])
  return (
    <Layout>
        <div className='container p-4'>
        <h3 className='text-center'>{t('school-books.rented-books.book-name')}: {bookName?.book?.name}</h3>
        <Link to='/school-books' className='bg-primary text-light p-2 rounded'>Назад к фонду учебников</Link>
        <ul className='list-group mt-4'>
          <li className='list-group-item'>{t('school-books.rented-books.book_count')}: {rentedBooks?.total}</li>
          <li className='list-group-item'>{t('school-books.rented-books.used_books')}: {rentedBooks?.given}</li>
      {rentedBooks?.classes.map((klass) => {
                return (
                  <>
                   <li className='list-group-item'>{t('school-books.rented-books.classes')}: {klass?.klass__grade} {klass?.klass__letter__name} Класс - {klass?.count} учебников</li>
                  </>
                )
            })}
            {rentedBooks?.teachers.map((teacher) => {
                return (
                  <>
                   <li className='list-group-item'> {t('school-books.rented-books.teachers')}: {teacher?.teacher__fullname} {teacher?.count} учебник</li>
                  </>
                )
            })}
        </ul>
        </div>
    </Layout>
  )
}

import {FormGroup, FormLabel} from "react-bootstrap"
import cn from 'classnames'
import styles from './select-field.module.css'
import {Controller} from "react-hook-form"
import { storage } from '../../service'
export const SelectField = ({
	translated = false,
	label,
	errors,
	options = [],
	optionGroup = [],
	className,
	register,
	required = true,
	control = null,
	name = '',
	rules,
	optGrpHead,
	...props
}) => {
	const language = storage.read('lang')

	return (
		<FormGroup className={cn(className, styles.wrapper)}>
			{label && <FormLabel className={styles.label}>
				{label}
				{required && <span className='asterix'> *</span>}
			</FormLabel>}
			{control ? <Controller
				control={control}
				name={name}
				rules={{...rules}}
				defaultValue={props?.defaultValue}
				shouldUnregister={true}
				render={({field}) => (
					<select
						{...props}
						{...field}
						className={cn(styles.select)}
					>
						{optGrpHead && <option value=''>{optGrpHead}</option>}
						{optionGroup && optionGroup.map((op) =>
							<optgroup key={op.label} label={op.label}>
								{op.options.map((o) => <option key={o.value} value={o.value}>
									{translated && language==='ky'?o.label_kg:o.label}
								</option>)}
							</optgroup>)}
						{options && options.map((o) => <option key={o.value} value={o.value}>
							{translated && language==='ky'?o.label_kg:o.label}
						</option>)}
					</select>
				)}
			/> :
				<select
					{...register}
					{...props}
					className={cn(styles.select)}
				>
					{optGrpHead && <option value=''>{optGrpHead}</option>}
					{optionGroup && optionGroup.map((op) => <optgroup key={op.label} label={op.label}>
						{op.options.map((o) => <option key={o.value} value={o.value}>
						{translated && language==='ky'?o.label_kg:o.label}
						</option>)}
					</optgroup>)}
					{options && options.map((o) => <option key={o.value} value={o.value}>
						{translated && language==='ky'?o.label_kg:o.label}
					</option>)}
				</select>
			}
			{errors && <FormLabel style={{color: 'red'}}>{errors.message}</FormLabel>}
		</FormGroup>
	)
}
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonC } from '../../../components'
import { storage } from '../../../service'
import { FilterModal } from './FilterModal'

export const D10Filter = ({onChange}) => {
  const [openModal, setOpenModal] = useState(false);
  const {t} = useTranslation()
  return (
  <>
    <FilterModal
			show={openModal}
			setShow={setOpenModal}
      onChange={onChange}
			/>
		<ButtonC
      variant='primary'
      onClick={() =>  setOpenModal(true)}
      style={{ marginBottom: '15px', backgroundColor: 'dodgerblue', color: '#fff'}}
      >
        <FontAwesomeIcon
          style={{color: '#fff'}}
          icon={faFilter} /> {t('report.search_by_filter')}
      </ButtonC>
</>
  )
}

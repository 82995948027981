import React, {useEffect, useState, useRef} from "react";
import {useParams} from "react-router";
import {Layout} from "../../../app/layout";
import {api} from "../../../service/old-api-2";
import styles from './order-detail.module.css'
import {read} from "../../../service/storage";
import {notifyError, notifySuccess} from "../../../components/notify";
import {isLibrarian, isDistObserver, isDirector, isJanyKitep} from "../../../lib/helper";
import {  Modal, Form } from "react-bootstrap";
import {ButtonC, DeleteNotifyWithRedux, InputField, PdfHeader, TableComponent } from "../../../components";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import classes from '../../../feature/school-books/books.module.css';
import {
    moveToCart,
    updateTotalPrice
} from "../../../app/store/bookstore-reducer.js";
import {useDispatch} from "react-redux";
import {faTrash, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, ListGroup} from "react-bootstrap";
import {useAppSelector} from "../../../hooks";
import { thunks } from "../../../app/store/bookstore-reducer";
import {useReactToPrint} from 'react-to-print'
import { PdfHeaderToOrder } from "../../../components/pdf-header/pdf-header-to-order";
import { PdfFooterToOrder } from "../../../components/pdf-header/pdf-footer-to-order";
import {storage} from '../../../service'
import {langStatus, transLangFromEng} from '../../../lib/helper'
import { PdfFooterToOrderDistrict } from "../../../components/pdf-header/pdf-footer-to-order-district";
import { useHistory } from "react-router";
import { PdfHeaderToOrderDistrict } from "../../../components/pdf-header/pdf-header-to-order-district";

export const GeneralOrderDetail = () => {
    const today = new Date(Date.now()).toLocaleDateString()
    const componentRef = useRef(null)
    const actions = {
        fetchBalance: thunks.fetchBalance,
      };
    const balance = useAppSelector((state) => state["bookstore"]["balance"])
    // const {updateSchoolBook, deleteSchoolBook} = useActions(actions)
    const dispatch = useDispatch()
    const {handleSubmit, register, reset, formState: {errors}, control, setValue} = useForm()
    const {t} = useTranslation()
    const [show, setShow] = useState(false)
    const [readyForGeneralOrder, setReadyForGeneralOrder] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [itemId, setItemId] = useState(0)
    const [order, setOrder] = useState({})
    const [selectedFile, setSelectedFile] = useState(null)
    const params = useParams()
    const history = useHistory();
    const selectedFileHandler = (event) => {
        setSelectedFile(event.target.files[0])
    }
    const onFocus = (id) => {
        const btn = document.querySelector(`#button${id}`)
        btn.style.borderColor = '#0d6efd'
        btn.style.color = 'black'
    }

    const onBlur = (id) => {
        const btn = document.querySelector(`#button${id}`)
        btn.style.borderColor = '#90909a'
        btn.style.color = 'black'
    }
    const selectedFileUploadHandler = () => {
        const access = read('access')
        if (selectedFile) {
            const fd = new FormData()
            fd.append("doc", selectedFile, selectedFile.name)
            const uri = `${process.env.REACT_APP_BASE_URL}/api/store/general-orders/${params?.id}/documents/`
            fetch(uri, {
                method: 'POST',
                body: fd,
                headers: {
                    "Authorization": `Bearer ${access}`,
                }
            }).then((response) => {
                if (response.ok) {
                    return response.json().then((data) => {
                        setOrder(data)
                        notifySuccess("Документ успешно загружен.")
                    })
                } else if (response.status === 400) {
                    response.json().then((errorData) => {
                        const errorMessage = errorData[0]
                        notifyError(errorMessage)
                    })
                }
            })
        } else {
            notifyError("No file is selected.")
        }
    }
    const docDownloadHandler = (doc) => {
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/general-orders/${params?.id}/documents/?file=${doc}`
        fetch(uri, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${access}`,
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `document.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }
    const approve = () => {
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/general-orders/${params?.id}/approve/`
            fetch(uri, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${access}`,
                }
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then((data) => {
                setOrder(data)
                if (isDistObserver) {
                    history.push("/store/orders-shools");
                    notifySuccess("Заявка передана Жаны китеп на утверждение и дальнейший закуп.")
                }
            })
    }
    const decline = (data) => {
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/general-orders/${params?.id}/decline/`
            fetch(uri, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${access}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then((data) => {
                setOrder(data)
                notifySuccess("Заявка отклонена")
                setShow(false)
            })
    }
    const changeQuantity = async (orderItem) => {
        const quantity = document.querySelector(`#quantity${orderItem.id}`)
        if (orderItem.quantity === quantity.value) {
            notifyError('Новая значения должно быть отличным от старого')
            return
        }
        const body = {
            quantity: quantity.value,
        }
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/order-item/${orderItem.id}/`
        fetch(uri, {
            method: 'PATCH',
            headers: {
                "Authorization": `Bearer ${access}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }).then((response) => {
            if (response.ok) {
                return response.json()
            }
        }).then(() => {
            setOrder()
            notifySuccess("Кол-во книги в заявке изменено")
        })

    }
    const deleteOrderItem = async (orderItemId) => {
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/store/order-item/delete/${orderItemId}/`
        fetch(uri, {
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${access}`,
            },
        }).then((response) => {
            if (response.ok) {
                return response.json()
            }
        }).then(() => {
            setOrder()
            notifySuccess("Книга удалена с заявки")
            setShowDelete(false)
        })

    }
    // const handleResend = () => {
    //     const access = read('access')
    //     const uri = `${process.env.REACT_APP_BASE_URL}/api/store/orders/${params?.id}/resend/`
    //         fetch(uri, {
    //             method: 'PUT',
    //             headers: {
    //                 "Authorization": `Bearer ${access}`,
    //             }
    //         }).then((response) => {
    //             if (response.ok) {
    //                 return response.json()
    //             }
    //         }).then((data) => {
    //             setOrder(data)
    //             notifySuccess("Заявка отправлена на утверждение директору.")
    //         })
    // }
    const GetRreadyForGeneralOrder = () => {
        api.get(`store/ready-for-general-order/`).then(
            (response) => {
                if (response.status === 'success') {
                    setReadyForGeneralOrder(true)
                }
            })
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
      })
    useEffect(() => {
        GetRreadyForGeneralOrder();
      }, []);
    useEffect(() => {
        dispatch(actions.fetchBalance());
      }, [dispatch]);
    useEffect(() => {
        try {
            api.get(`store/general-orders/${params.id}/`)
                .then((data) => {
                    setOrder(data)
                })
        } catch (error) {
            throw new Error(error)
        }
    }, order)
    const ordersDate = new Date(Date(order?.ordered_date))
	const year = ordersDate.toLocaleString("default", { year: "numeric" });
	const month = ordersDate.toLocaleString("default", { month: "2-digit" });
	const day = ordersDate.toLocaleString("default", { day: "2-digit" });
	const formattedDate = day + "-" + month + '-' + year;

    const books = {}
    order?.orders?.map((schoolOrder, index) => {
        schoolOrder.order_items.map((book) => {
            if (book.book_id in books) {
                books[book.book_id].quantity = books[book.book_id].quantity + book.quantity
            } else {
                books[book.book_id] = {
                            'id': book.book_id,
                            'name': book.book_name,
                            'author': book.book_author,
                            'grades': book.book_grades,
                            'language': book.book_language,
                            'price': book.book_price,
                            'quantity': book.quantity,
                            // 'schools': [f"{school.id} - {order_item.quantity}"]
                        }
            }
        })
    })
    const new_books = Object.values(books)
    let total = 0;
    let totalCount = 0;
    new_books?.forEach((book) => {
        total += book['price'] * book['quantity']
        totalCount += book['quantity']
    })

    const getPageMargins = () => {
        return `@page { margin: ${20} ${50} ${20} ${50} !important; }`;
      };

    return (
        <>
            <Layout>
                <style type="text/css" media="print">{"\
                    @page {\ size: portrait;\ }\
                "}</style>
                <p style={{textAlign: 'center'}}>
                    <h3 style={{fontSize: '38px', fontWeight: 600, letterSpacing: '5px'}}>{t('shop.order.forPrint.title', {value: order?.id})}</h3><span>от {formattedDate} на {parseInt(order?.study_period_start) + 1} - {parseInt(order?.study_period_end) + 1} учебный год</span>
                </p>
                <br />
                <div>Статус: <b>{langStatus(order?.status)}</b></div>
                <p>{t('print.region_only')}: <b>{order?.region}</b></p>
                <p>{t('print.region_id')}: <b>{order?.region_id}</b></p>
                <p>{t('print.district_only')}: <b>{order?.district}</b></p>
                <p>{t('print.district_id')}: <b>{order?.district_id}</b></p>
                {order?.comment && <div>Причина отказа: {order?.comment}</div>}
                <br />
                    <ButtonC variant='download' onClick={handlePrint} style={{float: 'left', marginBottom: '15px', backgroundColor: 'red', color: '#fff'}}>
                        СКАЧАТЬ ЗАЯВКУ <FontAwesomeIcon style={{color: '#fff'}} icon={faFilePdf} />
                    </ButtonC>
                <TableComponent ref={componentRef}>
                    <style> {`
                            @media print { 
                            html, body { 
                                height: initial !important; 
                                overflow: initial !important; 
                                -webkit-print-color-adjust: exact; 
                            } 
                        } 
                        @media all {
                        .page-break {
                            display: none;
                        }
                        }
                        @media print {
                        .page-break {
                            margin-top: 1rem;
                            display: block;
                            page-break-before: auto;
                        }
                        }

                        @page {
                        size: auto;
                        margin: 20mm;
                        }
                        `
                    }
                    </style>
                    <PdfHeaderToOrderDistrict title={t('shop.order.forPrint.title', {value: order?.id})} order={order} />
                    <br />
                    <thead className="pt-3">
                    <tr>
                        <th>№</th>
                        <th>Наименование учебника</th>
                        <th>Автор</th>
                        <th>Класс</th>
                        <th>Язык</th>
                        <th>Цена</th>
                        <th>Количество</th>
                        <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* {order && order.order_items?.map((orderItem) => ( */}
                    {order && new_books?.map((orderItem, index) => (
                        <tr key={orderItem.id}>
                            <td>{index + 1}</td>
                            <td>{orderItem?.name}</td>
                            <td>{orderItem?.author}</td>
                            <td>{orderItem?.grades.slice(1, -1)}</td>
                            <td>{transLangFromEng(orderItem?.language)}</td>
                            <td>{orderItem?.price}</td>
                            <td>{orderItem?.quantity}</td>
                            <td>{orderItem?.price * orderItem?.quantity}</td>
                        </tr>
                    ))}
                    <tr className={styles.print}>
                        <th colSpan={5}>Итого</th>
                        <td></td>
                        <td>{totalCount}</td>
                        <td>{total}</td>
                    </tr>
                    </tbody>
                    <PdfFooterToOrderDistrict order={order} />
                </TableComponent>
                <br />
                <p>{t('print.disclamer')}</p>
                <p>Районный методист (ФИО, подпись) {order?.district_observer}_________________</p>
                <p>ПИН {order?.district_observer_inn}</p>
                <p>Номер телефона {order?.district_observer_phone}</p>
                <br />
                <p>{t('print.dateOrder')}: {today}</p>
                <br />
                <p>{t('print.stamp')}</p>
                <br />
                <br />
                {(isDistObserver && (order?.status === 'district_review' || order?.status === 'janykitep_declined') ) && <>
                    <input
                        type="file"
                        accept="application/pdf"
                        placeholder="Choose a file"
                        onChange={selectedFileHandler}
                        className="mt-4"

                    />
                    <button className="btn btn-info text-white" onClick={selectedFileUploadHandler}>Загрузить файл</button>
                </>}
                <br />
                <br />
                <div>
                    {order?.observer_doc && <>
                        <div>Документ районного наблюдателя: {order?.observer_doc.split('/').slice(-1)}</div>
                        <button onClick={() => {
                            docDownloadHandler('observer')
                        }} className="btn btn-primary mt-2">Скачать
                        </button>
                    </>}
                </div>
                {(isDistObserver && order?.status === 'district_review') &&
                    <div>
                        {order?.observer_doc && <>
                            <button className="btn btn-success me-2 mt-2" onClick={approve}>Отправить Жаны китеп</button>
                        </>}
                        {!order?.observer_doc && <>
                            <p><b>Для отправки заявки Жаны китеп, необходимо распечатать заявку, поставить подпись и печать, отканировать и загрузить файл</b></p>
                            <button className="btn btn-success me-2 mt-2 disabled">Отправить Жаны китеп</button>
                        </>}
                    </div>
                }
                {(isDistObserver && order?.status === 'janykitep_declined') &&
                    <div>
                        {(order?.observer_doc && readyForGeneralOrder) && <>
                            <button className="btn btn-success me-2 mt-2" onClick={approve}>Переотправить Жаны китеп</button>
                        </>}
                        {(!order?.observer_doc || !readyForGeneralOrder) && <>
                            <p><b>Для переотправки заявки Жаны китеп, необходимо исправить ошибку, утвердить все заявки школ и затем обрано распечатать заявку, поставить подпись и печать, отканировать и загрузить файл</b></p>
                            <button className="btn btn-success me-2 mt-2 disabled">Переотправить Жаны китеп</button>
                        </>}
                    </div>
                }
                {(isJanyKitep && order?.status === 'janykitep_review') &&
                    <div>
                        {/* {order?.observer_doc && <> */}
                            <button className="btn btn-success me-2 mt-2" onClick={approve}>Принять</button>
                            <button className="btn btn-danger me-2 mt-2" onClick={() =>setShow(true)}>Отклонить</button>
                        {/* </>}
                        {!order?.observer_doc && <>
                            <p><b>Для отправки заявки Жаны китеп, необходимо распечатать заявку, поставить подпись и печать, отканировать и загрузить файл</b></p>
                            <button className="btn btn-success me-2 mt-2 disabled">Отправить Жаны китеп</button>
                        </>} */}
                    </div>
                }
                <br />
                <br />
                <br />
                <Modal
                    show={show}
                    onHide={function() {
                    setShow(false)
                    }}
                    keyboard={false}
                    size="lg"
                >
                    <Modal.Header>
                    <Modal.Title>{t('shop.order.modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(decline)}>
                    <Modal.Body>
                    <p>{t('shop.order.modal.body')}</p>
                    <InputField
                        control={control}
                        name='comment'
                        rules={{
                            required: t('error_message.no_empty'),
                            // max: {value: 60, message: t('classes.add-class.student_count_error')},
                            // min: {value: 1, message: t('classes.add-class.student_count_error')}
                        }}
                        label={t('events.field-comment')}
                        errors={errors.comment}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                    <ButtonC variant="close" onClick={function() {
                    setShow(false)
                    }}>
                        {t('btn.close')}
                    </ButtonC>
                    <button className="btn btn-danger me-2 mt-2" type='submit'>{t('shop.order.modal.decline')}</button>
                    </Modal.Footer>
                    </Form>
                </Modal>
            </Layout>
        </>
    )
}

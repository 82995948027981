import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import {
  ButtonC,
  PdfHeader,
  PdfHeaderDistrict,
  TableComponent,
} from "../../../components";
import { storage } from "../../../service";
import { D10DownloadFile } from "../d10-download-file";
import { api } from "../../../service/new-api-2";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useReactToPrint } from "react-to-print";
export const G3BeneficiariesDistrict = () => {
  const [beneficiaries, setBeneficiaries] = useState();
  const [allStudentsCount, setAllStudentsCount] = useState(0);
  const [all50DiscountCount, setAll50DiscountCount] = useState(0);
  const [all100DiscountCount, setAll100DiscountCount] = useState(0);
  const { t } = useTranslation();
  const district_id = storage.read("district_id");

  function calculateTotalClasses(school) {
    const classes = school.classes;
    const classTotals = {};
    classes.forEach((cls) => {
      const grade = cls.grade;
      if (!classTotals[grade]) {
        classTotals[grade] = {
          grade: grade,
          student_count2: 0,
          count_50_discount: 0,
          count_100_discount: 0,
        };
      }
      classTotals[grade].student_count2 += parseInt(cls.student_count2);
      classTotals[grade].count_50_discount += parseInt(cls.count_50_discount);
      classTotals[grade].count_100_discount += parseInt(cls.count_100_discount);
    });

    return Object.values(classTotals);
  }

  const getBeneficiaries = () => {
    api
      .get(`reports/students-with-discount/?district_id=${district_id}`)
      .then((response) => {
        if (response && response.length > 0) {
          const schoolsWithTotalClasses = response.map((school) => ({
            ...school,
            totalClasses: calculateTotalClasses(school),
          }));
          setBeneficiaries(schoolsWithTotalClasses);
          let totalStudentsCount = 0;
          let total50DiscountCount = 0;
          let total100DiscountCount = 0;
          response.forEach((beneficiary) => {
            totalStudentsCount += parseInt(beneficiary.student_count);
            total50DiscountCount += parseInt(
              beneficiary.student_count_50_discount
            );
            total100DiscountCount += parseInt(
              beneficiary.student_count_100_discount
            );
          });
          setAllStudentsCount(totalStudentsCount);
          setAll50DiscountCount(total50DiscountCount);
          setAll100DiscountCount(total100DiscountCount);
        }
      });
  };
  const componentRef = useRef(null);

  useEffect(() => {
    getBeneficiaries();
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      {" "}
      <>
        <ButtonC
          variant="download"
          onClick={handlePrint}
          style={{
            float: "left",
            marginBottom: "15px",
            backgroundColor: "red",
            color: "#fff",
          }}
        >
          PDF <FontAwesomeIcon style={{ color: "#fff" }} icon={faFilePdf} />
        </ButtonC>
        <TableComponent
          ref={componentRef}
          style={{
            margin: "5%",
            padding: "5%",
            width: "90%",
            minWidth: "150px",
          }}
        >
          <style type="text/css" media="print">
            {"@page {size: portrait;}"}
          </style>

          <PdfHeaderDistrict title={t("report.g3.title")} />
          <thead>
            <tr>
              <td>№</td>
              <td>{t("report.d10.secondHead.school_id")}</td>
              <td>{t("report.d10.district.school")}</td>
              {/* <td>{t("report.g3.class")}</td> */}
              <td>{t("report.g3.number_of_students")}</td>
              <td>{t("report.g3.student_with_50_percent")}</td>
              <td>{t("report.g3.student_with_100_percent")}</td>
            </tr>
          </thead>
          <tbody>
            {/* {console.log(beneficiaries)} */}
            {beneficiaries &&
              beneficiaries.length > 0 &&
              beneficiaries.map((beneficiary, index) => {
                return (
                  <tr key={beneficiary.id}>
                    <td data-label="№">{index + 1}</td>
                    <td data-label="ID школы">{beneficiary.id}</td>
                    <td data-label="Школа">{beneficiary.school_name}</td>
                    {/* <td data-label="Класс">
                        <ul>
                            {beneficiary.totalClasses.map((cls) => (
                            <li key={cls.grade}>
                                Grade {cls.grade}: Student Count: {cls.student_count2}, 50% Discount: {cls.count_50_discount}, 100% Discount: {cls.count_100_discount}
                            </li>
                            ))}
                        </ul>
                    </td> */}
                    <td data-label="Количество учащихся">
                      <ul>
                        {beneficiary.totalClasses.map((cls) => (
                          <li key={cls.grade}>
                            {cls.grade} кл. - {cls.student_count2}
                          </li>
                        ))}
                      </ul>
                      <b>Всего - {beneficiary.student_count}</b>
                    </td>
                    <td data-label="Количество льготников 50% скидкой">
                      <ul>
                        {beneficiary.totalClasses.map((cls) => (
                          <li key={cls.count_50_discount}>
                            {cls.grade} кл. - {cls.count_50_discount}
                          </li>
                        ))}
                      </ul>
                      <b>Всего - {beneficiary.student_count_50_discount}</b>
                    </td>
                    <td data-label="Количество льготников 100% скидкой">
                      <ul>
                        {beneficiary.totalClasses.map((cls) => (
                          <li key={cls.count_100_discount}>
                            {cls.grade} кл. - {cls.count_100_discount}
                          </li>
                        ))}
                      </ul>
                      <b>Всего - {beneficiary.student_count_100_discount}</b>
                    </td>
                  </tr>
                );
              })}
            {beneficiaries && beneficiaries.length > 0 && (
              <tr>
                <td colSpan={3}>
                  <b>Всего</b>
                </td>
                <td data-label="Количество учащихся">
                  <b>{allStudentsCount}</b>
                </td>
                <td data-label="Количество льготников 50% скидкой">
                  <b>{all50DiscountCount}</b>
                </td>
                <td data-label="Количество льготников 100% скидкой">
                  <b>{all100DiscountCount}</b>
                </td>
              </tr>
            )}
          </tbody>
        </TableComponent>
      </>
    </div>
  );
};

import {RestorePassword} from "../feature/proflle"
import {usePageTitle} from "../hooks"
import styles from '../feature/proflle/login.module.css'
import {useTranslation} from "react-i18next"
import {ChangeLanguage} from "../components"
import {Col, Row} from "react-bootstrap"

export const RestorePasswordPage = () => {
	const {t} = useTranslation()
	usePageTitle(`${t('profile.restore-password.title')}`)
	return (
		<div>
			<div className={`${styles.wrapperRestore}`}>
				<Row className='mb-4'>
					<Col md={9} xs={12}>
						<h2>{t('profile.restore-password.title')}</h2>
					</Col>
					<Col md={3} xs={6}>
						<ChangeLanguage />
					</Col>
				</Row>

				<RestorePassword />
			</div>
		</div>
	)
}
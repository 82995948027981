import { storage } from "../../service";
import styles from "./videos.module.css";

export const VideoItem = ({ title, url }) => {
  return (
    <div className={styles.card}>
      <iframe
        width="400"
        height="200"
        src={`${url}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <h5 className={styles.videoItemTitle}>{title}</h5>
    </div>
  );
};

import React, {useEffect, useState} from 'react';
import {Table} from 'react-bootstrap';
import { api } from '../../../service/old-api-2';
import { D10 } from '../d10/D10';
import { D10Table } from '../d10/D10Table';
import { D10SubHead } from '../school/d10-sub-head';

export const D10SubRepubItem = () => {
	const [regions, setRegions] = useState();
	const [d10Data, setD10Data] = useState();
	const getRegions = () => {
		api.get('references/regions/').then((res) => setRegions(res))
	}
	const fetchD10Data = () => {
		api.get(`reports/d10/?language=kyrgyz`).then((response) => {
		  setD10Data(response);
		})
	  }
	useEffect(() => {
		getRegions(),
		fetchD10Data()
	}, [])
	return (
		<D10 initQuery={{'language': 'kyrgyz', 'part': 1 }}/>
	)
}

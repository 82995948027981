import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { api } from '../../../service/old-api-2'
import './styles.css'
import {useParams} from 'react-router-dom';
import { ButtonC, TableComponent } from '../../../components'
export const OpenDataDistricts = () => {
    const params = useParams()
    const [districts, setDistricts] = useState();
    useEffect(() => {
        api.get(`references/districts/?region=${params.id}`).then((response) => {
            setDistricts(response)
        })
    }, [])
  return (
    <>
   <Container>
   <div className='open-data-layout'>
    <TableComponent>
        <thead>
            <th>Наименование</th>
            <th>Код района</th>
            <th>Действие</th>
        </thead>
        <tbody>
            {
                districts?.map((dist) => {
                    <div key={dist.id}>
                    <td>{dist?.name}</td>
                    <td>{dist?.id}</td>
                    <ButtonC>Посмотреть школы</ButtonC>
                    </div>
                })
            }
            <td>

            </td>
        </tbody>
    </TableComponent>
        </div>
   </Container>
    </>
  )
}

export default OpenDataDistricts
import {Col, Form, Modal, Row} from "react-bootstrap";
import classes from './action-book.module.css';
import {useForm} from "react-hook-form";
import {notifySuccess} from "../../components/notify";
import {useState, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {ButtonC, InputField, SelectField} from "../../components";
import {transLang, years} from "../../lib/helper";
import {thunks} from '../school-books/school-books-reducer';
import {thunks as classBookThunks} from './class-book-reducer';
import {useActions, useAppSelector} from '../../hooks';

const actions = {
  searchSchoolBooks: thunks.searchSchoolBooks,
  addClassBook: classBookThunks.addClassBook
}

export const AddClassBook = ({show, setShow, klass}) => {
  const renderBook = useAppSelector((state) => state.schoolBooks.searchResult)
  const classBooks = useAppSelector((state) => state.classBooks.classBooks)
  const {searchSchoolBooks, addClassBook} = useActions(actions)
  const [query, setQuery] = useState({})
  const {register, control, handleSubmit} = useForm()
  const {t} = useTranslation()

  const onSubmit = async (data) => {
    setQuery({
      book__name__icontains: data.book__name__icontains,
      year: data.book__year__in,
    })
  }
  const handleClose = () => {
    setShow(false)
  }

  const handleAddClassBook = async (id) => {
    const body = {
      klass: klass?.id,
      school_book: id,
      count: 0,
    }
    const payload = await addClassBook(body)
    if (payload.payload) {
      notifySuccess(`${t('notify.success.added')}`)
    }

  }

  const yearChange = (data) => {
    setQuery((query) => {
      return {...query, year: data}
    })
  }
  const bookChange = (data) => {
    setQuery((query) => {
      return {...query, book__name__icontains: data}
    })
  }
  useMemo(async () => {
    if (klass?.name) {
      const data = {
        ...query,
        book__grades__contains: `{${parseInt(klass.name)}}`,
        book__language__in: transLang(klass.language),
      }
      searchSchoolBooks(data)
    }
  }, [query])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="lg"
        scr
      >
        <Modal.Header>
          <Modal.Title>{t('action-books.class-action.add_class_book.btn')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row xs={1} md={3}>
              <Col>
                <InputField
                  type='text'
                  name='book__name__icontains'
                  placeholder={t('action-books.class-action.add_class_book.name_book')}
                  control={control}
                  onChange={ query}
                />
              </Col>
              <Col>
                <SelectField
                  register={{...register('book__year__in')}}
                  options={[
                    {value: '', label: 'Год'},
                    ...years.map((y) => ({value: y, label: y}))
                  ]}
                  onChange={(event) => yearChange(event.target.value)}
                />
              </Col>
              <Col>
                <ButtonC
                  variant='search'
                  type="submit"
                  style={{marginBottom: '4px'}}
                >{t('btn.search')}
                </ButtonC>
              </Col>
            </Row>

          </Form>
        </Modal.Body>
        <div className={classes.line}></div>

        <Modal.Body className={`p-3 ${classes.bookslist}`}>
          <ul >
            {
              renderBook.length > 0 ? renderBook.map((b) => (
                <li className={`d-flex align-items-center w-100 ${classes.bookItem}`} key={b.id}>
                  <ul className="ms-4">
                    <li>{t('action-books.class-action.add_class_book.name')}: &nbsp;{b.book.name} {b.book.number_of_parts > 1 ? <>({b.part}-{t('school-books.part')})</> : ''}</li>
                    <li>{t('action-books.class-action.add_class_book.author')}: &nbsp;{b.book.author}</li>
                    <li>{t('action-books.class-action.add_class_book.Class')}: &nbsp;{b.book.grades?.join(',')}</li>
                    <li>{t('action-books.class-action.add_class_book.language')}: &nbsp;{b.book.language}</li>
                    <li>{t('action-books.class-action.add_class_book.year')}: &nbsp;{b.year}</li>
                  </ul>
                  {!classBooks.find((el) => el.school_book.id === b.id) ?
                    <ButtonC
                      variant="add"
                      className='ms-auto'
                      type='submit'
                      onClick={() => handleAddClassBook(b.id)}
                    >
                      {t('btn.add')}
                    </ButtonC> :
                    <ButtonC
                      variant="disable"
                      className="ms-auto"
                    >
                      {t('btn.added')}
                    </ButtonC>
                  }
                </li>
              )) : <div className='d-flex align-content-center justify-content-center'>
                {t('action-books.class-action.add_class_book.is_empty')}
              </div>
            }
          </ul>

        </Modal.Body>
        <Modal.Footer>
          <ButtonC variant="close" onClick={() => setShow(false)}>
            {t('btn.close')}
          </ButtonC>
        </Modal.Footer>
      </Modal>
    </>
  )
}
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'

const namespace = 'classBook'
const uri = 'class-book'

const fetchClassBooks = createAsyncThunk(
	`${namespace}/get`,
	async ({klass, grade}) => {
		let classBooks

		try {
			classBooks = await api.get(`${uri}`, {
				search: {
					klass: klass,
					klass__grade: grade
				}
			})
		} catch (error) {
			throw new Error(error)
		}

		return {classBooks}
	})


const addClassBook = createAsyncThunk(
	`${namespace}/add`,
	async (data) => {
		let classBook

		try {
			await api.post(`${uri}`, {body: data}).then(async (res) => {
				classBook = await api.get(`${uri}/${res.id}`)
			})
		} catch (error) {
			throw new Error(error)
		}
		return {classBook}
	})

const updateClassBook = createAsyncThunk(
	`${namespace}/update`,
	async ({id, data}) => {
		let classBook


		try {
			await api.post(`${uri}/${id}/operation`, {body: data}).then(async () => {
				classBook = await api.get(`${uri}/${id}`)
			})
		} catch (error) {
			throw new Error(error)
		}

		return {classBook}
	})

const deleteClassBook = createAsyncThunk(
	`${namespace}/delete`,
	async (id) => {
		try {
			await api.delete(`${uri}/${id}`)
		} catch (error) {
			throw new Error(error)
		}
		return {id}
	})

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		classBooks: [],
		loading: false,
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchClassBooks.fulfilled, (state, action) => {
				state.classBooks = action.payload.classBooks
			})
			.addCase(addClassBook.fulfilled, (state, action) => {
				state.loading = false
				state.classBooks.push(action.payload.classBook)
			})
			.addCase(updateClassBook.fulfilled, (state, action) => {
				const index = state.classBooks.findIndex((t) => t.id == action.payload.classBook.id)
				state.classBooks[index] = action.payload.classBook
			})
			.addCase(deleteClassBook.fulfilled, (state, action) => {
				state.loading = false
				state.classBooks = state.classBooks.filter((t) => t.id != action.payload.id)
			})
})

const thunks = {fetchClassBooks, updateClassBook, deleteClassBook, addClassBook}

export {reducer, thunks}

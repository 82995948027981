import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { api } from "../../service/old-api-2";
import { storage } from "../../service";
import { VideoItem } from "./video-item";

export const Videos = () => {
  const [videos, setVideos] = useState();
  const getVideos = () => {
    api.get("videos/").then((response) => {
      setVideos(response.reverse());
    });
  };

  useEffect(() => {
    getVideos();
  }, []);
  return (
    <Row xs={1} md={2}>
      {videos?.map((vid) => (
        <Col key={vid.url}>
          <VideoItem title={vid?.name} url={vid?.url} />
        </Col>
      ))}
    </Row>
  );
};

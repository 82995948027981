import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { api } from '../../service/old-api-2';

const namespace = 'filling-report'

const fetchFillingReport = createAsyncThunk(
    `${namespace}/get`,
    async () => {
        let report;

        try {
            report = await api.get('reports/filling-report/')
            // console.log(report)
        } catch (error) {
            throw new Error(error);
        }
        return {report}
    }
)

const {reducer} = createSlice({
    name: namespace,
    initialState: {
        report: [],
    },
    reducers: [],
    extraReducers: (builder) =>
    builder
    .addCase(fetchFillingReport.fulfilled, (state, action) => {
        state.report =action.payload.report.data
    })
})

const thunks = {fetchFillingReport}

export {reducer, thunks}
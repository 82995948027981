import React from 'react'
import { Layout } from '../app/layout'
import './report.css'

export const Questions = () => {
  return (
    <Layout>
        <h1 className='title'>Вопросы и Ответы (ЧАВО)</h1>
        <p style={{textAlign: 'center', marginTop: '20px'}}>Часто задаваемые вопросы и ответы будут пополняться по ссылке ниже</p>
        <p style={{textAlign: 'center', marginTop: '20px'}}><a href="https://docs.google.com/document/d/1_2d6tM4ud5EY9x-KzGng0ZBFGTCVSx8QRfcq0DG6VTk/edit?usp=sharing" target="blank">Документ</a></p>
    </Layout>
  )
}

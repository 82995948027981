import React, { useEffect, useState } from "react";
import styles from "./store.module.css";
import { Layout } from "../../app/layout";
import { useAppSelector, usePageTitle } from "../../hooks";
import { useDispatch } from "react-redux";
import { thunks } from "../../app/store/bookstore-reducer";
import { BookList } from "./book-list";
import { SidebarCart } from "./sidebar-cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { ButtonC, InputField, SelectField } from "../../components";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Footer } from "../../components/footer/Footer";
import { faStore, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { notifySuccess } from "../../components/notify";
import {api} from "../../service/old-api-2";
import {read} from "../../service/storage.js";

export const BookShowcase = () => {
  usePageTitle("Каталог учебников");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [canCancelOrder, setCanCancelOrder] = useState(false);
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState({});
  const {handleSubmit, register, reset, formState: {errors}, control, setValue} = useForm()
  const books = useAppSelector((state) => state["bookstore"]["currentBooks"]);
  const { t } = useTranslation();
  const balance = useAppSelector((state) => state["bookstore"]["balance"]);
  const available_balance = useAppSelector(
    (state) => state["bookstore"]["available_balance"]
  );
  const shop_grades = useAppSelector(
    (state) => state["bookstore"]["shop_grades"]
  );
  const totalPrice = useAppSelector(
    (state) => state["bookstore"]["totalPrice"]
  );
  const [show, setShow] = useState(false);
  const [declined, setDeclined] = useState();
  const actions = {
    fetchBooks: thunks.fetchBooks,
    fetchStudentsCountByGrade: thunks.fetchStudentsCountByGrade,
    fetchBalance: thunks.fetchBalance,
  };
  const handleHover = () => {
    setOpen(!open);
  };
  const next = () => {
    if (books["next"]) {
      const localOffset = parseInt(books["next"].split("offset=")[1]);
      setOffset(localOffset);
    }
  };
  const previous = () => {
    if (books["previous"]) {
      const localOffset = parseInt(books["previous"].split("offset=")[1]) || 0;
      setOffset(localOffset);
    }
  };
  const categories = useAppSelector((state) => state.categories.categories);
  const categoriesOptions = categories.map((c) => {
    return {
      label: c.name,
      options: c?.subjects?.map((s) => {
        return { label: s.name, value: s.id };
      }),
    };
  });
  const categoryChange = (data) => {
    setQuery((query) => {
      return { ...query, subject: data };
    });
    setOffset(0);
  };

  const checkCanCancelOrder = () => {
    api.get("store/ready-for-cancel-order").then((response) => {
      console.log(response);
      if (response.status === 'success') {
        setCanCancelOrder(true);
      } else {
        setCanCancelOrder(false);
      }
    });
  };

  const checkDecline = () => {
    api.get("will-not-order-in-period").then((response) => {
      if (response === true) {
        setDeclined(true);
      } else {
        setDeclined(false);
      }
    });
  };
  const confirmedDecline = (data) => {
    const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/set_will_not_order_in_period/`
            fetch(uri, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${access}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then((data) => {
                setDeclined(true);
                notifySuccess("Успешно отказались от закупа")
                setShow(false)
            })
    // api.post("set_will_not_order_in_period").then((response) => {
    //   if (response.status === "success") {
    //     // dispatch(setBalanceConfirm());
    //     setDeclined(true);
    //     notifySuccess("Успешно отказались от закупа");
    //   } else {
    //     notifySuccess("Отказ отменен. Ошибка");
    //   }
    //   setShow(false);
    // });
  };
  useEffect(() => {
    dispatch(actions.fetchStudentsCountByGrade());
    dispatch(actions.fetchBalance());
  }, [dispatch]);
  useEffect(() => {
    // dispatch(actions.fetchBooks({ query}, {offset }));
    dispatch(actions.fetchBooks({ offset: offset, search_q: query }));
  }, [dispatch, offset, query]);
  useEffect(() => {
    checkDecline();
    checkCanCancelOrder();
  }, []);
  return (
    <Layout>
      <div className="mb-3 px-4 py-2">
        <h2 className="d-flex mb-4 ">Каталог учебников </h2>
        {/* <div
          className="d-flex justify-between balance"
          style={{ fontSize: "20px" }}
        >
          {" "}
          Баланс: <span style={{ fontWeight: 700 }}>
            &nbsp; {balance} сом
          </span>{" "}
        </div> */}
        <div
          className="d-flex justify-between balance"
          style={{ fontSize: "20px" }}
        >
          {" "}
          Доступный баланс на {shop_grades?.join(", ")} классы:{" "}
          <span style={{ fontWeight: 700 }}>
            &nbsp; {available_balance} сом
          </span>{" "}
        </div>
        {/* <div className="d-flex justify-between balance">Баланс: </div> */}
        <div
          style={{ fontSize: "20px" }}
          className="d-flex justify-between balance"
        >
          Сумма заказа:{" "}
          <span style={{ fontWeight: 700 }}>&nbsp; {totalPrice} сом</span>
        </div>
        <NavLink exact to="/store/orders">
          <button
            style={{ fontSize: "20px", fontWeight: 700 }}
            className="btn btn-success"
          >
            История заявок
          </button>
        </NavLink>
        {(!declined && canCancelOrder) &&
          <Button
            variant="danger d-block fs-5 me-auto mt-3 mb-3"
            onClick={() => setShow(true)}
          >
            Отказ от заказа книг
          </Button>
        }
      </div>
      {!declined &&
        <Row xs={1} lg={3} className="mb-1">
          <Col>
            <SelectField
              register={{ ...register("subject") }}
              optGrpHead={t("school-books.add.categories")}
              optionGroup={[...categoriesOptions]}
              onChange={(event) => categoryChange(event.target.value)}
            />
          </Col>
          <Col>
            <InputField
              control={control}
              name="name__icontains"
              placeholder={t("school-books.add.name_book")}
              type="text"
              onChange={(event) => bookChange(event.target.value)}
            />
          </Col>
          <Col>
            <InputField
              placeholder={t("school-books.author")}
              control={control}
              name="author__icontains"
              type="text"
              onChange={(event) => authorChange(event.target.value)}
            />
          </Col>
        </Row>
      }
      {!declined &&
        <BookList books={books.results} />
      }
      {!declined &&
        <div className={styles.navigation}>
          <Button
            variant={"success"}
            onClick={previous}
            disabled={books["previous"] === null}
          >
            <FontAwesomeIcon icon={faAngleLeft} className="mx-3" />
          </Button>
          <Button
            variant={"success"}
            onClick={next}
            disabled={books["next"] === null}
          >
            <FontAwesomeIcon icon={faAngleRight} className="mx-3" />
          </Button>
        </div>
      }
      {declined &&
        <div className="d-flex justify-content-center "><p><b>Вы отказались от заказа книг в текущем году</b></p></div>
      }
      <SidebarCart isOpen={open} />
      {/* <button className={styles.openBtn} onClick={handleHover}>
        {open ? "Закрыть" : "Открыть"} корзину
      </button> */}
      {!declined &&
        <NavLink exact to="/store/cart" className={styles.openBtn}>
          <Button
            variant="danger"
            className={styles.openBtn}
            style={{ fontSize: "15px", fontWeight: 700 }}
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faShoppingCart}
                style={{ fontSize: "25px" }}
                className="me-3 text-white"
              />
              <div>
                <p>Посмотреть</p>
                <p>корзину</p>
              </div>
            </div>
          </Button>
        </NavLink>
      }
        <Footer />
        <Modal
            show={show}
            onHide={function() {
            setShow(false)
            }}
            keyboard={false}
            size="lg"
        >
            <Modal.Header>
            <Modal.Title>{t('shop.orderCancel.title')}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(confirmedDecline)}>
            <Modal.Body>
            <p>{t('shop.orderCancel.body')}</p>
            <InputField
                control={control}
                name='comment'
                rules={{
                    required: t('error_message.no_empty'),
                    // max: {value: 60, message: t('classes.add-class.student_count_error')},
                    // min: {value: 1, message: t('classes.add-class.student_count_error')}
                }}
                label={t('events.field-comment')}
                errors={errors.comment}
                />
            </Modal.Body>
            <Modal.Footer>
            <ButtonC variant="close" onClick={function() {
            setShow(false)
            }}>
                {t('btn.close')}
            </ButtonC>
            <button className="btn btn-danger me-2 mt-2" type='submit'>{t('shop.orderCancel.decline')}</button>
            </Modal.Footer>
            </Form>
        </Modal>
          {/* <ButtonC variant="add" onClick={confirmedDecline}>
            {t("btn.add")}
          </ButtonC>
        </Modal.Footer>
      </Modal> */}
    </Layout>
  );
};

import {director, distObserver, edu_min, janyKitep, labrarian, regObserver} from '../lib/helper'
import {hasAccess, hasMatches} from "../route-utils"
import {storage} from '../service'

export const useAuth = ({pathname}) => {
  const profile = storage.read('access')
  const role = storage.read('role')
  const roles = [labrarian, director, regObserver, distObserver, janyKitep, edu_min]

  if (!profile || !roles.includes(role)) {
    storage.clearAll()
    // if (pathname === '/restore-password') return
    // if (pathname === '/open-data') return
    if (pathname === '/sign-in') return
    return '/sign-in'
  }

  const notFound = !hasMatches(pathname, role)

  if (notFound) return '/not-found'

  const forbidden = !hasAccess(pathname, role)

  if (forbidden) return '/forbidden'

}
import {faFilePdf} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useRef} from "react"
import {useTranslation} from "react-i18next"
import {useReactToPrint} from "react-to-print"
import {ButtonC, PdfHeader, TableComponent} from "../../../components"
import {useAppSelector} from '../../../hooks'
import styles from './teacher-books.module.css'

export const TbInfo = ({teacher}) => {
	const teacherBooks = useAppSelector((state) => state.teacherBooks.teacherBooks)
	const {t} = useTranslation()
	const printRef = useRef()

	const handlePrint = useReactToPrint({
		content: () => printRef.current
	})

	return (
		<>
			<ButtonC variant='download' onClick={handlePrint} className={styles.pdfButton}>
				PDF <FontAwesomeIcon icon={faFilePdf} />
			</ButtonC>
			<TableComponent ref={printRef}>
				<PdfHeader title={t('action-books.class-action.reception.title')}>
					<tr>
						<th colSpan='12'><p>{t('action-books.class-action.teacher')}: {teacher?.fullname}</p></th>
					</tr>
					<tr>
						<th colSpan='12'><p>{t('teacher-books.pin')}: {teacher?.pin}</p></th>
					</tr>
					<tr>
						<th colSpan='12'><p>{t('teacher-books.phone')}: {teacher?.phone}</p></th>
					</tr>
					<tr>
						<th colSpan='12'><p>{t('teacher-books.classes')}: {teacher?.classes?.join(', ')}</p></th>
					</tr>
				</PdfHeader>
				<thead>
					<tr>
						<th scope="col">№</th>
						<th scope="col">{t('action-books.class-action.reception.name')}</th>
						<th scope="col">{t('action-books.class-action.reception.author')}</th>
						<th scope="col">{t('teacher-books.classes')}</th>
						<th scope="col">{t('action-books.class-action.reception.issued')}</th>
					</tr>
				</thead>
				<tbody>
					{teacherBooks && teacherBooks.map((kb, index) => (
						<tr key={kb.id}>
							<td scope="col" data-label="№">{index + 1}</td>
							<td data-label="">{kb?.school_book?.book?.name}</td>
							<td>{kb?.school_book?.book?.author}</td>
							<td>{kb?.school_book?.book?.grades.join(', ')}</td>
							<td>{kb.count}</td>
						</tr>
					))}
				</tbody>
			</TableComponent>
		</>
	)
}

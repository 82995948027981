import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ButtonC, TableComponent } from '../../../components'
import { api } from '../../../service/old-api-2'

const OpenDataTable = () => {
    const [regions, setRegions] = useState()

    const getRegions = () => {
        api.get('references/regions/').then((response) => {
            setRegions(response)
        })
    }

    useEffect(() => {
        getRegions()
    }, [])
  return (
    <div>
        <TableComponent>
            <thead>
                <th>Наименование</th>
                <th>Код района</th>
                <th>Действие</th>
            </thead>
            {regions?.map((region) => {
                    return (
            <tbody key={region.id}>
                    <td>{region.name}</td>
                    <td>{region.id}</td>
                   <ButtonC variant="primary" > <Link className="text-white" to={{
					pathname: `/open-data-dist/${region.id}`,
		}} >Посмотреть </Link></ButtonC>
            </tbody>
                )
            })}
        </TableComponent>
    </div>
  )
}

export default OpenDataTable
export const read = (key) => {
	const data = localStorage.getItem(key)
	return data
}

export const write = (key, value) => {

	localStorage.setItem(key, value)
}

export const clear = (key) => localStorage.removeItem(key)

export const clearAll = () => localStorage.clear()

export const exists = (key) => {
	const item = localStorage.getItem(key)
	if (item) {
		return true
	} else {
		return false
	}
}

export const getObject = (key) => {
	return JSON.parse(localStorage.getItem(key))
}

export const setObject = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value))
}
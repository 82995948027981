import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'
import {thunks as gradeThunks} from '../../app/store/grade-reducer'
import { notifyError } from '../../components/notify'

const namespace = 'school-news'
const uri = 'school-news'

const fetchSchoolNews = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let school_news

		try {
			school_news = await api.get(`${uri}/school_news_for_librarian`)
		} catch (error) {
			throw new Error(error)
		}

		return {school_news}
	})

const addSchoolNews = createAsyncThunk(
	`${namespace}/add`,
	async (data) => {
		let school_new

		try {
			await api.post(`${uri}`, {body: data})
				.then(async (res) => school_new = await api.get(`${uri}/${res.id}`))
		} catch (error) {
			throw new Error(error)
		}
		return {school_new}
	})

const updateSchoolNews = createAsyncThunk(
	`${namespace}/update`,
	async ({id, data}) => {

		try {
			await api.put(`${uri}/${id}`, {body: data})
		} catch (error) {
			throw new Error(error)
		}
		const school_news = await api.get(`${uri}/${id}`)
		return {school_news}
	})

const deleteSchoolNews = createAsyncThunk(
	`${namespace}/delete`,
	async (id, thunkApi) => {
		try {
			await api.delete(`${uri}/${id}`).then(() => {
				thunkApi.dispatch(gradeThunks.fetchGrades())
			})
		} catch (error) {
			throw new Error(error)
		}
		return {id}
	})


const {reducer} = createSlice({
	name: namespace,
	initialState: {
		school_news_list: [],
		loading: false,
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchSchoolNews.fulfilled, (state, action) => {
				state.school_news_list = action.payload.school_news
			})
			.addCase(addSchoolNews.pending, (state) => {
				delete state.error
				state.loading = true
			})
			.addCase(addSchoolNews.fulfilled, (state, action) => {
				state.loading = false
				state.school_news_list.unshift(action.payload.school_new )
			})
			.addCase(addSchoolNews.rejected, (state) => {
				state.error = 'error'
				state.loading = false
			})
			.addCase(deleteSchoolNews.fulfilled, (state, action) => {
				state.loading = false
				state.school_news_list = state.school_news_list.filter((t) => t.id != action.payload.id)
			})
			.addCase(updateSchoolNews.fulfilled, (state, action) => {
				const index = state.school_news_list.findIndex((t) => t.id == action.payload.school_news.id)
				state.school_news_list[index] = {...state.school_news_list[index], ...action.payload.school_news}
			})
})

const thunks = {fetchSchoolNews, addSchoolNews, updateSchoolNews, deleteSchoolNews}

export {reducer, thunks}
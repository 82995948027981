import { faBan, faCheck, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonC, PdfHeader, TableComponent } from "../../../components";
import { useAppSelector } from "../../../hooks";
import { storage } from "../../../service";
import { api } from "../../../service/old-api-2";
import { thunks } from "../../school-events/school-events-reducer";
import { useReactToPrint } from "react-to-print";
import { PdfHeaderWhithDirector } from "../../../components/pdf-header/pdf-header-with-director";

export const G7ShoolEventsReportLibrarian = ({ place }) => {
  const language = storage.read("lang");
  const school_events = useAppSelector(
    (state) => state.schoolEvents.school_events
  );
  const [contingentReport, setContingentReport] = useState();
  const [republicContingentReport, setRepublicContingentReport] = useState();
  const [regionContingentReport, setRegionContingentReport] = useState();
  const { t } = useTranslation();
  const componentRef = useRef(null);
  // const getDirectorInfo = () => {
	// 	api.get('users/get_director_info').then((response) => {
	// 		storage.write('director_id', response?.id)
	// 		setDirector(response)
	// 	})
	// }
  // const [director, setDirector] = useState()
  const getContingentReport = () => {
    if (place === "region") {
      api
        .get(
          `reports/contingent-report/?region_id=${storage.read("region_id")}`
        )
        .then((response) => {
          setContingentReport(response.response_list);
          setRepublicContingentReport(response.republic_set);
        });
    } else if (place === "district") {
      api
        .get(
          `reports/contingent-report/?district_id=${storage.read(
            "district_id"
          )}`
        )
        .then((response) => {
          setContingentReport(response.response_list);
          setRepublicContingentReport(response.republic_set);
          setRegionContingentReport(response.region_set);
        });
    }
  };
  useEffect(() => {
    // getDirectorInfo(),
    getContingentReport();
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <h2 className="mt-5 mb-3" style={{ textAlign: "center" }}>
        {t("events.report-title")}
      </h2>
      <ButtonC
        variant="download"
        onClick={handlePrint}
        style={{
          float: "left",
          marginBottom: "15px",
          backgroundColor: "red",
          color: "#fff",
        }}
      >
        PDF <FontAwesomeIcon style={{ color: "#fff" }} icon={faFilePdf} />
      </ButtonC>
      <TableComponent ref={componentRef} >
        <PdfHeaderWhithDirector title={t("events.report-title")} />
        <thead>
          <tr>
            <th>№</th>
            <th scope="col">Дата</th>
            <th scope="col">{t("events.field-type")}</th>
            <th scope="col">{t("events.field-name")}</th>
            <th scope="col">{t("events.field-comment")}</th>
            <th scope="col">{t("events.field-result")}</th>
          </tr>
        </thead>
        <tbody>
          {school_events?.map((el, key) => {
            return (
              <tr key={key+1}>
                <td>{key+1}</td>
                <td>{el.date}</td>
                <td>{language === "ky" ? el.type?.name_kg : el.type?.name}</td>
                <td>{el.name}</td>
                <td>{el.comment}</td>
                <td>{el.result}</td>
              </tr>
            );
          })}
        </tbody>
      </TableComponent>
    </div>
  );
};

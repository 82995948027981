import { Modal, Form, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { notifyError, notifySuccess } from "../../components/notify";
import { useTranslation } from "react-i18next";
import { gradeEnum, langOptions, transLang } from "../../lib/helper";
import { ButtonC, InputField, SelectField } from "../../components";
import { useForm } from "react-hook-form";
import { useActions, useAppSelector } from "../../hooks";
import { thunks } from "./school-news-reducer";
import { thunks as gradeThunks } from "../../app/store/grade-reducer";
import { logDOM } from "@testing-library/react";

const actions = {
  updateSchoolNews: thunks.updateSchoolNews,
};

export const UpdateSchoolNews = ({
  show,
  handleClose,
  editValue,
  setShow,
}) => {
  // const schoolEventTypes = useAppSelector(
  //   (state) => state.schoolEventTypes.school_event_types
  // );
  const { updateSchoolNews } = useActions(actions);
  const [active, setActive] = useState(true);
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm();
  const school_events = useAppSelector((state) => state.schoolEvents.school_events).filter((event) => !event.schoolnews || event.id === editValue.event).map((l) => ({value: l.id, label: l.date + ' / ' + l.name}))
  const { t } = useTranslation();
  // const school_event_types = schoolEventTypes.map((l) => ({
  //   value: l.id,
  //   label: l.name,
  //   label_kg: l.name_kg,
  // }));

  const putSchoolEvent = async (data) => {
    // if (!active) {
    //   notifyError('Выберите учителя из списка')
    //   return
    // }
    // const klass = {
    //   teacher: currentTeacher?.id ?? editValue?.teacher?.id,
    //   student_count: data.student_count,
    //   student_count_50_discount: data.student_count_50_discount,
    //   student_count_100_discount: data.student_count_100_discount,
    //   grade: parseInt(editValue?.name),
    //   letter: data.letter,
    //   language: data.language
    // }
    const school_news = {
      // date: data.date,
      title: data.title,
      body: data.body,
      status: data.status,
      event: data.event,
    };

    const a = await updateSchoolNews({ id: editValue.id, data: school_news });
    if (a.payload) {
      reset();
      handleClose();
      notifySuccess("Изменено!");
      // setShow(false);
      // fetchGrades()
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
        <Modal.Header>
          <Modal.Title>
            {/* {t(`events.update-event-title`, { value: editValue?.title })} */}
            {'Изменить отчет'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(putSchoolEvent)}>
            {/* <Col>
              <InputField
                control={control}
                type="date"
                name="date"
                label="Дата"
                rules={{
                  required: t("error_message.no_empty"),
                }}
                defaultValue={editValue?.publish?.split('T')[0]}
                style={{paddingRight: '40px'}}
                errors={errors.date}
              />
            </Col> */}
            <Col>
              {/* <SelectField
                control={control}
                name="type"
                rules={{
                  // ...register("type", {
                    required: t("error_message.no_empty"),
                  // }),
                }}
                required={true}
                defaultValue={editValue?.type?.id}
                optGrpHead={t('events.chose-type')}
                label={t('events.field-type')}
                options={school_event_types}
                translated={true}
                errors={errors.type}
              /> */}
            </Col>
            <Col xs={12}>
              <InputField
                rules={{
                  required: t("error_message.no_empty"),
                }}
                control={control}
                name="title"
                defaultValue={editValue?.title}
                // required={true}
                // label={t('classes.add-class.pin')}
                // label={t('events.field-name')}
                label="Заголовок"
                errors={errors.title}
              />
            </Col>
            <Col xs={12}>
              <InputField
                rules={{
                  required: t("error_message.no_empty"),
                }}
                control={control}
                name="body"
                defaultValue={editValue?.body}
                // required={true}
                // label={t('classes.add-class.pin')}
                label="Текст"
                errors={errors.body}
              />
            </Col>
            <Col>
              {/* <InputField
                control={control}
                name="status"
                rules={{
                  required: t("error_message.no_empty"),
                }}
                errors={errors.status}
                // label={t('events.field-comment')}
                label="Статус"
                defaultValue={editValue?.status}
              /> */}
              <SelectField
                  control={control}
                  name="status"
                  // register={{
                  //   ...register('status',
                  //     {required: t('error_message.no_empty')})
                  // }}
                  required={true}
                  optGrpHead={t('events.chose-type')}
                  errors={errors.status}
                  label='Статус'
                  defaultValue={editValue?.status}
                  options={[
                    {"value": "draft", "label": "Черновик"},
                    {"value": "published", "label": "Опубликовано"},
                    {"value": "declined", "label": "Отменено"}]}
                  // translated={true}
                />
                <SelectField
                  control={control}
                  name="event"
                  // register={{
                  //   ...register('status',
                  //     {required: t('error_message.no_empty')})
                  // }}
                  required={false}
                  optGrpHead='Убрать мероприятие'
                  errors={errors.event}
                  label='Мероприятие'
                  defaultValue={editValue?.event}
                  options={school_events}
                  // translated={true}
                />
            </Col>
            <Col className="moduleBtns">
              <ButtonC
                variant="close"
                onClick={() => {
                  handleClose();
                  reset();
                }}
              >
                {t("btn.close")}
              </ButtonC>
              <ButtonC variant="add" type="submit">
                {t("btn.change")}
              </ButtonC>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

import i18n from '../i18n';
import {thunks as profileThunks} from '../feature/proflle/model';
import {useActions} from '.';
import {useEffect, useMemo, useRef} from 'react';
import {notifyError} from '../components/notify';
import {api} from '../service/new-api';

const actions = profileThunks.signOut

export const newUseApi2 = () => {
	const signOut = useActions(actions)
	const controller = useRef()

	useEffect(() => () => controller.current?.abort(), [])

	return useMemo(() => {
		const withSignal = (realApi) => (...p) => {
			if (!p[1]?.signal) {
				controller.current = new AbortController()
				p[1] = {...(p[1] ?? {})}
				p[1].signal = controller.current.signal
			}

			return realApi(...p).catch((error) => {
				if (error?.response?.status === 401) {
					signOut()
					notifyError(i18n.t('errors.401'))
				} else if (error?.response?.status === 403) {
					notifyError(i18n.t('error.403'))
				} else {
					throw error
				}
			})
		}

		const wrappedApi = withSignal(api)
		wrappedApi.get = withSignal(api.get)
		wrappedApi.post = withSignal(api.post)
		wrappedApi.put = withSignal(api.put)
		wrappedApi.delete = withSignal(api.delete)
		wrappedApi.patch = withSignal(api.patch)

		return wrappedApi
	}, [signOut])
}

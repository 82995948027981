import classes from "./header.module.css";
import "./header.module.css";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faChartPie,
  faExchangeAlt,
  faHome,
  faMobile,
  faPhone,
  faQuestion,
  faSignOutAlt,
  faStore,
  faUserFriends,
  faVideo,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import {
  isDistObserver,
  isLabrarianOrDirector,
  isRegObserver,
  replaceString,
} from "../../lib/helper";
import { useHistory } from "react-router";
import { storage } from "../../service";
import { thunks } from "../../feature/proflle/model";
import { useActions, useAppSelector } from "../../hooks";
// import logo from "../../img/logo.png";
import "./mobile.css";
import { api } from "../../service/old-api-2";
import { useEffect, useState } from "react";
import { ButtonC } from "../buttons/button";
import logo from '../../eu.jpg';
const actions = {
  signOut: () => thunks.signOut(true),
};
export const Footer = () => {
  const { signOut } = useActions(actions);
  const user = useAppSelector((state) => state.profile.user);
  const school = useAppSelector((state) => state.profile.school);
  const { t } = useTranslation();
  const history = useHistory();
  const [balance, setBalance] = useState();
  const selectSetting = (eventKey) => {
    switch (eventKey) {
      case "sign-out":
        signOut();
        break;
      case "profile":
        history.push("/profile");
        break;
      case "payments":
        history.push("/payments");
        break;
    }
  };
  const getBalance = () => {
    api.get("school/").then((response) => {
      setBalance(response);
    });
  };

  useEffect(() => {
    getBalance();
  }, []);
  return (
    <div style={{'height': '100px'}} className={classes.topbar}>
      <div className={classes.footer}>
        <p>© 2023. Информационная система управления учебниками разработан учреждением &quot;Жаңы Китеп&quot; Министерствa образования и науки Кыргызской Республики.</p>
        <p>
          <img className={classes.logot} src={logo} alt={logo} />
        Модуль сбора заявки был создан при содействии и финансовой поддержке ЕС. Содержание сайта не обязательно отражает точку зрения ЕС, ее программ и проектов.</p>
      </div>
    </div>
  );
};

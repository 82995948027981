import React, {useEffect, useState} from 'react'
import {Layout} from '../../../app/layout'
import {useParams} from 'react-router-dom'
import {useApi} from '../../../hooks'
import {Table} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {ButtonC} from '../../../components'
import { D10SubHead } from '../school/d10-sub-head'
import { D10Table } from '../d10/D10Table'
import { api } from '../../../service/old-api-2'

export const D10SubRepubDists = () => {
  const params = useParams();
  const [districts, setDistricts] = useState();
  const [d10Data, setD10Data] = useState();
  const getDistricts = () => {
    api.get(`references/districts/?region=${params.id}`)
    .then((res) => setDistricts(res))
  }

  const fetchD10Data = () => {
    api.get(`reports/d10/?language=kyrgyz`).then((response) => {
      setD10Data(response);
    })
  }
  useEffect(()=> {
    getDistricts(),
    fetchD10Data();
  }, [])
  return (
    <Layout>
      <Table>
        <D10SubHead />
				<D10Table D10Data={d10Data} />
      </Table>
    </Layout>
  )
}

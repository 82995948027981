import React, {useState, useEffect} from 'react'
import {Layout} from '../../../app/layout'
import {useParams} from 'react-router-dom/cjs/react-router-dom.min'
import {newUseApi2} from '../../../hooks/new-use-api2';
import {storage} from '../../../service';
import {Table} from 'react-bootstrap';

export const D10SubRegDistSchools = () => {
    const api = newUseApi2()
    const language = storage.read('language')
    const params = useParams()
    const [school, setSchool] = useState({});
    const getSchool = () => {
        api.get(`reports/d10/school/v2/?language=russian&school_id=${params.id}`)
        .then((res) => setSchool(res))
    }
    useEffect(() => {
      getSchool()
    }, [])
  return (
    <Layout>
             <Table>
            <thead>
              <tr>
      <th>Классы</th>
      <th>Всего учащихся</th>
      <th>Наименование учебника</th>
      <th>Автор учебника</th>
      <th>Учебников в фонде</th>
      <th>Используются</th>
      <th>% обеспеченности по фонду</th>
      <th>% использование фонда</th>
      </tr>
      </thead>
        {school && school.length > 0 && school?.map((myschool) => {
          return (
        <tbody key={myschool.id}>
          {/* {console.log(school)} */}
          <tr>
            <td className='p-4'>{myschool?.grade}</td>
            <td className='p-4'>{myschool?.number_of_students}</td>
            <td>{myschool?.subjects.map((sub) => {
                return (
                  <div key={sub.id}>
                    {sub.books.map((bookItem) => {
                      return (
                        <div className='p-3' key={bookItem.book_id}>{bookItem?.book__name}</div>
                      )
                    })}
                  </div>
                )
              })}</td>
            <td>{myschool?.subjects.map((sub) => {
                return (
                  <div key={sub.id}>
                    {sub.books.map((bookItem) => {
                      return (
                        <div className='p-3' key={bookItem.book_id}>{bookItem?.book__author}</div>
                      )
                    })}
                  </div>
                )
              })}</td>
              <td>{myschool?.subjects.map((sub) => {
                return (
                  <div key={sub.id}>
                    {sub.books.map((bookItem) => {
                      return (
                        <div className='p-4' key={bookItem.book_id}>{bookItem?.total_number_of_books}</div>
                      )
                    })}
                  </div>
                )
              })}</td>
              <td>{myschool?.subjects.map((sub) => {
                return (
                  <div key={sub.id}>
                    {sub.books.map((bookItem) => {
                      return (
                        <div  className='p-4' key={bookItem.book_id}>{bookItem?.number_of_issued_books}</div>
                      )
                    })}
                  </div>
                )
              })}</td>
              <td>{myschool?.subjects.map((sub) => {
                return (
                  <div key={sub.id}>
                    {sub.books.map((bookItem) => {
                      return (
                        <div  className='p-4' key={bookItem.book_id}>{bookItem?.fund_security_percentage}</div>
                      )
                    })}
                  </div>
                )
              })}</td>
              <td>{myschool?.subjects.map((sub) => {
                return (
                  <div key={sub.id}>
                    {sub.books.map((bookItem) => {
                      return (
                        <div  className='p-4' key={bookItem.book_id}>{bookItem?.fund_utilization_percentage}</div>
                      )
                    })}
                  </div>
                )
              })}</td>
          </tr>
        </tbody>
          )
        })}
          </Table>
    </Layout>
  )
}

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../../service/old-api-2'
import {api as api2} from '../../../service/old-api'

const namespace = 'teacherBooks'
const uri = 'teacher-books/'

const fetchTeacherBooks = createAsyncThunk(
	`${namespace}/get`,
	async (id) => {
		let teacherBooks

		try {
			teacherBooks = await api.get(`${uri}`, {
				search: {
					teacher: id
				}
			})
		} catch (error) {
			throw new Error(error)
		}

		return {teacherBooks}
	})


const addTeacherBooks = createAsyncThunk(
	`${namespace}/add`,
	async (data) => {
		let teacherBook

		try {
			await api.post(`${uri}`, {body: data}).then(async (res) => {
				teacherBook = await api.get(`${uri}${res.id}`)
			})
		} catch (error) {
			throw new Error(error)
		}
		return {teacherBook}
	})

const updateTeacherBook = createAsyncThunk(
	`${namespace}/update`,
	async ({id, data}) => {
		let teacherBook


		try {
			await api.post(`${uri}${id}/operation/`, {body: data}).then(async () => {
				teacherBook = await api.get(`${uri}${id}/`)
			})
		} catch (error) {
			throw new Error(error)
		}

		return {teacherBook}
	})

const deleteTeacherBook = createAsyncThunk(
	`${namespace}/delete`,
	async (id) => {
		try {
			await api.delete(`${uri}${id}/`)
		} catch (error) {
			throw new Error(error)
		}
		return {id}
	})

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		teacherBooks: [],
		loading: false,
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchTeacherBooks.fulfilled, (state, action) => {
				state.teacherBooks = action.payload.teacherBooks
			})
			.addCase(addTeacherBooks.fulfilled, (state, action) => {
				state.loading = false
				state.teacherBooks.push(action.payload.teacherBook)
			})
			.addCase(updateTeacherBook.fulfilled, (state, action) => {
				const index = state.teacherBooks.findIndex((t) => t.id == action.payload.teacherBook.id)
				state.teacherBooks[index] = action.payload.teacherBook
			})
			.addCase(deleteTeacherBook.fulfilled, (state, action) => {
				state.loading = false
				state.teacherBooks = state.teacherBooks.filter((t) => t.id != action.payload.id)
			})
})

const thunks = {fetchTeacherBooks, updateTeacherBook, deleteTeacherBook, addTeacherBooks}

export {reducer, thunks}

import React from 'react'
import {Col, Form, Modal} from 'react-bootstrap'
import {ButtonC, InputField} from '../../../components'
import {useTranslation} from "react-i18next"
import {useForm} from 'react-hook-form'
import {api} from '../../../service/old-api-2'
import {notifyError, notifySuccess} from '../../../components/notify'
import { storage } from '../../../service'


export const ConfirmModal = ({show, setShow, profile, params, language, setD10Status}) => {
    const {t} = useTranslation()
    const {control, handleSubmit, formState: {errors}} = useForm()
    const onClose = () => {
        setShow(false)
    }
    const onAccepted = () => {
        api.get(`d10-district-confirm/${params.id}/`).
        then((response) => {
                notifySuccess('Потверждено');
                setD10Status(true)
                setShow(false)
        })
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            keyboard={false}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>Подтверждение отчета д10 школы</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Вы действительно ознакомились с предоставленными и сгенерированными отчетами по всем языкам данной школы?</p>
                <Col className='moduleBtns'>
                    <ButtonC variant="close" onClick={onClose}>
                        {t('btn.close')}
                    </ButtonC>
                    <ButtonC variant="add"
                                onClick={() => onAccepted()}>{t('btn.send')}</ButtonC>
                </Col>
            </Modal.Body>
        </Modal>
    )
}

export const getRowSpan = (gradeData) => {
    let total = 0;
    if (gradeData) {
        for (const subject of gradeData.subjects) {
            total += subject.books.length;
            if (subject.subject_summary) {
                total += 1;
            }
        }
    }
    return total;
}
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'
import {thunks as gradeThunks} from '../../app/store/grade-reducer'
import { notifyError } from '../../components/notify'

const namespace = 'school-events'
const uri = 'school-events'

const fetchSchoolEvents = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let school_events

		try {
			school_events = await api.get(`${uri}/school_events_list`)
		} catch (error) {
			throw new Error(error)
		}

		return {school_events}
	})

const addSchoolEvent = createAsyncThunk(
	`${namespace}/add`,
	async (data) => {
		let school_event

		try {
			await api.post(`${uri}`, {body: data})
				.then(async (res) => school_event = await api.get(`${uri}/${res.id}`))
		} catch (error) {
			throw new Error(error)
		}
		return {school_event}
	})

const updateSchoolEvent = createAsyncThunk(
	`${namespace}/update`,
	async ({id, data}) => {

		try {
			await api.put(`${uri}/${id}`, {body: data})
		} catch (error) {
			throw new Error(error)
		}
		const school_event = await api.get(`${uri}/${id}`)
		return {school_event}
	})

const deleteSchoolEvent = createAsyncThunk(
	`${namespace}/delete`,
	async (id, thunkApi) => {
		try {
			await api.delete(`${uri}/${id}`).then(() => {
				thunkApi.dispatch(gradeThunks.fetchGrades())
			})
		} catch (error) {
			throw new Error(error)
		}
		return {id}
	})


const {reducer} = createSlice({
	name: namespace,
	initialState: {
		school_events: [],
		loading: false,
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchSchoolEvents.fulfilled, (state, action) => {
				state.school_events = action.payload.school_events
			})
			.addCase(addSchoolEvent.pending, (state) => {
				delete state.error
				state.loading = true
			})
			.addCase(addSchoolEvent.fulfilled, (state, action) => {
				state.loading = false
				state.school_events.unshift(action.payload.school_event)
			})
			.addCase(addSchoolEvent.rejected, (state) => {
				state.error = 'error'
				state.loading = false
			})
			.addCase(deleteSchoolEvent.fulfilled, (state, action) => {
				state.loading = false
				state.school_events = state.school_events.filter((t) => t.id != action.payload.id)
			})
			.addCase(updateSchoolEvent.fulfilled, (state, action) => {
				const index = state.school_events.findIndex((t) => t.id == action.payload.school_event.id)
				state.school_events[index] = {...state.school_events[index], ...action.payload.school_event}
			})
})

const thunks = {fetchSchoolEvents, addSchoolEvent, updateSchoolEvent, deleteSchoolEvent}

export {reducer, thunks}
import {useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'

export const useActions = (actions) => {
  const dispatch = useDispatch()

  return useMemo(
    () =>
      Array.isArray(actions) ?
        actions.map((ac) => bindActionCreators(ac, dispatch)) :
        bindActionCreators(actions, dispatch),
    [actions, dispatch],
  )
}
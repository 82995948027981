import { storage } from "."


const uri = `${process.env.REACT_APP_BASE_URL}/api`

export const apiFile = async (path, {...params}, type) => {
  let response
  let query
  const token = storage.read('access')
  const lang = storage.read('lang') || 'ru'
  const types = storage.read('type')

  const headers = token ? new Headers({
    "Authorization": `Bearer ${storage.read('access')}`,
    "Accept-Language": `${lang};q=0.9`,
    "Accept": `application/ms-excel`,
  }) : new Headers({
    "Accept-Language": `${lang};q=0.9`,
    "Accept": `application/ms-excel`,
  })
  if (params) {
    const coerced =
      typeof params === 'object' ?
        Object.entries(params)
          .filter(([k, v]) => v !== '' && v != null)
          .map(([k, v]) => [k, v.toString()]) :
        params
    query = `${new URLSearchParams(coerced).toString()}`
  }
  try {
    response = await fetch(query ? `${uri}/${path}${query}` : `${uri}/${path}`, {
      headers: headers,
    })
      .then(async (res) => {
        if (res.status === 200) {
          return await res.blob()
        }
      })
  } catch {}
  return response
}
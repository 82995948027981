import {createAsyncThunk, createSlice, createAction} from '@reduxjs/toolkit'
import {notifyError, notifySuccess} from '../../components/notify'
import i18n from '../../i18n'
import {storage} from '../../service'
import {api} from '../../service/old-api'
import {api as api2} from '../../service/new-api-2'
import {isLabrarianOrDirector} from '../../lib/helper'


const namespace = 'profile'
const director_id = storage.read('director_id')
const library_id = storage.read('library_id')
const school_id = storage.read('school_id')

const signIn = createAsyncThunk(
	`${namespace}/signIn`,
	async (payload) => {
		let user
		let school
		const token = await api.post('token', {
			body: {
				pin_code: payload.pin_code,
				password: payload.password
			}
		})
		if (token?.access) {
			for (const [key, value] of Object.entries(token)) {
				storage.write(key, value)
			}
			storage.write('lang', 'ru')
			user = await api.get('users')

			if (isLabrarianOrDirector) school = await api.get('school')
		}

		const timestamp = Date.now()

		return {user, timestamp, school}
	})

const signOutAction = createAction(`${namespace}/signOut`)

const signOut = createAsyncThunk(
	`${namespace}/signOut`,
	async (noRedirect = false, thunkApi) => {
		thunkApi.dispatch(signOutAction(noRedirect))
		storage.clearAll()
		location.href = '/sign-in'
	}
)

const restoreSession = createAsyncThunk(`${namespace}restoreSession`,
	async () => {
		const user = await api.get('users')
		const director = await api.get('users/get_director_info')
		let school
		if (isLabrarianOrDirector) school = await api.get('school')
		return {user, school, director, timestamp: Date.now()}
	}
)

const updateUser = createAsyncThunk(`${namespace}/updateUser`,
	async (data) => {
		let user
		try {
			user = await api.put('users', {
				body: data
			})
			notifySuccess(i18n.t('profile.update-user.changed'))
		} catch (error) {
			notifyError(error.message)
		}
		return user
	})
	const updateDirectorInfo = createAsyncThunk(`${namespace}/update`,
	async (data, {getState}) => {
		const state = getState();
		const directorId = state.profile.director.id
		let director
		try {
				director = await api.put(`users/${directorId}/update_director_info`, {
					body: data
				})
				notifySuccess('Данные директора успешно изменены')
		} catch (error) {
			throw new Error(error)
		}
		return director
	})


const updateSchool = createAsyncThunk(`${namespace}/updateSchool`,
	async (payload) => {
		let school
		try {
			school = await api.put('school', {
				body: payload
			})
			notifySuccess(i18n.t('profile.update-school.changed'))
		} catch (error) {
			notifyError(error.message)
		}

		return school
	})


const addNewWareHouse = createAsyncThunk(`${namespace}/addWarehouse`,
    async (payload) => {
		 try {
			 await api.post('warehouses', {body: {...payload, library: library_id}})
			notifySuccess('Склад успешно добавлен')
		} catch (e) {
			throw new Error(e)
		}
	}
)

const addLibrary = createAsyncThunk(`${namespace}/addLibrary`,
    async (payload) => {
		 try {
			 await api2.post('libraries/', {body: {...payload, school: school_id}})
			notifySuccess('Библиотека успешно создана')
		} catch (e) {
			throw new Error(e)
		}
	}
)

const addNewReadingRoom = createAsyncThunk(`${namespace}/addReadingRoom`,
     async (payload) => {
		try {
		await api.post('reading-rooms', {body: {...payload, library: library_id}}).then((response) => {
			// console.log(response)
		})
		notifySuccess('Читальный зал успешно добавлен')

		} catch (e) {
			throw new Error(e)
		}
})

const librariansOffice = createAsyncThunk(`${namespace}/libraries`,
    async (payload) => {
	    try {
			api.post(`libraries`,
			 {body: {has_an_office: payload, school: school_id}})
		} catch (error) {
		throw new Error(error)
	}
})

const getSchoolCategories = createAsyncThunk(
	`${namespace}/school-categories`,
    async () => {
		try {
			api.get('references/school-categories')
		} catch (error) {
			throw new Error(error)
		}
	}
)

const updateCombinedArea = createAsyncThunk(`${namespace}/update-combined-area`,
          async (payload) => {
			  try {
				  notifySuccess('Успешно изменено!')
				  combined_area = api.put(`libraries/${library_id}`,
				  {body: {...payload, school: school_id}})
			  } catch (error) {
				  throw new Error(error)
			  }
		  }
)
const getAllReasons  = createAsyncThunk(`${namespace}/get-all-reasons`,
        async () => {
			try {
				const reasons = api.get('references/no-library-reasons')
				// console.log(reasons)
			} catch (error) {
				throw new Error(error)
			}
		}
)

const textReason = createAsyncThunk(`${namespace}/text-reasons`,
       async (payload) => {
		   try {
			   const textReason = api.put('school', ...payload)
			//    console.log(textReason)
		   } catch (error) {
			   throw new Error(error)
		   }
	   })
const {reducer} = createSlice({
	name: namespace,
	initialState: {
		user: {},
		school: {},
		director: {},
		warehouse: [],
		office: {},
		readingRoom: [],
		loggedInAt: null,
		loading: false,
		error: false,
		noRedirect: false,
		school_categories: {},
		is_combined: {},
		library: [],
		deleteLibrary: {},
		allReasons: [],
		textReason: {},
		posts: ['hello wolrd', 'hello Nurlan']
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(restoreSession.fulfilled, (state, action) => {
				if (action.payload.user === undefined) return
				if (action.payload.director === undefined) return
				state.user = action.payload.user
				state.director = action.payload.director
				state.warehouse.push(action.payload.warehouse)
				state.office = action.payload.office
				state.readingRoom.push(action.payload.readingRoom)
				state.school = action.payload?.school
				state.loggedInAt = action.payload.timestamp
				state.school_categories = action.payload.school_categories
				state.is_combined = action.payload.is_combined
				state.library = action.payload.library
				state.allReasons = action.payload.allReasons
				state.textReason = action.payload.textReason
			})
			.addCase(signIn.pending, (state) => {
				state.loading = true
				delete state.error
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.user = action.payload.user
				state.director = action.payload.director
				state.warehouse.push(action.payload.warehouse)
				state.readingRoom.push(action.payload.readingRoom)
				state.office = action.payload.office
				state.school = action.payload?.school
				state.loggedInAt = action.payload.timestamp
				state.school_categories = action.payload.school_categories
				state.is_combined = action.payload.is_combined
				state.loading = false
				state.library = action.payload.library
				state.allReasons = action.payload.allReasons
				state.textReason = action.payload.textReason

				location.href = '/';
			})
			.addCase(signIn.rejected, (state, action) => {
				state.loading = false
				action.error.message === '401' ?
					(state.error = i18n.t('profile.error401')) :
					(state.error = i18n.t('profile.error401'))
			})
			.addCase(signOutAction, (state, action) => {
				state.noRedirect = action.payload
				delete state.user
				delete state.school
				delete state.office
				delete state.director
				delete state.warehouse
				delete state.readingRoom
				delete state.loggedInAt
				delete state.library
				delete state.allReasons
				delete state.textReason
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				state.user.fullname = action.payload?.fullname
				state.user.phone = action.payload?.phone
			})
			.addCase(updateDirectorInfo.fulfilled, (state, action) => {
				state.director.fullname = action.payload?.fullname
				state.director.email = action.payload?.email
				state.director.phone = action.payload?.phone
			})
			.addCase(updateSchool.fulfilled, (state, action) => {
				state.school = action.payload
			})
			.addCase(addNewWareHouse.fulfilled, (state, action) => {
				state.warehouse.library.push(action.payload.library)
				state.warehouse.area.push(action.payload.area)
			})
			.addCase(addNewReadingRoom.fulfilled, (state, action) => {
				state.readingRoom.library.push(action.payload.library)
				state.readingRoom.area.push(action.payload.area)
			})
			.addCase(librariansOffice.fulfilled, (state, action) => {
				state.office.has_an_office = action.payload.office,
				state.office.school = action.payload.school,
				state.office.library = action.payload.library.id
			})
		   .addCase(getSchoolCategories.fulfilled, (state, action) => {
			   state.school_categories.name = action.payload.school_categories.name
		   })
		   .addCase(updateCombinedArea.fulfilled, (state, action) => {
			   state.is_combined.combined_area = action.payload.is_combined.combined_area
			   state.is_combined.school = school_id
		   })
		   .addCase(addLibrary.fulfilled, (state, action) => {
			   state.library.id.push(action.payload.library.id)
		   })
		//    .addCase(deleteLibraryById.fulfilled, (state, action) => {
		// 	   state.deleteLibrary = action.payload.deleteLibrary
		//    })
		   .addCase(getAllReasons.fulfilled, (state, action) => {
			   state.getAllReasons = action.payload.getAllReasons
		   })
		   .addCase(textReason.fulfilled, (state, action) => {
			   state.textReason = action.payload.textReason
		   })
})


const thunks = {signIn, signOut, restoreSession,
	updateUser, updateSchool, updateDirectorInfo,
	addNewWareHouse, addNewReadingRoom, librariansOffice,
	 getSchoolCategories, updateCombinedArea, addLibrary,
 getAllReasons, textReason}

export {reducer, thunks}

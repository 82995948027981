import React, {useState, useEffect} from 'react'
import {Layout} from '../../app/layout'
import {Link, useParams} from 'react-router-dom/cjs/react-router-dom.min';
import {useApi} from '../../hooks';
import {newUseApi2} from '../../hooks/new-use-api2';
import classes from './books.module.css';
import {ButtonC} from '../../components';
import {useTranslation} from 'react-i18next';
import {Container} from 'react-bootstrap';
import {notifySuccess} from '../../components/notify';
import {setShow} from '../../app/store/menu-reducer';
import {storage} from '../../service';

export const SchoolGradesBooks = () => {
    const params = useParams()
    const {t} = useTranslation()
    const api = newUseApi2()
    const [gradeBook, setGradeBook] = useState()
    const getGradesBoook = () => {
        api.get(`school-books/${params.id}/`).then((response) => {
           setGradeBook(response)
        })
    }
    // console.log(gradeBook?.book)
    const onSubmit = (data) => {
		api.post('grade-books/', {
			body: JSON.stringify(data)
		}).then(() => {
			notifySuccess('Потверждено')
			setShow(true)
		})
	}
    useEffect(() => {
        getGradesBoook()
    }, [])
  return (
    <Layout>
        <Container>
            {/* <Link to="/school-books/">Назад</Link> */}
            <h2 className='d-flex justify-content-around p-5'>{gradeBook?.book.name}</h2>
            <h4 className='d-flex'>Количество учебников: {gradeBook?.book?.total}</h4>
            <div className='d-flex p-5'>
            {gradeBook?.book?.grades.map((grade) => {
                return (
                    <div className='d-flex' key={grade.id}>
                       <div className='d-flex p-2' key={grade.id}>{grade} класс</div>
                       &nbsp;
                       &nbsp;
                       <input
                       type="number"
                       className='form-control'
                       style={{width: 100}}
                       />
                       <ButtonC variant="change" onClick={() => onSubmit({grade, school_book: params.id})}>
                       {t('btn.change')}
                       </ButtonC>
                    </div>
                )
            })}
            </div>
</Container>
    </Layout>
  )
}
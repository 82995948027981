import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { storage } from "../../service";
import i18n from "../../i18n";
import { thunks as profileThunks } from "../../feature/proflle/model";
import { thunks as teacherTunks } from "../../feature/teacher/model";
import { thunks as letterThunks } from "./letter-reducer";
import { thunks as schoolEventTypesThunks } from "./school-event-type-reducer";
import { thunks as gradesThunks } from "./grade-reducer";
import { thunks as classesThunks } from "../../feature/klass/class-reducer";
import { thunks as schoolBooksThunks } from "../../feature/school-books/school-books-reducer";
import { thunks as schoolEventsThunks } from "../../feature/school-events/school-events-reducer";
import { thunks as schoolNewsThunks } from "../../feature/school-news/school-news-reducer";
import { thunks as districtsThunks } from "./district-reducer";
import { thunks as schoolsThunks } from "./schools-reducers";
import { thunks as regionsThunks } from "./regions-reducers";
import { thunks as categoriesThunks } from "./categories-reducers";
import { thunks as newsThunks } from "./news-reducer";
import { thunks as fillingReport } from "./filling-report-reducer";
import {
  isDistObserver,
  isLabrarianOrDirector,
  isRegObserver,
  isJanyKitep,
  isEdu_min,
} from "../../lib/helper";
import { thunks as profile2Thunks } from "./profileReducer";

const namespace = "app";

const restoreSession = createAsyncThunk(
  `${namespace}/restoreSession`,
  async (_, thunkApi) => {
    const region = storage.read("region_id");
    const district = storage.read("district_id");
    const school_id = storage.read("school_id");
    const language = storage.read("language");
    if (isLabrarianOrDirector) {
      thunkApi.dispatch(profileThunks.restoreSession());
      thunkApi.dispatch(teacherTunks.fetchTeacher());
      thunkApi.dispatch(letterThunks.fetchLetters());
      thunkApi.dispatch(schoolEventTypesThunks.fetchSchoolEventTypes());
      thunkApi.dispatch(gradesThunks.fetchGrades());
      thunkApi.dispatch(classesThunks.fetchClasses());
      thunkApi.dispatch(schoolEventsThunks.fetchSchoolEvents());
      thunkApi.dispatch(schoolNewsThunks.fetchSchoolNews());
      thunkApi.dispatch(schoolBooksThunks.fetchSchoolBooks());
      thunkApi.dispatch(categoriesThunks.fetchCategories());
      thunkApi.dispatch(newsThunks.fetchNews());
      thunkApi.dispatch(newsThunks.getNewsById());
      thunkApi.dispatch(profile2Thunks.fetchLibrary());
      thunkApi.dispatch(fillingReport.fetchFillingReport());
    } else if (isRegObserver) {
      thunkApi.dispatch(profileThunks.restoreSession());
      thunkApi.dispatch(districtsThunks.fetchDistricts(region));
      // thunkApi.dispatch(categoriesThunks.fetchCategories())
      thunkApi.dispatch(newsThunks.fetchNews());
    } else if (isDistObserver) {
      thunkApi.dispatch(profileThunks.restoreSession());
      thunkApi.dispatch(schoolsThunks.fetchSchools());
      // thunkApi.dispatch(categoriesThunks.fetchCategories())
      thunkApi.dispatch(newsThunks.fetchNews());
    } else if (isJanyKitep) {
      thunkApi.dispatch(profileThunks.restoreSession());
      thunkApi.dispatch(regionsThunks.fetchRegions());
      // thunkApi.dispatch(categoriesThunks.fetchCategories())
      thunkApi.dispatch(newsThunks.fetchNews());
    } else if (isEdu_min) {
      thunkApi.dispatch(profileThunks.restoreSession());
      thunkApi.dispatch(regionsThunks.fetchRegions());
      thunkApi.dispatch(categoriesThunks.fetchCategories());
      thunkApi.dispatch(newsThunks.fetchNews());
    }
    return { restoredAt: Date.now() };
  }
);

const appReducer = createSlice({
  name: namespace,
  initialState: {
    locale: "ru",
    restorePending: 0,
  },
  reducers: {
    setLocale: (state, actions) => {
      state.locale = actions.payload;

      i18n.changeLanguage(actions.payload);

      storage.write("lang", actions.payload);

      location.reload();
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(restoreSession.fulfilled, (state, action) => {
        state.restoredAt = action.payload.restoredAt;
      })
      .addCase(profileThunks.restoreSession.pending, (state) => {
        state.restorePending += 1;
      })
      .addCase(profileThunks.restoreSession.fulfilled, (state) => {
        state.restorePending -= 1;
      })
      .addCase(profileThunks.restoreSession.rejected, (state) => {
        state.restorePending -= 1;
      }),
});

const hasRestored = createSelector(
  (state) => state.restorePending === 0,
  (state) => state.restoredAt !== undefined
);

const { setLocale } = appReducer.actions;
const thunks = { restoreSession, setLocale };
const { reducer } = appReducer;
const selectors = { hasRestored };

export { reducer, thunks, selectors };

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { removeReadingRoom } from '../../app/store/profileReducer'
import { ButtonC } from '../../components'
import { useAppSelector } from '../../hooks'
import { AddNewReadingRoom } from './add-new-reading-room'
import { AddNewWarehouse } from './add-new-warehouse'

export const ReadingRoom = () => {
    const [readingRoom, setReadingRoom] = useState()
    const [addReadingRoom, setAddReadingRoom] = useState(false)
    const profileLibrary = useAppSelector((state) => state.profile2.library)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    let readingRoomIndex = 1

    const deleteReadingRoom = (id) => {
		dispatch(removeReadingRoom(id))
	}
  return (
    <>
    	<AddNewReadingRoom
			show={addReadingRoom}
			setShow={setAddReadingRoom}
			readingRoom={readingRoom}
			/>
    	 <ListGroup variant="flush" className="mt-3">
			<ListGroup.Item><strong>{t('profile.number_of_reading_rooms')}</strong></ListGroup.Item>
			{profileLibrary?.reading_rooms && profileLibrary?.reading_rooms.length > 0 &&  profileLibrary?.reading_rooms.map((room) => {
					return (
      <ListGroup.Item key={room.id}>{t('profile.reading_room')} # {readingRoomIndex++}  {t('profile.single_area')} {room?.area} м²		<ButtonC variant="delete" onClick={() => deleteReadingRoom(room.id)}><FontAwesomeIcon icon={faTrash} /></ButtonC> </ListGroup.Item>
	  )
	})}
   <p className="mt-2 m-5 mb-2"><ButtonC variant="change" onClick={() => setAddReadingRoom(true)}>{t('profile.add_new_reading_room')} </ButtonC></p>
    </ListGroup>
    </>
  )
}

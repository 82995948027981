import styles from "./store.module.css";
import {Button, ListGroup} from "react-bootstrap";
import React from "react";
import {useAppSelector} from "../../hooks";
import {SidebarCartItem} from "./sidebar-cart-item";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStore} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";

export const SidebarCart = ({isOpen}) => {
    const cart = useAppSelector(
        (state) => state['bookstore']['cart']
    )
    return (
        <>
            <div className={isOpen ? styles.basket : styles.basketOpen}>
                <ListGroup variant="flush">
                    {cart.map((cartItem) => (
                        <SidebarCartItem cartItem={cartItem} key={`cartItem-${cartItem.id}`}/>
                    ))}
                </ListGroup>
                <Button variant="primary" className=" pt-2 pb-2 ps-4 pe-4 fs-5 mt-5">
                    <NavLink exact to="/store/cart" className="text-white">
                        <FontAwesomeIcon icon={faStore} className="mx-3 text-white"/> Посмотреть корзину
                    </NavLink>
                </Button>
            </div>
        </>
    )
}

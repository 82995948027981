import {Link} from "react-router-dom";
import {ButtonC} from "../../components";
import {useAppSelector} from '../../hooks';


export const ClassList = ({grade, language}) => {
  const classes = useAppSelector((state) => state.classes.classes.filter((c) => parseInt(c.name) == grade && c.language == language))

  return (
    <>
      {classes && classes.map((k) => (
        <Link to={`/action-books/${k.id}`} key={k.id}>
          <ButtonC id={k.id} key={k.id} className="me-3" variant="change">
            <strong>{k.name.toUpperCase()}</strong> - класс
          </ButtonC>
        </Link>
      ))}
    </>
  )
}

import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ButtonC} from '../buttons/button'
import {notifySuccess} from '../notify';

export const DeleteNotifyWithRedux = ({message = '', show, setShow, func = () => {}, id}) => {
	const {t} = useTranslation()

	const handleYes = async () => {
		const a = await func(id)
		if (a.payload) {
			setShow(false)
			notifySuccess('Удалено')
		}
	};

	const handleNo = () => {
		setShow(false)
	};

	return (
		<>
			<Modal show={show} onHide={setShow}>
				<Modal.Body>{t('notify.delete', {value: message})}</Modal.Body>
				<Modal.Footer>
					<ButtonC variant="close" onClick={handleNo}>
						{t('notify.no')}
					</ButtonC>
					<ButtonC variant="add" onClick={handleYes}>
						{t('notify.yes')}
					</ButtonC>
				</Modal.Footer>
			</Modal>
		</>
	);
}
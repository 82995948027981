import {useTranslation} from 'react-i18next'
import {storage} from '../../service'
import styles from './pdf-header.module.css'
import { format } from 'date-fns'
export const PdfHeaderToBalance = ({title, children, order}) => {
	const today = new Date(Date.now()).toLocaleDateString()
	const {t} = useTranslation()

	const ordersDate = new Date(Date(order?.ordered_date))
	const year = ordersDate.toLocaleString("default", { year: "numeric" });
	const month = ordersDate.toLocaleString("default", { month: "2-digit" });
	const day = ordersDate.toLocaleString("default", { day: "2-digit" });
	const formattedDate = day + "-" + month + '-' + year;
	return (
		<div className={styles.title + ' ' + styles.print} style={{'margin': '3rem'}}>
			<h3>Акт<br />сверки финансовых средств</h3>
			<span>от аренды учебников между школой {storage.read('school')} (код-{storage.read('school_id')})</span>
			<br />
			<span>и учреждением &quot;Жаны китеп&quot; Министерства образования и науки <br /> Кыргызской Республики </span>
			<br />
			<span> с  2018 года по 2023 год.</span>
			<br />
			<br />
		</div>
	)
}
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { storage } from '../../service'
import {api} from '../../service/old-api'


const school_id = storage.read('school_id')
const namespace = 'categories'

const fetchCategories = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let categories

		try {
			categories = await api.get('references/categories-subjects')
		} catch (error) {
			throw new Error(error)
		}
		return {categories}
	})

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		categories: [],
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchCategories.fulfilled, (state, action) => {
				state.categories = action.payload.categories
			})
})

const thunks = {fetchCategories}

export {reducer, thunks}

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'
import {thunks as gradeThunks} from '../../app/store/grade-reducer'
import { notifyError } from '../../components/notify'

const namespace = 'classes'
const uri = 'classes'

const fetchClasses = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let classes

		try {
			classes = await api.get(`${uri}`)
		} catch (error) {
			throw new Error(error)
		}

		return {classes}
	})

const addClass = createAsyncThunk(
	`${namespace}/add`,
	async (data) => {
		let klass

		try {
			await api.post(`${uri}`, {body: data})
				.then(async (res) => klass = await api.get(`${uri}/${res.id}`))
		} catch (error) {
			throw new Error(error)
		}
		return {klass}
	})

const updateClass = createAsyncThunk(
	`${namespace}/update`,
	async ({id, data}) => {

		try {
			await api.put(`${uri}/${id}`, {body: data})
		} catch (error) {
			throw new Error(error)
		}
		const klass = await api.get(`${uri}/${id}`)
		return {klass}
	})

const deleteClass = createAsyncThunk(
	`${namespace}/delete`,
	async (id, thunkApi) => {
		try {
			await api.delete(`${uri}/${id}`).then(() => {
				thunkApi.dispatch(gradeThunks.fetchGrades())
			})
		} catch (error) {
			notifyError("Невозможно удалить класс, в котором есть книги")
			throw new Error(error)
		}
		return {id}
	})


const {reducer} = createSlice({
	name: namespace,
	initialState: {
		classes: [],
		loading: false,
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchClasses.fulfilled, (state, action) => {
				state.classes = action.payload.classes
			})
			.addCase(addClass.pending, (state) => {
				delete state.error
				state.loading = true
			})
			.addCase(addClass.fulfilled, (state, action) => {
				state.loading = false
				state.classes.push(action.payload.klass)
			})
			.addCase(addClass.rejected, (state) => {
				state.error = 'error'
				state.loading = false
			})
			.addCase(deleteClass.fulfilled, (state, action) => {
				state.loading = false
				state.classes = state.classes.filter((t) => t.id != action.payload.id)
			})
			.addCase(updateClass.fulfilled, (state, action) => {
				const index = state.classes.findIndex((t) => t.id == action.payload.klass.id)
				state.classes[index] = {...state.classes[index], ...action.payload.klass}
			})
})

const thunks = {fetchClasses, updateClass, deleteClass, addClass}

export {reducer, thunks}
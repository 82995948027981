import { useEffect, useState } from "react"
import {Col, Form, Modal} from "react-bootstrap"
import {useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {ButtonC, InputField} from "../../components"
import {useActions} from '../../hooks'
import { api } from "../../service/old-api"
import {thunks} from './model'

const actions = {updateSchool: thunks.updateSchool}

export const UpdateSchool = ({show, setShow, school}) => {
	const {t} = useTranslation()
	const {updateSchool} = useActions(actions)
	const {control, register, handleSubmit, formState: {errors}} = useForm()
	const [categories, setCategories]  = useState()

	const onClose = () => {
		setShow(false)
	}
	const school_categories = () => {
		api.get('references/school-categories').then((response) => {
			setCategories(response)
		})
	}
	useEffect(() => {
		school_categories()
	}, [])

	return (
		<Modal
			show={show}
			onHide={onClose}
			keyboard={false}
			size="lg"
		>
			<Modal.Header>
				<Modal.Title>{t('profile.update-school.title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(updateSchool)}>
					<select  {...register("kind")} style={{boxSizing: 'border-box', width: '100%', padding: '7px', border: '1px solid #ccc', borderRadius: '4px'}} >
					{categories?.map((category) => {
						{category.name}
						return (
						<option type="text" key={category.id} value={category.id} selected={school.kind===category.id?'true':'false'}> {category.name}</option>)
							})}
					</select>
					<InputField
						type='text'
						control={control}
						name='name'
						defaultValue={school?.name}
						rules={{required: t('error_message.no_empty')}}
						label={t('profile.update-school.name')}
						errors={errors.name}
					/>
					<InputField
						type='text'
						control={control}
						name='okpo'
						defaultValue={school?.okpo}
						rules={{required: t('error_message.no_empty')}}
						label={t('profile.update-school.okpo')}
						errors={errors.okpo}
					/>
					<Col className='moduleBtns'>
						<ButtonC variant="close" onClick={onClose}>
							{t('btn.close')}
						</ButtonC>
						<ButtonC variant="add" type='submit'>{t('btn.send')}</ButtonC>
					</Col>
				</Form>
			</Modal.Body>
		</Modal>
	)
}
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useState} from 'react'
import {ButtonC} from '../../../components'
import {faMinusSquare, faPlusSquare} from '@fortawesome/free-solid-svg-icons'
import {D10SubDist} from '../district/d10-sub-dist'
import {useEffect} from 'react'
import {storage} from '../../../service'
import {Card, Col, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom/cjs/react-router-dom.min'
import {newUseApi2} from '../../../hooks/new-use-api2';
import {D10DownloadFile} from '../d10-download-file'
import { useApi } from '../../../hooks'
import { api } from '../../../service/new-api-2'

const reg_id = storage.read('region_id');

export const D10SubRegItem = ({dist, index, withHead, quer}) => {
	const [district, setDistrict] = useState([])
	const [school, setSchool] = useState([])
	const getDistricts = async () => {
		const response = await api.get(`references/districts/?region=${reg_id}`)
		const data = await response
		new Array(data)
		setDistrict(data)
	}
	useEffect(() => {
		getDistricts()
	}, [])
	return (
		<dic>
		<Card>
			<Card.Header>
				<Row>
					<Col>Код района</Col>
					<Col>Отчеты по районам</Col>
					<Col>Действие</Col>
					{/* <Col>Скачать в Excel</Col> */}
				</Row>
			</Card.Header>
			{district  && district.length > 0 && district.map((dist) => {
					return (
						<Card.Body key={dist.id}>
							<Row>
								<Col>
								{dist.id}
								</Col>
								<Col>
								{dist.name}
								</Col>
								<Col>
								<Link to={{
					pathname: `/report-region-details/${dist.id}`,
		}} ><ButtonC variant="primary">проверить</ButtonC></Link>
								</Col>
								{/* <Col>
								<D10DownloadFile url={`reports/d10/xlsx/?language=russian&district_id=${dist.id}`} query='' />
								</Col> */}
							</Row>
						</Card.Body>
					)
				})}
		</Card>
		</dic>
	)
}

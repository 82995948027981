import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { ClassList, ClassDetail, AddClass } from "../feature/klass";
import { TeacherList } from "../feature/teacher";
import { useTranslation } from "react-i18next";
import { Layout } from "../app/layout";
import { useAppSelector, usePageTitle } from "../hooks";
import { ButtonC } from "../components";
import "./classes.css";
import { isLibrarian } from "../lib/helper";

export const Classes = () => {
  const [show, setShow] = useState(false);
  const grades = useAppSelector((state) => state.grades.grades);
  const gradeRender = [...new Set(grades?.map((el) => el.grade))];
  const { t } = useTranslation();
  usePageTitle(`${t("classes.title")}`);

  const handleShow = () => {
    setShow(true);
  };

  return (
    <Layout>
      <AddClass show={show} setShow={setShow} />
      <h2 className="mb-4 classes-title">{t("classes.title")}</h2>
      {isLibrarian && (
        <ButtonC
          variant="add"
          className="mb-4 ms-auto d-block add-class-btn"
          onClick={handleShow}
        >
          {t("classes.add-class.btn")}
        </ButtonC>
      )}
      <Tabs
        defaultActiveKey="all"
        transition={false}
        className={`mb-3`}
        variant="pills"
      >
        <Tab eventKey="all" title={t("classes.all-classes.title")}>
          <ClassList grades={grades} />
        </Tab>
        <Tab eventKey="teacherList" title={t("classes.all-teachers.title")}>
          <TeacherList />
        </Tab>
        {gradeRender.map((el) => {
          return (
            <Tab key={el} eventKey={el} title={`${el} кл`}>
              <ClassDetail grade={el} />
            </Tab>
          );
        })}
      </Tabs>
    </Layout>
  );
};

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from "../../service/old-api-2";


const namespace = 'bookstore'

const fetchBooks = createAsyncThunk(
    `${namespace}/fetchBooks`,
    async ({offset = null, search_q = null}, {getState}) => {
        const state = getState()
        let query = {limit: 20}
        if (offset) {
            query = {...query, offset: offset}
        }
        if (search_q) {
            query = {...query, ...search_q}
        }
        // if (!(offset in state.bookstore.books)) {
        let books
        try {
            books = await api.get('store/books/', {search: query})
        } catch (error) {
            throw new Error(error)
        }
        return {offset, books}
        // } else {
        //     const books = state.bookstore.books[offset]
        //     return {offset, books}
        // }
    }
)

const fetchStudentsCountByGrade = createAsyncThunk(
    `${namespace}/fetchStudentsCountByGrade`,
    async () => {
        let studentsCount
        try {
            studentsCount = await api.get('grades/')
        } catch (error) {
            throw new Error(error)
        }
        return studentsCount
    }
)

const fetchBalance = createAsyncThunk(
    `${namespace}/fetchBalance`,
    async () => {
        try {
            return await api.get('store/balance/')
        } catch (error) {
            throw new Error(error)
        }
    }
)
const createOrder = createAsyncThunk(
    `${namespace}/createOrder`,
    async (_, {getState}) => {
        try {
            const state = getState()
            const cartItems = []
            state['bookstore']['cart'].forEach((cartItem) => {
                cartItems.push({
                    book: cartItem.id,
                    quantity: cartItem.count
                })
            })
            return await api.post('store/orders/create/', {body: cartItems})
        } catch (error) {
            throw new Error(error)
        }
    }
)
const updateOrder = createAsyncThunk(
    `${namespace}/updateOrder`,
    async (_, {getState}) => {
        try {
            const state = getState()
            const cartItems = []
            state['bookstore']['cart'].forEach((cartItem) => {
                cartItems.push({
                    book: cartItem.id,
                    quantity: cartItem.count
                })
            })
            return await api.post('store/orders/update/', {body: cartItems})
        } catch (error) {
            throw new Error(error)
        }
    }
)
const fetchOrders = createAsyncThunk(
    `${namespace}/fetchOrders`,
    async () => {
        try {
            return api.get('store/orders/')
        } catch (error) {
            throw new Error(error)
        }
    }
)

const fetchOrdersInSchool = createAsyncThunk(
    `${namespace}/fetchOrders`,
    async (id) => {
        try {
            return api.get('store/orders-school/' + id)
        } catch (error) {
            throw new Error(error)
        }
    }
)

const fetchOrdersInDistrict = createAsyncThunk(
    `${namespace}/fetchOrders`,
    async (id) => {
        try {
            return api.get('store/orders-district/' + id)
        } catch (error) {
            throw new Error(error)
        }
    }
)

const {reducer, actions} = createSlice({
    name: namespace,
    initialState: {
        books: {},
        cart: [],
        studentsCountByGrade: [],
        currentBooks: {},
        balance: 0,
        totalPrice: 0,
        canAddBookToCart: true,
        order: {},
        orders: []
    },
    reducers: {
        addBook(state, action) {
            const book = state.cart.find((book) => book.id === action.payload.id)
            if (book) {
                book['count'] += 1
            } else {
                state.cart.push({...action.payload, count: 1})
            }
        },
        removeBook(state, action) {
            state.cart = state.cart.filter((cartItem) => cartItem.id !== action.payload.id);
        },
        increaseBookCount(state, action) {
            const book = state.cart.find((book) => book.id === action.payload.id)
            book['count'] += 1
        },
        dicreaseBookCount(state, action) {
            const book = state.cart.find((book) => book.id === action.payload.id)
            if (book.count > 1) {
                book['count'] -= 1
            }
        },
        changeBookCountByValue(state, action) {
            const book = state.cart.find((book) => book.id === action.payload.bookId)
            if (action.payload.count >= 1) {
                book['count'] = action.payload.count
            }
        },
        updateTotalPrice(state) {
            let total = 0;
            state.cart.forEach((cartItem) => {
                total += cartItem['price'] * cartItem['count']
            })
            if (total < state['available_balance']) {
                state.totalPrice = total;
                state.canAddBookToCart = true
            } else if (total === state['available_balance']) {
                state.totalPrice = total;
                state.canAddBookToCart = false
            } else {
                state.canAddBookToCart = false
            }
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(fetchBooks.fulfilled, (state, action) => {
                const {offset, search_q, books} = action.payload
                // state.books[`${offset} - ${search_q?search_q:0}`] = books
                state.currentBooks = books
            })
            .addCase(fetchStudentsCountByGrade.fulfilled, (state, action) => {
                state.studentsCountByGrade = action.payload
            })
            .addCase(fetchBalance.fulfilled, (state, action) => {
                state.balance = action.payload.balance
                state.available_balance = action.payload.available_balance
                state.shop_grades = action.payload.shop_grades
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.order = action.payload
            })
            .addCase(updateOrder.fulfilled, (state, action) => {
                state.order = action.payload
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.orders = action.payload
            })
})

export const {
    addBook,
    removeBook,
    moveToCart,
    increaseBookCount,
    dicreaseBookCount,
    changeBookCountByValue,
    updateTotalPrice,
} = actions;

const thunks = {
    fetchBooks,
    fetchStudentsCountByGrade,
    fetchBalance,
    createOrder,
    updateOrder,
    fetchOrders,
    fetchOrdersInSchool,
    fetchOrdersInDistrict
}

export {reducer, thunks}

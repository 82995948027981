import logo from "../logo-jk.png";
import { LoginForm } from "../feature/proflle";
import { usePageTitle } from "../hooks";
import styles from "../feature/proflle/login.module.css";
import { Link } from "react-router-dom";
import logoProject from "../lib/helper";

export const Login = () => {
  usePageTitle("Окуу Китеби");

  return (
    <div>
      <div className={styles.d10}>
        <Link to="/open-data" className="text-center d-block w-100">
          Д10 отчёт по республике
        </Link>
      </div>
      <div className={styles.wrapper}>
        <div className="w-50 ms-auto me-auto mb-4">
          <img src={logoProject} alt={logo} />
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonC, DeleteNotifyWithRedux } from "../../components";
import { notifyError, notifySuccess } from "../../components/notify";
import { useActions } from '../../hooks';
import classes from './books.module.css';
import { thunks } from './school-books-reducer'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import './styles.css';
import { isLibrarian } from "../../lib/helper";
import styles from "../../components/oneBook/oneBook.module.css";
import bookImg from "../../img/placeholder.png";
import { Accordion } from "react-bootstrap";

const actions = {
  updateSchoolBook: thunks.updateSchoolBook,
  deleteSchoolBook: thunks.deleteSchoolBook,
  addSchoolBook: thunks.addSchoolBooks,
}
export const SchoolBookItem = ({ book, index }) => {
  const years = new Array(new Date().getFullYear() - 1999)
  for (let i = 0; i < years.length; i++) {
    years[i] = new Date().getFullYear() - i
  }
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const { updateSchoolBook, deleteSchoolBook } = useActions(actions)
  const onFocus = (id) => {
    const btn = document.querySelector(`#button${id}`)
    btn.style.borderColor = '#0d6efd'
    btn.style.color = 'black'
  }

  const onBlur = (id) => {
    const btn = document.querySelector(`#button${id}`)
    btn.style.borderColor = '#90909a'
    btn.style.color = 'black'
  }

  async function changeYear(book, schoolbook) {

    const year = document.querySelector(`#year${book.id}`)

    if (book.year == '') {
      notifyError('Новая значения должен быть больше чем выданных книг')
      year = book.remaining
      return
    }
    const payload = await updateSchoolBook({
      id: book.id,
      data: { book: book.book.id, total: book.total, year: year.value }
    })

    if (payload.payload) {
      notifySuccess('Успешно изменено')
    }
  }

  const changeTotal = async (schoolbook) => {

    const total = document.querySelector(`#total${schoolbook.id}`)

    if (schoolbook.given > total.value) {
      notifyError('Новая значения должен быть больше чем выданных книг')
      total.value = schoolbook.remaining
      return
    }
    const body = {
      book: schoolbook.book.id,
      total: total.value,
      year: schoolbook.year
    }
    const payload = await updateSchoolBook({ id: schoolbook.id, data: body })

    if (payload.payload) {
      notifySuccess('Успешно изменено')
    }

  }

  return (
    <>
      <DeleteNotifyWithRedux
        func={deleteSchoolBook}
        id={book.id}
        show={show}
        setShow={setShow}
        message={book.book.name}
      />
      <tr>
        <td scope="col" data-label="№">{index + 1}</td>
        <td scope="col"
          data-label="Учебники / Автор">{book.book.name} {book.book.number_of_parts > 1 ? <>({book.part}-{t('school-books.part')})</> : ''}<br />{book.book.author}
        </td>
        <td scope="col" data-label="Изображение">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey={index}>
              <Accordion.Header style={{ whiteSpace: 'nowrap' }}>Обложка</Accordion.Header>
              <Accordion.Body>
                <div className={`${styles.imgWrap} my-4 ms-auto me-auto`}>
                  {book.book.image && <img src={book.book.image} alt="1" />}
                  {!book.book.image && <img src={bookImg} alt="1" />}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </td>
        <td scope="col" data-label="Класс">{(book.book.grades).join(',')} кл</td>
        <td scope="col" data-label="Год издания">{book.year}</td>
        <td scope="col" data-label="Язык обучения">{book.book.language}</td>
        <td scope="col" data-label="В аренде">
          {/* <input type="checkbox" name={book.id} className="me-1" defaultChecked={book.book.subject_to_renting}
                          disabled={!applicationBook.book.subject_to_renting}/> */}
          {/* <input type="checkbox" name={book.id} className="me-1" defaultChecked={book.subject_to_renting}
                          disabled={true}/> */}
          <label
            htmlFor={book.id}>{book.book.subject_to_renting ? t('school-books.yes') : t('school-books.no')}</label>
        </td>
        <td scope="col" data-label="Кол-во учебников">
          {book.book.grades.length <= 1 &&
            <p>Всего - <b>{book.total}</b></p>
          }
          {book.book.grades.length > 1 &&
            <>
              <p>Всего - <b>{book.total}</b></p>
              <br />
              {
                book.grade_books.map((grade, index) => (
                  <div key={`total${book.id}-${grade.grade}`} style={{ 'marginBottom': 10 }}>
                    <p>{grade.grade} кл. - {grade.total_number_of_books}</p>
                  </div>
                ))
              }
            </>
          }
          {/* <div className='d-flex justify-content-between my-column'> */}
          {/* <input
                            defaultValue={book.total}
                            type='number'
                            className={classes.total}
                            id={`total${book.id}`}
                            onFocus={() => onFocus(book.id)}
                            onBlur={() => onBlur(book.id)}
                        /> */}
          {/* {isLibrarian &&     <ButtonC
                            variant='change'
                            onClick={() => changeTotal(book)}
                            id={`button${book.id}`}
                            className={classes.changeButton}
                        >
                            {t('btn.change')}
                        </ButtonC>} */}
          {/* </div> */}
        </td>
        {/* <td scope="col" data-label="Кол-во учебников">{book.total}</td> */}
        <td scope="col" data-label="Выданые">
          {book.book.grades.length <= 1 &&
            <>
              <b>Всего - {book.given}</b>
              <hr />
              {book.given > 0 &&
                <>
                  50% льготники - <b>{book.student_count_50_discount}</b>
                  <hr />
                  100% льготники - <b>{book.student_count_100_discount}</b>
                </>
              }
            </>
          }
          {book.book.grades.length > 1 &&
            <>
              <p>Всего - <b>{book.given}</b></p>
              {book.given > 0 &&
                <>
                  <br />
                  50% льготники - <b>{book.student_count_50_discount}</b>
                  <br />
                  100% льготники - <b>{book.student_count_100_discount}</b>
                  {
                  book.grade_books.map((grade, index) => (
                    <div key={`total${book.id}-${grade.grade}`} style={{ 'marginBottom': 10 }}>
                      <p>{grade.grade} кл. - {grade.number_of_issued_books}</p>
                    </div>
                  ))
                  }
                </>
              }
              <br />
            </>
          }
        </td>
        <td scope="col" data-label="Доступно">{book.remaining}</td>
        <td scope="col" data-label="С QR">{book.with_code}</td>
        {/* {isLibrarian &&      <td scope="col" data-label="Удалить">
                    {book.delible && <ButtonC variant='delete' onClick={() => setShow(true)}>
                        <FontAwesomeIcon icon={faTrash} className="mx-3"/>
                    </ButtonC>}
                </td>} */}
        {book.given == 0 ? <td data-label="Кому выдана"><ButtonC
          variant="disable">{t('school-books.to-who-it-is-rent-btn')}</ButtonC></td> :
          <td data-label="Кому выдана" scope="col"><Link to={{
            pathname: `/school-books/rent/${book.id}`,
          }}><ButtonC>{t('school-books.to-who-it-is-rent-btn')}</ButtonC></Link></td>}
      </tr>
    </>
  )
}

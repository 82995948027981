import {useTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import {ButtonC, TableComponent} from "../../../components"
import {useAppSelector} from '../../../hooks'

export const TeachersBookList = () => {
	const {t} = useTranslation()
	const teachers = useAppSelector((state) => state.teachers.teachers)

	return (
		<>
			<TableComponent>
				<thead>
					<tr>
						<th>
							№
						</th>
						<th scope='col'>
							{t('teacher-books.teacher-list.fullname')}
						</th>
						<th scope='col'>
							{t('teacher-books.teacher-list.classes')}
						</th>
						<th scope='col'>
							{t('teacher-books.teacher-list.action')}
						</th>
					</tr>
				</thead>
				<tbody>
					{teachers.map((teacher, index) => (
						<tr key={teacher.id}>
							<td>{index + 1}</td>
							<td>{teacher.fullname}</td>
							<td>{teacher.classes.join(', ')}</td>
							<td>
								<Link to={`/teacher-books/${teacher.id}`}>
									<ButtonC variant='primary'>
										{t('teacher-books.teacher-list.action-btn')}
									</ButtonC>
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</TableComponent>
		</>
	)
}

import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ButtonC, DeleteNotifyWithRedux } from "../../../components"
import { notifyError, notifySuccess } from "../../../components/notify"
import { useActions } from '../../../hooks'
import { thunks } from '../../school-books/school-books-reducer'
import { thunks as classBookThunks } from '../class-book-reducer'
import classes from '../action-book.module.css'
import { isLibrarian } from "../../../lib/helper"
import {useAppSelector} from '../../../hooks';

const actions = {
	updateSchoolBook: thunks.updateSchoolBook,
	deleteClassBook: classBookThunks.deleteClassBook,
	updateClassBook: classBookThunks.updateClassBook
}

export const BookItemReception = ({ kb, index, klass }) => {
	const [show, setShow] = useState(false)
	const { updateSchoolBook, updateClassBook, deleteClassBook } = useActions(actions)
	const count = useRef('')
	const { t } = useTranslation()
	const classBooks = useAppSelector((state) => state.classBooks.classBooks)

	const acceptBook = async (id) => {
		if (!count.current || count.current == 0) {
			notifyError('Введите количество книг')
			return
		}
		if (kb.count < count.current) {
			notifyError('Принятые учебники должен быть меньше чем выданных')
			return
		}
		if (kb.count - count.current < kb.count_50 + kb.count_100) {
			notifyError('Принятые учебники не могут быть меньше выданных льготникам.')
			return
		}
		const body = {
			action: "accept",
			count: parseInt(count.current)
		}
		const payload = await updateClassBook({ id: id, data: body })
		if (payload.payload) {
			notifySuccess("Принято")
			count.current = ''
			updateSchoolBook({ id: kb.school_book.id })
		}
	}
	const recieveClassBookFrom50 = async (id) => {
		const count = document.querySelector(`#icountFor50recive${id}`)
		const book = classBooks.find((el) => el.id === id)
		const gradeIndex = book.school_book.grade_books.findIndex((grade) => grade.grade === klass.grade);
		const issuedToGrade = book.school_book.grade_books[gradeIndex]?.number_of_issued_books
		if (!count.value) {
		  notifyError('Введите количество учебников')
		  return
		}
		if (!book.count) {
		  notifyError('Нет выданных учебников')
		  return
		}
		if (parseInt(count.value) > klass?.student_count_50_discount) {
		  notifyError('Количество учебников превышает количество льготников')
		  return
		}
		if (parseInt(count.value) > book.count) {
		  notifyError('Количество учебников превышает количество выданных учебников')
		  return
		}
		const body = {
		  action: "change_50",
		  count: count.value
		}
		const payload = await updateClassBook({ id: id, data: body })
		if (payload.payload) {
		  notifySuccess(`${t('notify.success.changed')}`)
		  count.value = ''
		  updateSchoolBook({ id: book.school_book.id })
		}
	  }
	  const recieveClassBookFrom100 = async (id) => {
		const count = document.querySelector(`#icountFor100recive${id}`)
		const book = classBooks.find((el) => el.id === id)
		const gradeIndex = book.school_book.grade_books.findIndex((grade) => grade.grade === klass.grade);
		const issuedToGrade = book.school_book.grade_books[gradeIndex]?.number_of_issued_books
		if (!count.value) {
		  notifyError('Введите количество учебников')
		  return
		}
		if (!book.count) {
		  notifyError('Нет выданных учебников')
		  return
		}
		if (parseInt(count.value) > klass?.student_count_100_discount) {
		  notifyError('Количество учебников превышает количество льготников')
		  return
		}
		if (parseInt(count.value) > book.count) {
		  notifyError('Количество учебников превышает количество выданных учебников')
		  return
		}
		const body = {
		  action: "change_100",
		  count: count.value
		}
		const payload = await updateClassBook({ id: id, data: body })
		if (payload.payload) {
		  notifySuccess(`${t('notify.success.changed')}`)
		  count.value = ''
		  updateSchoolBook({ id: book.school_book.id })
		}
	  }


	return (
		<>
			<DeleteNotifyWithRedux
				message={kb?.school_book?.book?.name}
				func={deleteClassBook}
				id={kb.id}

				show={show}
				setShow={setShow}
			/>
			<tr key={kb.id}>
				<td data-label="№" scope="col">{index + 1}</td>
				<td data-label="Наименование">{kb?.school_book?.book?.name}</td>
				<td data-label="Автор">{kb?.school_book?.book?.author}</td>
				<td data-label="Год">{kb?.school_book?.year}</td>
				<td data-label="Язык обучения">{kb?.school_book?.book?.language}</td>
				<td data-label="Выданные">
					{kb.count}
					<p>50% льготникам - <b>{kb.count_50}</b></p>
					<p>100% льготникам - <b>{kb.count_100}</b></p>
					<p>Изменить количество учебников для 50% льготников:</p>
					<input
						type="number"
						id={`icountFor50recive${kb.id}`}
						className={classes.count}
						placeholder={0}
						max={kb.school_book.remaining}
						style={{ height: '100%' }}
					/>
					<ButtonC variant="add" onClick={() => recieveClassBookFrom50(kb.id)}>
						{t('action-books.class-action.issue.issue_discount_btn')}
					</ButtonC>
					<br />
					<p>Изменить количество учебников для 100% льготников:</p>
					<input
						type="number"
						id={`icountFor100recive${kb.id}`}
						className={classes.count}
						placeholder={0}
						max={kb.school_book.remaining}
						style={{ height: '100%' }}
					/>
					<ButtonC variant="add" onClick={() => recieveClassBookFrom100(kb.id)}>
						{t('action-books.class-action.issue.issue_discount_btn')}
					</ButtonC>
				</td>
				{isLibrarian && <td data-label="Принятые">
					<input
						type="number"
						id={`count${kb.id}`}
						className={classes.count}
						placeholder={0}
						onChange={(e) => count.current = e.target.value}
					/>
				</td>}
				{isLibrarian && <td data-label="Действие">
					{kb.count === 0 ? <div className={classes.action}>
						<ButtonC variant="disable" >
							{t('action-books.class-action.reception.received_btn')}
						</ButtonC>
						<ButtonC variant='delete' onClick={() => setShow(true)}>
							<FontAwesomeIcon icon={faTrash} />
						</ButtonC>
					</div> : <ButtonC variant="add" onClick={() => acceptBook(kb.id)}>
						{t('action-books.class-action.reception.receive_btn')}
					</ButtonC>
					}
				</td>}
			</tr>
		</>
	)
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notifyInfo, notifySuccess } from "../../components/notify";
import { storage } from "../../service";
import {api} from '../../service/new-api-2'
const school_id = storage.read('school_id')
const namespace ='profile2'

const fetchLibrary = createAsyncThunk(
    `${namespace}/fetchLibrary`,
    async () => {
        const response = await api.get(`libraries/?school=${school_id}`)
        return response
    }
)

const updateCombinedArea = createAsyncThunk(
    `${namespace}/updateCombinedArea`,
    async (data, {getState}) => {
        const state = getState()
        const library = state.profile2.library
        if (library) {
            data = {...data, school: library.school, is_combined: library.is_combined, has_an_office: library.has_an_office}
            notifySuccess('Успешно добавлено')
        }
        const response = await api.put(`libraries/${library?.id}/`, {body: {...data}})
        return response
    }
)

const updateLibrary = createAsyncThunk(
    `${namespace}/updateLibrary`,
    async (data) => {
        const response = await api.put(`libraries/${data.libraryId}/`, {body: {...data.body}})
        return response
    }
)

const isCombined = createAsyncThunk(
    `${namespace}/isCombined`,
    async (data) => {
        const response = await api.put(`libraries/${data.libraryId}/`, {body: {...data.body}})
        return response
    }
)

const addWarehouse = createAsyncThunk(
    `${namespace}/addWarehouse`,
    async (data, {getState}) => {
        const state = getState()
        const libraryId = state.profile2.library?.id
        const response = await api.post('warehouses/', {body: {...data, library: libraryId}})
        notifySuccess('Склад успешно добавлен')
        return response
    }
)

const removeWarehouse = createAsyncThunk(
    `${namespace}/removeWarehouse`,
    async (warehouseId) => {
        const response = await api.delete(`warehouses/${warehouseId}/`)
        const status = response.status
        notifyInfo('Склад успешно удален')
        return {status, warehouseId}
    }
)


const addReadingRoom = createAsyncThunk(
    `${namespace}/addReadingRoom`,
    async (data, {getState}) => {
        const state = getState()
        const libraryId = state.profile2.library?.id
        const response = await api.post('reading-rooms/', {body: {...data, library: libraryId}})
        notifySuccess('Читальный зал успешно добавлен')
        return response
    }
)

const removeReadingRoom = createAsyncThunk(
    `${namespace}/removeReadingRoom`,
    async (readingRoomId) => {
        const response = await api.delete(`reading-rooms/${readingRoomId}/`)
        const status = response.status;
        notifyInfo('Читальный зал успешно удален')
        return {status, readingRoomId}
    }
)

const createLibrary = createAsyncThunk(
    `${namespace}/createLibrary`,
    async (data) => {
        const response = await api.post('libraries/', {body: {...data}})
        return response
    }
)

const deleteLibrary = createAsyncThunk(
    `${namespace}/deleteLibrary`,
    async (libraryId) => {
        const response = await api.delete(`libraries/${libraryId}/`)
        const status = response.status;
        return {status, libraryId}
    }
)
const {reducer} = createSlice({
    name: namespace,
    initialState: {
        library: null,
    },
    reducers: {},
    extraReducers: (builder) =>
        builder
        .addCase(fetchLibrary.fulfilled, (state, action) => {
            const data = action.payload
            if (data.length > 0) {
                state.library = data[0]
            }
        })
        .addCase(updateLibrary.fulfilled, (state, action) => {
            const data = action.payload
            state.library = data
        })
        .addCase(updateCombinedArea.fulfilled, (state, action) => {
            state.library = action.payload
        })
        .addCase(isCombined.fulfilled, (state, action) => {
            const data = action.payload
            state.library = data
        })
        .addCase(addWarehouse.fulfilled, (state, action) => {
            state.library.warehouses.push(action.payload)
        })
        .addCase(removeWarehouse.fulfilled, (state, action) => {
            const {status, warehouseId} = action.payload
            if (status === 204) {
                state.library.warehouses = state.library.warehouses.filter((warehouse) => {
                    return warehouse.id !== warehouseId;
                })
            }
        })
        .addCase(addReadingRoom.fulfilled, (state, action) => {
            state.library.reading_rooms.push(action.payload)
        })
        .addCase(removeReadingRoom.fulfilled, (state, action) => {
            const {status, readingRoomId} = action.payload
            if (status === 204) {
                state.library.reading_rooms = state.library.reading_rooms.filter((readingRoom) => {
                    return readingRoom.id !== readingRoomId;
                })
            }
        })
        .addCase(createLibrary.fulfilled, (state, action) => {
            state.library = action.payload
        })
        .addCase(deleteLibrary.fulfilled, (state, action) => {
            const {status, libraryId} = action.payload
            if (status === 204) {
                state.library = null
            }
        })
    }
)

const thunks = {fetchLibrary, updateLibrary,
    isCombined, addWarehouse, removeWarehouse,
    addReadingRoom, removeReadingRoom, deleteLibrary,
    createLibrary, updateCombinedArea
}
export {fetchLibrary, updateLibrary, isCombined,
    addWarehouse, removeWarehouse, addReadingRoom,
    removeReadingRoom, deleteLibrary, createLibrary,
    updateCombinedArea
}
export {reducer, thunks}
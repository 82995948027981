import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { removeWarehouse } from '../../app/store/profileReducer'
import { ButtonC } from '../../components'
import { useAppSelector } from '../../hooks'
import { AddNewWarehouse } from './add-new-warehouse'

export const Warehouse = () => {
    const [addWarehouse, setAddWarehouse] = useState(false)
    const [warehouse, setWarehouse] = useState()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const profileLibrary = useAppSelector((state) => state.profile2.library)
    let index = 1
    const deleteWarehouse = (id) => {
		dispatch(removeWarehouse(id))
	}
  return (
    <>
    	<AddNewWarehouse
			show={addWarehouse}
			setShow={setAddWarehouse}
			warehouse={warehouse}
			/>
    	<ListGroup variant="flush" className="mt-3">
			<ListGroup.Item><strong>{t('profile.number_of_warhouses')}</strong></ListGroup.Item>
			{profileLibrary?.warehouses && profileLibrary?.warehouses.length > 0 &&  profileLibrary?.warehouses.map((warehouse) => {
					return (
      <ListGroup.Item key={warehouse.id}>{t('profile.warehouse')} # {index++}  {t('profile.single_area')} {warehouse?.area} м²
      <ButtonC variant="delete" onClick={() => deleteWarehouse(warehouse.id)}><FontAwesomeIcon icon={faTrash} /></ButtonC></ListGroup.Item>
	  )
	})}
   <p className="mt-2 m-5 mb-2"><ButtonC variant="change" onClick={() => setAddWarehouse(true)}>{t('profile.add_new_warehouse')} </ButtonC></p>
    </ListGroup>
    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { ButtonC, PdfHeader, TableComponent } from '../../../components';
import { api } from '../../../service/new-api-2';
import { storage } from '../../../service';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactToPrint } from 'react-to-print';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

export const G2LibraryRoomsJK = () => {
  const { t } = useTranslation()
  const [libraryRooms, setLibraryRooms] = useState();
  const region_id = storage.read('region_id');
  const getLibraryRooms = () => {
    api.get(`reports/library-rooms/`).then((response) => setLibraryRooms(response))
  }
  useEffect(() => {
    getLibraryRooms()
  }, [])
  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  let index = 1;
  return (
    <div>
      <>
        <TableComponent ref={componentRef}>
          <ButtonC variant='download' onClick={handlePrint} style={{ float: 'left', marginBottom: '15px', backgroundColor: 'red', color: '#fff' }}>
            PDF <FontAwesomeIcon style={{ color: '#fff' }} icon={faFilePdf} />
          </ButtonC>
          <PdfHeader title={t('report.g2.title')} />
          <thead>
            <tr>
              <td>№</td>
              <td>{t('report.g2.school_id')}</td>
              <td>{t('report.g2.region')}</td>
              <td>{t('report.g2.district')}</td>
              <td>{t('report.g2.school')}</td>
              <td>
                {t('report.g2.director')} /<br/>
                {t('report.g2.email')} /<br/>
                {t('report.g2.phone')} /
              </td>
              <td>
                {t('report.g2.librarian')} /<br/>
                {t('report.g2.email')} /<br/>
                {t('report.g2.phone')} /
              </td>
              <td>{t('report.g2.personal-room')}</td>
              <td>{t('report.g2.reading-room')}</td>
              <td>{t('report.g2.warehouse')}</td>
              <td>{t('report.g2.combined_area')}</td>
              <td>{t('report.g2.photos')}</td>
            </tr>
          </thead>
          <tbody>
            {libraryRooms && libraryRooms.length > 0 && libraryRooms.map((rooms) => {
              return (
                <tr key={rooms.id}>
                  <td data-label="№">{index++}</td>
                  <td data-label="Код школы">{rooms.school_id}</td>
                  <td data-label="Область">{rooms.region}</td>
                  <td data-label="Район">{rooms.district}</td>
                  <td data-label="Школа" style={{width: "100px"}} >{rooms.school_name}</td>
                  <td data-label="Директор" style={{'overflowWrap': 'anywhere'}}>
                    {rooms.director_name}<br/><hr />
                    {rooms.director_email}<br/><hr />
                    {rooms.director_phone}
                  </td>
                  <td data-label="Библиотекарь" style={{'overflowWrap': 'anywhere'}}>
                    {rooms.librarian_name}<br/><hr />
                    {rooms.librarian_email}<br/><hr />
                    {rooms.librarian_phone}
                  </td>
                  <td data-label="Личный кабинет">{rooms.has_an_office ? 'да' : 'нет'}</td>
                  <td data-label="Читальный зал">{rooms.reading_rooms?.map((room_area) => {
                    return (
                      room_area
                    )
                  }).join(', ')}</td>
                  <td data-label="Склад">{rooms.warehouses?.map((wh) => {
                    return (
                      wh
                    )
                  }).join(', ')}</td>
                  <td data-label="Площадь библиотеки">{rooms.combined_area}</td>
                  <td data-label="Фото">{rooms.photos?.map((photo) => {
                    return (
                      <><a href={photo} target='_blank' rel="noreferrer" >Фото</a><br/></>
                    )
                  })}</td>
                </tr>
              )
            })}
          </tbody>
        </TableComponent></>
    </div>
  )
}

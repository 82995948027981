import React, {useState} from "react";
import {Col, Form, Modal, Row} from 'react-bootstrap'
import {useForm} from "react-hook-form";
import {notifyError, notifySuccess} from "../../components/notify";
import {gradeEnum, langOptions} from "../../lib/helper";
import {useTranslation} from "react-i18next";
import {ButtonC, InputField, SelectField} from "../../components";
import {useActions, useAppSelector} from '../../hooks'
import {thunks} from './school-events-reducer';
import {thunks as gradeThunks} from '../../app/store/grade-reducer'

const actions = {
  addSchoolEvent: thunks.addSchoolEvent,
}


export const AddSchoolEvent = ({show, setShow}) => {
  const {handleSubmit, register, reset, formState: {errors}, control, setValue} = useForm()
  const schoolEventTypes = useAppSelector((state) => state.schoolEventTypes.school_event_types)
  const {addSchoolEvent} = useActions(actions)
  const {t} = useTranslation()

  const handleClose = () => {
    reset()
    setShow(false)
  };

  const school_event_types = schoolEventTypes.map((l) => ({value: l.id, label: l.name, label_kg: l.name_kg}))

  const postSchoolEvent = async (data) => {
    // if (!active) {
    //   notifyError('Выберите учителя из списка')
    //   return
    // }
    const school_event = {
      name: data.name,
      date: data.date,
      type: data.type,
      comment: data.comment,
      result: data.result
    }
    const a = await addSchoolEvent(school_event)
    if (a.payload) {
      reset()
      notifySuccess(`${t('notify.success.added')}`)
      setShow(false)
      // setCurrentTeacher({})
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{t('events.add-event')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(postSchoolEvent)}>
            <Row md={1} xs={1}>
              <Col>
                <InputField
                  control={control}
                  type='date'
                  name='date'
                  label='Дата'
                  rules={{
                    required: t('error_message.no_empty'),
                    // max: {value: 60, message: t('classes.add-class.student_count_error')},
                    // min: {value: 1, message: t('classes.add-class.student_count_error')}
                  }}
                  errors={errors.date}
                />
              </Col>
            </Row>
            <Row md={1} xs={1}>
              <Col>
                <SelectField
                  control={control}
                  name="type"
                  register={{
                    ...register('type',
                      {required: t('error_message.no_empty')})
                  }}
                  required={true}
                  optGrpHead={t('events.chose-type')}
                  errors={errors.type}
                  label={t('events.field-type')}
                  options={school_event_types}
                  translated={true}
                />
              </Col>
            </Row>
            <Row md={1} xs={1}>
            <Col>
                <InputField
                  rules={{
                    required: t('error_message.no_empty'),
                  }}
                  control={control}
                  name='name'
                  required={true}
                  label={t('events.field-name')}
                  errors={errors.name}
                />
              </Col>
              <Col>
                <InputField
                  control={control}
                  name='comment'
                  rules={{
                    required: t('error_message.no_empty'),
                    // max: {value: 60, message: t('classes.add-class.student_count_error')},
                    // min: {value: 1, message: t('classes.add-class.student_count_error')}
                  }}
                  label={t('events.field-comment')}
                  errors={errors.comment}
                />
              </Col>
              <Col>
                <InputField
                  control={control}
                  name='result'
                  rules={{
                    required: t('error_message.no_empty'),
                    // max: {value: 60, message: t('classes.add-class.student_count_error')},
                    // min: {value: 0, message: t('classes.add-class.student_count_error')}
                  }}
                  errors={errors.result}
                  label={t('events.field-result')}
                />
              </Col>
            </Row>
            <Col className='moduleBtns'>
              <ButtonC variant="close" onClick={handleClose}>
                {t('btn.close')}
              </ButtonC>
              <ButtonC variant="add" type='submit'>{t('btn.add')}</ButtonC>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

import {useTranslation} from 'react-i18next'
import {storage} from '../../service'
import styles from './pdf-header.module.css'
import { format } from 'date-fns'
import { useAppSelector } from "../../hooks";

export const PdfFooterToD10 = ({title, children, order, language}) => {
	const today = new Date(Date.now()).toLocaleDateString()
	const {t} = useTranslation()

	const user = useAppSelector((state) => state.profile.user);
	const role = storage.read('role')

	return (
		<div className={styles.print}>
			<br />
			{(role === 'librarian' && language === 'russian') &&
				<p>Подтверждаем правильность внесенных данных</p>
			}
			{(role === 'librarian' && (language === 'kyrgyz' || language === 'uzbek' || language === 'tajik')) &&
				<p>Киргизилген маалыматтардын тууралыгын тастыктайбыз</p>
			}
			<br />
			<p>{t('print.director2')} ____________________ {storage.read('director')}</p>
			<br />
			<p>{t('print.librarian')} _______________ {user?.fullname}</p>
			<br />
			{(role === 'librarian' && language === 'russian') &&
				<p>М.П.</p>
			}
			{(role === 'librarian' && (language === 'kyrgyz' || language === 'uzbek' || language === 'tajik')) &&
				<p>М.О.</p>
			}

		</div>
	)
}
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import React, { useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ButtonC } from '../../components'
import { useAppSelector } from '../../hooks'
import { UpdateCombinedArea } from './update-combined-area'

export const CombinedArea = () => {
    const [addCombinedArea, setAddCombinedArea] = useState(false)
    const {t} = useTranslation()
    const [combinedArea, setCombinedArea] = useState()
    const profileLibrary = useAppSelector((state) => state.profile2.library)
  return (
    <>
    	<UpdateCombinedArea
			show={addCombinedArea}
			setShow={setAddCombinedArea}
			combined_area={combinedArea}
			/>
    <ListGroup>
    <ListGroup.Item>
        <strong>{t('profile.combined_area')}</strong>
    </ListGroup.Item>
<ListGroup.Item >{t('profile.combined_area')} {profileLibrary?.combined_area} м²
</ListGroup.Item>
<p className="mt-4 mb-2">
    {profileLibrary?.combined_area == "0.00" ?
    <ButtonC variant="change" className="mt-2" onClick={() => setAddCombinedArea(true)}>{t('profile.add_combined_area')} </ButtonC> : <ButtonC onClick={() => setAddCombinedArea(true)}>{t('profile.edit_combined_area')}</ButtonC>}

</p>
    </ListGroup>
    </>
  )
}

import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonC, InputField, SelectField } from "../../components";
import { years } from "../../lib/helper";
import { storage } from "../../service";
import { api } from "../../service/old-api-2";

export const PaymentsUnknownFilter = ({ setUnknownPaymentItem }) => {
  const { t } = useTranslation();
  const { register, control } = useForm();
  const [query, setQuery] = useState({ school_id: 9999 });
  const getPaymentData = () => {
    api.get("payments/", { search: query }).then((response) => {
      setUnknownPaymentItem(response);
    });
  };
  useEffect(() => {
    getPaymentData(query);
  }, [query]);

  const searchByNumber = (data) => {
    setQuery((query) => {
      return { ...query, transaction_id: data };
    });
  };

  return (
    <Form className="mt-5">
      <Row>
        <Col xs={2}>
          <InputField
            type="text"
            control={control}
            name="transaction_id"
            placeholder={t("payment.number_operation")}
            onChange={(event) => searchByNumber(event.target.value)}
          />
        </Col>
      </Row>
    </Form>
  );
};

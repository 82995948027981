import {SelectField} from ".."
import cn from 'classnames'
import styles from './language.module.css'
import {storage} from '../../service'
import {thunks} from '../../app/store/model'
import {useActions} from '../../hooks'

const actions = {setLocale: thunks.setLocale}

export const ChangeLanguage = ({className, ...props}) => {
	const {setLocale} = useActions(actions)
	const lang = [
		{value: 'ky', label: 'Кыргызча'},
		{value: 'ru', label: 'Русский'},
	]
	const defaultLang = storage.read('lang')
	return (
		<SelectField
			defaultValue={defaultLang}
			className={cn(styles.lang, className)}
			options={lang}
			onChange={(event) => setLocale(event.target.value)}
			{...props}
		/>
	)
}
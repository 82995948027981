import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'

const namespace = 'school-event-types'

const fetchSchoolEventTypes = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let school_event_types

		try {
			school_event_types = await api.get('school-event-types')
		} catch (error) {
			throw new Error(error)
		}

		return {school_event_types}
	})

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		school_event_types: [],
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchSchoolEventTypes.fulfilled, (state, action) => {
				state.school_event_types = action.payload.school_event_types
			})
})

const thunks = {fetchSchoolEventTypes}

export {reducer, thunks}

import { useTranslation } from "react-i18next";
import {
  ButtonC,
  DeleteNotifyWithRedux,
  TableComponent,
} from "../../components";
import { useActions, useAppSelector } from "../../hooks";
import { thunks } from "./school-events-reducer";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isLibrarian } from "../../lib/helper";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { UpdateSchoolEvent } from "./update-school-event";
import { storage } from "../../service";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const actions = {
  fetchSchoolEvents: thunks.fetchSchoolEvents,
  deleteSchoolEvent: thunks.deleteSchoolEvent,
};

export const SchoolEventsList = (props) => {
  const { fetchSchoolEvents, deleteSchoolEvent } = useActions(actions);
  const { school_events } = props;
  const { t } = useTranslation();
  const [query, setQuery] = useState({});
  const [deleteSchoolEvents, setDeleteSchoolEvent] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [editValue, setEditValue] = useState({});
  const language = storage.read("lang");

  const handleClose = () => {
    setEditValue({});
    setShow(false);
  };
  const handleShow = async (id) => {
    setEditValue(school_events.filter((c) => c.id == id)[0]);
    setShow(true);
  };
  const handleDeleteSchoolEvent = async (school_event) => {
    setShowDelete(true);
    setDeleteSchoolEvent(school_event);
  };
  const tr =
    school_events?.length > 0 &&
    school_events.map((el, key) => (
      <tr key={key}>
        <td>{el.name}</td>
        <td>{el.date}</td>
        <td>{language === "ky" ? el.type?.name_kg : el.type?.name}</td>
        <td>{el.comment}</td>
        <td>{el.result}</td>
        <td>
          {el.created} / <br />
          {el.updated}
        </td>
        {isLibrarian && (
          <td className="d-flex justify-content-around">
            <div>
              <ButtonC variant="change" onClick={() => handleShow(el.id)}>
                {t("classes.class-detail.update")}
              </ButtonC>
              {
                <ButtonC
                  variant="delete"
                  onClick={() => handleDeleteSchoolEvent(el)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ButtonC>
              }
            </div>
          </td>
        )}
      </tr>
    ));
  useEffect(() => {
    fetchSchoolEvents();
  }, [query]);

  return (
    <>
      <UpdateSchoolEvent
        show={show}
        handleClose={handleClose}
        editValue={editValue}
        setShow={setShow}
      />
      <DeleteNotifyWithRedux
        message={deleteSchoolEvents.name}
        func={deleteSchoolEvent}
        id={deleteSchoolEvents.id}
        show={showDelete}
        setShow={setShowDelete}
      />
      <TableComponent>
        <thead>
          <tr>
            <th scope="col">{t("events.field-name")}</th>
            <th scope="col">Дата проведения</th>
            <th scope="col">{t("events.field-type")}</th>
            <th scope="col">{t("events.field-comment")}</th>
            <th scope="col">{t("events.field-result")}</th>
            <th scope="col">
              {t("events.field-create")} / <br />
              {t("events.field-update")}
            </th>
            {isLibrarian && (
              <th scope="col">{t("classes.class-detail.action")}</th>
            )}
          </tr>
        </thead>
        <tbody>{tr}</tbody>
      </TableComponent>
    </>
  );
};

import React from "react";
import {useTranslation} from "react-i18next";
import {TableComponent} from "../../../components";
import {useAppSelector} from '../../../hooks';
import { isLibrarian } from "../../../lib/helper";

import {BookItemReception} from "./book-item-recetption";

export const TableReception = (klass) => {
	const classBooks = useAppSelector((state) => state.classBooks.classBooks)
	const {t} = useTranslation()

	return (
		<TableComponent>
			<thead>
				<tr>
					<th scope="col">№</th>
					<th scope="col">{t('action-books.class-action.reception.name')}</th>
					<th scope="col">{t('action-books.class-action.reception.author')}</th>
					<th scope="col">{t('action-books.class-action.reception.year')}</th>
					<th scope="col">{t('action-books.class-action.language')}</th>
					<th scope="col">{t('action-books.class-action.reception.issued')}</th>
					{isLibrarian && <th scope="col">{t('action-books.class-action.reception.received')}</th>}
					{isLibrarian && <th scope="col">{t('action-books.class-action.reception.action')}</th>}
				</tr>
			</thead>
			<tbody>
				{classBooks && classBooks.map((kb, index) => (
					<BookItemReception
						key={kb.id}
						kb={kb}
						index={index}
						klass={klass}
					/>
				))}
			</tbody>
		</TableComponent>
	)
}

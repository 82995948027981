import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {faTrash} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {UpdateClass} from "./update-class";
import {ButtonC, DeleteNotifyWithRedux, TableComponent} from "../../components";
import {useActions, useAppSelector} from '../../hooks';
import {thunks} from './class-reducer';
import { isLibrarian } from "../../lib/helper";

const actions = {
  deleteClass: thunks.deleteClass
}


export const ClassDetail = ({grade}) => {
  const classes = useAppSelector((state) => state.classes.classes.filter((c) => parseInt(c.name) == grade))
  const [show, setShow] = useState(false);
  const {deleteClass} = useActions(actions)
  const [showDelete, setShowDelete] = useState(false)
  const [deleteClasses, setDeleteClass] = useState({})
  const {t} = useTranslation()
  const [editValue, setEditValue] = useState({})
  const handleClose = () => {
    setEditValue({})
    setShow(false)
  };

  const handleShow = async (id) => {
    setEditValue(classes.filter((c) => c.id == id)[0])
    setShow(true)
  }

  const handleDeleteClass = async (klass) => {
    setShowDelete(true)
    setDeleteClass(klass)
  }
  return (
    <>
      <UpdateClass
        show={show}
        handleClose={handleClose}
        editValue={editValue}
        setShow={setShow}
      />
      <DeleteNotifyWithRedux
        message={deleteClasses.name}
        func={deleteClass}
        id={deleteClasses.id}
        show={showDelete}
        setShow={setShowDelete}
      />
      <TableComponent>
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{t('classes.class-detail.Class')}</th>
            <th scope="col">{t('classes.class-detail.language')}</th>
            <th scope="col">{t('classes.class-detail.student_count')}</th>
            <th scope="col">{t('classes.add-class.student_with_50_percent')}</th>
            <th scope="col">{t('classes.add-class.student_with_100_percent')}</th>
            <th scope="col">{t('classes.class-detail.teacher')}</th>
           {isLibrarian && <th scope="col">{t('classes.class-detail.action')}</th>}
          </tr>
        </thead>
        <tbody>
          {classes && classes?.map((el, key) => {
            return (
              <tr key={el.id}>
                {/* {console.log('el', el)} */}
                <td>{key + 1}</td>
                <td>{el.name}</td>
                <td>{el.language}</td>
                <td>{el.student_count}</td>
                <td>{el.student_count_50_discount}</td>
                <td>{el.student_count_100_discount}</td>
                <td>{el.teacher?.fullname}</td>
             {isLibrarian &&    <td className='d-flex justify-content-around'>
                  <div>
                    <ButtonC variant="change" onClick={() => handleShow(el.id)}>
                      {t('classes.class-detail.update')}
                    </ButtonC>
                    {<ButtonC variant='delete' onClick={() => handleDeleteClass(el)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </ButtonC>}
                  </div>
                </td>}
              </tr>
            )
          })}
        </tbody>
      </TableComponent>
    </>
  )
}
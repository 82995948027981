import {faArrowRight, faArrowUp} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Button} from "react-bootstrap"
import {Menu, Header, ButtonC} from "../components"
import styles from './layout.module.css'
import cn from 'classnames'
import {useScroll} from "../hooks"
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {setShow} from './store/menu-reducer'
import { Footer } from "../components/footer/Footer"

export const Layout = ({children, className}) => {
  const y = useScroll()
  const show = useSelector((state) => state.menu.show)
  const dispatch = useDispatch()
  const scrollToTop = () => {
    document.body.scrollTop = 0
  }

  return (
    <main>
      <div>
        <Header />
        <div className="d-flex">
          <Menu />
          <ButtonC variant='download' className={cn(styles.navButton, {
            [styles.openMenu]: show === true
          })}
            onClick={() => dispatch(setShow(true))}
          >
            <FontAwesomeIcon icon={faArrowRight} size='2x' />
          </ButtonC>
          <div className={cn('container-fluid', styles.componentTable, className, {
            [styles.menuClose]: show === false
          })}>
            {children}
            <Button variant='outline-primary' size='lg' className={cn(styles.up, {
              [styles.up]: y > 50,
              [styles.hidden]: y < 50,
            })}
              onClick={scrollToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </Button>
          </div>
        </div>
      </div>
    </main>
  )
}
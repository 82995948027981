import {faTrash} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useState} from "react"
import {useTranslation} from "react-i18next"
import {ButtonC, DeleteNotifyWithRedux} from "../../../components"
import {notifySuccess} from "../../../components/notify"
import {useActions} from '../../../hooks'
import { isLibrarian } from "../../../lib/helper"
import {thunks} from '../../school-books/school-books-reducer'
import {thunks as tbThunks} from './tb-reducer'
import classes from './teacher-books.module.css'

const actions = {
	updateSchoolBook: thunks.updateSchoolBook,
	updateTeacherBook: tbThunks.updateTeacherBook,
	deleteTeacherBook: tbThunks.deleteTeacherBook
}

export const TbItemReception = ({kb, index}) => {
	const [show, setShow] = useState(false)
	const {updateSchoolBook, updateTeacherBook, deleteTeacherBook} = useActions(actions)
	const {t} = useTranslation()

	const acceptBook = async () => {
		const body = {
			action: "accept",
			count: 1,
		}
		const payload = updateTeacherBook({data: body, id: kb.id})
		if (payload.payload) {
			notifySuccess("Принято")
			updateSchoolBook({id: kb.school_book.id})
		}
	}

	return (
		<>
			<DeleteNotifyWithRedux
				message={kb?.school_book?.book?.name}
				func={deleteTeacherBook}
				id={kb.id}
				show={show}
				setShow={setShow}
			/>
			<tr key={kb.id}>
				<td scope="col">{index + 1}</td>
				<td>{kb?.school_book?.book?.name}</td>
				<td>{kb?.school_book?.book?.author}</td>
				<td>{kb?.school_book?.book?.grades.join(', ')}</td>
				<td>{kb?.school_book.year}</td>
				<td>{kb?.school_book?.book?.language}</td>
				<td>{kb.count}</td>
				{isLibrarian && <td>
					{kb.count === 0 ? <div className={classes.action}>
						<ButtonC variant="disable" >
							{t('action-books.class-action.reception.received_btn')}
						</ButtonC>
						<ButtonC variant='delete' onClick={() => setShow(true)}>
							<FontAwesomeIcon icon={faTrash} />
						</ButtonC>
					</div> : <ButtonC variant="add" onClick={() => acceptBook(kb.id)}>
						{t('action-books.class-action.reception.receive_btn')}
					</ButtonC>
					}
				</td>}
			</tr>
		</>
	)
}

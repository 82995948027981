import {Col, Form, Modal} from "react-bootstrap"
import {Controller, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {ButtonC, InputField, PhoneField} from "../../components"
import {useActions} from '../../hooks'
import {thunks} from './model'

const actions = {updateUser: thunks.updateUser}

export const UpdateUser = ({show, setShow, user}) => {
	const {t} = useTranslation()
	const {updateUser} = useActions(actions)
	const {control, handleSubmit, formState: {errors}} = useForm()
	const onClose = () => {
		setShow(false)
	}

	return (
		<Modal
			show={show}
			onHide={onClose}
			keyboard={false}
			size="lg"
		>
			<Modal.Header>
				<Modal.Title>{t('profile.update-user.title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(updateUser)}>
					<InputField
						type='text'
						control={control}
						name='fullname'
						defaultValue={user?.fullname}
						rules={{
							required: t('error_message.no_empty'),
						}}
						label={t('profile.update-user.fullname')}
						errors={errors.fullname}
					/>
					<InputField
						type='text'
						control={control}
						name='phone'
						defaultValue={user?.phone}
						rules={{
							required: t('error_message.no_empty'),
						}}
						label={t('classes.add-teacher.phone')}
						errors={errors.phone}
					/>
					{/* {console.log('USER', user)} */}
					<InputField
						type='email'
						control={control}
						name='email'
						defaultValue={user?.email}
						rules={{
							required: t('error_message.no_empty'),
						}}
						label={t('profile.user.email')}
						errors={errors.email}
					/>
					<Col className='moduleBtns'>
						<ButtonC variant="close" onClick={onClose}>
							{t('btn.close')}
						</ButtonC>
						<ButtonC variant="add" type='submit'>{t('btn.send')}</ButtonC>
					</Col>
				</Form>
			</Modal.Body>
		</Modal>
	)
}
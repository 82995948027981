import {useEffect} from 'react'
import {Tabs, Tab} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'
import {TableInfo, TableIssue, TableReception} from '../feature/class-books/action'
import {Layout} from '../app/layout'
import {useActions, useAppSelector, usePageTitle} from '../hooks'
import {thunks} from '../feature/class-books/class-book-reducer'
import './styles.css'
import { Link } from 'react-router-dom'

const actions = {fetchClassBooks: thunks.fetchClassBooks}


export const ClassAction = () => {
  const {t} = useTranslation()
  usePageTitle(t('action-books.class-action.title'))
  const params = useParams()
  const {fetchClassBooks} = useActions(actions)
  const currentClass = useAppSelector((state) => state.classes.classes.filter((c) => c.id == params.id)[0])

  useEffect(() => {
    fetchClassBooks({klass: params.id})
  }, [])

  return (
    <Layout>
      <Link to="/action-books"  className='back-link bg-primary text-light p-3 rounded'>Назад к странице выдача и прием</Link>
      <div className='mb-3 mt-5'>
        <h2 className="mb-2 class-action-title">{t('action-books.class-action.title')}</h2>
        <p className="mb-1 fs-5 ms-2 class-action-data"><strong>{t('action-books.class-action.teacher')}:</strong> {currentClass?.teacher?.fullname}</p>
        <p className="mb-1 fs-5 ms-2 class-action-data"><strong>{t('action-books.class-action.Class')}:</strong> {currentClass?.name}</p>
        <p className="mb-1 fs-5 ms-2 class-action-data"><strong>{t('action-books.class-action.language')}:</strong> {currentClass?.language}</p>
        <p className="mb-1 fs-5 ms-2 class-action-data"><strong>{t('action-books.class-action.student_count')}:</strong> {currentClass?.student_count}</p>
        <p className="mb-1 fs-5 ms-2 class-action-data"><strong>{t('action-books.class-action.disounters_50_count')}:</strong> {currentClass?.student_count_50_discount}</p>
        <p className="mb-1 fs-5 ms-2 class-action-data"><strong>{t('action-books.class-action.disounters_100_count')}:</strong> {currentClass?.student_count_100_discount}</p>
      </div>
      <div>
        <Tabs
          defaultActiveKey="1"
          transition={false}
          className="mb-3"
          variant='pills'
        >
          <Tab eventKey="1" className='class-action-tab' title={t('action-books.class-action.issue.title')}>
            <TableIssue
              klass={currentClass}
            />
          </Tab>
          <Tab eventKey="2"  className='class-action-tab' title={t('action-books.class-action.reception.title')}>
            <TableReception klass={currentClass} />
          </Tab>
          <Tab eventKey="3"  className='class-action-tab' title={t('action-books.class-action.info.title')}>
            <TableInfo
              klass={currentClass}
              className="class-action-tab-data"
            />
          </Tab>
        </Tabs>
      </div>
    </Layout>
  )
}
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import {useEffect, useState} from 'react'
import {Col, Container, Form, Modal, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {ButtonC, InputField} from '../../components'
import {notifySuccess} from '../../components/notify'
import {useActions, useApi, useAppSelector} from '../../hooks'
import styles from './report.module.css'
import {thunks as profileThunks} from '../proflle/model'
import dateFormat from 'dateformat'
import {D10DownloadFile} from './d10-download-file'
import {newUseApi2} from '../../hooks/new-use-api2'

import {storage} from '../../service'
import {useParams} from 'react-router-dom/cjs/react-router-dom.min'
const actions = {restoreSession: profileThunks.restoreSession}
const verId = storage.read('school_id');


export const D10Verify = () => {
	const [show, setShow] = useState(false);
	const {control, handleSubmit, formState: {errors}, reset} = useForm();
	const {restoreSession} = useActions(actions);
	const [status, setStatus] = useState({});
	const api = useApi()
	const [showPassword, setShowPassword] = useState(false)
	const handleClose = () => {
		setShow(false)
		reset()
	}
	const setStatusData = async () => {
		const response = await api.get('verifications')
		setStatus(response)
	}
	const onSubmit = (data) => {
		api.post('verifications/request', {
			body: data
		}).then(() => {
			notifySuccess('Потверждено')
			setShow(true)
			reset()
		})
	}
	useEffect(() => {
		setStatusData()
	}, []);
	return <>
			{/* <Col><D10DownloadFile url={`verifications/${status[0]?.id}/download`} query='' /></Col> */}
		{/* <Col><p>Последняя дата отчета: <br/> {dateFormat(status[0]?.verified_date, "dd.mm.yy HH:MM")}</p></Col> */}
	<Modal
			show={show}
			onHide={handleClose}
			keyboard={false}
			size="lg"
			scr
		>
			<Modal.Body>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<InputField
						label='Пароль'
						type={showPassword ? 'text' : 'password'}
						control={control}
						rules={{required: 'Заполните поле!'}}
						name='password'
						placeholder="Пароль"
						icon={showPassword ? faEye : faEyeSlash}
						iconClick={() => setShowPassword(!showPassword)}
						errors={errors.password}
					/>
					<div className={styles.verify__button}>
						<ButtonC variant='add' type='submit'>
							Подвердить
						</ButtonC>
						<ButtonC variant='close' onClick={handleClose}>
							Закрыть
						</ButtonC>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	<Row>
  {/* <Col>
  {status[0]?.status == 'declined'  ?
			<ButtonC variant='add' onClick={() => setShow(true)} className='mb-4' >
				Отправить на проверку
			</ButtonC> :  (status[0]?.status == 'accepted' ?  <ButtonC variant="close" onClick={() => setShow(false)} disabled>Потверждено</ButtonC> : <ButtonC variant='disabled' onClick={() => setShow(true)} className='mb-4'>
				Отправлено на проверку</ButtonC>)
		}
  </Col> */}
	</Row>
	</>
}
``
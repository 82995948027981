import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useEffect, useRef, useState} from 'react'
import {Accordion, OverlayTrigger, Popover, Table} from 'react-bootstrap'
import {ButtonC, PdfHeader} from '../../../components'
import {Link} from 'react-router-dom';
import {faAngleDown, faFileExcel, faFilePdf, faWindowClose, faMinusCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import {api} from '../../../service/old-api-2';
import {useReactToPrint} from 'react-to-print';
import {useTranslation} from 'react-i18next';
import { Layout } from '../../../app/layout';
import {Button, ListGroup} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {isJanyKitep, langStatus} from '../../../lib/helper'
import styles from '../store.module.css'

export const OrderDistrictList = () => {
    const [districts, setDistricts] = useState([])
    const [readyForGeneralOrder, setReadyForGeneralOrder] = useState(false)
    const [schoolBalance, setSchoolBalance] = useState(0)
    const [schoolBalanceJK, setSchoolBalanceJK] = useState(0)
    const [schoolAvailableBalance, setSchoolAvailableBalance] = useState(0)
    const [totalOrdersSum, setTotalOrdersSum] = useState(0)
    const [totalDistrictSchoolCount, setTotalDistrictSchoolCount] = useState(0)
    const [totalDistrictBalanceCount, setTotalDistrictBalanceCount] = useState(0)
    const [totalDistrictBalanceFixing, setTotalDistrictBalanceFixing] = useState(0)
    const [totalDistrictBalanceConfirmed, setTotalDistrictBalanceConfirmed] = useState(0)
    const [totalDistrictBalanceConfirmedAct, setTotalDistrictBalanceConfirmedAct] = useState(0)
    const [totalDistrictRefusedOrders, setTotalDistrictRefusedOrders] = useState(0)
    const [totalDistrictCreatedOrders, setTotalDistrictCreatedOrders] = useState(0)
    const [totalDistrictDirectorOrders, setTotalDistrictDirectorOrders] = useState(0)
    const [totalDistrictDirectorDeclOrders, setTotalDistrictDirectorDeclOrders] = useState(0)
    const [totalDistrictObserverOrders, setTotalDistrictObserverOrders] = useState(0)
    const [totalDistrictObserverApprovedOders, setTotalDistrictObserverApprovedOders] = useState(0)
    const [totalDistrictObserverDeclOrders, setTotalDistrictObserverDeclOrders] = useState(0)

    const {t} = useTranslation()
    const getData = () => {
        api.get(`store/orders-district/`).then(
            (response) => {
                setDistricts(response)
            })
    }
    const GetRreadyForGeneralOrder = () => {
        api.get(`store/ready-for-create-general-order/`).then(
            (response) => {
                if (response.status === 'success') {
                    setReadyForGeneralOrder(true)
                }
            })
    }
    const GeneralOrderCreate = () => {
        api.get(`store/general-order-create/`).then(
            (response) => {
                console.log(response);
                // if (response.status === 'success') {
                //     console.log();
                // }
            })
    }
    const filterOrders  = ((orders, cancelleds) => {
        const filtered = orders?.filter((order) => order?.study_period_active === 'True' )
        if (filtered?.length > 0) {
            if (filtered[0]?.status === 'janykitep_declined') {
                return <><FontAwesomeIcon icon={faWindowClose} style={{color: 'orangered', fontSize: '25px'}}/> <br /> {langStatus(filtered[0]?.status)}</>
            } else if (filtered[0]?.status === 'janykitep_approved') {
                return <><FontAwesomeIcon icon={faAngleDown} style={{color: 'forestgreen', fontSize: '25px'}}/> <br /> {langStatus(filtered[0]?.status)}</>
            } else if (filtered[0]?.status === 'janykitep_review') {
                return <><FontAwesomeIcon icon={faExclamationCircle} style={{color: 'blue', fontSize: '25px'}}/> <br /> {langStatus(filtered[0]?.status)}</>
            } else if (filtered[0]?.status === 'district_review') {
                return <>{langStatus(filtered[0]?.status)}</>
            } else {
                return <><FontAwesomeIcon icon={faMinusCircle} style={{color: 'orange', fontSize: '25px'}}/> <br /> {langStatus(filtered[0]?.status)}</>
            }
        } else {
            return <FontAwesomeIcon icon={faMinusCircle} style={{color: 'orange', fontSize: '25px'}}/>
        }
    })
    const filterOrders2  = ((status) => {
        if (status === 'janykitep_declined') {
            return <><FontAwesomeIcon icon={faWindowClose} style={{color: 'orangered', fontSize: '25px'}}/> <br /> {langStatus(status)}</>
        } else if (status === 'janykitep_approved') {
            return <><FontAwesomeIcon icon={faAngleDown} style={{color: 'forestgreen', fontSize: '25px'}}/> <br /> {langStatus(status)}</>
        } else if (status === 'janykitep_review') {
            return <><FontAwesomeIcon icon={faExclamationCircle} style={{color: 'blue', fontSize: '25px'}}/> <br /> {langStatus(status)}</>
        } else {
            return <><FontAwesomeIcon icon={faMinusCircle} style={{color: 'orange', fontSize: '25px'}}/> <br /> {langStatus(status)}</>
        }
    })
    const availableBalance  = ((orders) => {
        const filtered = orders?.filter((order) => order?.study_period_active === 'True' )
        if (filtered?.length > 0) {
            return filtered[0]?.school_available_balance
        } else {
            return 0
        }
    })
    const getComment  = ((cancelleds) => {
        const filteredCancelleds = cancelleds?.filter((cancelled) => cancelled?.study_period_active === 'True' )
        if (filteredCancelleds?.length > 0) {
            return filteredCancelleds[0]?.comment
        }
    })

    const getTotals  = (() => {
        let total_school_balance = 0
        let total_school_balance_jk = 0
        let total_available_balance = 0
        let total_orders_sum = 0
        let total_district_school_count = 0
        let total_district_balance_count = 0
        let total_district_balance_fixing = 0
        let total_district_balance_confirmed = 0
        let total_district_balance_confirmed_act = 0
        let total_district_refused_orders = 0
        let total_district_created_orders = 0
        let total_district_director_orders = 0
        let total_district_director_decl_orders = 0
        let total_district_observer_orders = 0
        let total_district_observer_approved_orders = 0
        let total_district_observer_decl_orders = 0

        districts?.forEach((district) => {
            total_school_balance += district?.total_school_balance?.total
            total_school_balance_jk += district?.total_school_balance_jk
            total_available_balance += district?.total_school_balance?.available_balance
            total_orders_sum += district?.total_school_balance?.orders_sum
            total_district_school_count += district?.district_school_count
            total_district_balance_count += district?.district_balance_count
            total_district_balance_fixing += district?.district_balance_fixing
            total_district_balance_confirmed += district?.district_balance_confirmed
            total_district_balance_confirmed_act += district?.district_balance_confirmed_act
            total_district_refused_orders += district?.district_refused_orders
            total_district_created_orders += district?.district_created_orders
            total_district_director_orders += district?.district_director_orders
            total_district_director_decl_orders += district?.district_director_decl_orders
            total_district_observer_orders += district?.district_observer_orders
            total_district_observer_approved_orders += district?.district_observer_approved_orders
            total_district_observer_decl_orders += district?.district_observer_decl_orders
        })
        setSchoolBalance(total_school_balance)
        setSchoolBalanceJK(total_school_balance_jk)
        setSchoolAvailableBalance(total_available_balance)
        setTotalOrdersSum(total_orders_sum)
        setTotalDistrictSchoolCount(total_district_school_count)
        setTotalDistrictBalanceCount(total_district_balance_count)
        setTotalDistrictBalanceFixing(total_district_balance_fixing)
        setTotalDistrictBalanceConfirmed(total_district_balance_confirmed)
        setTotalDistrictBalanceConfirmedAct(total_district_balance_confirmed_act)
        setTotalDistrictRefusedOrders(total_district_refused_orders)
        setTotalDistrictCreatedOrders(total_district_created_orders)
        setTotalDistrictDirectorOrders(total_district_director_orders)
        setTotalDistrictDirectorDeclOrders(total_district_director_decl_orders)
        setTotalDistrictObserverOrders(total_district_observer_orders)
        setTotalDistrictObserverApprovedOders(total_district_observer_approved_orders)
        setTotalDistrictObserverDeclOrders(total_district_observer_decl_orders)
    })

    useEffect(() => {
        getData();
        GetRreadyForGeneralOrder();
    }, [])

    useEffect(() => {
        getTotals();
    }, [districts])

    const componentRef = useRef(null)
    return (
        <Layout>
        <div>
            {/* <button className='btn btn-success mb-3' disabled={!readyForGeneralOrder} onClick={() => GeneralOrderCreate()}>Сформировать общую заявку</button> */}
            {(readyForGeneralOrder || isJanyKitep)  &&
                <NavLink exact to="/store/checkout-general-order" className="text-white">
                    <Button variant="primary" className='btn btn-success mb-3' style={{ fontSize: "20px", fontWeight: 700 }}>
                        <FontAwesomeIcon icon={faBook} className="mx-3"/> Перейти к оформлению общей заявки
                    </Button>
                </NavLink>
            }
            {(!readyForGeneralOrder && !isJanyKitep) &&
                <Button variant="primary" className='btn btn-success mb-3 disabled'>
                    <FontAwesomeIcon icon={faBook} className="mx-3"/> Перейти к оформлению общей заявки
                </Button>
            }
            {/* <NavLink exact to="/store/general-orders">
                <Button variant="primary" className='btn btn-success ms-3 mb-3'
                    style={{ fontSize: "20px", fontWeight: 700 }}
                >
                    История заявок
                </Button>
            </NavLink> */}
            <Table striped bordered hover ref={componentRef}>
                <thead>
                <tr>
                    <th>№</th>
                    <th>Код района</th>
                    <th>Область /<br /> Район</th>
                    {/* <th>Область</th> */}
                    <th>Имя / контакты</th>
                    {/* <th>Телефон / <br />Email</th> */}
                    <th>Баланс школ</th>
                    <th>Баланс Жаны Китеп</th>
                    <th>Доступный баланс</th>
                    <th>Сумма заявок</th>
                    <th>Сверка и заявки</th>
                    <th>Действие</th>
                    <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                {districts && districts?.length > 0 && districts?.map((district, index) => {
                    {/* let total = 0; */}
                    {/* district?.gradebalance_set?.forEach((grade) => {
                        total += parseInt(grade.balance)
                    }) */}
                    return (
                        <tr key={district?.id}>
                            <td data-label="№">{index+1}</td>
                            <td data-label="Код района">{district?.id}</td>
                            <td data-label="Область / Район">{district?.region_name} /<br /> {district?.name} </td>
                            {/* <td data-label="Область">{district?.region_name}</td> */}
                            <td style={{'textAlign': 'left'}} data-label="Имя библиотекаря">{district?.fullname} <br /> {district?.phone} <br /> {district?.email}</td>
                            {/* <td data-label="Телефон / Email">{district?.phone} /<br /> {district?.email}</td> */}
                            <td data-label="Баланс школы">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header style={{whiteSpace: 'nowrap'}}>{district?.total_school_balance?.total?.toLocaleString()} сом &nbsp;</Accordion.Header>
                                        <Accordion.Body>
                                            {district?.total_school_balance?.grade_balance?.sort((a, b) => {
                                                return a.grade - b.grade
                                                }).map((grade, index) => {
                                                    return (
                                                        <>
                                                            <p style={{whiteSpace: 'nowrap'}} key={index}>{grade.grade} класс - {grade.balance.toLocaleString()} сом</p>
                                                            <br />
                                                        </>
                                                    )
                                                }
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </td>
                            <td data-label="Баланс Жаны Китеп">{district?.total_school_balance_jk?.toLocaleString()}</td>
                            <td data-label="Доступный баланс">{district?.available_balance_sum?parseFloat(district?.available_balance_sum).toLocaleString():0}</td>
                            <td data-label="Сумма заявки">{district?.total_school_balance?.orders_sum?.toLocaleString()}</td>
                            <td data-label="Акт сверки" style={{'whiteSpace': 'nowrap'}}>
                                всего - {district?.district_school_count?.toLocaleString()} <br />
                                подали - {district?.district_balance_count?.toLocaleString()} <br />
                                исп. акт - {district?.district_balance_fixing?.toLocaleString()} <br />
                                подтв. акт - {district?.district_balance_confirmed?.toLocaleString()} <br />
                                акт прикр - {district?.district_balance_confirmed_act?.toLocaleString()} <br />
                                <br />
                                заяв. отказ - {district?.district_refused_orders?.toLocaleString()} <br />
                                заявки - {district?.district_created_orders?.toLocaleString()} <br />
                                заяв у дир - {district?.district_director_orders?.toLocaleString()} <br />
                                заяв откл дир - {district?.district_director_decl_orders?.toLocaleString()} <br />
                                заяв у рай - {district?.district_observer_orders?.toLocaleString()} <br />
                                заяв прин рай - {district?.district_observer_approved_orders?.toLocaleString()} <br />
                                заяв откл рай - {district?.district_observer_decl_orders?.toLocaleString()} <br />
                            </td>
                            <td data-label="Действие">
                                <Link to={{pathname: `/store/general-orders-districts/${district?.id}`}}>
                                    <ButtonC className='mb-2' variant="primary">Детально</ButtonC>
                                </Link>
                                <Link to={{pathname: `/store/orders-district/${district?.id}`}}>
                                    <ButtonC className='btn btn-warning'>Заявки</ButtonC>
                                </Link>
                            </td>
                            <td>
                                {filterOrders(district?.generalorder_set)}
                            </td>
                        </tr>
                    )
                })}
                <tr>
                    <td colSpan='4'>Итого</td>
                    <td>{schoolBalance?.toLocaleString()}</td>
                    <td>{schoolBalanceJK?.toLocaleString()}</td>
                    <td>{schoolAvailableBalance?.toLocaleString()}</td>
                    <td>{totalOrdersSum?.toLocaleString()}</td>
                    <td>
                        всего - {totalDistrictSchoolCount?.toLocaleString()} <br />
                        подали акт - {totalDistrictBalanceCount?.toLocaleString()} <br />
                        исп. акт - {totalDistrictBalanceFixing?.toLocaleString()} <br />
                        подтв. акт - {totalDistrictBalanceConfirmed?.toLocaleString()} <br />
                        акт прикр - {totalDistrictBalanceConfirmedAct?.toLocaleString()} <br />
                        <br />
                        заяв. отказ - {totalDistrictRefusedOrders?.toLocaleString()} <br />
                        заявки - {totalDistrictCreatedOrders?.toLocaleString()} <br />
                        заяв у дир - {totalDistrictDirectorOrders?.toLocaleString()} <br />
                        заяв откл дир - {totalDistrictDirectorDeclOrders?.toLocaleString()} <br />
                        заяв у рай - {totalDistrictObserverOrders?.toLocaleString()} <br />
                        заяв прин рай - {totalDistrictObserverApprovedOders?.toLocaleString()} <br />
                        заяв откл рай - {totalDistrictObserverDeclOrders?.toLocaleString()} <br />
                    </td>
                    <td className='d-flex flex-column'>
                    <Link
                    to={{pathname: `/store/not-act-schools/not-applied`}}
                    >
                        <ButtonC className='mb-2' variant="primary">Не подавшие</ButtonC>
                    </Link>
                    <Link
                    to={{pathname: `/store/not-act-schools/correcting`}}
                    >
                        <ButtonC className='mb-2' variant="primary">Исправляющие</ButtonC>
                    </Link>
                    <Link
                    to={{pathname: `/store/not-act-schools/without_act`}}
                    >
                        <ButtonC className='mb-2' variant="primary">Подавшие без акта</ButtonC>
                    </Link>
                    <br />
                    <Link
                    to={{pathname: `/store/not-act-schools/not-applied-order`}}
                    >
                        <ButtonC className='mb-2' variant="primary">Не подавшие заявку</ButtonC>
                    </Link>
                    <Link
                    to={{pathname: `/store/not-act-schools/not-need-order`}}
                    >
                        <ButtonC className='mb-2' variant="primary">Отказ от заявки</ButtonC>
                    </Link>
                    </td>
                </tr>
                </tbody>
            </Table>
        </div>
        </Layout>
    )
}

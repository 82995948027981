import {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router"
import {Layout} from '../app/layout'
import {Tab, Tabs} from "react-bootstrap"
import {TeacherBooksIssue} from "../feature/teacher/teacher-books/teacher-books-issue"
import {TbReception} from "../feature/teacher/teacher-books/tb-reception"
import {TbInfo} from "../feature/teacher/teacher-books/tb-info"
import {useActions, useAppSelector} from '../hooks'
import {thunks} from '../feature/teacher/teacher-books/tb-reducer'
import './styles.css'
import { Link } from "react-router-dom"

const actions = {fetchTeacherBooks: thunks.fetchTeacherBooks}

export const TeacherBooks = () => {
	const {t} = useTranslation()
	const params = useParams()
	const currentTeacher = useAppSelector((state) => state.teachers.teachers.filter((t) => t.id == params.id)[0])
	const {fetchTeacherBooks} = useActions(actions)

	useEffect(() => {
		fetchTeacherBooks(params.id)
	}, [])
	return (
		<Layout>
			  <Link to="/action-books"  className='back-link bg-primary text-light p-3 rounded'>Назад к странице выдача и прием</Link>
			<div className='mb-3 mt-5'>
				<h2 className="mb-2 teacher-book-title">{t('teacher-books.title')}</h2>
				<p className="mb-1 fs-5 ms-2 teacher-book-data"><strong>{t('teacher-books.fullname')}:</strong> {currentTeacher?.fullname}</p>
				<p className="mb-1 fs-5 ms-2 teacher-book-data"><strong>{t('teacher-books.pin')}:</strong> {currentTeacher?.pin}</p>
				<p className="mb-1 fs-5 ms-2 teacher-book-data"><strong>{t('teacher-books.phone')}:</strong> {currentTeacher?.phone}</p>
				<p className="mb-1 fs-5 ms-2 teacher-book-data"><strong>{t('teacher-books.classes')}:</strong> {currentTeacher?.classes?.join(', ')}</p>
			</div>
			<Tabs
				defaultActiveKey="1"
				transition={false}
				className="mb-3"
				variant='pills'
			>
				<Tab eventKey="1" title={t('teacher-books.issue.title')}>
					<TeacherBooksIssue
						teacher={currentTeacher}
					/>
				</Tab>
				<Tab eventKey="2" title={t('teacher-books.reception.title')}>
					<TbReception />
				</Tab>
				<Tab eventKey="3" title={t('teacher-books.info.title')}>
					<TbInfo
						teacher={currentTeacher}
					/>
				</Tab>
			</Tabs>
		</Layout>
	)
}
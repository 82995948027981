import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonC, TableComponent } from "../../../components";
import { notifyError, notifySuccess } from "../../../components/notify";
import { useActions, useAppSelector } from '../../../hooks';
import { thunks } from '../../school-books/school-books-reducer';
import { thunks as classBookThunks } from '../class-book-reducer'

import classes from "../action-book.module.css";
import { AddClassBook } from "../add-classbook";
import { useDispatch } from "react-redux";
import { isLibrarian } from "../../../lib/helper";

const actions = {
  updateSchoolBook: thunks.updateSchoolBook,
  updateClassBook: classBookThunks.updateClassBook
}


export const TableIssue = ({ klass }) => {
  const { updateSchoolBook, updateClassBook } = useActions(actions)
  const dispatch = useDispatch()
  // dispatch(updateClassBook)
  const classBooks = useAppSelector((state) => state.classBooks.classBooks)
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [issuedForClass, setIssuedForClass] = useState(0)

  const giveClassBook = async (id) => {
    const count = document.querySelector(`#icount${id}`)
    const book = classBooks.find((el) => el.id === id)
    const gradeIndex = book.school_book.grade_books.findIndex((grade) => grade.grade === klass.grade);
    // const issuedToGrade =  book.school_book.grade_books[gradeIndex]?.number_of_issued_books
    if (!count.value || count.value == 0) {
      notifyError('Введите количество книг')
      return
    }
    if (!book?.school_book.total) {
      notifyError('Нет книг')
      return
    }
    if (book?.school_book.book.grades.length > 1 && count.value > getGradesRemaining(book.school_book.grade_books, klass?.grade)) {
      notifyError(`Количество учебников превышает количество доступных для ${klass?.grade} класса`)
      return
    }
    // console.log(issuedToGrade);
    if (book?.school_book.book.grades.length > 1 && count.value > klass?.student_count && count.value <= getGradesRemaining(book.school_book.grade_books, klass?.grade)) {
      notifyError('Количество учебников превышает количество учащихся в данном классе но учебников доступных хватает')
      return
    }
    if (klass?.student_count < book.count + parseInt(count.value)) {
      notifyError('Количество учебников превышает количество учащихся')
      return
    }
    if (count.value > book.school_book.remaining) {
      notifyError('Количество учебников превышает количество доступных')
      return
    }
    const body = {
      action: "give",
      count: count.value
    }
    const payload = await updateClassBook({ id: id, data: body })
    if (payload.payload) {
      notifySuccess(`${t('notify.success.added')}`)
      count.value = ''
      updateSchoolBook({ id: book.school_book.id })
    }
  }

  const giveClassBookTo50 = async (id) => {
    const count = document.querySelector(`#icountFor50${id}`)
    const book = classBooks.find((el) => el.id === id)
    const gradeIndex = book.school_book.grade_books.findIndex((grade) => grade.grade === klass.grade);
    const issuedToGrade = book.school_book.grade_books[gradeIndex]?.number_of_issued_books
    if (!count.value) {
      notifyError('Введите количество учебников')
      return
    }
    if (!book.count) {
      notifyError('Нет выданных учебников')
      return
    }
    if (parseInt(count.value) > klass?.student_count_50_discount) {
      notifyError('Количество учебников превышает количество льготников')
      return
    }
    if (parseInt(count.value) > book.count) {
      notifyError('Количество учебников превышает количество выданных учебников')
      return
    }
    const body = {
      action: "change_50",
      count: count.value
    }
    const payload = await updateClassBook({ id: id, data: body })
    if (payload.payload) {
      notifySuccess(`${t('notify.success.changed')}`)
      count.value = ''
      updateSchoolBook({ id: book.school_book.id })
    }
  }
  const giveClassBookTo100 = async (id) => {
    const count = document.querySelector(`#icountFor100${id}`)
    const book = classBooks.find((el) => el.id === id)
    const gradeIndex = book.school_book.grade_books.findIndex((grade) => grade.grade === klass.grade);
    const issuedToGrade = book.school_book.grade_books[gradeIndex]?.number_of_issued_books
    if (!count.value) {
      notifyError('Введите количество учебников')
      return
    }
    if (!book.count) {
      notifyError('Нет выданных учебников')
      return
    }
    if (parseInt(count.value) > klass?.student_count_100_discount) {
      notifyError('Количество учебников превышает количество льготников')
      return
    }
    if (parseInt(count.value) > book.count) {
      notifyError('Количество учебников превышает количество выданных учебников')
      return
    }
    const body = {
      action: "change_100",
      count: count.value
    }
    const payload = await updateClassBook({ id: id, data: body })
    if (payload.payload) {
      notifySuccess(`${t('notify.success.changed')}`)
      count.value = ''
      updateSchoolBook({ id: book.school_book.id })
    }
  }
  const getGradesRemaining = (grades, classgrade) => {
    const bookIndex = grades.findIndex((grade) => grade.grade === classgrade);
    if (bookIndex !== -1) {
      return grades[bookIndex]?.total_number_of_books - grades[bookIndex]?.number_of_issued_books
    }
    return 0
  }
  const getGradesIssued = (grades, classgrade) => {
    const bookIndex = grades.findIndex((grade) => grade.grade === classgrade);
    if (bookIndex !== -1) {
      return grades[bookIndex]?.number_of_issued_books
    }
    return 0
  }
  const getGradesTotal = (grades, classgrade) => {
    const bookIndex = grades.findIndex((grade) => grade.grade === classgrade);
    if (bookIndex !== -1) {
      return grades[bookIndex]?.total_number_of_books
    }
    return 0
  }
  return (
    <div>
      <AddClassBook
        show={show}
        setShow={setShow}
        klass={klass}
      />
      <TableComponent>
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{t('action-books.class-action.issue.book_title')}</th>
            <th scope="col">{t('action-books.class-action.issue.year')}</th>
            <th scope="col">{t('action-books.class-action.language')}</th>
            <th scope="col">{t('action-books.class-action.issue.all')}</th>
            <th scope="col">{t('action-books.class-action.issue.issued')}</th>
            <th scope="col">{t('action-books.class-action.issue.avialable')}</th>
            {isLibrarian && <th scope="col">{t('action-books.class-action.issue.for_issuing')}</th>}
            {isLibrarian && <th scope="col">{t('action-books.class-action.issue.action')}</th>}
          </tr>
        </thead>
        <tbody>
          {classBooks && classBooks.map((cb, index) => (
            <tr key={cb.id}>
              <td scope="col" data-label="№">{index + 1}</td>
              <td data-label="Наименование/Автор	">
                {cb?.school_book?.book?.name} {cb?.school_book?.book.number_of_parts > 1 ? <>({cb?.school_book?.part}-{t('school-books.part')})</> : ''}
                <br />
                {cb?.school_book?.book?.author}</td>
              <td data-label="Год">{cb?.school_book?.year}</td>
              <td data-label="Язык обучения">{cb?.school_book?.book?.language}</td>
              <td data-label="Всего">
                {cb?.school_book.book.grades.length <= 1 && <b>{cb.school_book.total}</b>}
                {cb?.school_book.book.grades.length > 1 &&
                  <>
                    <p>На весь {klass?.grade} класс - <b>{getGradesTotal(cb.school_book.grade_books, klass?.grade)}</b></p>
                    <br />
                    <p>Всего - <b>{cb.school_book.total}</b></p>
                    <br />
                    {
                      cb.school_book.grade_books.map((grade, index) => (
                        <div key={`total${cb.id}-${grade.grade}`} style={{ 'marginBottom': 10 }}>
                          <p>{grade.grade} кл. - {grade.total_number_of_books}</p>
                        </div>
                      ))
                    }
                  </>
                }
              </td>
              <td scope="col" data-label="Выданые">
                {cb?.school_book.book.grades.length <= 1 &&
                  <p>
                    <b>{cb.count}</b>
                    <br />
                    По всем {klass?.grade} классам - {cb.school_book.given}
                    <p>50% льготникам - <b>{cb.count_50}</b></p>
                    <p>100% льготникам - <b>{cb.count_100}</b></p>
                    <p>Изменить количество учебников для 50% льготников:</p>
                    <input
                      type="number"
                      id={`icountFor50${cb.id}`}
                      className={classes.count}
                      placeholder={0}
                      max={cb.school_book.remaining}
                      style={{ height: '100%' }}
                    />
                    <ButtonC variant="add" onClick={() => giveClassBookTo50(cb.id)}>
                      {t('action-books.class-action.issue.issue_discount_btn')}
                    </ButtonC>
                    <p>Изменить количество учебников для 100% льготников:</p>
                    <input
                      type="number"
                      id={`icountFor100${cb.id}`}
                      className={classes.count}
                      placeholder={0}
                      max={cb.school_book.remaining}
                      style={{ height: '100%' }}
                    />
                    <ButtonC variant="add" onClick={() => giveClassBookTo100(cb.id)}>
                      {t('action-books.class-action.issue.issue_discount_btn')}
                    </ButtonC>
                  </p>
                }
                {cb?.school_book.book.grades.length > 1 &&
                  <>
                    <p>На данный класс - <b>{cb.count}</b></p>
                    <p>50% льготникам - <b>{cb.count_50}</b></p>
                    <p>100% льготникам - <b>{cb.count_100}</b></p>
                    <p>Изменить количество учебников для 50% льготников:</p>
                    <input
                      type="number"
                      id={`icountFor50${cb.id}`}
                      className={classes.count}
                      placeholder={0}
                      max={cb.school_book.remaining}
                      style={{ height: '100%' }}
                    />
                    <ButtonC variant="add" onClick={() => giveClassBookTo50(cb.id)}>
                      {t('action-books.class-action.issue.issue_discount_btn')}
                    </ButtonC>
                    <br />
                    <p>Изменить количество учебников для 100% льготников:</p>
                    <input
                      type="number"
                      id={`icountFor100${cb.id}`}
                      className={classes.count}
                      placeholder={0}
                      max={cb.school_book.remaining}
                      style={{ height: '100%' }}
                    />
                    <ButtonC variant="add" onClick={() => giveClassBookTo100(cb.id)}>
                      {t('action-books.class-action.issue.issue_discount_btn')}
                    </ButtonC>
                    <p>На весь {klass?.grade} класс - <b>{getGradesIssued(cb.school_book.grade_books, klass?.grade)}</b></p>
                    <br />
                    <p>Всего - <b>{cb.school_book.given}</b></p>
                    <br />
                    {
                      cb.school_book.grade_books.map((grade, index) => (
                        <div key={`total${cb.id}-${grade.grade}`} style={{ 'marginBottom': 10 }}>
                          <p>{grade.grade} кл. - {grade.number_of_issued_books}</p>
                        </div>
                      ))
                    }
                  </>
                }
              </td>
              <td data-label="Доступно">
                {cb?.school_book.book.grades.length <= 1 && <b>{cb.school_book.remaining}</b>}
                {cb?.school_book.book.grades.length > 1 &&
                  <>
                    <p>На весь {klass?.grade} класс - <b>{getGradesRemaining(cb.school_book.grade_books, klass?.grade)}</b></p>
                    <br />
                    <p>Всего - <b>{cb.school_book.remaining}</b></p>
                    <br />
                    {
                      cb.school_book.grade_books.map((grade, index) => (
                        <div key={`total${cb.id}-${grade.grade}`} style={{ 'marginBottom': 10 }}>
                          <p>{grade.grade} кл. - {grade.total_number_of_books - grade.number_of_issued_books}</p>
                        </div>
                      ))
                    }
                  </>
                }
              </td>
              {isLibrarian && <td data-label="Для выдачи">
                {cb?.school_book.book.grades.length <= 1 &&
                  <input
                    type="number"
                    id={`icount${cb.id}`}
                    className={classes.count}
                    placeholder={0}
                    max={cb.school_book.remaining}
                  />
                }
                {cb?.school_book.book.grades.length > 1 &&
                  <input
                    type="number"
                    id={`icount${cb.id}`}
                    className={classes.count}
                    placeholder={0}
                    max={getGradesRemaining(cb.school_book.grade_books, klass?.grade)}
                  />
                }
              </td>}
              {isLibrarian &&
                <td data-label="Действие">
                  <ButtonC variant="add" onClick={() => giveClassBook(cb.id)}>
                    {t('action-books.class-action.issue.issue_btn')}
                  </ButtonC>
                </td>
              }
            </tr>
          ))}
        </tbody>
      </TableComponent>
      {isLibrarian && <ButtonC onClick={() => setShow(true)} variant='info'>
        {t('action-books.class-action.add_class_book.btn')}
      </ButtonC>}
    </div>
  )
}

import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
// import {SchoolEventsList, ClassDetail, AddClass} from "../feature/school-events";
import { SchoolEventsList, AddSchoolEvent } from "../feature/school-events";
import { TeacherList } from "../feature/teacher";
import { useTranslation } from "react-i18next";
import { Layout } from "../app/layout";
import { useAppSelector, usePageTitle } from "../hooks";
import { ButtonC } from "../components";
import "./classes.css";
import { isLibrarian } from "../lib/helper";

export const SchoolEvents = () => {
  const [show, setShow] = useState(false);
  const school_events = useAppSelector(
    (state) => state.schoolEvents.school_events
  );
  // const gradeRender = [...new Set(grades?.map((el) => el.grade))]
  const { t } = useTranslation();
  usePageTitle(`${t("events.title")}`);

  const handleShow = () => {
    setShow(true);
  };

  return (
    <Layout>
      <AddSchoolEvent show={show} setShow={setShow} />
      {/* <h2 className="mb-4 classes-title">{t('classes.title')}</h2> */}
      <h2 className="mb-4 classes-title">{t("events.title")}</h2>

      {isLibrarian && (
        <ButtonC
          variant="add"
          className="mb-4 ms-auto d-block add-class-btn"
          onClick={handleShow}
        >
          {/* {t('classes.add-class.btn')} */}
          {t("events.add-event")}
        </ButtonC>
      )}

      {/* Надо ли эти табы */}
      {/* <Tabs
        defaultActiveKey="all"
        transition={false}
        className={`mb-3`}
        variant='pills'
      > */}
      {/* {school_events &&
          <Tab eventKey="all" title={<span className="invisible"> </span>}> */}
      <SchoolEventsList school_events={school_events} />
      {/* </Tab>
        } */}
      {/* <Tab eventKey="all">
          <SchoolEventsList school_events={school_events} />
        </Tab> */}
      {/* <Tab eventKey='teacherList' title={t('classes.all-teachers.title')}>
          <TeacherList />
        </Tab> */}
      {/* {gradeRender.map((el) => {
          return (
            <Tab key={el} eventKey={el} title={`${el} кл`}>
              <ClassDetail
                grade={el}
              />
            </Tab>
          )
        })} */}

      {/* </Tabs> */}
    </Layout>
  );
};

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useEffect, useState} from 'react'
import {storage} from '../../../service'
import {useApi} from '../../../hooks'
import {Card, Col, NavLink, Row, Table} from 'react-bootstrap'
import {ButtonC} from '../../../components'
import {Link} from 'react-router-dom'
import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'
import {notifyError, notifySuccess} from '../../../components/notify'
import {newUseApi} from '../../../hooks/new-use-api'
import {D10DownloadFile} from '../d10-download-file'

const dist_id = storage.read('district_id')

export const D10DistAccepted = () => {
	const [total, setTotal] = useState([]);
	const [status, setStatus] = useState([]);
	const api = useApi()
	const api2 = newUseApi()
	const getSchool = async () => {
	  await api.get(`references/schools/?district=${dist_id}`).then((response) => {
		// console.log(response)
	  })
	}

	const schools = async () => {
		await api.get(`references/schools/?district=${dist_id}`).then((response) => {
			// console.log(response)
		})
	}

	useEffect(() => {
		schools()
	}, [])
	return (
	<div>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>Код школы</th>
      <th>Название школы</th>
      <th>Статус</th>
      <th>Имя библиотекаря</th>
	  <th>Телефон / Email</th>
	  <th>Посмотреть</th>
	  <th>Скачать отчет</th>
    </tr>
  </thead>
  <tbody>
	{/* {console.log(total)} */}
  {total && total.length > 0 && total.map((status) => {
	  return (
		<tr key={status.id}>
		<td>{status?.id}</td>
		<td>{status?.name}</td>
		<td>{status?.verification?.status == 'accepted' ? 'потверждено' : ''}</td>
		<td>{status?.fullname}</td>
		<td><FontAwesomeIcon icon={faPhone} color="#F36AA7"></FontAwesomeIcon> {status?.phone || 'нет информации'} <br/> <FontAwesomeIcon icon={faEnvelope} color="#BF344E"></FontAwesomeIcon>{status?.email || ' нет почты'}</td>
		<td><Link to={{
					pathname: `/report-result/${status.id}`,
		}} ><ButtonC variant="primary">посмотреть отчет</ButtonC></Link></td>
		<td><D10DownloadFile url={`verifications/${status?.verification?.id}/download`} query='' /></td>
	  </tr>
	  )
})}
  </tbody>
</Table>
	</div>
)
}

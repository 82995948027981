import React, { useEffect, useRef, useState } from 'react'
import { ButtonC, PdfHeader, TableComponent } from '../../../components';
import { api } from '../../../service/new-api-2';
import { storage } from '../../../service';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';

export const G2LibraryRoomsDist = () => {
    const [libraryRooms, setLibraryRooms] = useState();
    const district_id = storage.read('district_id');
    const {t} = useTranslation()
    const getLibraryRooms =  ()  => {
        api.get(`reports/library-rooms/?district_id=${district_id}`).then((response) => setLibraryRooms(response))
    }
    const componentRef = useRef(null)
    useEffect(() => {
        getLibraryRooms()
    }, [])
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
      })
      let index = 1;
  return (
        <div>
                <>
                   <TableComponent  ref={componentRef}>
                   <ButtonC variant='download' onClick={handlePrint} style={{float: 'left', marginBottom: '15px', backgroundColor: 'red', color: '#fff'}}>
        PDF <FontAwesomeIcon style={{color: '#fff'}} icon={faFilePdf} />
      </ButtonC>
      <PdfHeader title={t('report.g2.title')} />
            <thead>
                <tr>
                    <td>№</td>
                    <td>{t('report.g2.region')}</td>
                    <td>{t('report.g2.district')}</td>
                    <td>{t('report.g2.school')}</td>
                    <td>{t('report.g2.personal-room')}</td>
                    <td>{t('report.g2.reading-room')}</td>
                    <td>{t('report.g2.warehouse')}</td>
                    <td>{t('report.g2.combined_area')}</td>
                </tr>
            </thead>
            <tbody>
            {libraryRooms && libraryRooms.length > 0 && libraryRooms.map((rooms) => {
            return (
            <tr key={rooms.id}>
          <td data-label="№">{index++}</td>
                    <td data-label="Область">{rooms.region}</td>
                    <td data-label="Район">{rooms.district}</td>
                    <td data-label="Школа">{rooms.school_name}</td>
                    <td data-label="Личный кабинет">{rooms.has_an_office ? 'да' : 'нет'}</td>
                    <td data-label="Читальный зал">{rooms.reading_rooms.map((room_area) => {
                        return (
                            room_area.area
                        )
                    }).join(',')}</td>
                    <td data-label="Склад">{rooms.warehouses.map((wh) => {
                        return (
                            wh.area
                        )
                    }).join(',')}</td>
                    <td data-label="Площадь библиотеки">{rooms.combined_area}</td>
                </tr>
                   )
        })}
            </tbody>
            </TableComponent></>
         </div>
  )
}

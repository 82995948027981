import React from 'react'
import { Col, Form, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import {useTranslation} from "react-i18next"
import { thunks } from '../../app/store/profileReducer'
import { ButtonC, InputField, PhoneField } from '../../components'
import { useActions } from '../../hooks'

const actions = {addReadingRoom: thunks.addReadingRoom}

export const AddNewReadingRoom = ({show, setShow, readingRoom}) => {
    const {t} = useTranslation()
    const {control, handleSubmit, formState: {errors}} = useForm()
    const {addReadingRoom} = useActions(actions)
	const onClose = () => {
		setShow(false)
	}

  return (
    <Modal
			show={show}
			onHide={onClose}
			keyboard={false}
			size="lg"
		>
			<Modal.Header>
				<Modal.Title>{t('profile.add-reading-room.title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(addReadingRoom)} setShow={false}>
					<div className='d-flex justify-center align-center'>
					<InputField
						type='text'
						control={control}
						name='area'
						defaultValue={readingRoom?.area}
						rules={{
							required: t('error_message.no_empty'),
						}}
						label={t('profile.add-reading-room.area')}
						errors={errors.area}
					/>
					<div className='mt-4 p-3'>м2</div>
					</div>
					<Col className='moduleBtns'>
						<ButtonC variant="close" onClick={onClose}>
							{t('btn.close')}
						</ButtonC>
						<ButtonC variant="add" type='submit'>{t('btn.send')}</ButtonC>
					</Col>
				</Form>
			</Modal.Body>
		</Modal>
  )
}

import { useEffect } from 'react'
import { useState } from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ButtonC, InputField, SelectField} from '../../components'
import {years} from '../../lib/helper'
import { storage } from '../../service'
import { api } from '../../service/old-api-2'

export const PaymentsFilter = ({setPaymentItem}) => {
	const {t} = useTranslation()
	const {register, control} = useForm()
	const [query, setQuery] = useState({school_id: storage.read('school_id')})
	const getPaymentData = () => {
		api.get('payments/', { search: query }).then((response) => {
			setPaymentItem(response)
		})
	}

	// useEffect(() => {
	// 	getPaymentData()
	// }, [])
	useEffect(() => {
		getPaymentData(query)
	}, [query])

	const searchByServiceProvider = (data) => {
		setQuery((query) => {
			return {...query, service_provider: data}
		})
	}
	const searchByNumber = (data) => {
		setQuery((query) => {
			return {...query, transaction_id: data}
		})
	}

	const searchByDate = (data) => {
		setQuery((query) => {
			return {...query, }
		})
	}
	return (
		<Form className='mt-5'>
			<Row>
				{/* <Col xs={2}>
				<InputField
					type="date"
					placeholder={t('payment.number_operation')}
					control={control}
					name='number_operation'
				/>
				</Col> */}
				<Col xs={2} >
				<InputField
					type="text"
					control={control}
					name='transaction_id'
					placeholder={t('payment.number_operation')}
					onChange={(event) => searchByNumber(event.target.value)}
				/></Col>
				<Col xs={2} >			<InputField
					type="text"
					control={control}
					name='service_provider'
					placeholder={t('payment.service_provider')}
					onChange={(event) => searchByServiceProvider(event.target.value)}
				/></Col>
				{/* <Col xs={1} >
					<InputField
					type="text"
					placeholder={t('classes.all-classes.class')}
					control={control}
					name='class'
				/></Col>
				<Col xs={1} >
				<InputField
					type="number"
					placeholder={t('payment.summ')}
					control={control}
					name='summ'
				/>
				</Col> */}
				{/* <Col xs={2} >
				<InputField
					type="number"
					placeholder={t('payment.success')}
					control={control}
					name='success'
				/>
				</Col>
				<Col xs={2} >
					<ButtonC>
						{t('btn.search')}
					</ButtonC>
				</Col> */}
			</Row>
		</Form>
	)
}
import {useTranslation} from "react-i18next";
import {TableComponent} from "../../components";

export const ClassList = (props) => {
  // console.log(props);
  const {grades} = props
  const {t} = useTranslation()

  const tr = grades?.length > 0 && grades.map((el, key) => (
    <tr key={key}>
      <td>{el.grade}-{el.letter__name} {t('classes.all-classes.class')}</td>
      <td>{el.student_count} {t('classes.all-classes.students')}</td>
      <td>{el.language}</td>
    </tr>
  ))


  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col">{t('classes.all-classes.Class')}</th>
          <th scope="col">{t('classes.all-classes.amount_students')}</th>
          <th scope="col">{t('classes.all-classes.language')}</th>
        </tr>
      </thead>
      <tbody>
        {tr}
      </tbody>

    </TableComponent>
  )
}

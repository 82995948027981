import {storage} from '../../../service'
import {D10SubItem} from '../school/d10-sub-item'

const id = storage.read('school_id')

export const D10SubSchool = ({schoolId = id, quer, withHead = true, summary}) => {

	return (
		<D10SubItem
			id={schoolId}
			reportApi='reports/d10'
			type='school_id'
			withHead={withHead}
			quer={quer}
			summary={summary}
		/>
	)
}

import {useState} from "react"
import {useTranslation} from "react-i18next"
import {ButtonC, TableComponent} from "../../../components"
import {notifyError, notifySuccess} from "../../../components/notify"
import {useActions, useAppSelector} from '../../../hooks'
import {thunks} from '../../school-books/school-books-reducer'
import {thunks as TbThunks} from './tb-reducer'
import {AddTeacherBook} from "./add-teacher-books"
import { isLibrarian } from "../../../lib/helper"

const actions = {
	updateSchoolBook: thunks.updateSchoolBook,
	updateTeacherBook: TbThunks.updateTeacherBook
}

export const TeacherBooksIssue = ({
	teacher
}) => {
	const {t} = useTranslation()
	const [showAddTeacherBook, setAddTeacherBook] = useState(false)
	const teacherBooks = useAppSelector((state) => state.teacherBooks.teacherBooks)
	const {updateSchoolBook, updateTeacherBook} = useActions(actions)
	const giveTeacherBook = async (id) => {
		const book = teacherBooks.find((el) => el.id === id)
		if (!book?.school_book.total) {
			notifyError('Нет книг')
			return
		}

		const body = {
			action: "give",
			count: 1,
		}
		const payload = await updateTeacherBook({data: body, id: id})
		if (payload.payload) {
			notifySuccess(`${t('notify.success.added')}`)
			updateSchoolBook({id: book.school_book.id})
		}
	}
	return (
		<div>
			<AddTeacherBook
				show={showAddTeacherBook}
				setShow={setAddTeacherBook}
				teacher={teacher}
			/>
			<TableComponent>
				<thead>
					<tr>
						<th scope="col">№</th>
						<th scope="col">{t('action-books.class-action.issue.book_title')}</th>
						<th scope="col">{t('action-books.class-action.issue.year')}</th>
						<th scope="col">{t('action-books.class-action.language')}</th>
						<th scope="col">{t('teacher-books.classes')}</th>
						<th scope="col">{t('action-books.class-action.issue.avialable')}</th>
						{isLibrarian && <th scope="col">{t('action-books.class-action.issue.action')}</th> }
					</tr>
				</thead>
				<tbody>
					{teacherBooks && teacherBooks.map((tb, index) => (
						<tr key={tb.id}>
							<td scope="col">{index + 1}</td>
							<td>
								{tb?.school_book?.book?.name}
								<br />
								{tb?.school_book?.book?.author}</td>
							<td>{tb?.school_book?.book?.year}</td>
							<td>{tb?.school_book?.book?.language}</td>
							<td>{tb?.school_book?.book?.grades.join(', ')}</td>
							<td>{tb.school_book?.remaining}</td>
							{isLibrarian && <td>
								{tb.count === 0 ? <ButtonC variant="add" onClick={() => giveTeacherBook(tb.id)}>
									{t('action-books.class-action.issue.issue_btn')}
								</ButtonC> : <ButtonC variant='disable'>
									{t('action-books.class-action.issue.issued_btn')}
								</ButtonC>
								}
							</td>}
						</tr>
					))}
				</tbody>
			</TableComponent>
		{isLibrarian && 	<ButtonC variant="add" onClick={() => setAddTeacherBook(true)}>
				{t('action-books.class-action.add_class_book.btn')}
			</ButtonC>}
		</div>
	)
}
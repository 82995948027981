import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { ButtonC, PdfHeader, TableComponent } from "../../../components";
import { api } from "../../../service/new-api-2";
import { storage } from "../../../service";
import { D10DownloadFile } from "../d10-download-file";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { tr } from "date-fns/locale";

export const G3Beneficiaries = () => {
  const [beneficiaries, setBeneficiaries] = useState();
  const [allStudentsCount, setAllStudentsCount] = useState(0);
  const [all50DiscountCount, setAll50DiscountCount] = useState(0);
  const [all100DiscountCount, setAll100DiscountCount] = useState(0);
  const school_id = storage.read("school_id");
  const { t } = useTranslation();
  const getBeneficiaries = () => {
    api
      .get(`reports/students-with-discount/?school_id=${school_id}`)
      .then((response) => {
        setBeneficiaries(response);
        if (response && response.length > 0) {
          let totalStudentsCount = 0;
          let total50DiscountCount = 0;
          let total100DiscountCount = 0;
          response.forEach((beneficiary) => {
            totalStudentsCount += beneficiary.student_count;
            total50DiscountCount += beneficiary.student_count_50_discount;
            total100DiscountCount += beneficiary.student_count_100_discount;
          });
          setAllStudentsCount(totalStudentsCount);
          setAll50DiscountCount(total50DiscountCount);
          setAll100DiscountCount(total100DiscountCount);
        }
      });
  };
  const componentRef = useRef(null);
  useEffect(() => {
    getBeneficiaries();
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let index = 1;
  return (
    <div style={{ margin: "20mm" }}>
      <ButtonC
        variant="download"
        onClick={handlePrint}
        style={{
          float: "left",
          marginBottom: "15px",
          backgroundColor: "red",
          color: "#fff",
        }}
      >
        PDF <FontAwesomeIcon style={{ color: "#fff" }} icon={faFilePdf} />
      </ButtonC>
      <style type="text/css" media="print">
        {"@page {size: portrait;}"}
      </style>{" "}
      <TableComponent
        ref={componentRef}
        style={{
          margin: "10%",
          padding: "10%",
          width: "80%",
          minWidth: "150px",
        }}
      >
        <PdfHeader title={t("report.g3.title")} />
        <thead>
          <tr>
            <td>№</td>
            <td>{t("report.g3.class")}</td>
            <td>{t("report.g3.number_of_students")}</td>
            <td>{t("report.g3.student_with_50_percent")}</td>
            <td>{t("report.g3.student_with_100_percent")}</td>
          </tr>
        </thead>
        <tbody>
          {beneficiaries &&
            beneficiaries.length > 0 &&
            beneficiaries.map((beneficiary) => {
              {
                /* setAllStudentsCount(allStudentsCount + beneficiary.student_count) */
              }
              {
                /* addToStudentCount(beneficiary.student_count) */
              }
              return (
                <tr key={beneficiary.id}>
                  <td data-label="№">{index++}</td>
                  <td data-label="Класс">{beneficiary.grade}</td>
                  <td data-label="Количество учащихся">
                    {beneficiary.student_count}
                  </td>
                  <td data-label="Количество льготников 50% скидкой">
                    {beneficiary.student_count_50_discount}
                  </td>
                  <td data-label="Количество льготников 100% скидкой">
                    {beneficiary.student_count_100_discount}
                  </td>
                </tr>
              );
            })}
          {beneficiaries && beneficiaries.length > 0 && (
            <tr>
              <td colSpan={2}>
                <b>Всего</b>
              </td>
              <td data-label="Количество учащихся">
                <b>{allStudentsCount}</b>
              </td>
              <td data-label="Количество льготников 50% скидкой">
                <b>{all50DiscountCount}</b>
              </td>
              <td data-label="Количество льготников 100% скидкой">
                <b>{all100DiscountCount}</b>
              </td>
            </tr>
          )}
        </tbody>
      </TableComponent>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { thunks } from "../../app/store/profileReducer";
import { ButtonC, InputField, PhoneField } from "../../components";
import { notifySuccess } from "../../components/notify";
import { useActions, useApi, useAppSelector } from "../../hooks";

const actions = { deleteLibrary: thunks.deleteLibrary };

export const DeleteLibrary = ({ show, setShow }) => {
  const library = useAppSelector((state) => state.profile2.library);
  const { t } = useTranslation();
  const { deleteLibrary } = useActions(actions);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={onClose} keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>{t("profile.delete_library")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div>
            {library ? ( <> {" "} {library?.is_combined ? ( <> <p>У вас есть библиотека {library?.combined_area} м2</p> </> ) : ( <></> )}
                <p>
                  {library?.reading_rooms.length > 1 ? `У вас есть ${library?.reading_rooms.length} читальных зала \n` : `У вас есть ${library?.reading_rooms.length} читальный зал \n`}
                </p>
                <p>
                  {library?.warehouses.length > 1 ? `У вас есть ${library?.warehouses.length} склада` : `\n У вас есть ${library?.warehouses.length} склад`}
                </p>
                {library?.has_an_office ? <p>У вас есть офис</p> : <></>}
              </>
            ) : (
              <></>
            )}
          </div>
          <Col className="moduleBtns">
            <ButtonC variant="close" onClick={onClose}>
              {t("btn.close")}
            </ButtonC>
            <ButtonC
              variant="delete2"
              onClick={() => deleteLibrary(library?.id)}
            >
              {t("school-books.delete")}
            </ButtonC>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

import {Button, ListGroup, ListGroupItem} from "react-bootstrap";
import React, {useEffect} from "react";
import {useAppSelector} from "../../hooks";
import {CartItem} from "./cart-item";
import {Layout} from "../../app/layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import styles from './store.module.css'
import {thunks} from "../../app/store/bookstore-reducer";
import {useDispatch} from "react-redux";
import { notifySuccess, notifyError } from "../../components/notify";
import { useHistory } from "react-router-dom";
import { TableComponent } from "../../components";

export const Cart = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const actions = {
        fetchOrders: thunks.fetchOrders,
        updateOrder: thunks.updateOrder,
        fetchBalance: thunks.fetchBalance,
    }
    const orders = useAppSelector(
        (state) => state['bookstore']['orders']['results']
    )
    const existed = orders?.filter((order) => order?.status === 'director_declined' || order?.status === 'district_declined')
    const balance = useAppSelector((state) => state["bookstore"]["balance"])
    const available_balance = useAppSelector((state) => state["bookstore"]["available_balance"]);
    const shop_grades = useAppSelector((state) => state["bookstore"]["shop_grades"]);
    const cart = useAppSelector(
        (state) => state['bookstore']['cart']
    )
    const totalPrice = useAppSelector(
        (state) => state['bookstore']['totalPrice']
    )
    const canAddBookToCart = useAppSelector(
        (state) => state['bookstore']['canAddBookToCart']
    )
    let total = 0;
    let totalCount = 0;
    cart.forEach((cartItem) => {
        total += cartItem['price'] * cartItem['count']
        totalCount += cartItem['count']
    })
    useEffect(() => {
        dispatch(actions.fetchOrders())
    }, [])
    useEffect(() => {
        dispatch(actions.fetchBalance());
      }, [dispatch]);
    const handleClick = () => {
        dispatch(actions.updateOrder()).then((response) => {
            if (response.payload) {
                notifySuccess('Актуальная заявка обновлена.')
            } else {
                notifyError('Не удалось обновить заявку')
            }
            history.push("/store");
        })
    }
    return (
        <>
            <Layout>
            <div className={styles.cartTitle}>Ваша корзина</div>
                <NavLink
                  exact
                  to="/store"
                  className={styles.links}
                  activeClassName={styles.active}
                >
                    <Button variant="warning" style={{fontSize: '15px', fontWeight: 700, marginBottom: '1rem', display: 'flex', alignItems: 'center'}}>
                        <FontAwesomeIcon icon={faArrowLeft} className="mx-2" style={{fontSize: '20px'}} />Вернуться к списку учебников
                    </Button>
                </NavLink>
                <TableComponent>
                    <thead className="pt-3">
                        <tr>
                            <th>№</th>
                            <th>Наименование учебника</th>
                            <th>Автор</th>
                            <th>Класс</th>
                            <th>Язык</th>
                            <th>Цена</th>
                            <th>Количество</th>
                            <th>Сумма</th>
                            <th>Удалить</th>
                        </tr>
                    </thead>
                    <tbody>
                    {cart.map((cartItem, index) => (
                        <CartItem
                                index={index}
                                cartItem={cartItem}
                                key={`cartItem-${cartItem.id}`}
                                canAddBookToCart={canAddBookToCart}
                                className="py-4 px-5 ml-2"
                            />
                    ))}
                    <tr className={styles.print}>
                        <th colSpan={6} style={{fontSize: '20px', fontWeight: 'bold'}}>Итого</th>
                        <td style={{fontSize: '20px', fontWeight: 'bold'}}>{totalCount}</td>
                        {/* <td>{order?.total_quantity}</td> */}
                        <td style={{fontSize: '20px', fontWeight: 'bold'}}>{total}</td>
                    </tr>
                    </tbody>
                </TableComponent>
                <p style={{fontSize: '17px', fontWeight: 'bold'}}>Итого заявки на сумму: {total}</p>
                {/* <p style={{fontSize: '17px', fontWeight: 'bold'}}>Баланс от аренды: {balance}</p> */}
                <p style={{fontSize: '17px', fontWeight: 'bold'}}>Баланс от аренды {shop_grades?.join(', ')} классов : {available_balance}</p>
                {(total<=available_balance && total != 0) &&
                    <NavLink exact to="/store/checkout" className="text-white">
                        <Button variant="primary" className={styles.orderBtn} disabled={total>available_balance}>
                            <FontAwesomeIcon icon={faBook} className="mx-3"/> Перейти к оформлению заявки
                        </Button>
                    </NavLink>
                }
                {(total>available_balance || total === 0) &&
                    <div>
                        <Button variant="danger" className={styles.orderBtn} disabled={total>available_balance || total === 0}>
                            <FontAwesomeIcon icon={faBook} className="mx-3"/> Перейти к оформлению заявки
                        </Button>
                        {total>available_balance &&
                            <div style={{'width': '100%', 'textAlign': 'center', 'fontWeight': 700, 'fontSize': '24px', 'color': 'brown'}}><p>Недостаточно денежных средств</p></div>
                        }
                        {total === 0 &&
                            <div style={{'width': '100%', 'textAlign': 'center', 'fontWeight': 700, 'fontSize': '24px', 'color': 'brown'}}><p>Корзина пуста</p></div>
                        }
                    </div>
                }

                {(existed && existed.length > 0) && <Button onClick={handleClick} className={styles.orderBtn}>
                    Добавить к актуальному заказу
                </Button>
                }
            </Layout>
        </>
    )
}

import {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap"
import {useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {InputField, SelectField} from "../../components";
import {useActions, useAppSelector} from '../../hooks';
import {gradeEnum, langOptions, years} from "../../lib/helper";
import {thunks} from './school-books-reducer';
import './styles.css'

const actions = {searchSchoolBooks: thunks.searchSchoolBooks}

export const SearchSchoolBooks = ({}) => {
  const {t} = useTranslation()
  const {searchSchoolBooks} = useActions(actions)
  const categories = useAppSelector((state) => state.categories.categories)
  const categoriesOptions = categories.map((c) => {
    return {
      label: c.name, options: c?.subjects?.map((s) => {
        return {label: s.name, value: s.id}
      })
    }
  })
  const [query, setQuery] = useState({})
  const {register, control} = useForm()

  useEffect(() => {
    searchSchoolBooks(query)
  }, [query])

  const langChange = (data) => {
    setQuery((query) => {
      return {...query, book__language__in: data}
    })
  }
  const bookChange = (data) => {
    setQuery((query) => {
      return {...query, book__name__icontains: data}
    })
  }

  const classesChange = (data) => {
		setQuery((query) => {
			return {...query, book__grades: data}
		})
	}
  const yearChange = (data) => {
    setQuery((query) => {
      return {...query, year: data}
    })
  }

  const categoryChange = (data) => {
    setQuery((query) => {
      return {...query, book__subject: data}
    })
  }


  return (
    <Form className="mb-2">
      <Row lg={5} sm={1} className="search-books">
        <Col sm={1}>
          <SelectField
            register={{...register('subject')}}
            optGrpHead={t('school-books.add.categories')}
            optionGroup={[
              ...categoriesOptions
            ]}
            onChange={(event) => categoryChange(event.target.value)}
          />
        </Col>
        <Col sm={1}>
          <SelectField
            register={{...register('language')}}
            options={[
              {value: '', label: t('report.d10.lang')},
              ...langOptions
            ]}
            onChange={(event) => langChange(event.target.value)}
          />
        </Col>
        <Col sm={1}>
          <InputField
            type="text"
            placeholder={t('school-books.book_name')}
            control={control}
            name='book__name__icontains'
            onChange={(event) => bookChange(event.target.value)}
          />
        </Col>
        <Col sm={1}>
          <SelectField
            register={{...register('year')}}
            options={[
              {value: '', label: t('school-books.book_year')},
              ...years.map((y) => ({value: y, label: y}))
            ]}
            onChange={(event) => yearChange(event.target.value)}
          />
        </Col>
        <Col sm={1}>
							<SelectField
              className='select'
								register={{...register('grades')}}
								options={[
									{value: '', label: t('school-books.add.all_class')},
									...gradeEnum.map((g) => ({value: `{${g}}`, label: g}))
								]}
								onChange={(event) => classesChange(event.target.value)}
							/>
						</Col>
      </Row>
    </Form>
  )
}

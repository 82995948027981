import React, { useEffect, useState } from 'react'
import { Button, Col, FloatingLabel, Form, ListGroup, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import {useTranslation} from "react-i18next"
import { ButtonC, InputField, PhoneField } from '../../components'
import { notifyError, notifyInfo } from '../../components/notify'
import { useActions, useApi } from '../../hooks'
import { storage } from '../../service'
import { thunks } from './model'

const actions = {getAllReasons: thunks.getAllReasons}
export const AddReasons = ({show, setShow, reason}) => {
    const {t} = useTranslation()
    const school_id = storage.read('school_id');
    const {control, handleSubmit, formState: {errors}} = useForm()
    const {getAllReasons} = useActions(actions)
	const onClose = () => {
		setShow(false)
	}
    const [reasons, setReasons] = useState()
    const [writeReason, setWriteReason] = useState('')
    const api = useApi()
    const getReasons = () => {
        api.get('references/no-library-reasons').then((response) => {
            setReasons(response)
        })
    }
    const chooseReason = (text) => {
        api.put(`school`, {body: {no_library_reason_text: text}}).then((response) => {
            notifyInfo('Успешно изменено')
            // console.log(response)
        })
    }
    const removeReason = () => {
        notifyError('Успешно удалено')
        api.put(`school`, {body: {no_library_reason_text: ""}}).then((response) => {
            // console.log(response)
        })
    }
    useEffect(() =>{
        getReasons()
    }, [])

  return (
    <Modal
			show={show}
			onHide={onClose}
			keyboard={false}
			size="lg"
		>
			<Modal.Header>
				<Modal.Title>{t('profile.add-reasons.title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(getAllReasons)}>
				<div>
                 <>
                 <InputField
						type='text'
						control={control}
						name='reason'
						defaultValue=""
						label={t('profile.no-library-reason-text')}
						errors={errors.name}
					/>
                <ButtonC variant="delete2" onClick={removeReason}>
							Удалить
						</ButtonC>
                  </>
                  </div>
					<Col className='moduleBtns'>
						<ButtonC variant="close" onClick={onClose}>
							{t('btn.close')}
						</ButtonC>
						<ButtonC variant="add" onClick={() => chooseReason(control._formValues.reason)}>
							{t('btn.send')}
						</ButtonC>
					</Col>
				</Form>
			</Modal.Body>
		</Modal>
  )
}

import React from 'react'
import {Col, Form, Modal} from 'react-bootstrap'
import {ButtonC, InputField} from '../../../components'
import {useTranslation} from "react-i18next"
import {useForm} from 'react-hook-form'
import {api} from '../../../service/old-api-2'
import {notifyError, notifySuccess} from '../../../components/notify'
import { storage } from '../../../service'


export const CancelModal = ({show, setShow, profile, params, language, setD10Status}) => {
    const {t} = useTranslation()
    const {control, handleSubmit, formState: {errors}} = useForm()
    const onClose = () => {
        setShow(false)
    }
    const learnLanguage = storage.read('language')
    const onCanceled = (text) => {
        const data = {
                    d10_declined_comment: text,
                };
        api.post(`d10-district-decline/${params.id}/`, {
                body: data,
            }).
        then((response) => {
                notifySuccess('Отчет данной школы отклонен');
                setD10Status(true)
                onClose()
        })
    }
    // const onCanceled = (text) => {
    //     const data = {
    //         is_d10_verified: false,
    //         school: params.id,
    //         d10_declined_comment: text,
    //     };
    //     switch (learnLanguage) {
    //         case "kyrgyz":
    //             data["is_d10_ky_verified"] = false;
    //             break;
    //         case "russian":
    //             data["is_d10_ru_verified"] = false;
    //             break;
    //         case "uzbek":
    //             data["is_d10_uz_verified"] = false;
    //             break;
    //         case "tajik":
    //             data["is_d10_tj_verified"] = false;
    //             break;
    //     }
    //     if (profile) {
    //         api.put(`schools/profiles/${profile?.id}/`, {
    //             body: data,
    //         })
    //             .then((response) => {
    //                 notifyError("Отклонено");
    //             });
    //     } else {
    //         api.post(`schools/profiles/`, {
    //             body: data,
    //         }).then((response) => {
    //             notifySuccess('Отклонено');
    //         })
    //     }
    // };
    return (
        <Modal
            show={show}
            onHide={onClose}
            keyboard={false}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>Опишите причины отмены отчета</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form setShow={false}>
                    <InputField
                        type='text'
                        control={control}
                        name='reason'
                        defaultValue=""
                        label={t('report.d10-district.report-reason')}
                        errors={errors.name}
                    />
                    <Col className='moduleBtns'>
                        <ButtonC variant="close" onClick={onClose}>
                            {t('btn.close')}
                        </ButtonC>
                        <ButtonC variant="add"
                                 onClick={() => onCanceled(control._formValues.reason)}>{t('btn.send')}</ButtonC>
                    </Col>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

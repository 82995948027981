import React, {useState, useEffect, useRef} from 'react'
import {Layout} from '../../../app/layout'
import {useApi} from '../../../hooks';
import {newUseApi2} from '../../../hooks/new-use-api2';
import {useParams} from 'react-router-dom';
import {storage} from '../../../service';
import {Col, Container, Row, Table} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {ButtonC, PdfHeader, TableComponent} from '../../../components';
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { D10SubHead } from '../school/d10-sub-head';
import { D10Table } from '../d10/D10Table';
import { api } from '../../../service/old-api-2';
import { D10 } from '../d10/D10';

export const D10SubRegDetails = () => {
    const region = storage.read('region_id')
    const params = useParams()
    const [results, setResults] = useState()
    const {t} = useTranslation()
    const componentRef = useRef(null)
    const handlePrint = useReactToPrint({
     content: () => componentRef.current
   })
  return (
    <Layout>
        <Link  to="/report" style={{ fontWeight: 700, position: 'relative', bottom: '1px'}}>Назад к странице отчетов</Link>
        <D10 initQuery={{'language': 'kyrgyz', 'district_id': params.id}}/>
  </Layout>
  )
}

import {Col, Form, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ButtonC, InputField} from '../../components'
import {useActions} from '../../hooks'
import {thunks} from './model'
import styles from './teachers.module.css'
const actions = {searchTeachers: thunks.searchTeachers}

export const SearchTeachers = () => {
	const {t} = useTranslation()
	const {searchTeachers} = useActions(actions)
	const {control, handleSubmit, formState: {errors}} = useForm()

	return (
		<Form onSubmit={handleSubmit(searchTeachers)}>
			<Row className='mt-2'>
				<Col lg={5} sm={1}>
					<InputField
						type="text"
						placeholder={t('classes.all-teachers.fullname')}
						control={control}
						name='fullname__icontains'
					/>
				</Col>
				<Col lg={5} sm={1}>
					<InputField
						type="number"
						placeholder={t('classes.all-teachers.pin')}
						control={control}
						rules={{
							maxLength: {value: 14, message: t('classes.add-teacher.pin_error')},
							minLength: {value: 14, message: t('classes.add-teacher.pin_error')}
						}}
						name='pin'
						errors={errors.pin}
					/>
				</Col>
				<Col lg={2} sm={1}>
					<ButtonC variant='search' type='submit' className="mb-3">{t('btn.search')}</ButtonC>
				</Col>
			</Row>
		</Form>

	)
}
import {faTrash} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useState} from "react"
import {useTranslation} from "react-i18next"
import {ButtonC, DeleteNotifyWithRedux} from "../../components"
import {useActions} from '../../hooks'
import { isLibrarian } from "../../lib/helper"
import {thunks} from './model'
import styles from './teachers.module.css'
import {UpdateTeacher} from "./update-teacher"

const actions = {
	deleteTeacher: thunks.deleteTeacher,
}


export const TeacherItem = ({te, index}) => {
	const {t} = useTranslation()
	const {deleteTeacher} = useActions(actions)
	const [show, setShow] = useState(false)
	const [updateShow, setUpdateShow] = useState(false)

	return (
		<>
			<DeleteNotifyWithRedux
				message={te.fullname}
				func={deleteTeacher}
				id={te.id}
				show={show}
				setShow={setShow}
			/>
			<UpdateTeacher
				show={updateShow}
				setShow={setUpdateShow}
				teacher={te}
			/>
			<tr>
				<td>{index + 1}</td>
				<td>{te.fullname}</td>
				<td>{te.pin}</td>
				<td>{te.phone}</td>
				<td>{te.classes?.join(',')}</td>
				<td>{te.email}</td>
				{isLibrarian && <td>
					<div className={styles.actions}>
						<ButtonC
							variant="change"
							onClick={() => setUpdateShow(true)}
						>{t('classes.update-teacher.btn')}</ButtonC>
						{te?.is_delible && <ButtonC
							variant="delete"
							onClick={() => setShow(true)}
						>
							<FontAwesomeIcon icon={faTrash} />
						</ButtonC>}
					</div>
				</td>}
			</tr>
		</>
	)
}
import {useState} from "react"
import {useTranslation} from "react-i18next"
import {AddTeacher} from "./add-teacher"
import styles from './teachers.module.css'
import {ButtonC, TableComponent} from "../../components"
import {HireTeacher} from "./hire-teachers"
import {TeacherItem} from "./teacher-item"
import {Col, Row} from 'react-bootstrap'
import {SearchTeachers} from './search-teachers'
import {useAppSelector} from '../../hooks'
import { isLibrarian } from "../../lib/helper"

export const TeacherList = () => {
  const teachers = useAppSelector((state) => state.teachers.searchResult)
  const [show, setShow] = useState(false)
  const [hireShow, setHireShow] = useState(false)
  const {t} = useTranslation()

  return (
    <div style={{overflowX: "scroll", overflowY: 'hidden'}}>
      <AddTeacher
        show={show}
        setShow={setShow}
      />
      <HireTeacher
        show={hireShow}
        setShow={setHireShow}
      />
      <Row>
        <Col>
       {isLibrarian && <>
        <ButtonC
            variant='add'
            className={styles.addTeacher}
            onClick={() => setShow(true)}
          >{t('classes.add-teacher.btn')}</ButtonC>
          <ButtonC
            variant='add'
            className={styles.addTeacher}
            onClick={() => setHireShow(true)}
          >
            {t('classes.hire-teacher.title')}
          </ButtonC>
       </>}
        </Col>
      </Row>
      <SearchTeachers />
      <TableComponent style={{fontSize: '0.85rem'}}>
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{t('classes.all-teachers.fullname')}</th>
            <th scope="col">{t('classes.all-teachers.pin')}</th>
            <th scope="col">{t('classes.all-teachers.phone')}</th>
            <th scope="col">{t('classes.all-teachers.class')}</th>
            <th scope="col">E-mail</th>
          {isLibrarian && <th scope="col">{t('classes.all-teachers.action')}</th>}
          </tr>
        </thead>
        <tbody>
          {teachers.length > 0 && teachers.map((te, key) => (
            <TeacherItem
              key={te.id}
              te={te}
              index={key}
            />
          ))}
        </tbody>
      </TableComponent>
    </div>
  )
}

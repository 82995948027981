import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api-2'

const namespace = 'regions'

const fetchRegions = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let regions

		try {
			regions = await api.get('references/regions')
		} catch (error) {
			throw new Error(error)
		}
		// console.log(regions)
		return {regions}
	})

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		regions: [],
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchRegions.fulfilled, (state, action) => {
				state.regions = action.payload.regions
			})
})

const thunks = {fetchRegions}

export {reducer, thunks}

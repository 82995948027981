import React, {useState} from "react";
import {Col, Form, Modal, Row} from 'react-bootstrap'
import {useForm} from "react-hook-form";
import {notifyError, notifySuccess} from "../../components/notify";
import {gradeEnum, langOptions} from "../../lib/helper";
import {useTranslation} from "react-i18next";
import {ButtonC, InputField, SelectField} from "../../components";
import {useActions, useAppSelector} from '../../hooks'
import {thunks} from './class-reducer';
import {thunks as gradeThunks} from '../../app/store/grade-reducer'

const actions = {
  addClass: thunks.addClass,
  fetchGrades: gradeThunks.fetchGrades
}


export const AddClass = ({show, setShow}) => {
  const {handleSubmit, register, reset, formState: {errors}, control, setValue} = useForm()
  const teachers = useAppSelector((state) => state.teachers.teachers)
  const letter = useAppSelector((state) => state.letters.letters)
  const {addClass, fetchGrades} = useActions(actions)
  const [active, setActive] = useState(false)
  const [currentTeacher, setCurrentTeacher] = useState()
  const {t} = useTranslation()

  const handleClose = () => {
    reset()
    setShow(false)
    setCurrentTeacher({})
  };

  const letters = letter.map((l) => ({value: l.id, label: l.name}))

  const changeTeacher = (event) => {
    for (let i = 0; i < teachers.length; i++) {
      if (teachers[i].fullname === event.target.value) {
        setActive(true)
        setCurrentTeacher(teachers[i])
        setValue('email', teachers[i].email)
        setValue('pin', teachers[i].pin)
        setValue('phone', teachers[i].phone)

        break
      } else {
        setActive(false)
        setCurrentTeacher({})
        setValue('email', '')
        setValue('pin', '')
        setValue('phone', '')
      }
    }
  }

  const postKlass = async (data) => {
    if (!active) {
      notifyError('Выберите учителя из списка')
      return
    }
    const klass = {
      teacher: currentTeacher.id,
      student_count: data.student_count,
      student_count_50_discount: data.student_count_50_discount,
      student_count_100_discount: data.student_count_100_discount,
      grade: data.grade,
      letter: data.letter,
      language: data.language
    }
    const a = await addClass(klass)
    if (a.payload) {
      reset()
      notifySuccess(`${t('notify.success.added')}`)
      setShow(false)
      setCurrentTeacher({})
      fetchGrades()
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{t('classes.add-class.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(postKlass)}>
            <Col xs={12}>
              <InputField
                name='teacher'
                rules={{required: t('error_message.no_empty')}}
                label={t('classes.add-class.fullname')}
                list='teachers'
                autoComplete='off'
                onChange={changeTeacher}
                type='text'
                errors={errors.teacher}
                control={control}
                size='full'
              />
            </Col>

            <datalist id="teachers">
              {
                teachers.length > 0 && teachers.map(((t) => <option key={t.id} value={t.fullname} />))
              }
            </datalist>
            <Col xs={12}>
              <InputField
                disabled={true}
                control={control}
                name='email'
                label='E-mail'
                size='full'
              />
            </Col>
            <Row md={2} xs={1}>
              <Col>
                <InputField
                  disabled={true}
                  control={control}
                  name='pin'
                  label={t('classes.add-class.pin')}
                />
              </Col>
              <Col>
                <InputField
                  disabled={true}
                  control={control}
                  name='phone'
                  label={t('classes.add-class.phone')}
                />
              </Col>
            </Row>
            <Row md={2} xs={1}>
              <Col>
                <SelectField
                  register={{
                    ...register('grade',
                      {required: t('error_message.no_empty')})
                  }}
                  required={true}
                  errors={errors.grade}
                  label={t('classes.add-class.choose_class')}
                  options={gradeEnum.map((g) => ({value: g, label: g}))}
                />
              </Col>
              <Col>
                <SelectField
                  register={{
                    ...register('letter',
                      {required: t('error_message.no_empty')})
                  }}
                  required={true}
                  errors={errors.letter}
                  label={t('classes.add-class.letter')}
                  options={letters}
                />
              </Col>
            </Row>

            <Row md={1} xs={1}>
              <Col>
                <InputField
                  control={control}
                  name='student_count'
                  type='number'
                  rules={{
                    required: t('error_message.no_empty'),
                    max: {value: 60, message: t('classes.add-class.student_count_error')},
                    min: {value: 1, message: t('classes.add-class.student_count_error')}
                  }}
                  errors={errors.student_count}
                  label={t('classes.add-class.student_count')}
                />
              </Col>
              <Col>
                <InputField
                  control={control}
                  name='student_count_50_discount'
                  type='number'
                  rules={{
                    required: t('error_message.no_empty'),
                    max: {value: 60, message: t('classes.add-class.student_count_error')},
                    min: {value: 0, message: t('classes.add-class.student_count_error')}
                  }}
                  errors={errors.student_count_50_discount}
                  label={t('classes.add-class.student_with_50_percent')}
                />
              </Col>
              <Col>
                <InputField
                  control={control}
                  name='student_count_100_discount'
                  type='number'
                  rules={{
                    required: t('error_message.no_empty'),
                    max: {value: 60, message: t('classes.add-class.student_count_error')},
                    min: {value: 0, message: t('classes.add-class.student_count_error')}
                  }}
                  errors={errors.student_count_100_discount}
                  label={t('classes.add-class.student_with_100_percent')}
                />
              </Col>
              <Col>
                <SelectField
                  register={{
                    ...register('language',
                      {required: t('error_message.no_empty')})
                  }}
                  required
                  errors={errors.language}
                  label={t('classes.add-class.language')}
                  options={langOptions}
                />
              </Col>
            </Row>
            <Col className='moduleBtns'>
              <ButtonC variant="close" onClick={handleClose}>
                {t('btn.close')}
              </ButtonC>
              <ButtonC variant="add" type='submit'>{t('btn.add')}</ButtonC>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'

const namespace = 'grades'

const fetchGrades = createAsyncThunk(
	`${namespace}/get`,
	async () => {
		let grades

		try {
			grades = await api.get('grades')
		} catch (error) {
			throw new Error(error)
		}

		return {grades}
	})

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		grades: [],
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchGrades.fulfilled, (state, action) => {
				state.loading = 'pending'
				state.grades = action.payload.grades
			})
})

const thunks = {fetchGrades}

export {reducer, thunks}

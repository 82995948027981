import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Layout} from "../app/layout";
import {usePageTitle} from "../hooks";
import './classes.css'
import NotificationHistory from "../components/notification/NotificationHistory";


export const Notifications = () => {
  const {t} = useTranslation()
  usePageTitle(`${t('classes.title')}`)
  return (
    <Layout>
      <NotificationHistory />
    </Layout>
  )
}
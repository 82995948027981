import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import classes from "./books.module.css";
import { transLang } from "../../lib/helper";
import { useForm } from "react-hook-form";
import { notifySuccess } from "../../components/notify";
import { useTranslation } from "react-i18next";
import { ButtonC, InputField, SelectField } from "../../components";
import { thunks } from "./school-books-reducer";
import { useActions, useApi, useAppSelector } from "../../hooks";
import { BookCard } from "../../components/books/BookCard";

const actions = { addSchoolBook: thunks.addSchoolBooks };

export const AddSchoolBook = ({ show, handleShow, newBooks, setNewBooks }) => {
  const years = new Array(new Date().getFullYear() - 1999);
  for (let i = 0; i < years.length; i++) {
    years[i] = new Date().getFullYear() - i;
  }
  const { t } = useTranslation();
  const { addSchoolBook } = useActions(actions);
  const categories = useAppSelector((state) => state.categories.categories);
  const categoriesOptions = categories.map((c) => {
    return {
      label: c.name,
      options: c?.subjects?.map((s) => {
        return { label: s.name, value: s.id };
      }),
    };
  });
  const [total, setTotal] = useState({});
  const [books, setBooks] = useState([]);
  const [query, setQuery] = useState({});
  const { register, control } = useForm();
  const api = useApi();

  const authorChange = (data) => {
    setQuery((query) => {
      return { ...query, author__icontains: data };
    });
  };
  const bookChange = (data) => {
    setQuery((query) => {
      return { ...query, name__icontains: data };
    });
  };

  async function changeYear(book, schoolbook) {
    let year = document.querySelector(`#year${book.id}`);
    if (book.year === "") {
      notifyError("Новая значения должен быть больше чем выданных книг");
      year = book.remaining;
      return;
    }
    const payload = await updateSchoolBook({
      id: book.id,
      data: { book: book.book.id, total: book.total, year: year.value },
    });
    if (payload.payload) {
      notifySuccess("Успешно изменено");
    }
  }

  const classesChange = (data) => {
    setQuery((query) => {
      return { ...query, grades: data };
    });
  };
  const categoryChange = (data) => {
    setQuery((query) => {
      return { ...query, subject: data };
    });
  };

  const prevBooks = async () => {
    if (total.previous) {
      const prev = parseInt(total.previous.split("offset=")[1]);
      setQuery((query) => {
        return { ...query, offset: prev };
      });
    }
  };

  const nextBooks = async () => {
    const scroll = document.querySelector("#bookList");
    if (total.next) {
      const next = parseInt(total.next.split("offset=")[1]);
      setQuery((query) => {
        return { ...query, offset: next };
      });
      scroll.scrollTop = 0;
    }
  };

  async function addSchoolBooks(book) {
    const year = document.querySelector(`#year${book.id}`).value;
    const err = document.querySelector(`#err${book.id}`);
    if (!year) {
      err.style.display = "inline";
      return;
    }
    err.style.display = "none";
    const data = { ...book, language: transLang(book.language), year: year };
    const payload = await addSchoolBook(data);
    if (payload.payload) {
      notifySuccess(`${t('notify.success.added')}`);
    }
  }

  useEffect(async () => {
    try {
      await api.get(`books`, { search: query }).then((res) => {
        setBooks(res.results);
        setTotal(res);
      });
    } catch (error) {
      throw new Error(error);
    }
  }, [query]);

  return (
    <>
      <Modal show={show} onHide={handleShow} keyboard={false} size="lg" scr>
        <Modal.Header>
          <Modal.Title>{t("school-books.add.btn")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.filterBlock}>
          <Row xs={1} lg={3}>
            <Col>
              <SelectField
                register={{ ...register("subject") }}
                optGrpHead={t("school-books.add.categories")}
                optionGroup={[...categoriesOptions]}
                onChange={(event) => categoryChange(event.target.value)}
              />
            </Col>
            <Col>
              <InputField
                control={control}
                name="name__icontains"
                placeholder={t("school-books.add.name_book")}
                type="text"
                onChange={(event) => bookChange(event.target.value)}
              />
            </Col>
            <Col>
              <InputField
                placeholder={t("school-books.author")}
                control={control}
                name="author__icontains"
                type="text"
                onChange={(event) => authorChange(event.target.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <div className={classes.booksActions}>{total.count} результата</div>
        <div className={classes.line}></div>

        <Modal.Body className={`p-0 ${classes.bookList}`} id="bookList">
          {total.previous && (
            <ButtonC
              variant="change"
              onClick={prevBooks}
              className={classes.nextBooks}
            >
              {t("school-books.add.prev")}
            </ButtonC>
          )}
          <ul>
            {books &&
              books.map((b) => <BookCard book={b} years={years} key={b.id} newBooks={newBooks} setNewBooks={setNewBooks} />)}
          </ul>
          {total.next && (
            <ButtonC
              variant="change"
              onClick={nextBooks}
              className={classes.nextBooks}
            >
              {t("school-books.add.more")}
            </ButtonC>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonC variant="close" onClick={handleShow}>
            {t("btn.close")}
          </ButtonC>
        </Modal.Footer>
      </Modal>
    </>
  );
};

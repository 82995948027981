import React, { useEffect, useState } from "react";
import { read } from "../../service/storage";
import { TableComponent } from "../table/table";
import Moment from "moment";

const NotificationHistory = () => {
  const [notifications, setNotifications] = useState([]);
  const access = read("access");
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/notifications/history/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setNotifications(data));
  }, []);
  Moment.locale("en");
  return (
    <div>
      <h2>История уведомлений</h2>
      <TableComponent>
        <thead>
          <tr>
          <th scope="col">№</th>
            <th scope="col">Дата</th>
            <th scope="col">Текст</th>
          </tr>
        </thead>
        <tbody>
          {notifications?.map((notification, key) => (
            <tr key={notification.id}>
              <td style={{fontWeight: !notification.read?'bold':''}}>{key + 1}</td>
              <td style={{fontWeight: !notification.read?'bold':''}}>
                {Moment(notification.created).format("DD.MM.YYYY, HH:mm")}
              </td>
              <td style={{fontWeight: !notification.read?'bold':''}}>{notification.message}</td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    </div>
  );
};

export default NotificationHistory;

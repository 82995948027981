import {useTranslation} from "react-i18next"
import {Layout} from '../app/layout'
import {Payment} from '../feature/payments'
import {usePageTitle} from "../hooks"
import {storage} from '../service'

export const Payments = () => {
	const {t} = useTranslation()
	usePageTitle(t('payments.title'))
	return (
		<Layout>
			<h2 className='mb-4'>{t('payment.school')}: {storage.read('school_name')}</h2>
			<Payment />
		</Layout>
	)
}
import {useTranslation} from 'react-i18next'
import {useState, useEffect} from "react"
import {storage} from '../../service'
import styles from './pdf-header.module.css'
export const PdfHeaderWhithDirector = ({title, children}) => {

	const today = new Date(Date.now()).toLocaleDateString()
	const {t} = useTranslation()
	return (
		<thead className={styles.print}>
			<tr>
				<th colSpan='12' className={styles.title}><h3>{title}</h3></th>
			</tr>
			<tr>
				<th colSpan='12'><p>{t('print.region')}: {storage.read('region')}, {storage.read('district')}</p></th>
			</tr>
			<tr>
				<th colSpan='5'><p>{t('print.school_name')}: {storage.read('school')}</p></th>
				<th colSpan='7'><p>{t('print.director')}: <br/> Директор ______ <br/> {storage.read('director')}</p></th>
			</tr>
			<tr>
				<th colSpan='12'><p>{t('print.school_id')}: {storage.read('school_id')}</p></th>
			</tr>
			{children}
			<tr>
				<th colSpan='12'><p>{t('print.date')}: {today}</p></th>
			</tr>
		</thead>
	)
}
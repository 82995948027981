import {useState} from "react"
import {Col, Form, Modal} from "react-bootstrap"
import {useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {ButtonC, InputField} from "../../components"
import {notifyError, notifyInfo, notifySuccess} from "../../components/notify"
import {useApi, useAppSelector} from '../../hooks'

export const EmailConfirm = ({
	show,
	setShow,
}) => {
	const {t} = useTranslation()
	const {control, handleSubmit, formState: {errors}, reset} = useForm()
	const [sendEmail, setSendEmail] = useState(false)
	const user = useAppSelector((state) => state.profile.user)
	const api = useApi()
	const emailSend = async () => {
		const email = {
			email: document.querySelector('#email').value
		}
		try {
			await api.post('users/send-confirmation-email', {
				body: email
			})
			notifyInfo(t('profile.confirm-email.code_send'))
			setSendEmail(true)

		} catch (err) {
			notifyError(err.message)
		}
	}
	const confirmEmail = async (data) => {
		const code = {
			code: data.code
		}
		try {
			await api.put('users/confirm-email', {
				body: code
			})
			setShow(false)
			setSendEmail(false)
			reset()
			notifySuccess(t('profile.confirm-email.email_confirm'))
			location.reload()

		} catch (err) {
			throw new Error(err)
		}
	}
	return (
		<Modal
			show={show}
			onHide={setShow}
			backdrop='static'
			keyboard={false}
			size="lg"
		>
			<Modal.Header>
				<Modal.Title>{t('profile.confirm-email.title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(confirmEmail)}>
					<InputField
						type='text'
						control={control}
						name='email'
						defaultValue={user.email}
						id='email'
						rules={{
							pattern: {
								value: /\S+@\S+\.\S+\S+/,
								message: t('profile.confirm-email.email_error')
							}
						}}
						label={t('profile.confirm-email.email_label')}
						errors={errors.email}
					/>
					<ButtonC variant='primary' onClick={emailSend}>
						{t('profile.confirm-email.email_send')}
					</ButtonC>
					{
						sendEmail && <InputField
							type='text'
							control={control}
							name='code'
							rules={{required: t('error_message.no_empty')}}
							label={t('profile.confirm-email.code_label')}
							errors={errors.code}
						/>
					}
					<Col className='moduleBtns'>
						<ButtonC variant="close" onClick={() => setShow(false)}>
							{t('btn.close')}
						</ButtonC>
						{sendEmail && <ButtonC variant="add" type='submit'>{t('btn.send')}</ButtonC>}
					</Col>
				</Form>
			</Modal.Body>
		</Modal>
	)
}
import { Layout } from "../../app/layout";
import { Button, ListGroup, ListGroupItem } from "react-bootstrap";
import React from "react";
import { useDispatch } from "react-redux";
import { thunks } from "../../app/store/bookstore-reducer";
import styles from "./store.module.css";
import {
  notifySuccess,
  notifyError,
  notifyInfo,
} from "../../components/notify";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { CartItem } from "./cart-item";
import { TableComponent } from "../../components";
import { useTranslation } from "react-i18next";
import { storage } from "../../service";
import { useState, useEffect, useMemo } from "react";
import { useActions, useApi, useAppSelector } from "../../hooks";
import { isJanyKitep, langStatus, transLangFromEng } from "../../lib/helper";
import { th } from "date-fns/locale";

export const CheckoutGeneralOrder = () => {
  const { t } = useTranslation();
  const [director, setDirector] = useState();
  const [orders, setOrders] = useState();

  // const orders = useAppSelector(
  //     (state) => state['bookstore']['orders']['results']
  // )
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useAppSelector((state) => state["bookstore"]["cart"]);
  const actions = {
    createOrder: thunks.createOrder,
  };
  const handleClick = () => {
    api.get("store/create-general-order").then((response) => {
      if (response.status === "success") {
        notifyInfo(
          "Заявка сформирована! Распечатайте, подпишите, поставьте печать и прикрепите к заявке затем отправьте заявку Жаны китеп"
        );
        history.push("/store/general-orders/" + response.id);
      } else {
        notifyError("Ошибка.");
        history.push("/store/orders");
      }
    });
  };
  const balance = useAppSelector((state) => state["bookstore"]["balance"]);
  const available_balance = useAppSelector(
    (state) => state["bookstore"]["available_balance"]
  );
  const shop_grades = useAppSelector(
    (state) => state["bookstore"]["shop_grades"]
  );
  const [showRegions, setShowRegions] = useState(false);
  const [showDistricts, setShowDistricts] = useState(false);
  const [showSchools, setShowSchools] = useState(false);
  let total = 0;
  let totalCount = 0;
  if (isJanyKitep) {
    Object.keys(orders?orders['books']:'').map((book) => {
      total += orders['books'][book]?.["price"] * orders['books'][book]?.["quantity"];
      totalCount += orders['books'][book]?.["quantity"];
    });
  } else {
  orders?.map((book) => {
    console.log(book);
    total += book.price * book.quantity
    totalCount += book.quantity
    console.log(total, totalCount);
  });
  }
  const user = useAppSelector((state) => state.profile.user);
  const ordersDate = new Date();
  const year = ordersDate.toLocaleString("default", { year: "numeric" });
  const month = ordersDate.toLocaleString("default", { month: "2-digit" });
  const day = ordersDate.toLocaleString("default", { day: "2-digit" });
  const formattedDate = day + "-" + month + "-" + year;
  const today = new Date(Date.now()).toLocaleDateString();
  const getDirectorInfo = () => {
    api.get("users/get_director_info").then((response) => {
      storage.write("director_id", response?.id),
        storage.write("director_pin", response?.pin_code);
      setDirector(response);
    });
  };
  const getOrdersForGeneral = () => {
    if (isJanyKitep) {
      api.get("store/get_total_genorders_for_jk").then((response) => {
        setOrders(response);
      });
    } else {
      api.get("store/get_orders_for_general").then((response) => {
        setOrders(response);
      });
    }
  };
  const api = useApi();

  const handleToggleRegions = () => {
    setShowRegions(!showRegions);
  };

  const handleToggleDistricts = (regionId) => {
    setShowDistricts((prevShowDistricts) => ({
      ...prevShowDistricts,
      [regionId]: !prevShowDistricts[regionId],
    }));
  };
  const handleToggleSchools = (districtId) => {
    setShowSchools((prevShowSchools) => ({
      ...prevShowSchools,
      [districtId]: !prevShowSchools[districtId],
    }));
  };

  useEffect(() => {
    getDirectorInfo();
    getOrdersForGeneral();
  }, []);

  return (
    <>
      <Layout>
        {!isJanyKitep && (
          <>
            <h2 className={styles.cartTitle}>
              Новая районная заявка от {formattedDate}
            </h2>
            <p>
              {t("print.region_only")}: <b>{storage.read("region")}</b>
            </p>
            <p>
              {t("print.district_only")}: <b>{storage.read("district")}</b>
            </p>
            <p>
              {t("print.district_id")}: <b>{storage.read("district_id")}</b>
            </p>
          </>
        )}
        {isJanyKitep && (
          <>
            <h2 className={styles.cartTitle}>Общая заявка</h2>
          </>
        )}
        <br />
        <TableComponent>
          <thead className={styles.print + " pt-3"}>
            <tr>
              <th>№</th>
              <th>Наименование учебника</th>
              <th>Автор</th>
              <th>Класс</th>
              <th>Язык</th>
              <th>Цена</th>
             {isJanyKitep &&  (
              showRegions && // Проверяем состояние отображения регионов
                orders['books'] &&
                Object.keys(orders?orders['regions']:'').map((regionId) => (
                    <>
                      {showDistricts[regionId] &&
                          Object.keys(orders['regions'][regionId].districts).map((districtId) => (
                              <>
                                  {showSchools[districtId] &&
                                      Object.keys(orders['regions'][regionId].districts[districtId].schools).map((schoolId) => (
                                          <th key={schoolId}>
                                              {orders['regions'][regionId]['districts'][districtId].schools[schoolId].name}
                                          </th>
                                      ))
                                  }
                                  <th key={districtId}>
                                      {orders['regions'][regionId].districts[districtId].name}
                                      <button className={!showSchools[districtId]?'btn btn-success':'btn btn-danger'} onClick={() => handleToggleSchools(districtId)}>
                                          {showSchools[districtId] ? "Скрыть школы" : "Показать школы"}
                                      </button>
                                  </th>
                              </>
                          ))
                      }
                      <th key={regionId}>
                        {orders['regions'][regionId].name}
                        <button className={!showDistricts[regionId]?'btn btn-success':'btn btn-danger'} onClick={() => handleToggleDistricts(regionId)}>
                            {showDistricts[regionId] ? "Скрыть районы" : "Показать районы"}
                        </button>
                      </th>
                    </>
                ))
             )}
              <th>Общее количество <br />
                {isJanyKitep && (<>
                  <button className={!showRegions?'btn btn-success':'btn btn-danger'} onClick={handleToggleRegions}>
                  {showRegions ? "Скрыть регионы" : "Показать регионы"}
                </button>
                </>)}
              </th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
          {isJanyKitep &&
            Object.keys(orders?orders['books']:'').map((book, index) => (
              <tr key={book}>
                <td>{index + 1}</td>
                <td>{orders['books'][book].name}</td>
                <td>{orders['books'][book].author}</td>
                <td>{orders['books'][book].grades?.join(",")}</td>
                <td>{transLangFromEng(orders['books'][book].language)}</td>
                <td>{orders['books'][book].price}</td>
                {showRegions && // Проверяем состояние отображения регионов
                orders &&
                Object.keys(orders?orders['regions']:'').map((regionId) => (
                <>
                    {showDistricts[regionId] &&
                    Object.keys(orders['regions'][regionId].districts).map((districtId) => (
                        <>
                            {showSchools[districtId] &&
                                Object.keys(orders['regions'][regionId]?.['districts'][districtId]?.schools).map((schoolId) => (
                                    <td key={schoolId}>
                                        {orders['books'][book]['regions'][regionId]?.['districts'][districtId]?.['schools'][schoolId]?.quantity || 0}
                                    </td>
                                ))
                            }
                            <td key={districtId}>
                                {orders['books'][book]['regions'][regionId]?.['districts'][districtId]?.quantity || 0}
                            </td>
                        </>
                    ))}
                    <td key={regionId}>
                      {orders['books'][book]['regions'][regionId]?.quantity || 0}
                    </td>
                </>
                ))}
                <td>{orders['books'][book].quantity}</td>
                <td>{orders['books'][book].price * orders['books'][book].quantity}</td>
              </tr>
            ))

          }
          {!isJanyKitep && (<>
          {orders?.map((book, index)=> <tr key={book}>
                <td>{index + 1}</td>
                <td>{book.name}</td>
                <td>{book.author}</td>
                <td>{book.grades.join(",")}</td>
                <td>{transLangFromEng(book.language)}</td>
                <td>{book.price}</td>
                <td>{book.quantity}</td>
                <td>{book.price * book.quantity}</td>
                </tr>)}
          </>) }
            <tr className={styles.print}>
              <th colSpan={6} style={{ fontSize: "20px", fontWeight: "bold" }}>
                Итого
              </th>
              <td style={{ fontSize: "20px", fontWeight: "bold" }}>
                {totalCount}
              </td>
              <td style={{ fontSize: "20px", fontWeight: "bold" }}>{total}</td>
            </tr>
          </tbody>
        </TableComponent>
        <br />
        {!isJanyKitep && (
          <>
            <p>{t("print.disclamer")}</p>
            <p>Районный методист (ФИО, подпись) _________________</p>
            <p>ПИН ________________</p>
            <p>Номер телефона _____________</p>
            <br />
            <p>
              {t("print.dateOrder")}: {today}
            </p>
            <br />
            <p>{t("print.stamp")}</p>
          </>
        )}
        <div className="mt-5 d-flex justify-content-center">
          <NavLink
            exact
            to={
              !isJanyKitep? "/store/orders-shools": "/store/general-orders-districts"
            }
            //   className={styles.links}
            activeClassName={styles.active}
            style={{ display: "inline-block", marginRight: "30px" }}
          >
            <Button
              variant="danger"
              style={{
                padding: "15px 15px",
                fontSize: "17px",
                fontWeight: 700,
                marginBottom: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mx-2"
                style={{ fontSize: "20px" }}
              />
              Вернуться
            </Button>
          </NavLink>
          {!isJanyKitep && (
            <Button
              variant="success"
              onClick={handleClick}
              style={{
                padding: "15px 15px",
                fontSize: "17px",
                fontWeight: 700,
                marginBottom: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              Сформировать заявку
            </Button>
          )}
        </div>
      </Layout>
    </>
  );
};

import React, { useEffect, useState } from 'react'
import { Col, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { thunks } from '../../app/store/profileReducer'
import { ButtonC, InputField } from '../../components'
import { useActions, useApi, useAppSelector } from '../../hooks'
import {api} from '../../service/new-api-2'
const actions = {updateCombinedArea: thunks.updateCombinedArea}

export const UpdateCombinedArea = ({show, setShow, combined_area}) => {
    const {t} = useTranslation()
	const {updateCombinedArea} = useActions(actions)
	const {control, handleSubmit, formState: {errors}} = useForm()
    const profileLibrary = useAppSelector((state) => state.profile2.library)
	const onClose = () => {
		setShow(false)
    }
  return (
    <Modal
    show={show}
    onHide={onClose}
    keyboard={false}
    size="lg"
>
    <Modal.Header>
        <Modal.Title>{t('profile.library.title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form onSubmit={handleSubmit(updateCombinedArea)}>
        <div className='d-flex justify-center align-center'>
            <input type="text" style={{display: 'none'}} name="libraryId" defaultValue={profileLibrary.id}></input>
                            <InputField
                type='text'
                control={control}
                name='combined_area'
                defaultValue={profileLibrary?.combined_area}
                rules={{
                    required: t('error_message.no_empty'),
                }}
                label={t('profile.library.area')}
                errors={errors.combined_area}
            />
            	<div className='mt-4 p-3'>м2</div>
            </div>
            <Col className='moduleBtns'>
                <ButtonC variant="close" onClick={onClose}>
                    {t('btn.close')}
                </ButtonC>
                <ButtonC variant="add" type='submit'>{t('btn.send')}</ButtonC>
            </Col>
        </Form>
    </Modal.Body>
</Modal>
  )
}

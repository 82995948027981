/* eslint-disable react/display-name */
import {D10SubRepubItem} from './d10-sub-repub-item'


export const D10SubRepub = () => {

	return (
		<D10SubRepubItem />
	)
}

import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom/cjs/react-router-dom.min';
import { useAppSelector } from '../hooks';
import { storage } from '../service';
import {useApi} from '../hooks/use-api';
import { Layout } from '../app/layout';
import HTMLRenderer from 'react-html-renderer'
import { ButtonC } from '../components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './news.css'
export const NewsDetails = () => {
  const params = useParams();
  const api = useApi()
  const [news, setNews] = useState()
  const {t} = useTranslation()

  const getNewsByid = async () => {
    await api.get(`news/${params.id}`).then((response) => {
      setNews(response)
    })
  }
  useEffect(() => {
    getNewsByid()
  }, [])
  return (
    <Layout>
       <Link to='/'><ButtonC className="news-details-btn">{t('news.back_to_main_page')}</ButtonC></Link>
      <h2 className='news-details-title'>{news?.title}</h2>
   <img src={news?.preview} className="news-preview"/>
      <p className="container news-details-description"><HTMLRenderer html={news?.body} /></p>
    </Layout>
  )
}


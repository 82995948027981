import { Modal, Form, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { notifyError, notifySuccess } from "../../components/notify";
import { useTranslation } from "react-i18next";
import { gradeEnum, langOptions, transLang } from "../../lib/helper";
import { ButtonC, InputField, SelectField } from "../../components";
import { useForm } from "react-hook-form";
import { useActions, useAppSelector } from "../../hooks";
import { thunks } from "./school-events-reducer";
import { thunks as gradeThunks } from "../../app/store/grade-reducer";
import { logDOM } from "@testing-library/react";

const actions = {
  updateSchoolEvent: thunks.updateSchoolEvent,
};

export const UpdateSchoolEvent = ({
  show,
  handleClose,
  editValue,
  setShow,
}) => {
  const schoolEventTypes = useAppSelector(
    (state) => state.schoolEventTypes.school_event_types
  );
  const { updateSchoolEvent } = useActions(actions);
  const [active, setActive] = useState(true);
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm();
  const { t } = useTranslation();
  const school_event_types = schoolEventTypes.map((l) => ({
    value: l.id,
    label: l.name,
    label_kg: l.name_kg,
  }));

  const putSchoolEvent = async (data) => {
    // if (!active) {
    //   notifyError('Выберите учителя из списка')
    //   return
    // }
    // const klass = {
    //   teacher: currentTeacher?.id ?? editValue?.teacher?.id,
    //   student_count: data.student_count,
    //   student_count_50_discount: data.student_count_50_discount,
    //   student_count_100_discount: data.student_count_100_discount,
    //   grade: parseInt(editValue?.name),
    //   letter: data.letter,
    //   language: data.language
    // }
    const school_event = {
      name: data.name,
      date: data.date,
      type: data.type,
      comment: data.comment,
      result: data.result,
    };

    const a = await updateSchoolEvent({ id: editValue.id, data: school_event });
    if (a.payload) {
      reset();
      handleClose();
      notifySuccess("Изменено!");
      // setShow(false);
      // fetchGrades()
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
        <Modal.Header>
          {editValue?.name &&
            <Modal.Title>
              {t(`events.update-event-title`, { value: editValue?.name })}
            </Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(putSchoolEvent)}>
            <Col>
              <InputField
                control={control}
                type="date"
                name="date"
                label="Дата"
                rules={{
                  required: t("error_message.no_empty"),
                }}
                defaultValue={editValue?.date}
                style={{paddingRight: '40px'}}
                errors={errors.date}
              />
            </Col>
            <Col>
              <SelectField
                control={control}
                name="type"
                rules={{
                  // ...register("type", {
                    required: t("error_message.no_empty"),
                  // }),
                }}
                required={true}
                defaultValue={editValue?.type?.id}
                optGrpHead={t('events.chose-type')}
                label={t('events.field-type')}
                options={school_event_types}
                translated={true}
                errors={errors.type}
              />
            </Col>
            <Col xs={12}>
              <InputField
                rules={{
                  required: t("error_message.no_empty"),
                }}
                control={control}
                name="name"
                defaultValue={editValue?.name}
                // required={true}
                // label={t('classes.add-class.pin')}
                label={t('events.field-name')}
                errors={errors.name}
              />
            </Col>
            <Col>
              <InputField
                control={control}
                name="comment"
                rules={{
                  required: t("error_message.no_empty"),
                }}
                errors={errors.comment}
                label={t('events.field-comment')}
                defaultValue={editValue?.comment}
              />
            </Col>
            <Col>
              <InputField
                control={control}
                name="result"
                rules={{
                  required: t("error_message.no_empty"),
                }}
                errors={errors.result}
                label={t('events.field-result')}
                defaultValue={editValue?.result}
              />
            </Col>
            <Col className="moduleBtns">
              <ButtonC
                variant="close"
                onClick={() => {
                  handleClose();
                  reset();
                }}
              >
                {t("btn.close")}
              </ButtonC>
              <ButtonC variant="add" type="submit">
                {t("btn.change")}
              </ButtonC>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

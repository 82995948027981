import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
// import {SchoolEventsList, ClassDetail, AddClass} from "../feature/school-events";
import { TeacherList } from "../feature/teacher";
import { useTranslation } from "react-i18next";
import { Layout } from "../app/layout";
import { useAppSelector, usePageTitle } from "../hooks";
import { ButtonC } from "../components";
import "./classes.css";
import { isLibrarian } from "../lib/helper";
import { AddSchoolNews, SchoolNewsList } from "../feature/school-news";

export const SchoolNews = () => {
  const [show, setShow] = useState(false);
  const school_news = useAppSelector(
    (state) => state.schoolNews.school_news_list
  );
  // const gradeRender = [...new Set(grades?.map((el) => el.grade))]
  const { t } = useTranslation();
  usePageTitle(`${t("news.news-pages-title")}`);

  const handleShow = () => {
    setShow(true);
  };

  return (
    <Layout>
      <AddSchoolNews show={show} setShow={setShow} />
      <h2 className="mb-4 classes-title">{t("news.news-pages-title")}</h2>

      {isLibrarian && (
        <ButtonC
          variant="add"
          className="mb-4 ms-auto d-block add-class-btn"
          onClick={handleShow}
        >
          {/* {t('classes.add-class.btn')} */}
          {t("news.add-news")}
        </ButtonC>
      )}
      <Tabs
        defaultActiveKey="all"
        transition={false}
        className={`mb-3`}
        variant="pills"
      >
        <Tab eventKey="all">
          <SchoolNewsList school_news={school_news} />
        </Tab>
        {/* <Tab eventKey='teacherList' title={t('classes.all-teachers.title')}>
          <TeacherList />
        </Tab> */}
        {/* {gradeRender.map((el) => {
          return (
            <Tab key={el} eventKey={el} title={`${el} кл`}>
              <ClassDetail
                grade={el}
              />
            </Tab>
          )
        })} */}
      </Tabs>
    </Layout>
  );
};

import { useState, useEffect, useMemo, use, useRef } from "react"
import { useTranslation } from "react-i18next"
import { ButtonC, TableComponent } from "../../components"
import { EmailConfirm } from "./email-confirm"
import styles from './login.module.css'
import { PasswordChange } from "./password-change"
import { UpdateSchool } from "./update-school"
import { UpdateUser } from "./update-user"
import { UpdateDirector } from './update-director-info'
import { storage } from '../../service'
import { isDistObserver, isLabrarianOrDirector, isLibrarian, isLibrarianOrDistObserver, isRegObserver, labrarian } from '../../lib/helper'
import { useActions, useApi, useAppSelector, usePageTitle } from '../../hooks'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import { notifyError, notifySuccess, notifyInfo } from "../../components/notify"
import { AddNewWarehouse } from "./add-new-warehouse"
import { Layout } from "../../app/layout"
import { Button, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap"
import { thunks } from "../../app/store/profileReducer"
import { useForm } from "react-hook-form"
import { DeleteLibrary } from "./delete-library"
import useDebounce from "../../hooks/use-debounce"
import { AddReasons } from "./add-reasons"
import { useDispatch } from "react-redux"
import { fetchLibrary, isCombined, updateLibrary, createLibrary } from "../../app/store/profileReducer"
import { CombinedArea } from "./combined-area"
import { Warehouse } from "./warehouse"
import { ReadingRoom } from "./reading-room"
import { read } from "../../service/storage";

export const ProfileSettings = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	usePageTitle(`${t('profile.title')}`)
	const user = useAppSelector((state) => state.profile.user)
	const director = useAppSelector((state) => state.profile.director)
	const school = useAppSelector((state) => state.profile.school)
	const posts = useAppSelector((state) => state.profile.posts)
	const profileLibrary = useAppSelector((state) => state.profile2.library)
	const [confirmShow, setConfirmShow] = useState(false)
	const [passwordShow, setPasswordShow] = useState(false)
	const [updateShcool, setUpdateSchool] = useState(false)
	const [updateUser, setUpdateUser] = useState(false)
	const [updateDirectorInfo, setUpdateDirectorInfo] = useState(false)
	const [addWarehouse, setAddWarehouse] = useState(false)
	const [addCombinedArea, setAddCombinedArea] = useState(false)
	const [addReadingRoom, setAddReadingRoom] = useState(false)
	const [deleteLibraryModalById, setDeleteLibraryModalById] = useState(false)
	const [reasonModal, setReasonModal] = useState(false)
	const [hasAnOffice, setHasAnOffice] = useState(null)
	const [readingRoom, setReadingRoom] = useState([])
	const [office, setOffice] = useState('')
	const [library, setLibrary] = useState()
	const [warehouse, setWarehouse] = useState([])
	const [combinedArea, setCombinedArea] = useState()
	const [deleteLibrary, setDeleteLibrary] = useState()
	const [reasons, setReasons] = useState()
	const [schoolReason, setSchoolReason] = useState()
	const [post, setPost] = useState()
	const fileInputRef = useRef(null);
	const api = useApi()

	const { control, handleSubmit, formState: { errors } } = useForm()
	const debouncedWarehouseTerm = useDebounce(warehouse, 500);
	const actions = { createLibrary: thunks.createLibrary }
	const { createLibrary } = useActions(actions)
	const library_id = storage.read('library_id')
	const school_id = storage.read('school_id');
	const [selectedFile, setSelectedFile] = useState(null)

	const selectedFileHandler = (event) => {
		setSelectedFile(event.target.files[0])
	}

	const selectedFileUploadHandler = () => {
		const access = read('access')
		if (selectedFile) {
			const fd = new FormData()
			fd.append("doc", selectedFile, selectedFile.name)
			const uri = `${process.env.REACT_APP_BASE_URL}/api/library/${profileLibrary?.id}/photos/`
			fetch(uri, {
				method: 'POST',
				body: fd,
				headers: {
					"Authorization": `Bearer ${access}`,
				}
			}).then((response) => {
				if (response.ok) {
					fileInputRef.current.value = '';
					return response.json().then((data) => {
						// setOrder(data)
						dispatch(fetchLibrary())
						notifySuccess("Фотография успешно загружена")
					})
				} else if (response.status === 400) {
					response.json().then((errorData) => {
						const errorMessage = errorData[0]
						notifyError(errorMessage)
					})
				}
			})
		} else {
			notifyError("Документ не прикреплен.")
		}
	}

	const photoDownloadHandler = (id, name) => {
		const access = read('access')
		const uri = `${process.env.REACT_APP_BASE_URL}/api/library/${id}/photos/`
		fetch(uri, {
			method: 'GET',
			headers: {
				"Authorization": `Bearer ${access}`,
				'Content-Type': 'image/png',
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					`${name}`,
				);
				// Append to html link element page
				document.body.appendChild(link);
				// Start download
				link.click();
				// Clean up and remove the link
				link.parentNode.removeChild(link);
			});
	}

	const deletePhoto = (photo_id) => {
		const access = read('access')
		const uri = `${process.env.REACT_APP_BASE_URL}/api/photo/${photo_id}/delete/`
		fetch(uri, {
			method: 'GET',
			headers: {
				"Authorization": `Bearer ${access}`,
			}
		}).then((response) => {
			if (response.ok) {
				// setOrder(data)
				dispatch(fetchLibrary())
				notifySuccess("Фотография успешно удалена")
			}
		})
	}

	const PhotoDisplay = ({ id, name }) => {
		const [photoUrl, setPhotoUrl] = useState(null);

		useEffect(() => {
			const access = read('access');
			const uri = `${process.env.REACT_APP_BASE_URL}/api/library/${id}/photos/`;

			fetch(uri, {
				method: 'GET',
				headers: {
					"Authorization": `Bearer ${access}`,
					'Content-Type': 'image/png',
				},
			})
				.then((response) => response.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					setPhotoUrl(url);
				});
		}, [id]);

		return (
			<div>
				{photoUrl && <img src={photoUrl} alt={name} style={{maxHeight: '300px', maxWidth: '300px'}}/>}
			</div>
		);
	}

	const getWareHouses = async (event) => {
		await api.get('warehouses').then((response) => {
			setWarehouse(response)
		})
	}

	const getReadingRoom = () => {
		api.get(`reading-rooms`).then((response) => {
			setReadingRoom(response)
		})
	}

	const hasOfficeIsTrue = () => {
		notifyInfo('У Вас есть личный кабинет')
		const data = {
			'school': profileLibrary.school,
			'has_an_office': true,
			'is_combined': profileLibrary.is_combined,
			'combined_area': profileLibrary.combined_area
		}
		dispatch(updateLibrary({ libraryId: profileLibrary?.id, body: data }))
	}
	const hasOfficeIsFalse = () => {
		notifyInfo('У Вас нету личного кабинета')
		const data = {
			'school': profileLibrary.school,
			'has_an_office': false,
			'is_combined': profileLibrary.is_combined,
			'combined_area': profileLibrary.combined_area
		}
		dispatch(updateLibrary({ libraryId: profileLibrary?.id, body: data }))
	}
	const IsCombinedTrue = () => {
		notifyInfo('Успешно изменено')
		const data = {
			'school': profileLibrary.school,
			'has_an_office': profileLibrary.has_an_office,
			'is_combined': true,
			'combined_area': profileLibrary.combined_area
		}
		dispatch(isCombined({ libraryId: profileLibrary?.id, body: data }))
	}
	const IsCombinedFalse = () => {
		notifyInfo('Успешно изменено')
		const data = {
			'school': profileLibrary.school,
			'has_an_office': profileLibrary.has_an_office,
			'is_combined': false,
			'combined_area': '0.00'
		}
		dispatch(isCombined({ libraryId: profileLibrary?.id, body: data }))

	}
	const getReason = () => {
		api.get('school').then((response) => {
			setSchoolReason(response)
		})
	}
	const removeReason = () => {
		notifyError('Успешно удалено')
		api.put(`school`, { body: { no_library_reason_text: '' } }).then((response) => {
			// console.log(response)
		})
	}

	useEffect(() => {
		getReason(),
			dispatch(fetchLibrary())
	}, [])
	return (
		<Layout>
			<EmailConfirm
				show={confirmShow}
				setShow={setConfirmShow}
			/>
			<PasswordChange
				show={passwordShow}
				setShow={setPasswordShow}
			/>
			<UpdateSchool
				show={updateShcool}
				setShow={setUpdateSchool}
				school={school}
			/>
			<UpdateUser
				show={updateUser}
				setShow={setUpdateUser}
				user={user}
			/>
			<UpdateDirector
				show={updateDirectorInfo}
				setShow={setUpdateDirectorInfo}
				director={director}
			/>
			<AddNewWarehouse
				show={addWarehouse}
				setShow={setAddWarehouse}
				warehouse={warehouse}
			/>
			<DeleteLibrary
				show={deleteLibraryModalById}
				setShow={setDeleteLibraryModalById}
			/>
			<AddReasons
				show={reasonModal}
				setShow={setReasonModal}
				reason={reasons}
			/>
			{isLibrarianOrDistObserver &&
				<ListGroup variant="flush">
					<ListGroup.Item><strong>{t('profile.your-profile-info')}</strong></ListGroup.Item>
					<ListGroup.Item>{t('profile.user.pin_code')}: {user?.pin_code}</ListGroup.Item>
					<ListGroup.Item>{t('profile.user.fullname')}: {user?.fullname}</ListGroup.Item>
					<ListGroup.Item>{t('profile.user.phone')} : {user?.phone}</ListGroup.Item>
					<ListGroup.Item>{t('profile.user.email')} : {user?.email}</ListGroup.Item>
					{/* Изменить данные библиотекаря */}
					{/* <ListGroup.Item><ButtonC variant='change' onClick={() => setUpdateUser(true)}>
						  {t('profile.update-user.title')}
					  </ButtonC></ListGroup.Item> */}
					{user.email_confirmed == 'false' ? <ButtonC variant='delete' onClick={() => setConfirmShow(true)}>
						{t('profile.confirm-email.title')}
					</ButtonC> : ""}
				</ListGroup>
			}
			{isLabrarianOrDirector && <>
				<ListGroup variant="flush" className="mt-3">
					<ListGroup.Item><strong>{t('profile.director-info')}</strong></ListGroup.Item>
					<ListGroup.Item>{t('profile.user.pin_code')} : {director?.pin_code}</ListGroup.Item>
					<ListGroup.Item>{t('profile.update-director-info.fullname')} : {director?.fullname}</ListGroup.Item>
					<ListGroup.Item>{t('profile.update-director-info.phone')}: {director?.phone}</ListGroup.Item>
					<ListGroup.Item>{t('profile.update-director-info.email')}: {director?.email}</ListGroup.Item>
					{/* Изменить данные директора
      <ListGroup.Item>	{isLabrarianOrDirector && <>
					<ButtonC variant='change' onClick={() => setUpdateDirectorInfo(true)}>
						{t('profile.update-director-info.title')}
					</ButtonC></>}</ListGroup.Item> */}
				</ListGroup>
				<ListGroup variant="flush" className="mt-3">
					<ListGroup.Item><strong>{t('profile.school-info')}</strong></ListGroup.Item>
					<ListGroup.Item>{t('profile.school_name')}: {school?.name}</ListGroup.Item>
					<ListGroup.Item>{t('profile.kind')}: {school?.kind_name}</ListGroup.Item>
					<ListGroup.Item>{t('profile.okpo')}: {school?.okpo}</ListGroup.Item>
					<ListGroup.Item>{t('profile.balance')}: {school?.balance}</ListGroup.Item>
					<ListGroup.Item>{t('profile.district')}: {storage.read('district')}</ListGroup.Item>
					<ListGroup.Item>{t('profile.region')}:{storage.read('region')}</ListGroup.Item>
					{/* Изменить данные школы */}
					{/* <ListGroup.Item>	{isLibrarian && <ButtonC variant='change' onClick={() => setUpdateSchool(true)}>
						  {t('profile.update-school.title')}
					  </ButtonC>}</ListGroup.Item> */}
				</ListGroup>
			</>
			}

			{isLibrarianOrDistObserver && profileLibrary &&
				<h2 className='mt-2 mb-3 text-center'>{t('profile.library-info')}</h2>
			}
			{profileLibrary ? <></> : <>{isLibrarian && <p>{t('profile.has_a_library')}<p>{t('profile.your_answer')} {isLibrarian && profileLibrary?.length ? <strong style={{ backgroundColor: 'forestgreen', color: '#fff', marginTop: '10px', padding: '8px', borderRadius: '25px' }}>{t('profile.user.yes')}</strong> : <strong style={{ backgroundColor: 'orangered', color: '#fff', marginTop: '10px', padding: '8px', borderRadius: '25px' }}>{t('profile.user.no')}</strong>}</p> </p>}
				{labrarian && <div className="d-flex justify-content-start p-3">
					<ButtonC className="me-2" onClick={() => handleSubmit(createLibrary({ school: school_id }))}>{t('profile.user.yes')}</ButtonC>
					<ButtonC className="me-2" onClick={() => setReasonModal(true)}>{t('profile.user.no')}</ButtonC>
				</div>}</>}
			{isLibrarianOrDistObserver && schoolReason?.no_library_reason_text ?
				<div className="mt-4">{t('profile.no-library-reason')}: <strong>{schoolReason?.no_library_reason_text}</strong> <ButtonC variant="delete" onClick={() => removeReason()}><FontAwesomeIcon icon={faTrash} /></ButtonC></div> : <>
					<Row>
						<Col>
							{isLibrarianOrDistObserver && profileLibrary ? <><div className="mt-4">
								<p>{t('profile.is_combined')} </p><p>{t('profile.your_answer')}  {profileLibrary?.is_combined ? <strong style={{ backgroundColor: 'forestgreen', color: '#fff', marginTop: '10px', padding: '8px', borderRadius: '25px' }}>{t('profile.user.yes')}</strong> : <strong style={{ backgroundColor: 'orangered', color: '#fff', marginTop: '10px', padding: '8px', borderRadius: '25px' }}>{t('profile.user.no')}</strong>}</p>
								<div className="d-flex justify-content-start p-3">
									<ButtonC variant='change' className="me-2" onClick={() => IsCombinedTrue()}>{t('profile.user.yes')}</ButtonC>
									<ButtonC variant='change' className="me-2" onClick={() => IsCombinedFalse()}>{t('profile.user.no')}</ButtonC>
								</div>
							</div></> : <></>}
							{isLibrarian && profileLibrary ? <><div className="mt-4">
								<p>{t('profile.has_an_office')} <p>{t('profile.your_answer')} {profileLibrary?.has_an_office ? <strong style={{ backgroundColor: 'forestgreen', color: '#fff', marginTop: '10px', padding: '8px', borderRadius: '25px' }}>{t('profile.user.yes')}</strong> : <strong style={{ backgroundColor: 'orangered', color: '#fff', marginTop: '10px', padding: '8px', borderRadius: '25px' }}>{t('profile.user.no')}</strong>}</p></p>
								<div className="d-flex justify-content-start p-3">
									<ButtonC variant='change' className="me-2" onClick={() => hasOfficeIsTrue()}>{t('profile.user.yes')}</ButtonC>
									<ButtonC variant='change' className="me-2" onClick={() => hasOfficeIsFalse()}>{t('profile.user.no')}</ButtonC>
								</div>
							</div>
							</> : <></>}
							{isLibrarian && profileLibrary ? <><div className="mt-4">
								<p>{t('profile.photos')}</p>
								{profileLibrary?.photos && profileLibrary?.photos.length > 0 && profileLibrary?.photos.map((photo, index) => {
									return (
										<>
											<PhotoDisplay id={photo.id} name={photo.photo} />
											<button className="btn btn-link" onClick={() => {
												photoDownloadHandler(photo?.id, photo.photo.split('/')[photo.photo.split('/').length - 1])
											}}>
												Скачать
											</button>
											<ButtonC variant="delete" onClick={() => deletePhoto(photo.id)}>
												<FontAwesomeIcon icon={faTrash} />
											</ButtonC>
											<br />
										</>
									)
								})}
								<input
									type="file"
									title="Choose a video please"
									accept="application/image"
									placeholder={t('profile.photos-placeholder')}
									onChange={selectedFileHandler}
									ref={fileInputRef}
									className="mt-4"
								/>
								<button className="btn btn-success text-white" onClick={selectedFileUploadHandler}>Загрузить фотографию</button>
							</div>
							</> : <></>}
						</Col>
						<Col className="mt-4">
							{isLibrarianOrDistObserver && profileLibrary && profileLibrary?.is_combined ? <CombinedArea /> : <></>}
							{isLibrarianOrDistObserver && profileLibrary ? <><ReadingRoom /> <Warehouse /></> : <></>}
						</Col>
					</Row>
					{isLibrarianOrDistObserver && <div className="mt-4">
						{profileLibrary ?
							<><ButtonC variant='change'
								onClick={() => setDeleteLibraryModalById(true)}>{t('profile.remove_library')}</ButtonC></> : <></>} </div>}
					{/* {isLibrarianOrDistObserver && <div className={styles.buttonsProfile}>
					<ButtonC variant='change' onClick={() => setPasswordShow(true)}>
						{t('profile.password-change.title')}
					</ButtonC>
				</div>} */}
				</>}
		</Layout>
	)
}
import {useTranslation} from 'react-i18next'
import {storage} from '../../service'
import styles from './pdf-header.module.css'
import { format } from 'date-fns'
export const PdfFooterToOrderDistrict = ({order}) => {
	const today = new Date(Date.now()).toLocaleDateString()
	const {t} = useTranslation()

	const ordersDate = new Date(Date(order?.ordered_date))
	const year = ordersDate.toLocaleString("default", { year: "numeric" });
	const month = ordersDate.toLocaleString("default", { month: "2-digit" });
	const day = ordersDate.toLocaleString("default", { day: "2-digit" });
	const formattedDate = day + "-" + month + '-' + year;
	return (
		<>
		{/* // <tfoot className={styles.print}> */}
			<br />
			<tr className={styles.print}>
				<th colSpan='12'><p>{t('print.disclamer')}</p></th>
			</tr>
			<tr className={styles.print}>
				<th colSpan='12'><p>Районный методист {order.district_observer} ____________________ </p></th>
			</tr>
			<tr className={styles.print}>
				<th colSpan='12'><p>Пин: {order?.district_observer_inn}</p></th>
			</tr>
			<tr className={styles.print}>
				<th colSpan='12'><p>{t('print.directorPhone')}: {order?.district_observer_phone}</p></th>
			</tr>
			<tr className={styles.print}>
				<th colSpan='12'><p>{t('print.dateOrder')}: {today}</p></th>
			</tr>
			<tr className={styles.print}>
				<th colSpan='12'><p>{t('print.stamp')}</p></th>
			</tr>
		{/* // </tfoot> */}
		</>
	)
}
import React from 'react'
import { useState } from 'react'
import { Col, Form, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import {useTranslation} from "react-i18next"
import { ButtonC, InputField, PhoneField } from '../../../components'
import { FilterD10V2 } from '../filter-d10v2'


export const FilterModal = ({show, setShow, onChange }) => {
    const {t} = useTranslation()
    const {control, handleSubmit, formState: {errors}} = useForm()
	const [query, setQuery] = useState({})
	const onClose = () => {
		setShow(false)
	}

	const search = (e) => {
		e.preventDefault();
		onChange(query);
		setShow(false);
	}

  return (
    <Modal
			show={show}
			onHide={onClose}
			keyboard={false}
			size="lg"
		>
			<Modal.Header>
				<Modal.Title>{t('profile.add-reading-room.title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={search} setShow={false}>
					<FilterD10V2 setQuery={setQuery} />
					<Col className='moduleBtns'>
						<ButtonC variant="close" onClick={onClose}>
							{t('btn.close')}
						</ButtonC>
						<ButtonC variant="add" type="submit">Применить фильтры</ButtonC>
					</Col>
				</Form>
			</Modal.Body>
		</Modal>
  )
}

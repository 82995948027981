import {faSearch} from "@fortawesome/free-solid-svg-icons"
import {useRef, useState} from "react"
import {Modal, Form, Row, Col} from "react-bootstrap"
import {useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {ButtonC, InputField, TextField} from "../../components"
import {notifyError, notifySuccess} from "../../components/notify"
import {useActions, useApi} from '../../hooks'
import {thunks} from './model'

const actions = {hireTeacher: thunks.hireTeacher}

export const HireTeacher = ({show, setShow}) => {
	const {hireTeacher} = useActions(actions)
	const api = useApi()
	const [teacher, setTeacher] = useState({})
	const {t} = useTranslation()
	const pinRef = useRef('')
	const {handleSubmit, register, control, reset, formState: {errors}, setValue} = useForm({
		mode: 'onChange',
	})

	const searchTeachers = async () => {
		if (pinRef.current.length === 14) {
			const res = await api.get('teachers/search', {search: {pin: pinRef.current}})
			if (res[0]?.id) {
				setTeacher(res[0])
				setValue('email', res[0]?.email)
				setValue('phone', res[0]?.phone)
				setValue('fullname', res[0]?.fullname)
				notifySuccess(t('Ok'))
			} else {
				notifyError(t('classes.hire-teacher.pin-search'))
				setTeacher({})
				setValue('email', '')
				setValue('phone', '')
				setValue('fullname', '')
			}
			return
		}

		notifyError(t('classes.add-teacher.pin_error'))

	}

	const hireTeachers = async (data) => {
		if (data.pin !== teacher.pin) {
			notifyError(t('classes.hire-teacher.pin-post'))
			return
		}
		const payload = await hireTeacher(teacher.id)
		if (payload?.payload) {
			notifySuccess(`${t('notify.success.added')}`)
			reset()
			setShow(false)
			setTeacher({})
		}
	}
	return (
		<>
			<Modal
				show={show}
				onHide={setShow}
				keyboard={false}
				size="lg"
			>
				<Modal.Header>
					<Modal.Title>{t('classes.hire-teacher.title')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit(hireTeachers)}>
						<InputField
							control={control}
							name='pin'
							rules={{
								required: t('error_message.no_empty'),
								maxLength: {value: 14, message: t('classes.add-teacher.pin_error')},
								minLength: {value: 14, message: t('classes.add-teacher.pin_error')}
							}}
							type="number"
							onChange={(e) => {
								pinRef.current = e.target.value
							}}
							errors={errors.pin}
							label={t('classes.add-teacher.pin')}
							icon={faSearch}
							iconClick={searchTeachers}
							/>
							<h6 style={{"color": "red", "fontStyle": "oblique", "margin": "0 0 15px 0"}}>{t('classes.hire-teacher.p')}</h6>
						<Row xs={1}>
							<TextField
								register={{...register('fullname')}}
								disabled
								label={t('classes.hire-teacher.fullname')}
							/>
						</Row>
						<Row xs={1} md={2}>
							<Col>
								<TextField
									register={{...register('email')}}
									disabled
									label='E-mail'
								/>
							</Col>
							<Col>
								<TextField
									register={{...register('phone')}}
									disabled
									label={t('classes.add-teacher.phone')}
								/>
							</Col>

						</Row>
						<Col xs={12} className='moduleBtns'>
							<ButtonC variant="close" onClick={() => {
								setShow(false)
								reset()
								setTeacher({})
							}}
							>
								{t('btn.close')}
							</ButtonC>
							<ButtonC
								variant="add"
								type='submit'
							>{t('btn.add')}</ButtonC>
						</Col>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	)
}

import React from "react";
import {useTranslation} from "react-i18next";
import {TableComponent} from "../../../components";
import {useAppSelector} from '../../../hooks';
import { isLibrarian } from "../../../lib/helper";
import {TbItemReception} from "./tb-item-receptions";

export const TbReception = () => {
	const teacherBooks = useAppSelector((state) => state.teacherBooks.teacherBooks)
	const {t} = useTranslation()

	return (
		<TableComponent>
			<thead>
				<tr>
					<th scope="col">№</th>
					<th scope="col">{t('action-books.class-action.reception.name')}</th>
					<th scope="col">{t('action-books.class-action.reception.author')}</th>
					<th scope="col">{t('teacher-books.classes')}</th>
					<th scope="col">{t('action-books.class-action.reception.year')}</th>
					<th scope="col">{t('action-books.class-action.language')}</th>
					<th scope="col">{t('action-books.class-action.reception.issued')}</th>
					{isLibrarian && <th scope="col">{t('action-books.class-action.reception.action')}</th> }
				</tr>
			</thead>
			<tbody>
				{teacherBooks && teacherBooks.map((kb, index) => (
					<TbItemReception
						key={kb.id}
						kb={kb}
						index={index}
					/>
				))}
			</tbody>
		</TableComponent>
	)
}
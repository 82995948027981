import styles from "./store.module.css";
import {Button, Form, ListGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    changeBookCountByValue,
    dicreaseBookCount,
    increaseBookCount,
    removeBook,
    updateTotalPrice
} from "../../app/store/bookstore-reducer";
import {useDispatch} from "react-redux";
import {faMinus, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAppSelector} from "../../hooks";

export const CartItem = ({index, cartItem, canAddBookToCart}) => {
    const [maxStudentsNumber, setMaxStudentsNumber] = useState(0)
    const grades = useAppSelector(
        (state) => state['bookstore']['studentsCountByGrade']
    )
    const dispatch = useDispatch()
    const handleRemoveClick = () => {
        dispatch(removeBook(cartItem))
        dispatch(updateTotalPrice())
    }
    const handleIncreaseCount = () => {
        // if (cartItem.count < maxStudentsNumber) {
        dispatch(increaseBookCount(cartItem))
        dispatch(updateTotalPrice())
        // }
    }
    const handleDicreaseCount = () => {
        dispatch(dicreaseBookCount(cartItem))
        dispatch(updateTotalPrice())
    }
    const handleCountChange = (event) => {
        const count = parseInt(event.target.value, 10);
        // if (count > maxStudentsNumber) {
        //     count = maxStudentsNumber
        // }
        dispatch(changeBookCountByValue({
                    bookId: cartItem.id,
                    count: count
                }
            )
        )
        dispatch(updateTotalPrice())
    }
    const validateBookCount = () => {
        let total = 0;
        grades.forEach((grade) => {
            if (cartItem.grades.includes(grade.grade) && cartItem.language === grade.language) {
                total += grade.student_count;
            }
        })
        total = Math.ceil(total + total * 0.1)
        setMaxStudentsNumber(total)
    }
    useEffect(() => {
        // validateBookCount()
    }, [])
    return (
        <>
            <tr key={cartItem.id}>
                <td>{index + 1}</td>
                <td>{cartItem.name}</td>
                <td>{cartItem.author}</td>
                <td>{cartItem.grades.join(',')}</td>
                <td>{cartItem.language}</td>
                <td>{cartItem.price}</td>
                <td className={styles.countForm}>
                    <Button
                        variant={"danger"}
                        onClick={handleDicreaseCount}
                        // disabled={!canAddBookToCart}
                    >
                        <FontAwesomeIcon icon={faMinus} className="mx-3"/>
                    </Button>
                    <Form.Control
                        style={{width: '30%'}}
                        type="number"
                        className={`w-10 text-center ms-2 me-2 ${styles.countInput}`}
                        value={cartItem.count}
                        onChange={handleCountChange}
                        disabled={!canAddBookToCart}
                    />
                    <Button
                        variant={"success"}
                        onClick={handleIncreaseCount}
                        disabled={!canAddBookToCart}
                    >
                        <FontAwesomeIcon icon={faPlus} className="mx-3"/>
                    </Button>
                </td>
                <td>{cartItem.price * cartItem.count}</td>
                <td>
                    <button className={`ms-2 btn btn-danger`}
                        onClick={handleRemoveClick}><FontAwesomeIcon icon={faTrash} className="mx-3"/>
                    </button>
                </td>
            </tr>
        </>
    )
}
import {D10SubRepub} from '../feature/report'
import cn from 'classnames'
import styles from './open-data.module.css'
import {Link} from 'react-router-dom'
import {D10Region} from '../feature/report/region/d10-region'
import { OpenDataFilter } from '../feature/report/open-data/opendata-filter'
import { Opendata } from '../feature/report/open-data/open-data'
export const OpenData = () => {
	return (
		<div className={cn('container', styles.wrapper)}>
			<Link to='/sign-in'>
				Назад к форме авторизации
			</Link>
		{/* <D10Region /> */}
		<Opendata/>
		</div>
	)
}
import React, { useEffect, useState } from "react";

import {
  AddSchoolBook,
  SchoolBookItem,
  SearchSchoolBooks,
} from "../feature/school-books";
import { useTranslation } from "react-i18next";
import { Layout } from "../app/layout";
import { ButtonC, TableComponent } from "../components";
import { useAppSelector, usePageTitle } from "../hooks";
import "./styles.css";
import { isLibrarian } from "../lib/helper";
import { api } from "../service/old-api-2";
import {Link} from "react-router-dom";
import { isDistObserver, isLabrarian, isJanyKitep } from "../lib/helper";
import {applicationType} from '../lib/helper'
import { NavLink } from "react-router-dom";

export const BooksFondApplication = () => {
  const { t } = useTranslation();
  usePageTitle(t("school-books.title"));
  const [show, setShow] = useState(false);
  const [applications, setApplications] = useState([]);
  const schoolBooks = useAppSelector((state) => state.schoolBooks.searchResult);
  const GeneralOrderlist = () => {
    api.get(`school-books-applications/`).then((response) => {
      setApplications(response);
      // console.log(response);
      // if (response.status === 'success') {
      //     console.log();
      // }
    });
  };
  // console.log(orders);
  useEffect(() => {
    // dispatch(actions.fetchOrders())
    GeneralOrderlist();
  }, []);

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <Layout>
      <h2 className="mb-4 book-fond-title">
        {t('school-books.historyBook.title')}
      </h2>
      <NavLink
            exact
            to="/school-books"
            //   className={styles.links}
            // activeClassName={styles.active}
            style={{display: 'inline-block', marginRight: '30px'}}
            >
            <ButtonC
        variant="add"
        className="ms-auto  add-books-btn mb-3 mx-3"
        // onClick={handleShow}
      >{t('school-books.historyBook.back-to-fund')}</ButtonC>
      </NavLink>
      <TableComponent
        style={{ fontSize: "0.85rem" }}
        className="book-fond-table"
      >
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{t("school-book-applications.created")}</th>
            <th scope="col">{t("school-book-applications.year")}</th>
            <th scope="col">{t("school-book-applications.type")}</th>
            <th scope="col">{t("school-book-applications.comment")}</th>
            <th scope="col">{t("school-book-applications.file")}</th>
          </tr>
        </thead>
        <tbody>
          {applications?.map((application) => (
            <tr key={application["id"]}>
              <td>{application["id"]}</td>
              <td>{application.created.split('T')[0]}</td>
              <td>
                {application["study_period_start"]} - {application["study_period_end"]}
              </td>
              <td>{applicationType(application["type"])}</td>
              <td>{application["comment"] === 'null'? '': application["comment"]}</td>
              <td><a target="_blank" rel="noreferrer" href={application.application_doc}>скачать</a></td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    </Layout>
  );
};

import React, { useEffect, useState } from "react";
import { Layout } from "../../../app/layout";
import { thunks } from "../../../app/store/bookstore-reducer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { Button, ListGroup } from "react-bootstrap";
import styles from "../store.module.css";
import { useParams } from "react-router";
import {
  isLibrarian,
  isDistObserver,
  isDirector,
  isEdu_min,
} from "../../../lib/helper";
import { isJanyKitep, langStatus } from "../../../lib/helper";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import '@react-pdf-viewer/core/lib/styles/index.css';
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { api } from "../../../service/old-api-2";
import { Worker } from "@react-pdf-viewer/core";
import { read } from "../../../service/storage";

export const OrderSchoolDetailList = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const params = useParams();
  const dispatch = useDispatch();
  const actions = {
    fetchOrdersInSchool: thunks.fetchOrdersInSchool,
  };
  const orders = useAppSelector(
    (state) => state["bookstore"]["orders"]["results"]
  );
  const [file, setFile] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [vers, setVers] = useState();
  async function getVersion() {
    import("pdfjs-dist/package.json").then(({ version }) => {
      setVers(version);
    });
  }
  const docDownloadHandler = () => {
    const access = read("access");
    const uri = `${process.env.REACT_APP_BASE_URL}/api/store/get-act-url/${params?.id}`;
    fetch(uri, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access}`,
        // 'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = (e) => {
          setFile(e.target.result);
        };
      });
  };

  const getData = () => {
    api.get(`store/get-act/${params?.id}`).then((response) => {
      // setFile(response.url)
      // console.log(response);
      const reader = new FileReader();
      reader.readAsDataURL(response);
      reader.onloadend = (e) => {
        // console.log(e.target.result);
        setFile(e.target.result);
      };
    });
  };
  const getFileUrl = () => {
    api.get(`store/get-act-url/${params?.id}`).then((response) => {
      setFileUrl(response.url);
      // console.log(response);
      // const reader = new FileReader();
      // reader.readAsDataURL(response)
      // reader.onloadend=(e)=>{
      //     // console.log(e.target.result);
      //     setFile(e.target.result)
      // }
    });
  };

  // let reader = new FileReader();
  // reader.readAsDataURL()
  // console.log(orders);
  useEffect(() => {
    getData();
    getFileUrl();
    getVersion();
    // docDownloadHandler();
    dispatch(actions.fetchOrdersInSchool(params?.id));
  }, []);
  return (
    <>
      <Layout>
        <Button variant="primary" className={"my-3"}>
          <NavLink exact to="/store/orders-shools" className="text-white">
            <FontAwesomeIcon icon={faBook} className="mx-3" /> Вернуться к
            выбору школ
          </NavLink>
        </Button>
        <table style={{ width: "100%" }}>
          <thead>
            <th>№ заявки</th>
            <th>Учебный год</th>
            <th>Кол-во учебников</th>
            <th>Баланс</th>
            <th>Доступный баланс</th>
            <th>Сумма заявки</th>
            <th>Статус</th>
            <th>Действие</th>
          </thead>
          <tbody>
            {orders?.map((order) => (
              <tr key={order["id"]}>
                <td>{order["id"]}</td>
                <td>
                  {order["study_period_start"]} - {order["study_period_end"]}
                </td>
                {/* <td>{order['total_quantity']}</td> */}
                <td>{order["total_quantity"]}</td>
                {(isDistObserver || isDirector || isJanyKitep || isEdu_min) && (
                  <td>{order["school_balance"]} сом</td>
                )}
                {((!isDistObserver && !isDirector && !isJanyKitep) ||
                  isEdu_min) && <td>{balance} сом</td>}
                {(isDistObserver || isDirector || isJanyKitep || isEdu_min) && (
                  <td>{order["school_available_balance"]} сом</td>
                )}
                {((!isDistObserver && !isDirector && !isJanyKitep) ||
                  isEdu_min) && <td>{available_balance} сом</td>}
                {/* {(isDistObserver || isDirector) &&
                                <td>{order['total_price']} сом</td>
                            } */}
                <td>{order["total_price"]} сом</td>
                <td>
                  {/* {order['status']} */}
                  {langStatus(order["status"])}
                  {/* {filterOrders(order['status'])} */}
                </td>
                <td>
                  <Link to={{ pathname: `/store/orders/${order["id"]}` }}>
                    Подробнее
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <h3 className="mb-4 mt-4 text-center">Актуальный акт сверки</h3>
          {file && !fileUrl?.toLowerCase().includes("pdf") && (
            <a
              href={`${process.env.REACT_APP_BASE_URL}` + fileUrl}
              download="act"
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}` + fileUrl}
                alt="act-sverki"
                width="100%"
                height="100%"
              />
            </a>
          )}
          {file && fileUrl?.toLowerCase().includes("pdf") && (
            <Worker
              workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${vers}/pdf.worker.min.js`}
            >
              <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
          )}
          {!file && <div>Нет акта сверки</div>}
        </div>
      </Layout>
    </>
  );
};

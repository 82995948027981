import { ClassList } from "../feature/class-books/class-list";
import styles from "../feature/class-books/action-book.module.css";
import { useTranslation } from "react-i18next";
import { Layout } from "../app/layout";
import { useAppSelector, usePageTitle } from "../hooks";
import { Tab, Tabs } from "react-bootstrap";
import { TeachersBookList } from "../feature/teacher/teacher-books/teacher-list";

export const ActionBooks = () => {
  const grades = useAppSelector((state) => state.grades.grades);
  const { t } = useTranslation();
  usePageTitle(`${t("action-books.title")}`);
  const elem = grades?.map((g) => (
    <div key={`${g.grade}${g.language}`} className={styles.gradeBox}>
      <h4>{`${g.grade} клacc(${g.language})`}</h4>
      <ClassList grade={g.grade} language={g.language} />
    </div>
  ));

  return (
    <Layout>
      <h2 className="mb-4 ">{t("action-books.title")}</h2>
      <Tabs
        defaultActiveKey="classes"
        transition={false}
        className="mb-3"
        variant="pills"
      >
        <Tab eventKey="classes" title={t("action-books.class")}>
          {elem}
        </Tab>
        <Tab eventKey="teachers" title={t("action-books.teachers")}>
          <TeachersBookList />
        </Tab>
      </Tabs>
    </Layout>
  );
};

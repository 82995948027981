import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { storage } from '../../../service'
import { D10 } from '../d10/D10'
export const D10DistItemResults = () => {
    const district_id = storage.read('district_id')
    const {t} = useTranslation()
    const componentRef = useRef(null)
    const handlePrint = useReactToPrint({
      content: () => componentRef.current
    })
  return (
      <D10 initQuery={{'language': 'kyrgyz', 'part': 1, 'district_id': district_id}}/>
  )
}

import {faFilePdf} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useRef} from "react"
import {useTranslation} from "react-i18next"
import {useReactToPrint} from "react-to-print"
import {ButtonC, PdfHeader, TableComponent} from "../../../components"
import {useAppSelector} from '../../../hooks'
import styles from '../action-book.module.css'

export const TableInfo = ({klass}) => {
  const classBooks = useAppSelector((state) => state.classBooks.classBooks)
  const {t} = useTranslation()
  const printRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  })

  return (
    <>
      <TableComponent ref={printRef}>
        <PdfHeader title={t('action-books.class-action.reception.title')}>
          <tr>
            <th colSpan='12'><p>{t('action-books.class-action.teacher')}: {klass?.teacher?.fullname}</p></th>
          </tr>
          <tr>
            <th colSpan='12'><p>{t('action-books.class-action.Class')}: {klass?.name}</p></th>
          </tr>
          <tr>
            <th colSpan='12'><p>{t('action-books.class-action.language')}: {klass?.language}</p></th>
          </tr>
          <tr>
            <th colSpan='12'><p>{t('action-books.class-action.student_count')}: {klass?.student_count}</p></th>
          </tr>
        </PdfHeader>
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{t('action-books.class-action.reception.name')}</th>
            <th scope="col">{t('action-books.class-action.reception.author')}</th>
            <th scope="col">{t('action-books.class-action.reception.issued')}</th>
          </tr>
        </thead>
        <tbody>
          {classBooks && classBooks.map((kb, index) => (
            <tr key={kb.id}>
              <td scope="col">{index + 1}</td>
              <td>{kb?.school_book?.book?.name}</td>
              <td>{kb?.school_book?.book?.author}</td>
              <td>{kb.count}</td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    </>
  )
}
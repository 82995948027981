import {useEffect, useState} from "react"
import {apiFile, storage} from "../service"
import dateFormat from "dateformat"

export const useFileDownload = (url, {...params}, type) => {
    const [fileUrl, setFileUrl] = useState('')
    const ext = type === 'application/ms-excel' ? '.xlsx' : '.csv'
    const school_name = storage.read('school')
    const now = dateFormat("dd.mm.yy HH:MM")

    async function download() {
        if (url === undefined) return

        let fileurl

        try {
            const blob = await apiFile(url, {...params}, type)
            fileurl = URL.createObjectURL(blob)
        } catch {
        }
        setFileUrl(fileurl)
        const link = document.createElement("a")
        link.href = fileurl
        link.download = `отчеты ${school_name} ${now}/${ext}`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        return fileurl
    }

    useEffect(() => {
        if (fileUrl) return () => URL.revokeObjectURL(fileUrl)
    }, [fileUrl])

    return {
        fileUrl,
        download
    }
}

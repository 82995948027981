import React from 'react'
import { Col, Form, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import {useTranslation} from "react-i18next"
import { thunks } from '../../app/store/profileReducer'
import { ButtonC, InputField, PhoneField } from '../../components'
import { useActions } from '../../hooks'
import { storage } from '../../service'

const actions = {addWarehouse: thunks.addWarehouse}
const library_id = storage.read('library_id')
export const AddNewWarehouse = ({show, setShow, warehouse}) => {
    const {t} = useTranslation()
    const {control, handleSubmit, formState: {errors}} = useForm()
    const {addWarehouse} = useActions(actions)
	const onClose = () => {
		setShow(false)
	}

  return (
    <Modal
			show={show}
			onHide={onClose}
			keyboard={false}
			size="lg"
		>
			<Modal.Header>
				<Modal.Title>{t('profile.add-new-warehouse.title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit(addWarehouse)}>
				<div className='d-flex justify-center align-center'>
				<InputField
						type='text'
						control={control}
						name='area'
						defaultValue={warehouse?.area}
						rules={{
							required: t('error_message.no_empty'),
						}}
						label={t('profile.add-new-warehouse.area')}
						errors={errors.area}
					/>
					<div className='mt-4 p-3'>м2</div>
				</div>
					<Col className='moduleBtns'>
						<ButtonC variant="close" onClick={onClose}>
							{t('btn.close')}
						</ButtonC>
						<ButtonC variant="add" type='submit'>{t('btn.send')}</ButtonC>
					</Col>
				</Form>
			</Modal.Body>
		</Modal>
  )
}

import {Form, Modal, Row, Col} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {notifySuccess} from "../../../components/notify";
import {useState} from "react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {ButtonC, InputField, SelectField} from "../../../components";
import {gradeEnum, years} from "../../../lib/helper";
import styles from './teacher-books.module.css'
import {thunks} from '../../school-books/school-books-reducer'
import {thunks as tbThunks} from './tb-reducer';
import {useActions, useAppSelector} from '../../../hooks'

const actions = {
	updateSchoolBook: thunks.updateSchoolBook,
	searchSchoolBooks: thunks.searchSchoolBooks,
	addTeacherBook: tbThunks.addTeacherBooks
}

export const AddTeacherBook = ({
	show,
	setShow,
	teacher
}) => {
	const [query, setQuery] = useState({})
	const {register, control, handleSubmit} = useForm()
	const {updateSchoolBook, searchSchoolBooks, addTeacherBook} = useActions(actions)
	const renderBook = useAppSelector((state) => state.schoolBooks.searchResult)
	const teacherBooks = useAppSelector((state) => state.teacherBooks.teacherBooks)
	const {t} = useTranslation()

	const onSubmit = async (data) => {
		setQuery(data)
	}
	const handleClose = () => {
		setShow(false)
	}

	const addTeacherBookFunc = async (id) => {
		const body = {
			teacher: teacher.id,
			school_book: id,
			count: 1,
		}
		const payload = await addTeacherBook(body)
		if (payload.payload) {
			notifySuccess(`${t('notify.success.added')}`)
			updateSchoolBook({id})
		}
	}
	useEffect(async () => {
		searchSchoolBooks(query)
	}, [query])

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				keyboard={false}
				size="lg"
				scr
			>
				<Modal.Header>
					<Modal.Title>{t('action-books.class-action.add_class_book.btn')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row xs={1} md={2} lg={4}>
							<Col>
								<InputField
									type='text'
									name='book__name__icontains'
									placeholder={t('action-books.class-action.add_class_book.name_book')}
									control={control}
								/>
							</Col>
							<Col>
								<SelectField
									register={{...register('book__year__in')}}
									options={[
										{value: '', label: 'Год'},
										...years.map((y) => ({value: y, label: y}))
									]}
								/>
							</Col>
							<Col>
								<SelectField
									register={{...register('book__grades__contains')}}
									options={[
										{value: '', label: 'Все классы'},
										...gradeEnum.map((y) => ({value: `{${y}}`, label: y}))
									]}
								/>
							</Col>
							<Col>
								<ButtonC
									variant='search'
									type="submit"
									style={{marginBottom: '4px'}}
								>{t('btn.search')}
								</ButtonC>
							</Col>
						</Row>

					</Form>
				</Modal.Body>
				<div className={styles.line}></div>
				<Modal.Body className={`p-3 ${styles.bookslist}`}>
					<ul >
						{
							renderBook.length > 0 ? renderBook.map((b) => (
								<li className={`d-flex align-items-center w-100 ${styles.bookItem}`} key={b.id}>
									<ul className="ms-4">
										<li>{t('action-books.class-action.add_class_book.name')}: &nbsp;{b.book.name}</li>
										<li>{t('action-books.class-action.add_class_book.author')}: &nbsp;{b.book.author}</li>
										<li>{t('action-books.class-action.add_class_book.Class')}: &nbsp;{b.book.grades?.join(',')}</li>
										<li>{t('action-books.class-action.add_class_book.language')}: &nbsp;{b.book.language}</li>
										<li>{t('action-books.class-action.add_class_book.year')}: &nbsp;{b.year}</li>
									</ul>
									{!teacherBooks?.find((el) => el.school_book.id === b.id) ?
										<ButtonC
											variant="add"
											className='ms-auto'
											type='submit'
											onClick={() => addTeacherBookFunc(b.id)}
										>
											{t('btn.add')}
										</ButtonC> :
										<ButtonC
											variant="disable"
											className="ms-auto"
										>
											{t('btn.added')}
										</ButtonC>
									}
								</li>
							)) : <div className='d-flex align-content-center justify-content-center'>
								{t('action-books.class-action.add_class_book.is_empty')}
							</div>
						}
					</ul>

				</Modal.Body>
				<Modal.Footer>
					<ButtonC variant="close" onClick={() => setShow(false)}>
						{t('btn.close')}
					</ButtonC>
				</Modal.Footer>
			</Modal>
		</>
	)
}

import {Controller} from 'react-hook-form'
import {TextField} from "./text-field"

export const InputField = ({
	control,
	...props
}) => {
	return (
		<Controller
			control={control}
			name={props.name}
			rules={{...props?.rules}}
			defaultValue={props?.defaultValue ?? ''}
			shouldUnregister={true}
			render={({field}) => (
				<TextField
					{...props}
					// value={props.value ?? field.value}
					required={props?.rules?.required}
					onChange={(event) => {
						field.onChange(event)
						props?.onChange?.(event)
					}}
					inputRef={field.ref}
					onBlur={field.onBlur}
					defaultValue={props.defaultValue ?? field.value}
				/>
			)}
		/>
	)
}
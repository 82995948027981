/* eslint-disable react/display-name */
import {useActions, useApi, useAppSelector} from "../../../hooks"
import {storage} from '../../../service'

import {ButtonC, TableComponent} from '../../../components'
import {D10SubItem} from "../school/d10-sub-item"
import {useEffect, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMinusSquare, faPlusSquare} from '@fortawesome/free-solid-svg-icons'
import {thunks} from '../../../app/store/district-reducer'
import {D10SubRegItem} from './d10-sub-reg-item'
import {Col, Row} from 'react-bootstrap'
import {FilterD10V2} from '../filter-d10v2'
import {D10SubRegHead} from './d10-sub-reg-head'

const actions = {fetchDistricts: thunks.fetchDistricts}


const id = storage.read('region_id')
export const D10SubReg = ({regId = id, quer, withHead = true, summary}) => {
	return (
		<div>
			<D10SubRegItem />
		</div>
	)
}

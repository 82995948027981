import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../service/old-api'

const namespace = 'schools'

const fetchSchools = createAsyncThunk(
	`${namespace}/get`,
	async (districts) => {
		let schools

		try {
			schools = await api.get('references/schools'),  {
				search: {
					districts
				}
			}
		} catch (error) {
			throw new Error(error)
		}
		return {schools}
	})

const {reducer} = createSlice({
	name: namespace,
	initialState: {
		schools: [],
	},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchSchools.fulfilled, (state, action) => {
				state.schools = action.payload.schools
			})
})

const thunks = {fetchSchools}

export {reducer, thunks}

import React, {useEffect, useRef, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom/cjs/react-router-dom.min';
import {Layout} from '../../../app/layout'
import {ButtonC} from '../../../components';
import {notifySuccess} from '../../../components/notify';
import { storage } from '../../../service';
import {api} from '../../../service/old-api-2';
import {D10} from '../d10/D10';
import {CancelModal} from './cancel-modal';
import { ConfirmModal } from './confirm-modal';

export const D10SubDistResult = ({id = '', quer, withHead = true}) => {
    const learnLanguage = storage.read('language')
    const params = useParams()
    const [schoolName, setSchoolName] = useState();
    const initQuery = {language: learnLanguage, part: 1, school_id: params.id}
    const [cancel, setCancel] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [language, setLanguage] = useState();
    const printRef = useRef()
    const {t} = useTranslation()
    const [laoding, setLoading] = useState(false)
    const [d10Status, setD10Status] = useState(true)
    const getSchoolName = async () => {
        const response = await api.get(`librarians/?school=${params.id}`)
        const data = await response
        setSchoolName(data)
    }

    const getD10Status = async () => {
        await api.get(`actual-d10-status-district/${params.id}/`).then((response) => {
            setD10Status(response)
        })
    }
    const getProfileData = async () => {
        await api.get(`schools/profiles/?school_id=${params.id}/`).then((response) => {
            if (response.length > 0) {
                setProfileData(response[0])
            }
        })
    }
    const handleChange = (lang) => {
        setLanguage(learnLanguage);
    }
    useEffect(() => {
        getSchoolName();
        getProfileData();
        getD10Status()
    }, [])
    const componentRef = useRef(null)
    return (
        <Layout>
            <CancelModal
                show={cancel}
                setShow={setCancel}
                profile={profileData}
                params={params}
                language={language}
                setD10Status={setD10Status}
            />
            <ConfirmModal
                show={confirm}
                setShow={setConfirm}
                profile={profileData}
                params={params}
                language={language}
                setD10Status={setD10Status}
            />
            <Container>
                {schoolName && schoolName.length > 0 && schoolName.map((data) => {
                    return (
                        <div key={data.id}>
                            <Row>Имя библиотекаря: {data?.fullname} </Row>
                            <Row>Школа: {data?.school_name}</Row>
                            <Row>Телефон: {data?.phone || "номер телефона отсуствует необходимо проинформировать чтобы добавили номер телефона"} </Row>
                            <Row>Email: {data?.email || "электронная почта отсуствует необходимо проинформировать чтобы добавили электронную почту"} </Row>
                        </div>
                    )
                })}
                <Row>
                    <Col>{schoolName && schoolName.length > 0 && schoolName.map((school) => {
                        <div>Имя {school?.fullname}</div>
                    })}</Col>
                </Row>
            </Container>
            <Row>
                <Col><Link to="/report"><ButtonC variant="primary">Вернуться на страницу отчетов</ButtonC></Link></Col>
                <Col></Col>
                <Col></Col>
            </Row>
            <D10 initQuery={initQuery} forDistrict={true} onChange={handleChange}/>
            {!d10Status &&
                <div className='d-flex mt-5 justify-content-center'>
                    <ButtonC variant="primary"
                            style={{width: '500px', fontSize: '24px', padding: '20px', borderRadius: '8px'}}
                            onClick={() => setConfirm(true)}>{t('report.d10-district.submit-report')}</ButtonC>
                    <ButtonC variant="delete2"
                            style={{marginLeft: '20px', width: '500px', fontSize: '24px', borderRadius: '8px'}}
                            onClick={() => setCancel(true)}>{t('report.d10-district.cancel-report')}</ButtonC>
                </div>
            }
        </Layout>
    )
}

import {Layout} from "../../app/layout";
import {Button, ListGroup, ListGroupItem} from "react-bootstrap";
import React from "react";
import {useDispatch} from "react-redux";
import {thunks} from "../../app/store/bookstore-reducer";
import styles from './store.module.css'
import { notifySuccess, notifyError } from "../../components/notify";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { CartItem } from "./cart-item";
import { TableComponent } from "../../components";
import {useTranslation} from 'react-i18next'
import {storage} from '../../service'
import {useState, useEffect, useMemo} from "react"
import {useActions, useApi, useAppSelector} from '../../hooks'

export const Checkout = () => {
    const {t} = useTranslation()

    const orders = useAppSelector(
        (state) => state['bookstore']['orders']['results']
    )
    const history = useHistory();
    const dispatch = useDispatch()
    const cart = useAppSelector(
        (state) => state['bookstore']['cart']
    )
    const actions = {
        createOrder: thunks.createOrder,
    }
    const [disabled, setDisabled] = useState(false);
    const handleClick = () => {
        setDisabled(true)
        dispatch(actions.createOrder()).then((response) => {
            if (response.payload) {
                notifySuccess('Заявка сформирована и направлена директору для подтверждения! Детали заявки можете посмотреть в истории заявок.')
                history.push('/store/orders/' + response.payload.id);
            } else {
                notifyError('Заявка уже сформирована! Для его просмотра перейдите в историю заявок.')
                history.push("/store");
            }
        })
    }
    const balance = useAppSelector((state) => state["bookstore"]["balance"]);
    const available_balance = useAppSelector((state) => state["bookstore"]["available_balance"]);
    const shop_grades = useAppSelector((state) => state["bookstore"]["shop_grades"]);
    let total = 0;
    let totalCount = 0;
    cart.forEach((cartItem) => {
        total += cartItem['price'] * cartItem['count']
        totalCount += cartItem['count']
    })
    const user = useAppSelector((state) => state.profile.user)

    const ordersDate = new Date()
	const year = ordersDate.toLocaleString("default", { year: "numeric" });
	const month = ordersDate.toLocaleString("default", { month: "2-digit" });
	const day = ordersDate.toLocaleString("default", { day: "2-digit" });
	const formattedDate = day + "-" + month + '-' + year;
    const today = new Date(Date.now()).toLocaleDateString()
    const getDirectorInfo = () => {
		api.get('users/get_director_info').then((response) => {
			storage.write('director_id', response?.id),
			storage.write('director_pin', response?.pin_code)
			setDirector(response)
		})
	}
    const [director, setDirector] = useState()
    const api = useApi()

    useEffect(() => {
		getDirectorInfo()
	}, [])
    return (
        <>
            <Layout>
                <h2 className={styles.cartTitle}>Новая заявка от {formattedDate}</h2>
                <p>{t('print.region')}: <b>{storage.read('region')}, {storage.read('district')}</b></p>
                <p>{t('print.school_name')}: <b>{storage.read('school')}</b></p>
                <p>{t('print.school_id')}: <b>{storage.read('school_id')}</b></p>
                {/* <div>Баланс: <b>{balance} сом</b></div> */}
                <div>Баланс от аренды {shop_grades?.join(', ')} классов: <b>{available_balance} сом</b></div>
                <br />
                <TableComponent>
                    <thead className={styles.print + ' pt-3'}>
                        <tr>
                            <th>№</th>
                            <th>Наименование учебника</th>
                            <th>Автор</th>
                            <th>Класс</th>
                            <th>Язык</th>
                            <th>Цена</th>
                            <th>Количество</th>
                            <th>Сумма</th>
                        </tr>
                    </thead>
                    <tbody>
                    {cart.map((cartItem, index) => (
                        <tr key={cartItem.id}>
                            <td>{index + 1}</td>
                            <td>{cartItem.name}</td>
                            <td>{cartItem.author}</td>
                            <td>{cartItem.grades.join(',')}</td>
                            <td>{cartItem.language}</td>
                            <td>{cartItem.price}</td>
                            <td>{cartItem.count}</td>
                            <td>{cartItem.price * cartItem.count}</td>
                        </tr>
                    ))}
                        <tr className={styles.print}>
                            <th colSpan={6} style={{fontSize: '20px', fontWeight: 'bold'}}>Итого</th>
                            <td style={{fontSize: '20px', fontWeight: 'bold'}}>
                                {totalCount}
                            </td>
                            {/* <td>{order?.total_quantity}</td> */}
                            <td style={{fontSize: '20px', fontWeight: 'bold'}}>
                                {total}
                            </td>
                        </tr>
                    </tbody>
                </TableComponent>
                <br />
                <p>{t('print.disclamer')}</p>
                <p>{t('print.director2')} ____________________ {storage.read('director')}</p>
                <p>{t('print.directorPin')}: {storage.read('director_pin')}</p>
                <p>{t('print.directorPhone')}: {director?.phone}</p>
                <br />
                <p>{t('print.disclamer')}</p>
                <p>{t('print.librarian')} _______________ {user?.fullname}</p>
                <p>{t('print.librarianPin')}: {user?.pin_code}</p>
                <p>Номер: {user?.phone}</p>
                <p>Почта: {user?.email}</p>
                <p>{t('print.dateOrder')}: {today}</p>
                <br />
                <p>{t('print.stamp')}</p>
                <div className="mt-5 d-flex justify-content-center">
                    <NavLink
                    exact
                    to="/store/cart"
                    //   className={styles.links}
                    activeClassName={styles.active}
                    style={{display: 'inline-block', marginRight: '30px'}}
                    >
                        <Button variant="danger" style={{padding: '15px 15px', fontSize: '17px', fontWeight: 700, marginBottom: '1rem', display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={faArrowLeft} className="mx-2" style={{fontSize: '20px'}} />Вернуться
                        </Button>
                    </NavLink>
                    <Button disabled={disabled} variant="success" onClick={handleClick} style={{padding: '15px 15px', fontSize: '17px', fontWeight: 700, marginBottom: '1rem', display: 'flex', alignItems: 'center'}}>
                        Отправить на подтверждение директору
                    </Button>
                </div>
            </Layout>
        </>
    )
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useEffect, useRef, useState} from 'react'
import {Accordion, OverlayTrigger, Popover, Table} from 'react-bootstrap'
import {ButtonC, PdfHeader} from '../../../components'
import {Link} from 'react-router-dom';
import {faAngleDown, faFileExcel, faFilePdf, faWindowClose, faMinusCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import {api} from '../../../service/old-api-2';
import {useReactToPrint} from 'react-to-print';
import {useTranslation} from 'react-i18next';
import { Layout } from '../../../app/layout';
import {Button, ListGroup} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {langStatus} from '../../../lib/helper'
import styles from '../store.module.css'
import {useParams} from "react-router";

export const OrderDistrictSchoolList = () => {
    const params = useParams()
    const [schools, setSchools] = useState([])
    const [readyForGeneralOrder, setReadyForGeneralOrder] = useState(false)
    const {t} = useTranslation()
    const getData = () => {
        api.get(`store/orders-school/?district=${params?.id}`).then(
            (response) => {
                setSchools(response)
            })
    }
    const GetRreadyForGeneralOrder = () => {
        api.get(`store/ready-for-create-general-order/`).then(
            (response) => {
                if (response.status === 'success') {
                    setReadyForGeneralOrder(true)
                }
            })
    }
    const GeneralOrderCreate = () => {
        api.get(`store/general-order-create/`).then(
            (response) => {
                console.log(response);
                // if (response.status === 'success') {
                //     console.log();
                // }
            })
    }
    const filterOrders  = ((orders, cancelleds) => {
        const filtered = orders?.filter((order) => order?.study_period_active === 'True' )
        if (filtered?.length > 0) {
            if (filtered[0]?.status === 'district_declined') {
                return <><FontAwesomeIcon icon={faWindowClose} style={{color: 'orangered', fontSize: '25px'}}/> <br /> {langStatus(filtered[0]?.status)}</>
            } else if (filtered[0]?.status === 'district_approved') {
                return <><FontAwesomeIcon icon={faAngleDown} style={{color: 'forestgreen', fontSize: '25px'}}/> <br /> {langStatus(filtered[0]?.status)}</>
            } else if (filtered[0]?.status === 'district_review') {
                return <><FontAwesomeIcon icon={faExclamationCircle} style={{color: 'blue', fontSize: '25px'}}/> <br /> {langStatus(filtered[0]?.status)}</>
            } else {
                return <><FontAwesomeIcon icon={faMinusCircle} style={{color: 'orange', fontSize: '25px'}}/> <br /> {langStatus(filtered[0]?.status)}</>
            }
        } else {
            const filteredCancelleds = cancelleds?.filter((cancelled) => cancelled?.study_period_active === 'True' )
            if (filteredCancelleds?.length > 0) {
                return <p><b>Отказано</b><br /> {getComment(cancelleds)}</p>
            }
            return <FontAwesomeIcon icon={faMinusCircle} style={{color: 'orange', fontSize: '25px'}}/>
        }
    })
    // const availableBalance  = ((orders) => {
    //     const filtered = orders?.filter((order) => order?.study_period_active === 'True' )
    //     if (filtered?.length > 0) {
    //         return filtered[0]?.school_available_balance
    //     } else {
    //         return 0
    //     }
    // })
    const orderSum  = ((orders) => {
        const filtered = orders?.filter((order) => order?.study_period_active === 'True' )
        if (filtered?.length > 0) {
            return filtered[0]?.total_price
        } else {
            return 0
        }
    })
    const getComment  = ((cancelleds) => {
        const filteredCancelleds = cancelleds?.filter((cancelled) => cancelled?.study_period_active === 'True' )
        if (filteredCancelleds?.length > 0) {
            return filteredCancelleds[0]?.comment
        }
    })
    useEffect(() => {
        getData();
        GetRreadyForGeneralOrder();
    }, [])

    const componentRef = useRef(null)
    return (
        <Layout>
        <div>
            {/* <NavLink exact to="/store/general-orders">
                <Button variant="primary" className='btn btn-success ms-3 mb-3'
                    style={{ fontSize: "20px", fontWeight: 700 }}
                >
                    История заявок
                </Button>
            </NavLink> */}
            <Table striped bordered hover ref={componentRef}>
                <thead>
                <tr>
                    <th>№</th>
                    <th>Код школы</th>
                    <th>Название школы</th>
                    <th>Имя библиотекаря</th>
                    <th>Телефон / Email</th>
                    <th>Баланс школы</th>
                    <th>Баланс Жаны Китеп</th>
                    <th>Доступный баланс</th>
                    <th>Сумма заявки</th>
                    <th>Заявки</th>
                    <th>Акт сверки</th>
                    <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                {schools && schools?.length > 0 && schools?.map((school, index) => {
                    let total = 0;
                    school?.gradebalance_set?.forEach((grade) => {
                        total += parseInt(grade.balance)
                    })
                    return (
                        <tr key={school?.school_id}>
                            <td data-label="№">{index+1}</td>
                            <td data-label="Код школы">{school?.id}</td>
                            <td data-label="Название школы">{school?.name}</td>
                            <td data-label="Имя библиотекаря">{school?.fullname}</td>
                            <td data-label="Телефон / Email">{school?.phone} / {school?.email}</td>
                            <td data-label="Баланс школы">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header style={{whiteSpace: 'nowrap'}}>{total} сом &nbsp;</Accordion.Header>
                                        <Accordion.Body>
                                            {school?.gradebalance_set?.sort((a, b) => {
                                                return a.grade - b.grade
                                                }).map((grade, index) => {
                                                    return (
                                                        <>
                                                            <p style={{whiteSpace: 'nowrap'}} key={index}>{grade.grade_number} класс - {grade.balance} сом</p>
                                                            <br />
                                                        </>
                                                    )
                                                }
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </td>
                            <td data-label="Баланс Жаны Китеп">{school?.balance}</td>
                            <td data-label="Доступный баланс">{school?.school_available_balance}</td>
                            <td data-label="Сумма заявки">{orderSum(school?.orders)}</td>
                            <td data-label="Заявки">
                                <Link to={{pathname: `/store/orders-shools/${school?.id}`}}>
                                    <ButtonC variant="primary">{t('report.d10-district.check')}</ButtonC>
                                </Link>
                            </td>
                            <td data-label="Акт сверки">{school?.has_act?'Есть':'Нет'}</td>
                            <td>
                                {filterOrders(school?.orders, school?.willnotorderinperiod_set)}
                            </td>
                        </tr>
                    )
                })}
                <tr>

                </tr>
                </tbody>
            </Table>
        </div>
        </Layout>
    )
}

import {notifyError} from '../components/notify'
import {ApiError} from './api-error'
import {clearAll, read} from './storage'

const api = async (
    path,
    {id, search, body, ...rest} = {},
) => {
    let uri = `${process.env.REACT_APP_BASE_URL}/api/${path}`
    const language = read('lang') || 'ru'
    const access = read('access')

    const header = access ? new Headers({
        "Authorization": `Bearer ${access}`,
        "Accept-Language": `${language};q=0.9`,
        "Content-Type": 'application/json'
    }) : new Headers({
        "Accept-Language": `${language};q=0.9`,
        "Content-Type": 'application/json'
    })
    if (id) uri += `/${id}/`

    if (search) {
        const coerced = typeof search === 'object' ?
            Object.entries(search)
                .filter(([k, v]) => v !== '' && v != null)
                .map(([k, v]) => [k, v.toString()]) :
            search

        uri += `?${new URLSearchParams(coerced).toString()}`
    }

    const init = rest

    init.headers = header

    if (body) {
        init.body = JSON.stringify(body)
    }

    let response


    try {
        response = await fetch(uri, init)
    } catch (error) {
        if (error.name === 'AbortError') return
        throw error
    }

    const payload = await (isJson(response) ?
        response.json() :
        isText(response) ?
            response.text() :
            response.blob())
    if (!response.ok) {
        if (response.status === 400) {
            // eslint-disable-next-line guard-for-in
            for (const key in payload) {
                payload[key].map((e) => {
                    notifyError(e)
                })
            }
        } else if (response.status === 401) {
            clearAll()
        }
        // else if (response.status === 403) {
        // 	location.href = location.origin + '/forbidden'
        // } else if (response.status === 404) {
        // 	location.href = location.origin + '/not-found'
        // }
        throw new ApiError()
    }
    return payload
}

api.get = (path, options = {}) => api(path, {...options, method: 'get'})
api.post = (path, options = {}) => api(path, {...options, method: 'post'})
api.put = (path, options = {}) => api(path, {...options, method: 'put'})
api.delete = (path, options = {}) => api(path, {...options, method: 'delete'})
api.patch = (path, options = {}) => api(path, {...options, method: 'patch'})

export {api}
const isContent = (type) => (response) =>
    response.headers.get('Content-Type')?.includes(type) ?? false

const isJson = isContent('application/json')
const isText = isContent('text/plain')

import styles from "./store.module.css";
import {ListGroup} from "react-bootstrap";
import React from "react";
import {removeBook, updateTotalPrice} from "../../app/store/bookstore-reducer";
import {useDispatch} from "react-redux";

export const SidebarCartItem = ({cartItem}) => {
    const dispatch = useDispatch()
    const handleClick = () => {
        dispatch(removeBook(cartItem))
        dispatch(updateTotalPrice())
    }
    return (
        <>
            <ListGroup.Item className="mb-2 d-flex justify-content-between py-3 px-2">
                {cartItem.name} * {cartItem.count}
                <button className={`ms-2 rounded btn btn-danger`}
                        onClick={handleClick}>x
                </button>
            </ListGroup.Item>
        </>
    )
}

import React, {useEffect} from "react";
import {Layout} from "../../../app/layout";
import {thunks} from "../../../app/store/bookstore-reducer";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../hooks";
import {Link} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {Button, ListGroup} from "react-bootstrap";
import styles from '../store.module.css'
import {faAngleDown, faFileExcel, faFilePdf, faWindowClose, faMinusCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import {isJanyKitep, langStatus} from '../../../lib/helper'
import {isLibrarian, isDistObserver, isDirector} from "../../../lib/helper";

export const OrderList = () => {
    const filterOrders  = ((order) => {
        if (order === 'district_declined') {
            return <FontAwesomeIcon icon={faWindowClose}
                                                style={{color: 'orangered', fontSize: '25px'}}/>
        } else if (order === 'district_approved') {
            return <FontAwesomeIcon icon={faAngleDown} style={{color: 'forestgreen', fontSize: '25px'}}/>
        } else if (order === 'district_review') {
            return <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'blue', fontSize: '25px'}}/>
        } else {
            return <FontAwesomeIcon icon={faMinusCircle} style={{color: 'orange', fontSize: '25px'}}/>
        }
    })
    const dispatch = useDispatch()
    const balance = useAppSelector((state) => state["bookstore"]["balance"])
    const available_balance = useAppSelector((state) => state["bookstore"]["available_balance"]);
    const actions = {
        fetchOrders: thunks.fetchOrders
    }
    const orders = useAppSelector(
        (state) => state['bookstore']['orders']['results']
    )
    // console.log(orders);
    useEffect(() => {
        dispatch(actions.fetchOrders())
    }, [])
    return (
        <>
            <Layout>
                {(isDistObserver) && <Button variant="primary" className={'my-3'}>
                    <NavLink exact to="/store/orders-shools" className="text-white">
                        <FontAwesomeIcon icon={faBook} className="mx-3"/> Заявки по школам
                    </NavLink>
                </Button>}
                {(isLibrarian && <NavLink
                  exact
                  to="/store"
                  className={styles.links}
                  activeClassName={styles.active}
                >
                    <Button variant="warning" style={{fontSize: '15px', fontWeight: 700}}>
                        В магазин
                    </Button>
                    <br />
                </NavLink>)}
                <table style={{width: "100%", marginTop: '25px'}}>
                    <thead>
                        <tr>
                            <th>№ заявки</th>
                            <th>Учебный год</th>
                            <th>Кол-во учебников</th>
                            <th>Баланс</th>
                            <th>Доступный баланс</th>
                            <th>Сумма заявки</th>
                            <th>Статус</th>
                            <th>Действие</th>
                        </tr>
                    </thead>
                    <tbody>
                    {orders?.map((order) => (
                        <tr key={order['id']}>
                            <td>{order['id']}</td>
                            <td>{order['study_period_start']} - {order['study_period_end']}</td>
                            {/* <td>{order['total_quantity']}</td> */}
                            <td>{order['total_quantity']}</td>
                            {(isDistObserver || isDirector) &&
                                <td>{order['school_balance']} сом</td>
                            }
                            {(!isDistObserver && !isDirector) &&
                                <td>{balance} сом</td>
                            }
                            {(isDistObserver || isDirector) &&
                                <td>{order['school_available_balance']} сом</td>
                            }
                            {(!isDistObserver && !isDirector) &&
                                <td>{available_balance} сом</td>
                            }
                            {/* {(isDistObserver || isDirector) &&
                                <td>{order['total_price']} сом</td>
                            } */}
                            <td>{order['total_price']} сом</td>
                            <td>
                            {/* {order['status']} */}
                                {langStatus(order['status'])}
                                {/* {filterOrders(order['status'])} */}
                            </td>
                            <td>
                                <Link to={{pathname: `/store/orders/${order['id']}`}}>Подробнее</Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Layout>
        </>
    )
}

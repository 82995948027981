import React, { useState, useEffect } from "react";
import { Layout } from "../app/layout";
import { Card, Button } from "react-bootstrap";
import { useAppSelector } from "../hooks";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ButtonC } from "../components";
import HTMLRenderer from "react-html-renderer";
import { useTranslation } from "react-i18next";
import { replaceString } from "../lib/helper";
import "./contacts.css";
import "./news.css";
import { AddNewsLibrarian } from "../feature/news/add-news-librarian";
import { isLibrarian } from "../lib/helper";

export const News = () => {
  // const [show, setShow] = useState(false)
  // const handleShow = () => {
  //   setShow(true);
  // }
  const user = useAppSelector((state) => state.profile.user);
  const { t } = useTranslation();
  const news = useAppSelector((state) => state.news.news);
  return (
    <Layout>
      <h2 className="home-title" style={{ margin: "20px" }}>
        {replaceString(user?.fullname)}
        <br /> {t("home.title")}
      </h2>
      <h3 className="last-news-title">{t("news.last_news")}:</h3>
      {/* <AddNewsLibrarian */}
      {/* show={show} */}
      {/* setShow={setShow} */}
      {/* /> */}
      {/* {isLibrarian &&     <ButtonC variant="add" className="mb-4 ms-auto d-block add-class-btn" onClick={handleShow}> */}
      {/* {t('classes.add-class.btn')} */}
      {/* {t('news.add_news')} */}
      {/* </ButtonC>} */}
      <Card className="my-card">
        {news.length &&
          news.map((post) => (
            <>
              <Card.Header>
                <strong>
                  {new Date(post.publish).toLocaleString("ru-RU", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  })}
                </strong>
              </Card.Header>
              <Card.Body key={post.id}>
                <Card.Title className="my-card-title">
                  <strong>{post?.title}</strong>
                </Card.Title>
                <Card.Text className="my-card-description">
                  <HTMLRenderer html={post?.body.slice(0, 300)} />
                </Card.Text>
                <td>
                  <Link
                    to={{
                      pathname: `/news-list/${post.id}`,
                    }}
                  >
                    <ButtonC variant="primary" className="my-card-btn">
                      {t("news.read_more")}
                    </ButtonC>
                  </Link>
                </td>
              </Card.Body>
            </>
          ))}
      </Card>
    </Layout>
  );
};

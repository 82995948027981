import { faBan, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TableComponent } from "../../../components";
import { storage } from "../../../service";
import { api } from "../../../service/old-api-2";
import Chart from "./Chart";

export const G6ContingentReportRegion = ({ place }) => {
  const [contingentReport, setContingentReport] = useState();
  const [republicContingentReport, setRepublicContingentReport] = useState();
  const [regionContingentReport, setRegionContingentReport] = useState();
  const { t } = useTranslation();
  const getContingentReport = () => {
    if (place === "region") {
      api
        .get(
          `reports/contingent-report/?region_id=${storage.read("region_id")}`
        )
        .then((response) => {
          setContingentReport(response.response_list);
          setRepublicContingentReport(response.republic_set);
        });
    } else if (place === "district") {
      api
        .get(
          `reports/contingent-report/?district_id=${storage.read(
            "district_id"
          )}`
        )
        .then((response) => {
          setContingentReport(response.response_list);
          setRepublicContingentReport(response.republic_set);
          setRegionContingentReport(response.region_set);
        });
    }
  };
  let index = 1;
  let index2 = 1;
  useEffect(() => {
    getContingentReport();
  }, []);
  let summary_man_directors = 0;
  let summary_woman_directors = 0;
  let summary_man_librarians = 0;
  let summary_woman_librarians = 0;
  const summary_ages = {
    "-20 dir": 0,
    "20-30 dir": 0,
    "30-40 dir": 0,
    "40-50 dir": 0,
    "50-60 dir": 0,
    "60-70 dir": 0,
    "70-80 dir": 0,
    "80- dir": 0,
    "-20 lib": 0,
    "20-30 lib": 0,
    "30-40 lib": 0,
    "40-50 lib": 0,
    "50-60 lib": 0,
    "60-70 lib": 0,
    "70-80 lib": 0,
    "80- lib": 0,
  };
  return (
    <div>
      <h2 className="mt-5 mb-3" style={{ textAlign: "center" }}>
        Сортировка пользователей по полу
      </h2>
      <TableComponent>
        <thead>
          <tr>
            <th>№</th>
            <th>{t("profile.region")}</th>
            <th>{t("profile.district")}</th>
            {place === "district" ? <th>Школа</th> : ""}
            <th>Директоры мужчины</th>
            <th>Директоры женщины</th>
            <th>Всего директоров</th>
            <th>Библиотекари мужчины </th>
            <th>Библиотекари женщины </th>
            <th>Всего библиотекарей</th>
            <th>Всего пользователей по району</th>
          </tr>
        </thead>
        <tbody>
          {contingentReport?.map((report) => {
            summary_man_directors += report?.directors_data.sex_count.m;
            summary_woman_directors += report?.directors_data.sex_count.w;
            summary_man_librarians += report?.librarians_data.sex_count.m;
            summary_woman_librarians += report?.librarians_data.sex_count.w;
            return (
              <tr key={report?.district_name}>
                <td>{index++}</td>
                <td>{report?.region_name}</td>
                <td>{report?.district_name}</td>
                {place === "district" ? <td>{report?.school_name}</td> : ""}
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.directors_data.sex_count.m}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.directors_data.sex_count.w}
                </td>
                <td style={{ backgroundColor: "rgba(186, 80, 231, 0.3)" }}>
                  {report?.directors_data.sex_count.m +
                    report?.directors_data.sex_count.w}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.librarians_data.sex_count.m}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.librarians_data.sex_count.w}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "rgba(186, 80, 231, 0.3)",
                  }}
                >
                  {report?.librarians_data.sex_count.m +
                    report?.librarians_data.sex_count.w}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "rgba(231, 226, 80, 0.3)",
                  }}
                >
                  {report?.directors_data.sex_count.m +
                    report?.directors_data.sex_count.w +
                    report?.librarians_data.sex_count.m +
                    report?.librarians_data.sex_count.w}
                </td>
              </tr>
            );
          })}
          <tr
            style={{
              fontWeight: "bold",
              backgroundColor: "rgb(0, 208, 234)",
              color: "rgb(255, 255, 255)",
            }}
          >
            <td colSpan={place ==="district" ? "4": "3"}>
              Всего пользователей по{" "}
              {place === "district" ? "району" : "области"}
            </td>
            <td>{summary_man_directors}</td>
            <td>{summary_woman_directors}</td>
            <td>{summary_man_directors + summary_woman_directors}</td>
            <td>{summary_man_librarians}</td>
            <td>{summary_woman_librarians}</td>
            <td>{summary_man_librarians + summary_woman_librarians}</td>
            <td>
              {summary_man_directors +
                summary_woman_directors +
                summary_man_librarians +
                summary_woman_librarians}
            </td>
          </tr>
          {regionContingentReport ? (
            <tr
              style={{
                fontWeight: "bold",
                backgroundColor: "rgba(43, 191, 61, 0.2)",
              }}
            >
             <td colSpan={place ==="district" ? "4": "3"}>
                Всего пользователей по области
                </td>
              <td>{regionContingentReport?.directors_data?.sex_count.m}</td>
              <td>{regionContingentReport?.directors_data.sex_count.w}</td>
              <td>
                {regionContingentReport?.directors_data.sex_count.m +
                  regionContingentReport?.directors_data.sex_count.w}
              </td>
              <td>{regionContingentReport?.librarians_data.sex_count.m}</td>
              <td>{regionContingentReport?.librarians_data.sex_count.w}</td>
              <td>
                {regionContingentReport?.librarians_data.sex_count.m +
                  regionContingentReport?.librarians_data.sex_count.w}
              </td>
              <td>
                {regionContingentReport?.directors_data.sex_count.m +
                  regionContingentReport?.directors_data.sex_count.w +
                  regionContingentReport?.librarians_data.sex_count.m +
                  regionContingentReport?.librarians_data.sex_count.w}
              </td>
            </tr>
          ) : (
            ""
          )}
          <tr
            style={{
              fontWeight: "bold",
              backgroundColor: "rgba(43, 191, 61, 0.2)",
            }}
          >
              <td colSpan={place ==="district" ? "4": "3"}>
              Всего пользователей по Республике
              </td>
            <td>{republicContingentReport?.directors_data?.sex_count.m}</td>
            <td>{republicContingentReport?.directors_data.sex_count.w}</td>
            <td>
              {republicContingentReport?.directors_data.sex_count.m +
                republicContingentReport?.directors_data.sex_count.w}
            </td>
            <td>{republicContingentReport?.librarians_data.sex_count.m}</td>
            <td>{republicContingentReport?.librarians_data.sex_count.w}</td>
            <td>
              {republicContingentReport?.librarians_data.sex_count.m +
                republicContingentReport?.librarians_data.sex_count.w}
            </td>
            <td>
              {republicContingentReport?.directors_data.sex_count.m +
                republicContingentReport?.directors_data.sex_count.w +
                republicContingentReport?.librarians_data.sex_count.m +
                republicContingentReport?.librarians_data.sex_count.w}
            </td>
          </tr>
        </tbody>
      </TableComponent>
      <h2 className="mt-5 mb-3" style={{ textAlign: "center" }}>
        Сортировка пользователей по годам
      </h2>
      <TableComponent>
        <thead>
          <tr>
            <th rowSpan="2" style={{ verticalAlign: "middle" }}>
              №
            </th>
            <th rowSpan="2" style={{ verticalAlign: "middle" }}>
              {t("profile.region")}
            </th>
            <th rowSpan="2" style={{ verticalAlign: "middle" }}>
              {t("profile.district")}
            </th>
            {place === "district" ? (
              <th rowSpan="2" style={{ verticalAlign: "middle" }}>
                Школа
              </th>
            ) : (
              ""
            )}
            <th colSpan="9">Директоры</th>
            <th colSpan="9">Библиотекари</th>
            <th rowSpan="2" style={{ verticalAlign: "middle" }}>
              Всего
            </th>
          </tr>
          <tr>
            <th> -20</th>
            <th>20-30</th>
            <th>30-40</th>
            <th>40-50</th>
            <th>50-60 </th>
            <th>60-70 </th>
            <th>70-80</th>
            <th>80-</th>
            <th>Всего директоров</th>
            <th> -20</th>
            <th>20-30</th>
            <th>30-40</th>
            <th>40-50</th>
            <th>50-60 </th>
            <th>60-70 </th>
            <th>70-80</th>
            <th>80-</th>
            <th>Всего библиотекарей</th>
          </tr>
        </thead>
        <tbody>
          {contingentReport?.map((report) => {
            summary_ages["-20 dir"] +=
              report?.directors_data.age_count["10-20"];
            summary_ages["20-30 dir"] +=
              report?.directors_data.age_count["20-30"];
            summary_ages["30-40 dir"] +=
              report?.directors_data.age_count["30-40"];
            summary_ages["40-50 dir"] +=
              report?.directors_data.age_count["40-50"];
            summary_ages["50-60 dir"] +=
              report?.directors_data.age_count["50-60"];
            summary_ages["60-70 dir"] +=
              report?.directors_data.age_count["60-70"];
            summary_ages["70-80 dir"] +=
              report?.directors_data.age_count["70-80"];
            summary_ages["80- dir"] +=
              report?.directors_data.age_count["80-90"];
            summary_ages["-20 lib"] +=
              report?.librarians_data.age_count["10-20"];
            summary_ages["20-30 lib"] +=
              report?.librarians_data.age_count["20-30"];
            summary_ages["30-40 lib"] +=
              report?.librarians_data.age_count["30-40"];
            summary_ages["40-50 lib"] +=
              report?.librarians_data.age_count["40-50"];
            summary_ages["50-60 lib"] +=
              report?.librarians_data.age_count["50-60"];
            summary_ages["60-70 lib"] +=
              report?.librarians_data.age_count["60-70"];
            summary_ages["70-80 lib"] +=
              report?.librarians_data.age_count["70-80"];
            summary_ages["80- lib"] +=
              report?.librarians_data.age_count["80-90"];
            return (
              <tr key={report?.district_name}>
                <td>{index2++}</td>
                <td>{report?.region_name}</td>
                <td>{report?.district_name}</td>
                {place === "district" ? <td>{report?.school_name}</td> : ""}
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.directors_data.age_count["10-20"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.directors_data.age_count["20-30"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.directors_data.age_count["30-40"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.directors_data.age_count["40-50"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.directors_data.age_count["50-60"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.directors_data.age_count["60-70"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.directors_data.age_count["70-80"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.directors_data.age_count["80-90"]}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "rgba(186, 80, 231, 0.3)",
                  }}
                >
                  {report?.directors_data.age_count["10-20"] +
                    report?.directors_data.age_count["20-30"] +
                    report?.directors_data.age_count["30-40"] +
                    report?.directors_data.age_count["40-50"] +
                    report?.directors_data.age_count["50-60"] +
                    report?.directors_data.age_count["60-70"] +
                    report?.directors_data.age_count["70-80"] +
                    report?.directors_data.age_count["80-90"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.librarians_data.age_count["10-20"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.librarians_data.age_count["20-30"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.librarians_data.age_count["30-40"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.librarians_data.age_count["40-50"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.librarians_data.age_count["50-60"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.librarians_data.age_count["60-70"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.librarians_data.age_count["70-80"]}
                </td>
                <td style={{ backgroundColor: "rgba(43, 191, 61, 0.2)" }}>
                  {report?.librarians_data.age_count["80-90"]}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "rgba(186, 80, 231, 0.3)",
                  }}
                >
                  {report?.librarians_data.age_count["10-20"] +
                    report?.librarians_data.age_count["20-30"] +
                    report?.librarians_data.age_count["30-40"] +
                    report?.librarians_data.age_count["40-50"] +
                    report?.librarians_data.age_count["50-60"] +
                    report?.librarians_data.age_count["60-70"] +
                    report?.librarians_data.age_count["70-80"] +
                    report?.librarians_data.age_count["80-90"]}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "rgba(231, 226, 80, 0.3)",
                  }}
                >
                  {report?.directors_data.age_count["10-20"] +
                    report?.directors_data.age_count["20-30"] +
                    report?.directors_data.age_count["30-40"] +
                    report?.directors_data.age_count["40-50"] +
                    report?.directors_data.age_count["50-60"] +
                    report?.directors_data.age_count["60-70"] +
                    report?.directors_data.age_count["70-80"] +
                    report?.directors_data.age_count["80-90"] +
                    report?.librarians_data.age_count["10-20"] +
                    report?.librarians_data.age_count["20-30"] +
                    report?.librarians_data.age_count["30-40"] +
                    report?.librarians_data.age_count["40-50"] +
                    report?.librarians_data.age_count["50-60"] +
                    report?.librarians_data.age_count["60-70"] +
                    report?.librarians_data.age_count["70-80"] +
                    report?.librarians_data.age_count["80-90"]}
                </td>
              </tr>
            );
          })}
          <tr
            style={{
              fontWeight: "bold",
              backgroundColor: "rgb(0, 208, 234)",
              color: "rgb(255, 255, 255)",
            }}
          >
           <td colSpan={place ==="district" ? "4": "3"}>
              Всего пользователей по{" "}
              {place === "district" ? "району" : "области"}
            </td>
            <td>{summary_ages["-20 dir"]}</td>
            <td>{summary_ages["20-30 dir"]}</td>
            <td>{summary_ages["30-40 dir"]}</td>
            <td>{summary_ages["40-50 dir"]}</td>
            <td>{summary_ages["50-60 dir"]}</td>
            <td>{summary_ages["60-70 dir"]}</td>
            <td>{summary_ages["70-80 dir"]}</td>
            <td>{summary_ages["80- dir"]}</td>
            <td>
              {summary_ages["-20 dir"] +
                summary_ages["20-30 dir"] +
                summary_ages["30-40 dir"] +
                summary_ages["40-50 dir"] +
                summary_ages["50-60 dir"] +
                summary_ages["60-70 dir"] +
                summary_ages["70-80 dir"] +
                summary_ages["80- dir"]}
            </td>
            <td>{summary_ages["-20 lib"]}</td>
            <td>{summary_ages["20-30 lib"]}</td>
            <td>{summary_ages["30-40 lib"]}</td>
            <td>{summary_ages["40-50 lib"]}</td>
            <td>{summary_ages["50-60 lib"]}</td>
            <td>{summary_ages["60-70 lib"]}</td>
            <td>{summary_ages["70-80 lib"]}</td>
            <td>{summary_ages["80- lib"]}</td>
            <td>
              {summary_ages["-20 lib"] +
                summary_ages["20-30 lib"] +
                summary_ages["30-40 lib"] +
                summary_ages["40-50 lib"] +
                summary_ages["50-60 lib"] +
                summary_ages["60-70 lib"] +
                summary_ages["70-80 lib"] +
                summary_ages["80- lib"]}
            </td>
            <td>
              {summary_ages["-20 dir"] +
                summary_ages["20-30 dir"] +
                summary_ages["30-40 dir"] +
                summary_ages["40-50 dir"] +
                summary_ages["50-60 dir"] +
                summary_ages["60-70 dir"] +
                summary_ages["70-80 dir"] +
                summary_ages["80- dir"] +
                summary_ages["-20 lib"] +
                summary_ages["20-30 lib"] +
                summary_ages["30-40 lib"] +
                summary_ages["40-50 lib"] +
                summary_ages["50-60 lib"] +
                summary_ages["60-70 lib"] +
                summary_ages["70-80 lib"] +
                summary_ages["80- lib"]}
            </td>
          </tr>
          {regionContingentReport ? (
            <tr
              style={{
                fontWeight: "bold",
                backgroundColor: "rgba(43, 191, 61, 0.2)",
              }}
            >
           <td colSpan={place ==="district" ? "4": "3"}>
                Всего пользователей по области
                </td>
              <td>
                {regionContingentReport?.directors_data.age_count["10-20"]}
              </td>
              <td>
                {regionContingentReport?.directors_data.age_count["20-30"]}
              </td>
              <td>
                {regionContingentReport?.directors_data.age_count["30-40"]}
              </td>
              <td>
                {regionContingentReport?.directors_data.age_count["40-50"]}
              </td>
              <td>
                {regionContingentReport?.directors_data.age_count["50-60"]}
              </td>
              <td>
                {regionContingentReport?.directors_data.age_count["60-70"]}
              </td>
              <td>
                {regionContingentReport?.directors_data.age_count["70-80"]}
              </td>
              <td>
                {regionContingentReport?.directors_data.age_count["80-90"]}
              </td>
              <td>
                {regionContingentReport?.directors_data.age_count["10-20"] +
                  regionContingentReport?.directors_data.age_count["20-30"] +
                  regionContingentReport?.directors_data.age_count["30-40"] +
                  regionContingentReport?.directors_data.age_count["40-50"] +
                  regionContingentReport?.directors_data.age_count["50-60"] +
                  regionContingentReport?.directors_data.age_count["60-70"] +
                  regionContingentReport?.directors_data.age_count["70-80"] +
                  regionContingentReport?.directors_data.age_count["80-90"]}
              </td>
              <td>
                {regionContingentReport?.librarians_data.age_count["10-20"]}
              </td>
              <td>
                {regionContingentReport?.librarians_data.age_count["20-30"]}
              </td>
              <td>
                {regionContingentReport?.librarians_data.age_count["30-40"]}
              </td>
              <td>
                {regionContingentReport?.librarians_data.age_count["40-50"]}
              </td>
              <td>
                {regionContingentReport?.librarians_data.age_count["50-60"]}
              </td>
              <td>
                {regionContingentReport?.librarians_data.age_count["60-70"]}
              </td>
              <td>
                {regionContingentReport?.librarians_data.age_count["70-80"]}
              </td>
              <td>
                {regionContingentReport?.librarians_data.age_count["80-90"]}
              </td>
              <td>
                {regionContingentReport?.librarians_data.age_count["10-20"] +
                  regionContingentReport?.librarians_data.age_count["20-30"] +
                  regionContingentReport?.librarians_data.age_count["30-40"] +
                  regionContingentReport?.librarians_data.age_count["40-50"] +
                  regionContingentReport?.librarians_data.age_count["50-60"] +
                  regionContingentReport?.librarians_data.age_count["60-70"] +
                  regionContingentReport?.librarians_data.age_count["70-80"] +
                  regionContingentReport?.librarians_data.age_count["80-90"]}
              </td>
              <td>
                {regionContingentReport?.directors_data.age_count["10-20"] +
                  regionContingentReport?.directors_data.age_count["20-30"] +
                  regionContingentReport?.directors_data.age_count["30-40"] +
                  regionContingentReport?.directors_data.age_count["40-50"] +
                  regionContingentReport?.directors_data.age_count["50-60"] +
                  regionContingentReport?.directors_data.age_count["60-70"] +
                  regionContingentReport?.directors_data.age_count["70-80"] +
                  regionContingentReport?.directors_data.age_count["80-90"] +
                  regionContingentReport?.librarians_data.age_count["10-20"] +
                  regionContingentReport?.librarians_data.age_count["20-30"] +
                  regionContingentReport?.librarians_data.age_count["30-40"] +
                  regionContingentReport?.librarians_data.age_count["40-50"] +
                  regionContingentReport?.librarians_data.age_count["50-60"] +
                  regionContingentReport?.librarians_data.age_count["60-70"] +
                  regionContingentReport?.librarians_data.age_count["70-80"] +
                  regionContingentReport?.librarians_data.age_count["80-90"]}
              </td>
            </tr>
          ) : (
            ""
          )}
          <tr
            style={{
              fontWeight: "bold",
              backgroundColor: "rgba(43, 191, 61, 0.2)",
            }}
          >
           <td colSpan={place ==="district" ? "4": "3"}>
              Всего пользователей по Республике
            </td>
            <td>
              {republicContingentReport?.directors_data.age_count["10-20"]}
            </td>
            <td>
              {republicContingentReport?.directors_data.age_count["20-30"]}
            </td>
            <td>
              {republicContingentReport?.directors_data.age_count["30-40"]}
            </td>
            <td>
              {republicContingentReport?.directors_data.age_count["40-50"]}
            </td>
            <td>
              {republicContingentReport?.directors_data.age_count["50-60"]}
            </td>
            <td>
              {republicContingentReport?.directors_data.age_count["60-70"]}
            </td>
            <td>
              {republicContingentReport?.directors_data.age_count["70-80"]}
            </td>
            <td>
              {republicContingentReport?.directors_data.age_count["80-90"]}
            </td>
            <td>
              {republicContingentReport?.directors_data.age_count["10-20"] +
                republicContingentReport?.directors_data.age_count["20-30"] +
                republicContingentReport?.directors_data.age_count["30-40"] +
                republicContingentReport?.directors_data.age_count["40-50"] +
                republicContingentReport?.directors_data.age_count["50-60"] +
                republicContingentReport?.directors_data.age_count["60-70"] +
                republicContingentReport?.directors_data.age_count["70-80"] +
                republicContingentReport?.directors_data.age_count["80-90"]}
            </td>
            <td>
              {republicContingentReport?.librarians_data.age_count["10-20"]}
            </td>
            <td>
              {republicContingentReport?.librarians_data.age_count["20-30"]}
            </td>
            <td>
              {republicContingentReport?.librarians_data.age_count["30-40"]}
            </td>
            <td>
              {republicContingentReport?.librarians_data.age_count["40-50"]}
            </td>
            <td>
              {republicContingentReport?.librarians_data.age_count["50-60"]}
            </td>
            <td>
              {republicContingentReport?.librarians_data.age_count["60-70"]}
            </td>
            <td>
              {republicContingentReport?.librarians_data.age_count["70-80"]}
            </td>
            <td>
              {republicContingentReport?.librarians_data.age_count["80-90"]}
            </td>
            <td>
              {republicContingentReport?.librarians_data.age_count["10-20"] +
                republicContingentReport?.librarians_data.age_count["20-30"] +
                republicContingentReport?.librarians_data.age_count["30-40"] +
                republicContingentReport?.librarians_data.age_count["40-50"] +
                republicContingentReport?.librarians_data.age_count["50-60"] +
                republicContingentReport?.librarians_data.age_count["60-70"] +
                republicContingentReport?.librarians_data.age_count["70-80"] +
                republicContingentReport?.librarians_data.age_count["80-90"]}
            </td>
            <td>
              {republicContingentReport?.directors_data.age_count["10-20"] +
                republicContingentReport?.directors_data.age_count["20-30"] +
                republicContingentReport?.directors_data.age_count["30-40"] +
                republicContingentReport?.directors_data.age_count["40-50"] +
                republicContingentReport?.directors_data.age_count["50-60"] +
                republicContingentReport?.directors_data.age_count["60-70"] +
                republicContingentReport?.directors_data.age_count["70-80"] +
                republicContingentReport?.directors_data.age_count["80-90"] +
                republicContingentReport?.librarians_data.age_count["10-20"] +
                republicContingentReport?.librarians_data.age_count["20-30"] +
                republicContingentReport?.librarians_data.age_count["30-40"] +
                republicContingentReport?.librarians_data.age_count["40-50"] +
                republicContingentReport?.librarians_data.age_count["50-60"] +
                republicContingentReport?.librarians_data.age_count["60-70"] +
                republicContingentReport?.librarians_data.age_count["70-80"] +
                republicContingentReport?.librarians_data.age_count["80-90"]}
            </td>
          </tr>
        </tbody>
      </TableComponent>
      <div className="d-flex justify-content-center">
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            width: "33%",
            height: "370px",
            padding: "50px",
            textAlign: "center",
          }}
        >
          <h5>Соотношение мужчин и женщин по {place === "district" ? "району" : "области"}</h5>
          <div
            style={{
              height: "80%",
              width: "100%",
              textAlign: "-webkit-center",
            }}
          >
            <Chart
              labels={["Мужчин", "Женщин"]}
              datas={[
                summary_man_directors + summary_man_librarians,
                summary_woman_directors + summary_woman_librarians,
              ]}
              backgroundColors={[
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
              ]}
              borderColors={["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"]}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            width: "33%",
            height: "370px",
            padding: "50px",
            textAlign: "center",
          }}
        >
          <h5>Соотношение мужчин и женщин по республике</h5>
          <div
            style={{
              height: "80%",
              width: "100%",
              textAlign: "-webkit-center",
            }}
          >
            <Chart
              labels={["Мужчин", "Женщин"]}
              datas={[
                republicContingentReport?.directors_data.sex_count.m +
                  republicContingentReport?.librarians_data.sex_count.m,
                republicContingentReport?.directors_data.sex_count.w +
                  republicContingentReport?.librarians_data.sex_count.w,
              ]}
              backgroundColors={[
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
              ]}
              borderColors={["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"]}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            width: "50%",
            height: "600px",
            padding: "50px",
            textAlign: "center",
          }}
        >
          <h5>Соотношение возрастов по {place === "district" ? "району" : "области"}</h5>
          <div
            style={{
              height: "80%",
              width: "100%",
              textAlign: "-webkit-center",
            }}
          >
            <Chart
              labels={[
                "До 20",
                "20-30",
                "30-40",
                "40-50",
                "50-60",
                "60-70",
                "70-80",
                "После 80",
              ]}
              datas={[
                summary_ages["-20 dir"] + summary_ages["-20 lib"],
                summary_ages["20-30 dir"] + summary_ages["20-30 lib"],
                summary_ages["30-40 dir"] + summary_ages["30-40 lib"],
                summary_ages["40-50 dir"] + summary_ages["40-50 lib"],
                summary_ages["50-60 dir"] + summary_ages["50-60 lib"],
                summary_ages["60-70 dir"] + summary_ages["60-70 lib"],
                summary_ages["70-80 dir"] + summary_ages["70-80 lib"],
                summary_ages["80- dir"] + summary_ages["80- lib"],
              ]}
              backgroundColors={[
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(147, 119, 33, 0.2)",
                "rgba(183, 100, 33, 0.2)",
              ]}
              borderColors={[
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(147, 119, 33, 1)",
                "rgba(183, 100, 33, 1)",
              ]}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            width: "50%",
            height: "600px",
            padding: "50px",
            textAlign: "center",
          }}
        >
          <h5>Соотношение возрастов по республике</h5>
          <div
            style={{
              height: "80%",
              width: "100%",
              textAlign: "-webkit-center",
            }}
          >
            <Chart
              labels={[
                "До 20",
                "20-30",
                "30-40",
                "40-50",
                "50-60",
                "60-70",
                "70-80",
                "После 80",
              ]}
              datas={[
                republicContingentReport?.directors_data.age_count["10-20"] +
                  republicContingentReport?.librarians_data.age_count["10-20"],
                republicContingentReport?.directors_data.age_count["20-30"] +
                  republicContingentReport?.librarians_data.age_count["20-30"],
                republicContingentReport?.directors_data.age_count["30-40"] +
                  republicContingentReport?.librarians_data.age_count["30-40"],
                republicContingentReport?.directors_data.age_count["40-50"] +
                  republicContingentReport?.librarians_data.age_count["40-50"],
                republicContingentReport?.directors_data.age_count["50-60"] +
                  republicContingentReport?.librarians_data.age_count["50-60"],
                republicContingentReport?.directors_data.age_count["60-70"] +
                  republicContingentReport?.librarians_data.age_count["60-70"],
                republicContingentReport?.directors_data.age_count["70-80"] +
                  republicContingentReport?.librarians_data.age_count["70-80"],
                republicContingentReport?.directors_data.age_count["80-90"] +
                  republicContingentReport?.librarians_data.age_count["80-90"],
              ]}
              backgroundColors={[
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(147, 119, 33, 0.2)",
                "rgba(183, 100, 33, 0.2)",
              ]}
              borderColors={[
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(147, 119, 33, 1)",
                "rgba(183, 100, 33, 1)",
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import {useTranslation} from 'react-i18next'
import {storage} from '../../service'
import styles from './pdf-header.module.css'
import { format } from 'date-fns'
export const PdfHeaderToOrderDistrict = ({title, children, order}) => {
	const today = new Date(Date.now()).toLocaleDateString()
	const {t} = useTranslation()

	const ordersDate = new Date(Date(order?.ordered_date))
	const year = ordersDate.toLocaleString("default", { year: "numeric" });
	const month = ordersDate.toLocaleString("default", { month: "2-digit" });
	const day = ordersDate.toLocaleString("default", { day: "2-digit" });
	const formattedDate = day + "-" + month + '-' + year;
	return (
		<thead className={styles.print}>
			<tr>
				<th colSpan='12' className={styles.title}><h3>{title}</h3><span>от {formattedDate} на {parseInt(order?.study_period_start) + 1} - {parseInt(order?.study_period_end) + 1} учебный год</span></th>
			</tr>
			<tr>
				<th colSpan='12'><p>{t('print.region')}: {storage.read('region')}, {storage.read('district')}</p></th>
			</tr>
			<tr>
				<th colSpan='12'><p>{t('print.district_id')}: {storage.read('district_id')}</p></th>
			</tr>
			{/* {children} */}
			<tr>
				<th colSpan='12'><p>{t('print.date')}: {today}</p></th>
			</tr>
		</thead>
	)
}
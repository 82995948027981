import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import {useState} from "react"
import {Button, Form} from "react-bootstrap"
import {useForm} from "react-hook-form"
import {Link} from "react-router-dom"

import {InputField} from "../../components"
import {useActions, useAppSelector} from '../../hooks'
import {thunks} from './model'

const actions = {signIn: thunks.signIn}

export const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false)
  const {handleSubmit, formState: {errors}, control} = useForm()
  const {signIn} = useActions(actions)
  const submitError = useAppSelector((state) => state.profile.error)
  return (
    <Form onSubmit={handleSubmit(signIn)}>
      <InputField
        control={control}
        name='pin_code'
        rules={{required: 'Заполните поле!'}}
        type="text"
        placeholder="Логин"
        label='Логин'
        errors={errors.pin_code}
      />
      <InputField
        label='Пароль'
        type={showPassword ? 'text' : 'password'}
        control={control}
        rules={{required: 'Заполните поле!'}}
        name='password'
        placeholder="Пароль"
        icon={showPassword ? faEye : faEyeSlash}
        iconClick={() => setShowPassword(!showPassword)}
        errors={errors.password}
      />
      {submitError && <Form.Label style={{color: 'red'}}>{submitError}</Form.Label>}
      <Button variant="primary" type="submit" className="w-50 mb-3 d-block ms-auto me-auto">
        Войти
      </Button>

      {/* <Link to='/restore-password' className="text-center d-block w-100">
        Забыли пароль?
      </Link> */}
    </Form>
  )
}

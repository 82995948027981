import styles from "../../components/oneBook/oneBook.module.css";
import bookImg from "../../img/placeholder.png";
import { Button, Col, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { addBook, updateTotalPrice } from "../../app/store/bookstore-reducer";
import { useDispatch } from "react-redux";
import { notifySuccess } from "../../components/notify";
import { useAppSelector } from "../../hooks";
import {useTranslation} from "react-i18next";
import {ButtonC} from "../../components";


export const BookSingle = ({ book, canAddBookToCart, cartItems }) => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const [canAdd, setCanAdd] = useState(true);
  const [show, setShow] = useState(false)
  const grades = useAppSelector(
    (state) => state["bookstore"]["studentsCountByGrade"]
  );
  const confirmedAdd = () => {
    dispatch(addBook(book));
    dispatch(updateTotalPrice());
    setCanAdd(false);
    notifySuccess("Учебник добавлен в корзину");
    setShow(false)
  }
  const handleClick = () => {
    let language_exists = false
    grades.forEach((grade) => {
      if (
        book.grades.includes(grade.grade) &&
        book.language === grade.language
      ) {
        language_exists = true
      }
    });
    if (language_exists) {
      dispatch(addBook(book));
      dispatch(updateTotalPrice());
      setCanAdd(false);
      notifySuccess("Учебник добавлен в корзину");
    } else {
      setShow(true)
    }
  };
  useEffect(() => {
    const isBookFound = cartItems.find((cartItem) => cartItem.id === book.id);
    if (isBookFound) {
      setCanAdd(false);
    }
  }, []);
  return (
    <>
      <Col sm={1} lg={3} md={5} className="my-3">
        <div className="flex flex-column h-100 card">
          <div className={`${styles.imgWrap} my-4 ms-auto me-auto`}>
            { book.image && <img src={book.image} alt="1" />}
            { !book.image && <img src={bookImg} alt="1" />}
          </div>
          <div className="px-5">
            <h4 className="mb-3 me-auto text-center">{book.name}</h4>
            <p className="mb-1">Класс: {book.grades.join(",")}</p>
            <p className="mb-1">Язык обучения: {book.language}</p>
            <p className="mb-4">Автор: {book.author}</p>
            <p className="mb-1">Цена: {book.price}</p>
          </div>
          <Button
            variant="primary d-block fs-5 ms-auto me-auto mt-auto mb-3"
            onClick={handleClick}
            disabled={!canAddBookToCart || !canAdd}
          >
            Добавить в корзину
          </Button>
        </div>
      </Col>
      <Modal
        show={show}
        onHide={function() {
          setShow(false)
          }}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{t('shop.modal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('shop.modal.body1')}</p><p>{t('shop.modal.body2')}</p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonC variant="close" onClick={function() {
          setShow(false)
          }}>
            {t('btn.close')}
          </ButtonC>
          <ButtonC variant="add" onClick={confirmedAdd}>{t('btn.add')}</ButtonC>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import {Modal, Form, Col, Row} from "react-bootstrap"
import {Controller, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {ButtonC, InputField, PhoneField} from "../../components"
import {notifyError, notifySuccess} from "../../components/notify"
import {useActions} from '../../hooks'
import {thunks} from './model'
import styles from './teachers.module.css'
const actions = {
  addTeacher: thunks.addTeacher
}

export const AddTeacher = ({show, setShow}) => {
  const {t} = useTranslation()
  const {addTeacher} = useActions(actions)
  const {handleSubmit, reset, formState: {errors}, control} = useForm()

  const postTeacher = async (data) => {
    if (data.email === '') {
      delete data.email
    }
    if (!(data.pin.toString().startsWith('1') || data.pin.toString().startsWith('2'))) {
      notifyError('Первый символ ИННа должен быть либо 1, либо 2')
      return
    }
    data = {...data, phone: data.phone?.replace(/\D/g, "")}
    const a = await addTeacher(data)
    if (a.payload) {
      notifySuccess(`${t('notify.success.added')}`)
      reset()
      setShow(false)
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={setShow}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{t('classes.add-teacher.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(postTeacher)}>
            <Col xs={12}>
              <InputField
                type='text'
                placeholder="Иванова А.А"
                control={control}
                name='fullname'
                rules={{required: {value: true, message: t('error_message.no_empty')}}}
                label={t('classes.add-teacher.fullname')}
                errors={errors.fullname}
                className={`mb-3 ${styles.formGroup}`}
              />
            </Col>
            <Col xs={12}>
              <InputField
                type="email"
                placeholder="exp@gmail.com"
                rules={{pattern: {value: /\S+@\S+\.\S+\S+/, message: t('classes.add-teacher.email_error')}}}
                name='email'
                label='E-mail'
                control={control}
                errors={errors.email}
                className={`mb-3 ${styles.formFullWidht}`}
              />
            </Col>
            <Row xs={1} md={2}>
              <Col>
                <InputField
                  type="number"
                  placeholder="0010119000000"
                  rules={{
                    required: t('error_message.no_empty'),
                    maxLength: {value: 14, message: t('classes.add-teacher.pin_error')},
                    minLength: {value: 14, message: t('classes.add-teacher.pin_error')}
                  }}
                  name='pin'
                  label={t('classes.add-teacher.pin')}
                  control={control}
                  errors={errors.pin}
                />
              </Col>
              <Col>
              <InputField
                  type="number"
                  placeholder="996559232323"
                  name='phone'
                  label={t('classes.add-teacher.phone')}
                  control={control}
                  errors={errors.phone}
                />
              </Col>
            </Row>
            <Col className='moduleBtns'>
              <ButtonC variant="close" onClick={() => {
                setShow(false)
                reset()
              }}>
                {t('btn.close')}
              </ButtonC>
              <ButtonC variant="add" type='submit'>{t('btn.add')}</ButtonC>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
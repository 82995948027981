import {Table} from "react-bootstrap"
import styles from './table.module.css'
import cn from 'classnames'
import {forwardRef} from "react"

export const TableComponent = forwardRef(({className, children, striped = true, ...props}, ref) => {
	return (
		<Table
			bordered
			className={cn(styles.table, className)}
			responsive="sm"
			ref={ref}
			{...props}
		>
			{children}
		</Table>
	)
})
import { useTranslation } from "react-i18next";
import {
  ButtonC,
  DeleteNotifyWithRedux,
  TableComponent,
} from "../../components";
import { useActions, useAppSelector } from "../../hooks";
import { thunks } from "./school-news-reducer";
import { thunks as eventThunks } from "../school-events/school-events-reducer";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isLibrarian } from "../../lib/helper";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { UpdateSchoolEvent, UpdateSchoolNews } from "./update-school-news";
import { storage } from "../../service";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import HTMLRenderer from "react-html-renderer";

const actions = {
  fetchSchoolNews: thunks.fetchSchoolNews,
  deleteSchoolNews: thunks.deleteSchoolNews,
  fetchSchoolEvents: eventThunks.fetchSchoolEvents
};

export const SchoolNewsList = (props) => {
  const { fetchSchoolNews, deleteSchoolNews } = useActions(actions);
  const { school_news } = props;
  const { t } = useTranslation();
  const [query, setQuery] = useState({});
  const [deleteSchoolNewss, setdeleteSchoolNews] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [editValue, setEditValue] = useState({});
  const language = storage.read("lang");
  const { fetchSchoolEvents } = useActions(actions);

  const handleClose = () => {
    setEditValue({});
    setShow(false);
  };
  const handleShow = async (id) => {
    setEditValue(school_news.filter((c) => c.id == id)[0]);
    setShow(true);
  };
  const handleDeleteSchoolNews = async (school_news) => {
    setShowDelete(true);
    setdeleteSchoolNews(school_news);
  };
  const get_status = (status) => {
    switch (status) {
      case 'published':
        return <>{t("news.published")}</>
      case 'draft':
        return <>{t("news.draft")}</>
      case 'declined':
        return <>{t("news.declined")}</>
    }
  }
  const tr =
    school_news?.length > 0 &&
    school_news.map((el, key) => (
      <tr key={key}>
        {/* <td>{el.publish.split('T')[0]}</td> */}
        <td>{el.title}</td>
        <td><HTMLRenderer html={el?.body} /></td>
        <td>{el.event_name?el.event_name:'-'}</td>
        <td>
          {get_status(el.status)}
        </td>
        <td>
          {el.created.split('T')[0]} / <br />
          {el.updated.split('T')[0]}
        </td>
        {isLibrarian && (
          <td className="d-flex justify-content-around">
            <div>
              <ButtonC variant="change" onClick={() => handleShow(el.id)}>
                {t("classes.class-detail.update")}
              </ButtonC>
              {
                <ButtonC
                  variant="delete"
                  onClick={() => handleDeleteSchoolNews(el)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ButtonC>
              }
            </div>
          </td>
        )}
      </tr>
    ));
  // useEffect(() => {
  //   fetchSchoolNews();
  //   // fetchSchoolEvents();
  // }, [school_news]);
  useEffect(() => {
    fetchSchoolEvents();
  }, [school_news]);

  return (
    <>
      <UpdateSchoolNews
        show={show}
        handleClose={handleClose}
        editValue={editValue}
        setShow={setShow}
      />
      <DeleteNotifyWithRedux
        message={deleteSchoolNewss.name}
        func={deleteSchoolNews}
        id={deleteSchoolNewss.id}
        show={showDelete}
        setShow={setShowDelete}
      />
      <TableComponent>
        <thead>
          <tr>
            {/* <th scope="col">Дата</th> */}
            <th scope="col">{t("news.news-title")}</th>
            <th scope="col">{t("news.news-body")}</th>
            <th scope="col">{t("news.event")}</th>
            <th scope="col">{t("news.status")}</th>
            <th scope="col">
              {t("events.field-create")} / <br />
              {t("events.field-update")}
            </th>
            {isLibrarian && (
              <th scope="col">{t("classes.class-detail.action")}</th>
            )}
          </tr>
        </thead>
        <tbody>{tr}</tbody>
      </TableComponent>
    </>
  );
};

import {faFileCsv, faFileExcel} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import {ButtonC} from '../../components'
import {useFileDownload} from '../../hooks'
import styles from './report.module.css'
import { useState } from 'react'

export const D10DownloadFile = ({url, query}) => {
	const {t} = useTranslation()
	const [loading, setLoading] = useState(false);
	const excel = useFileDownload(`${url}?`, {...query}, 'application/ms-excel')
	const csv = useFileDownload(`${url}?`, {...query}, 'text/csv')

	const handleDownload = async () => {
		setLoading(true);
		try {
			await excel.download()
		} catch (error) {
		    console.error('Error downloading file', error);
		} finally {
		    setLoading(false);
		}
	}
	return (
		<div className={styles.download} id='btns'>
			<ButtonC variant="close"  onClick={handleDownload}>
			{loading ? (<>
                <span style={{marginRight: 10}} className="spinner-border text-success spinner-border-sm" aria-hidden="true"></span>
                <span>Загрузка...</span>
                </>
                ) : ( <>
				<FontAwesomeIcon style={{color: 'green'}} icon={faFileExcel} />
				{t('report.download_excel_file')}
				</>)}
			</ButtonC>
			{/* <ButtonC variant='download' onClick={handlePrint}>
		PDF <FontAwesomeIcon style={{color: 'red'}} icon={faFilePdf} />
	</ButtonC> */}
		</div>
	)
}

import {matchPath} from "react-router"
import {routeArray, routes} from "./routes"

const matches = (url, path) => {
  return matchPath(url, {path, strict: true, exact: true}) != null
}

export const hasMatches = (url, userRole) => {
  return routes.has(url) ? true : routeArray.some((r) => matches(url, r.path))
}

export const hasAccess = (url, userRole) => {
  const checkRoute = (route) => {
    return route?.roles?.some((item) => userRole === item)
  }
  return routes.has(url) ?
    checkRoute(routes.get(url)) :
    routeArray.some((r) => checkRoute(r) && matches(url, r.path))
}
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { ButtonC, PdfHeader, TableComponent } from '../../../components';
import { transLangFromEng } from '../../../lib/helper';
import { storage } from '../../../service';
import { api } from '../../../service/old-api-2'

export const G4RbSchool = () => {
  const school_id = storage.read('school_id');
  const [recommendedBook, setRecommendedBook] = useState()
  const getRecommendedBooks = () => {
    api.get(`reports/recommended-books/?school_id=${school_id}`).then((response) => {
      setRecommendedBook(response)
    })
  }
  const {t} = useTranslation()
  useEffect(() => {
    getRecommendedBooks();
  }, [])
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  let index = 1;
  const componentRef = useRef({})
  return (
    <TableComponent ref={componentRef}>
            <ButtonC variant='download' onClick={handlePrint} style={{float: 'left', marginBottom: '15px', backgroundColor: 'red', color: '#fff'}}>
        PDF <FontAwesomeIcon style={{color: '#fff'}} icon={faFilePdf} />
      </ButtonC>
      <PdfHeader title={t('report.g4.title')} />
  <thead>
  <tr>
    <th>№</th>
       <th>{t('report.g1.book-name')}</th>
       <th>{t('report.g1.book-author')}</th>
       <th>{t('report.g1.book_grades')}</th>
       <th>{t('report.d10.district.student_count')}</th>
       <th>{t('report.g1.book-class-language')}</th>
       <th>{t('report.g1.total-books')}</th>
       <th>{t('report.d10.secondHead.given')}</th>
       <th>{t('report.g1.remaining-books')}</th>
       <th>{t('report.g4.a_lack_of')}</th>
   </tr>
  </thead>
  <tbody>
    {recommendedBook?.map((rb) => {
     return (
        <tr key={rb?.id}>
          <td  data-label="№">{index++}</td>
        <td data-label="Наименование учебника">{rb?.book_name}</td>
        <td data-label="Автор">{rb?.book_author}</td>
        <td data-label="Классы">{(rb?.book_grades).join(',')}</td>
        <td data-label="Количество учеников	">{rb?.student_count}</td>
        <td data-label="Язык обучения">{transLangFromEng(rb?.book_language)}</td>
        <td data-label="Количество книг">{rb?.total}</td>
        <td data-label="Используются">{rb?.given}</td>
        <td data-label="Осталось">{rb?.remaining}</td>
        <td data-label="Нехватка">{rb?.deficit}</td>
        </tr>
     )
    })}
  </tbody>
    </TableComponent>
  )
}

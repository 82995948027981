import {combineReducers} from '@reduxjs/toolkit';
import menuReducer from './menu-reducer';
import {reducer as appReducer} from './model'
import {reducer as profile} from '../../feature/proflle/model';
import {reducer as teacherReducer} from '../../feature/teacher/model';
import {reducer as lettersReducer} from './letter-reducer'
import {reducer as gradesReducer} from './grade-reducer';
import {reducer as classesReducer} from '../../feature/klass/class-reducer'
import {reducer as schoolEventsReducer} from '../../feature/school-events/school-events-reducer'
import {reducer as schoolNewsReducer} from '../../feature/school-news/school-news-reducer'
import {reducer as schoolEventTypesReducer} from './school-event-type-reducer'
import {reducer as schoolBooksReducer} from '../../feature/school-books/school-books-reducer'
import {reducer as districtsReducer} from './district-reducer'
import {reducer as schoolsReducer} from './schools-reducers';
import {reducer as regionsReducer} from './regions-reducers';
import {reducer as categoriesReducer} from './categories-reducers';
import {reducer as teacherBooksReducer} from '../../feature/teacher/teacher-books/tb-reducer'
import {reducer as classBooksReducer} from '../../feature/class-books/class-book-reducer'
import {reducer as newsReducer} from './news-reducer';
import {reducer as profile2Reducer} from './profileReducer';
import {reducer as BookstoreReducer} from './bookstore-reducer'
import {reducer as fillingReport} from './filling-report-reducer'

export const rootReducer = combineReducers({
	menu: menuReducer,
	app: appReducer,
	profile,
	teachers: teacherReducer,
	letters: lettersReducer,
	schoolEventTypes: schoolEventTypesReducer,
	grades: gradesReducer,
	classes: classesReducer,
	schoolBooks: schoolBooksReducer,
	schoolEvents: schoolEventsReducer,
	schoolNews: schoolNewsReducer,
	districts: districtsReducer,
	schools: schoolsReducer,
	regions: regionsReducer,
	categories: categoriesReducer,
	teacherBooks: teacherBooksReducer,
	classBooks: classBooksReducer,
	news: newsReducer,
	profile2: profile2Reducer,
	bookstore: BookstoreReducer,
	fillingReport: fillingReport,
})

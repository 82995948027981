import {useTranslation} from 'react-i18next'
import styles from '../report.module.css'
export const D10SubHead = () => {
const {t} = useTranslation()
 return (
  <thead className={styles.thead}>
   <tr>
    <th>{t('report.d10.secondHead.grade')}</th>
    <th>{t('report.d10.secondHead.student_count')}</th>
    <th>{t('report.d10.secondHead.subject')}</th>
    <th>{t('report.d10.secondHead.total_in_fond')}</th>
    <th>{t('report.d10.secondHead.given')}</th>
    <th>{t('report.d10.secondHead.deficit')}</th>
    <th>{t('report.d10.secondHead.provided_percent')}</th>
   </tr>
  </thead>
 )
}

import {useTranslation} from 'react-i18next'
import {storage} from '../../service'
import styles from './pdf-header.module.css'
import { format } from 'date-fns'
import { useAppSelector } from "../../hooks";

export const PdfFooterToBalance = ({title, children, order}) => {
	const today = new Date(Date.now()).toLocaleDateString()
	const {t} = useTranslation()

	const ordersDate = new Date(Date(order?.ordered_date))
	const year = ordersDate.toLocaleString("default", { year: "numeric" });
	const month = ordersDate.toLocaleString("default", { month: "2-digit" });
	const day = ordersDate.toLocaleString("default", { day: "2-digit" });
	const formattedDate = day + "-" + month + '-' + year;
	const user = useAppSelector((state) => state.profile.user);
	return (
		<div className={styles.print}>
			<br />
			<p>Настоящим актом подтверждаем правильность внесенных данных</p>
			<br />
			<p>{t('print.director2')} ____________________ {storage.read('director')}</p>
			<br />
			<p>{t('print.librarian')} _______________ {user?.fullname}</p>
			<br />
			<p>М.П.</p>
		</div>
	)
}
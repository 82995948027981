import React, {useState} from "react";
import {AddSchoolBook, SchoolBookItem, SearchSchoolBooks} from "../feature/school-books";
import {useTranslation} from "react-i18next";
import {Layout} from "../app/layout";
import {ButtonC, TableComponent} from "../components";
import {useAppSelector, usePageTitle} from "../hooks";
import './styles.css'
import { isLibrarian } from "../lib/helper";
import { NavLink } from "react-router-dom";


export const BooksFond = () => {
  const {t} = useTranslation()
  usePageTitle(t('school-books.title'))
  const [show, setShow] = useState(false);
  const schoolBooks = useAppSelector((state) => state.schoolBooks.searchResult)


  const handleShow = () => {
    setShow(!show);
  }

  return (
    <Layout>
      <AddSchoolBook
        show={show}
        handleShow={handleShow}
        book={schoolBooks}
      />
      <h2 className="mb-4 book-fond-title">{t('school-books.title')}</h2>
      {/* {isLibrarian &&  <ButtonC
        variant="add"
        className="ms-auto  add-books-btn mb-3 mx-3"
        onClick={handleShow}
      >{t('school-books.add.btn')} </ButtonC>} */}
      <NavLink
            exact
            to="/school-books-application-new"
            //   className={styles.links}
            // activeClassName={styles.active}
            style={{display: 'inline-block', marginRight: '30px'}}
            >
            <ButtonC
        variant="add"
        className="ms-auto  add-books-btn mb-3 mx-3"
        // onClick={handleShow}
      >{t('school-books.addBook.title')}</ButtonC>
      </NavLink>
      <NavLink
        exact
        to="/school-books-application-change"
        //   className={styles.links}
        // activeClassName={styles.active}
        style={{display: 'inline-block', marginRight: '30px'}}
        >
          <ButtonC
            variant="close"
            className="ms-auto  add-books-btn mb-3 mx-3"
            >{t('school-books.changeBook.title')}
          </ButtonC>
      </NavLink>
      <NavLink
        exact
        to="/school-books-application-delete"
        //   className={styles.links}
        // activeClassName={styles.active}
        style={{display: 'inline-block', marginRight: '30px'}}
        >
          <ButtonC
            variant="delete2"
            className="ms-auto  add-books-btn mb-3 mx-3"
            >{t('school-books.deleteBook.title')}
          </ButtonC>
      </NavLink>
      {isLibrarian &&  <NavLink
                    exact
                    to="/school-books-application"
                    //   className={styles.links}
                    // activeClassName={styles.active}
                    style={{display: 'inline-block', marginRight: '30px'}}
                    >
                    <ButtonC
        variant="search"
        className="ms-auto  add-books-btn mb-3 mx-3"
        // onClick={handleShow}
      >{t('school-books.historyBook.title')}</ButtonC>
      </NavLink>}
      <SearchSchoolBooks />
      <TableComponent style={{fontSize: '0.85rem'}} className="book-fond-table">
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{t('school-books.book_author')}</th>
            <th scope="col">{t('school-books.img')}</th>
            <th scope="col">{t('school-books.class')}</th>
            <th scope="col">{t('school-books.year_publish')}</th>
            <th scope="col">{t('school-books.language')}</th>
            <th scope="col" className='noWrap'>{t('school-books.rent')}</th>
            <th scope="col">{t('school-books.book_count')}</th>
            <th scope="col">{t('school-books.issue_book')}</th>
            <th scope="col">{t('school-books.available')}</th>
            <th scope="col" className='noWrap'>{t('school-books.qr')}</th>
          {/* {isLibrarian &&   <th scope="col">{t('school-books.delete')}</th>} */}
            <th scope="col">{t('school-books.to-who-it-is-rent')}</th>
          </tr>
        </thead>
        <tbody>
          {(schoolBooks.length > 0) ? schoolBooks.map((book, index) => (
            <SchoolBookItem
              key={book.id}
              book={book}
              index={index} />
          )) : <div></div>}
        </tbody>
      </TableComponent>
      {/* {isLibrarian && <ButtonC
        variant="add"
        className="ms-auto d-block"
        onClick={handleShow}
      >{t('school-books.add.btn')} </ButtonC>} */}
    </Layout>
  )
}

import { useTranslation } from "react-i18next";
import { replaceString } from "../lib/helper";
import { Layout } from "../app/layout";
import { usePageTitle } from "../hooks";
import { faMobile, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "../hooks";
import { Row, Col } from "react-bootstrap";
import telegram from "../telegram.png";
import { ButtonC } from "../components";
import { PollModal } from "../feature/main-page/poll-modal";
import { useState, useEffect, useRef } from "react";
import "./contacts.css";

export const Contacts = () => {
  const [showPoll, setShowPoll] = useState(false);
  const { t } = useTranslation();
  usePageTitle(t("home.title"));
  const user = useAppSelector((state) => state.profile.user);
  const school = useAppSelector((state) => state.profile.school);
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    const { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00:10");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);

    return deadline;
  };
  const Poll = () => {
    setShowPoll(true);
    timer == "00:00:00" ? <></> : clearTimer(getDeadTime());
  };
  return (
    <Layout>
      <h2 className="home-title">
        {replaceString(user?.fullname)}
        <br /> {t("home.title")}
      </h2>
      <Row>
        <Col>
          <p className="phone-numbers">{t("home.phone-numbers")}</p>
          <div className="phone-numbers-container">
            <p className="whatsapp">
              <a href="https://api.whatsapp.com/send?phone=996706608010&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%2C%20%D1%8F%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B2%D0%BE%D0%B4%D1%83...">
                <img
                  className="icon-size"
                  src="https://cdn-icons-png.flaticon.com/128/733/733585.png"
                />{" "}
                +996 (706) 60 80 10{" "}
              </a>
            </p>
            <p className="numbers">
              <a href="https://api.whatsapp.com/send?phone=996556608010&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%2C%20%D1%8F%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B2%D0%BE%D0%B4%D1%83...">
                <img
                  className="icon-size"
                  src="https://cdn-icons-png.flaticon.com/128/733/733585.png"
                />{" "}
                +996 (556) 60 80 10{" "}
              </a>
            </p>
            <p className="numbers">
              <a href="tel:+996559608010">
                <FontAwesomeIcon
                  icon={faMobile}
                  color="#183153"
                ></FontAwesomeIcon>{" "}
                +996 (559) 60 80 10
              </a>
            </p>
            <p className="numbers">
              <a href="tel:+996558608010">
                <FontAwesomeIcon
                  icon={faMobile}
                  color="#183153"
                ></FontAwesomeIcon>{" "}
                +996 (558) 60 80 10
              </a>
            </p>
            <p className="mail">
              <a href="mailto:okuukitebi.ph@gmail.com">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  color="#183153"
                ></FontAwesomeIcon>{" "}
                okuukitebi.ph@gmail.com
              </a>
            </p>
          </div>
        </Col>
        <Col>
          <h3 className="social-networks">{t("home.social")}</h3>
          <h1>
            {" "}
            <a href="https://www.facebook.com/okuu.kitebi/">
              <img
                className="icon-size"
                src="https://cdn-icons-png.flaticon.com/512/733/733547.png"
              />{" "}
              <span className="social-networks-name">{t("home.facebook")}</span>{" "}
            </a>
          </h1>
          <h1>
            <a href="https://www.instagram.com/okuu.kitebi/">
              <img
                className="icon-size"
                src="https://cdn-icons-png.flaticon.com/512/174/174855.png"
              />
              <span className="social-networks-name">
                {" "}
                {t("home.instagram")}
              </span>
            </a>
          </h1>
          <h1>
            <a href="https://t.me/+ijWta5DohU5iN2My">
              <img className="icon-size" src={telegram} />
              <span className="social-networks-name">
                {" "}
                {t("home.telegram")}
              </span>
            </a>
          </h1>
          <h3 className="subscribe-text">{t("home.subscribe-to-social")}</h3>
        </Col>
      </Row>
    </Layout>
  );
};
